// @ts-strict-ignore

import {
  ComboboxButton,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
  Combobox as HeadlessCombobox,
} from "@headlessui/react"
import { ComponentPropsWithoutRef, ReactElement, useState } from "react"

import { ChevronDownIcon } from "@heroicons/react/solid"
import { TextInput } from "components/TextInput"
import { classNames } from "lib/classNames"

export interface ComboboxProps<T>
  extends Omit<ComponentPropsWithoutRef<"div">, "onChange"> {
  label?: string
  secondaryLabel?: string
  items: T[]
  selectedItem: T | null
  onSelectItem: (item: T | null) => void
  buildItemFromNew?: (newItem: string) => T
  newItemText?: string
  keyExtractor: (item: T) => string
  valueExtractor: (item: T) => string
  invalid?: boolean
  errorMessage?: string
  disabled?: boolean
  placeholder?: string
  onChangeQuery?: (query: string) => void
  autoFocus?: boolean
  hideChevronButton?: boolean
  eq?: (a: T, b: T) => boolean
  isOpenOnFocus?: boolean
  renderItemPrefix?: (a: T) => ReactElement
  optionsClassName?: string
  isDeselectable?: boolean
}

export function Combobox<T>(props: ComboboxProps<T>) {
  const [query, setQuery] = useState("")

  const {
    label,
    secondaryLabel,
    items,
    selectedItem,
    onSelectItem,
    buildItemFromNew = (newItem) => newItem,
    newItemText,
    keyExtractor,
    valueExtractor,
    invalid,
    errorMessage,
    disabled,
    placeholder,
    onChangeQuery,
    autoFocus,
    hideChevronButton = false,
    isOpenOnFocus = false,
    renderItemPrefix,
    eq,
    optionsClassName,
    isDeselectable,
    ...otherProps
  } = props

  const filterFn = onChangeQuery
    ? () => () => true
    : (query) => (item) =>
        valueExtractor(item).toLowerCase().includes(query.toLowerCase())
  const filteredItems = query === "" ? items : items.filter(filterFn(query))

  const isNewItemInList = items
    .map((item) => valueExtractor(item))
    .includes(query)

  return (
    <div {...otherProps}>
      <HeadlessCombobox
        value={selectedItem}
        onChange={onSelectItem}
        disabled={disabled}
        by={eq}
        multiple={false}
        immediate={isOpenOnFocus}
      >
        <ComboboxInput
          as={TextInput}
          label={label}
          secondaryLabel={secondaryLabel}
          onChange={(event) => {
            setQuery(event.target.value)
            if (onChangeQuery) {
              onChangeQuery(event.target.value)
            }
          }}
          displayValue={(item) =>
            item ? valueExtractor(item as unknown as T) : ""
          }
          placeholder={placeholder ?? "Select one"}
          autoFocus={autoFocus}
          errorMessage={errorMessage}
          invalid={invalid}
          className={classNames(
            "block truncate px-4",
            !hideChevronButton && "pr-8"
          )}
          RightView={
            <ComboboxButton
              className={classNames(
                "absolute inset-y-0 right-0 items-center rounded-r-md px-2.5 focus:outline-none",
                hideChevronButton && "hidden"
              )}
            >
              <ChevronDownIcon
                className="h-5 w-5 text-gray-600 dark:text-gray-200"
                aria-hidden="true"
              />
            </ComboboxButton>
          }
        />

        <ComboboxOptions
          anchor={{
            to: "bottom",
            gap: 4,
          }}
          className={classNames(
            "z-10 max-h-60 w-[var(--input-width)] overflow-auto rounded bg-white text-sm shadow-lg ring-1 ring-black ring-opacity-5 [--anchor-max-height:15rem] focus:outline-none dark:bg-gray-800",
            optionsClassName
          )}
          modal={false}
        >
          {filteredItems.length === 0 && query !== "" && !newItemText ? (
            <div className="relative cursor-default select-none px-4 py-2 text-gray-600 dark:text-gray-200">
              No results
            </div>
          ) : (
            <>
              {isDeselectable && (
                <ComboboxOption
                  value={null}
                  className={({ focus, selected }) =>
                    classNames(
                      selected
                        ? "text-black dark:text-white"
                        : "text-gray-600 dark:text-gray-200",
                      selected
                        ? "bg-cobalt-100 dark:bg-cobalt-300"
                        : focus
                          ? "bg-gray-100 dark:bg-gray-900"
                          : "bg-white dark:bg-gray-800",
                      "relative cursor-default select-none px-4 py-2"
                    )
                  }
                >
                  <div className="flex flex-row items-center gap-2">
                    <span className="block truncate text-sm">None</span>
                  </div>
                </ComboboxOption>
              )}
              {filteredItems.map((item) => (
                <ComboboxOption
                  key={keyExtractor(item)}
                  value={item}
                  className={({ focus, selected }) =>
                    classNames(
                      selected
                        ? "text-black dark:text-white"
                        : "text-gray-600 dark:text-gray-200",
                      selected
                        ? "bg-cobalt-100 dark:bg-cobalt-300"
                        : focus
                          ? "bg-gray-100 dark:bg-gray-900"
                          : "bg-white dark:bg-gray-800",
                      "relative cursor-default select-none px-4 py-2"
                    )
                  }
                >
                  <div className="flex flex-row items-center gap-2">
                    {renderItemPrefix && renderItemPrefix(item)}
                    <span className="block truncate text-sm">
                      {valueExtractor(item)}
                    </span>
                  </div>
                </ComboboxOption>
              ))}
            </>
          )}
          {!!newItemText && !isNewItemInList && (
            <ComboboxOption
              value={buildItemFromNew(query)}
              className={({ focus }) =>
                classNames(
                  focus
                    ? "bg-gray-100 dark:bg-gray-900"
                    : "bg-white dark:bg-gray-800",
                  "text-cobalt-400 dark:text-cobalt-200 relative cursor-default select-none px-4 py-2 font-semibold"
                )
              }
            >
              {newItemText} {query}
            </ComboboxOption>
          )}
        </ComboboxOptions>
      </HeadlessCombobox>
    </div>
  )
}
