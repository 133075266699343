/* eslint-disable */
// @generated by protobuf-ts 2.9.0 with parameter generate_dependencies,add_pb_suffix,ts_nocheck,eslint_disable
// @generated from protobuf file "api/vendors/service.proto" (package "rd.api.vendors", syntax proto3)
// tslint:disable
// @ts-nocheck
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { BankPaymentMethod } from "../../payments/models_pb";
import { Contacts } from "../../vendors/models_pb";
import { Vendor } from "../../vendors/models_pb";
import { Address } from "../../proto/address/models_pb";
import { Summary } from "../../bub/models_pb";
import { UUID } from "../../proto/uuid/models_pb";
// Vendor 

/**
 * @generated from protobuf message rd.api.vendors.CreateVendorReq
 */
export interface CreateVendorReq {
    /**
     * @generated from protobuf field: string name = 1;
     */
    name: string;
    /**
     * @generated from protobuf field: string orders_email = 2;
     */
    ordersEmail: string;
    /**
     * @generated from protobuf field: string phone = 3;
     */
    phone: string;
    /**
     * @generated from protobuf field: string notes = 4;
     */
    notes: string;
}
/**
 * @generated from protobuf message rd.api.vendors.CreateVendorRes
 */
export interface CreateVendorRes {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID id = 1;
     */
    id?: UUID;
}
/**
 * @generated from protobuf message rd.api.vendors.BulkUpsertVendorsReq
 */
export interface BulkUpsertVendorsReq {
    /**
     * @generated from protobuf field: string file_url = 1;
     */
    fileUrl: string;
    /**
     * @generated from protobuf field: bool write_changes = 2;
     */
    writeChanges: boolean;
}
/**
 * @generated from protobuf message rd.api.vendors.BulkUpsertVendorsRes
 */
export interface BulkUpsertVendorsRes {
    /**
     * @generated from protobuf field: rd.bub.Summary summary = 1;
     */
    summary?: Summary;
}
/**
 * @generated from protobuf message rd.api.vendors.UpdateVendorInformationReq
 */
export interface UpdateVendorInformationReq {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID id = 1;
     */
    id?: UUID;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: string orders_email = 3;
     */
    ordersEmail: string;
    /**
     * @generated from protobuf field: string phone = 4;
     */
    phone: string;
    /**
     * @generated from protobuf field: rd.proto.address.Address address = 5;
     */
    address?: Address;
}
/**
 * @generated from protobuf message rd.api.vendors.UpdateVendorInformationRes
 */
export interface UpdateVendorInformationRes {
}
/**
 * @generated from protobuf message rd.api.vendors.UpdateVendorNotesReq
 */
export interface UpdateVendorNotesReq {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID id = 1;
     */
    id?: UUID;
    /**
     * @generated from protobuf field: string notes = 2;
     */
    notes: string;
}
/**
 * @generated from protobuf message rd.api.vendors.UpdateVendorNotesRes
 */
export interface UpdateVendorNotesRes {
}
/**
 * @generated from protobuf message rd.api.vendors.UpdateVendorIsActiveReq
 */
export interface UpdateVendorIsActiveReq {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID id = 1;
     */
    id?: UUID;
    /**
     * @generated from protobuf field: bool active = 2;
     */
    active: boolean;
}
/**
 * @generated from protobuf message rd.api.vendors.UpdateVendorIsActiveRes
 */
export interface UpdateVendorIsActiveRes {
}
/**
 * @generated from protobuf message rd.api.vendors.GetVendorReq
 */
export interface GetVendorReq {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID id = 1;
     */
    id?: UUID;
}
/**
 * @generated from protobuf message rd.api.vendors.GetVendorRes
 */
export interface GetVendorRes {
    /**
     * @generated from protobuf field: rd.vendors.Vendor vendor = 1;
     */
    vendor?: Vendor;
    /**
     * @generated from protobuf field: rd.vendors.Contacts contacts = 2;
     */
    contacts?: Contacts;
    /**
     * @generated from protobuf field: rd.payments.BankPaymentMethod bank_payment_method = 3;
     */
    bankPaymentMethod?: BankPaymentMethod;
}
/**
 * @generated from protobuf message rd.api.vendors.ListVendorsForCompanyReq
 */
export interface ListVendorsForCompanyReq {
    /**
     * @generated from protobuf field: bool filter_active = 1;
     */
    filterActive: boolean;
}
/**
 * @generated from protobuf message rd.api.vendors.ListVendorsForCompanyRes
 */
export interface ListVendorsForCompanyRes {
    /**
     * @generated from protobuf field: repeated rd.vendors.Vendor vendors = 1;
     */
    vendors: Vendor[];
}
/**
 * @generated from protobuf message rd.api.vendors.SaveVendorBankPaymentMethodReq
 */
export interface SaveVendorBankPaymentMethodReq {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID vendor_id = 1;
     */
    vendorId?: UUID;
    /**
     * @generated from protobuf field: string stripe_payment_method_id = 2;
     */
    stripePaymentMethodId: string;
}
/**
 * @generated from protobuf message rd.api.vendors.SaveVendorBankPaymentMethodRes
 */
export interface SaveVendorBankPaymentMethodRes {
}
/**
 * @generated from protobuf message rd.api.vendors.DeleteVendorBankPaymentMethodReq
 */
export interface DeleteVendorBankPaymentMethodReq {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID vendor_id = 1;
     */
    vendorId?: UUID;
}
/**
 * @generated from protobuf message rd.api.vendors.DeleteVendorBankPaymentMethodRes
 */
export interface DeleteVendorBankPaymentMethodRes {
}
/**
 * @generated from protobuf message rd.api.vendors.CreateVendorWithBankAccountReq
 */
export interface CreateVendorWithBankAccountReq {
    /**
     * @generated from protobuf field: string vendor_name = 1;
     */
    vendorName: string;
    /**
     * @generated from protobuf field: string stripe_payment_method_id = 2;
     */
    stripePaymentMethodId: string;
}
/**
 * @generated from protobuf message rd.api.vendors.CreateVendorWithBankAccountRes
 */
export interface CreateVendorWithBankAccountRes {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID id = 1;
     */
    id?: UUID;
}
/**
 * @generated from protobuf message rd.api.vendors.UpsertVendorBankAccountReq
 */
export interface UpsertVendorBankAccountReq {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID vendor_id = 1;
     */
    vendorId?: UUID;
    /**
     * @generated from protobuf field: string stripe_payment_method_id = 2;
     */
    stripePaymentMethodId: string;
}
/**
 * @generated from protobuf message rd.api.vendors.UpsertVendorBankAccountRes
 */
export interface UpsertVendorBankAccountRes {
}
/**
 * @generated from protobuf message rd.api.vendors.UpdateVendorAccountsByLocationReq
 */
export interface UpdateVendorAccountsByLocationReq {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID id = 1;
     */
    id?: UUID;
    /**
     * @generated from protobuf field: map<string, string> accounts_by_location_id = 2;
     */
    accountsByLocationId: {
        [key: string]: string;
    };
}
/**
 * @generated from protobuf message rd.api.vendors.UpdateVendorAccountsByLocationRes
 */
export interface UpdateVendorAccountsByLocationRes {
}
// Vendor Contact 

/**
 * @generated from protobuf message rd.api.vendors.CreateVendorContactReq
 */
export interface CreateVendorContactReq {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID vendor_id = 1;
     */
    vendorId?: UUID;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: string phone = 3;
     */
    phone: string;
    /**
     * @generated from protobuf field: string email = 4;
     */
    email: string;
    /**
     * @generated from protobuf field: string notes = 5;
     */
    notes: string;
}
/**
 * @generated from protobuf message rd.api.vendors.CreateVendorContactRes
 */
export interface CreateVendorContactRes {
    /**
     * @generated from protobuf field: string id_string = 1;
     */
    idString: string;
}
/**
 * @generated from protobuf message rd.api.vendors.UpdateVendorContactReq
 */
export interface UpdateVendorContactReq {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID vendor_id = 1;
     */
    vendorId?: UUID;
    /**
     * @generated from protobuf field: string id_string = 2;
     */
    idString: string;
    /**
     * @generated from protobuf field: string name = 3;
     */
    name: string;
    /**
     * @generated from protobuf field: string phone = 4;
     */
    phone: string;
    /**
     * @generated from protobuf field: string email = 5;
     */
    email: string;
    /**
     * @generated from protobuf field: string notes = 6;
     */
    notes: string;
}
/**
 * @generated from protobuf message rd.api.vendors.UpdateVendorContactRes
 */
export interface UpdateVendorContactRes {
}
/**
 * @generated from protobuf message rd.api.vendors.DeactivateVendorContactReq
 */
export interface DeactivateVendorContactReq {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID vendor_id = 1;
     */
    vendorId?: UUID;
    /**
     * @generated from protobuf field: string id_string = 2;
     */
    idString: string;
}
/**
 * @generated from protobuf message rd.api.vendors.DeactivateVendorContactRes
 */
export interface DeactivateVendorContactRes {
}
/**
 * @generated from protobuf message rd.api.vendors.DuplicateVendorReq
 */
export interface DuplicateVendorReq {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID vendor_id = 1;
     */
    vendorId?: UUID;
}
/**
 * @generated from protobuf message rd.api.vendors.DuplicateVendorRes
 */
export interface DuplicateVendorRes {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID vendor_id = 1;
     */
    vendorId?: UUID;
}
// @generated message type with reflection information, may provide speed optimized methods
class CreateVendorReq$Type extends MessageType<CreateVendorReq> {
    constructor() {
        super("rd.api.vendors.CreateVendorReq", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "rd.validator.require": true } },
            { no: 2, name: "orders_email", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "rd.validator.is_email": true } },
            { no: 3, name: "phone", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "rd.validator.is_phone": true } },
            { no: 4, name: "notes", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<CreateVendorReq>): CreateVendorReq {
        const message = { name: "", ordersEmail: "", phone: "", notes: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CreateVendorReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateVendorReq): CreateVendorReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string name */ 1:
                    message.name = reader.string();
                    break;
                case /* string orders_email */ 2:
                    message.ordersEmail = reader.string();
                    break;
                case /* string phone */ 3:
                    message.phone = reader.string();
                    break;
                case /* string notes */ 4:
                    message.notes = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CreateVendorReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string name = 1; */
        if (message.name !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.name);
        /* string orders_email = 2; */
        if (message.ordersEmail !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.ordersEmail);
        /* string phone = 3; */
        if (message.phone !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.phone);
        /* string notes = 4; */
        if (message.notes !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.notes);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.vendors.CreateVendorReq
 */
export const CreateVendorReq = new CreateVendorReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateVendorRes$Type extends MessageType<CreateVendorRes> {
    constructor() {
        super("rd.api.vendors.CreateVendorRes", [
            { no: 1, name: "id", kind: "message", T: () => UUID }
        ]);
    }
    create(value?: PartialMessage<CreateVendorRes>): CreateVendorRes {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CreateVendorRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateVendorRes): CreateVendorRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID id */ 1:
                    message.id = UUID.internalBinaryRead(reader, reader.uint32(), options, message.id);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CreateVendorRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID id = 1; */
        if (message.id)
            UUID.internalBinaryWrite(message.id, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.vendors.CreateVendorRes
 */
export const CreateVendorRes = new CreateVendorRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BulkUpsertVendorsReq$Type extends MessageType<BulkUpsertVendorsReq> {
    constructor() {
        super("rd.api.vendors.BulkUpsertVendorsReq", [
            { no: 1, name: "file_url", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "rd.validator.require": true } },
            { no: 2, name: "write_changes", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<BulkUpsertVendorsReq>): BulkUpsertVendorsReq {
        const message = { fileUrl: "", writeChanges: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<BulkUpsertVendorsReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BulkUpsertVendorsReq): BulkUpsertVendorsReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string file_url */ 1:
                    message.fileUrl = reader.string();
                    break;
                case /* bool write_changes */ 2:
                    message.writeChanges = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BulkUpsertVendorsReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string file_url = 1; */
        if (message.fileUrl !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.fileUrl);
        /* bool write_changes = 2; */
        if (message.writeChanges !== false)
            writer.tag(2, WireType.Varint).bool(message.writeChanges);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.vendors.BulkUpsertVendorsReq
 */
export const BulkUpsertVendorsReq = new BulkUpsertVendorsReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BulkUpsertVendorsRes$Type extends MessageType<BulkUpsertVendorsRes> {
    constructor() {
        super("rd.api.vendors.BulkUpsertVendorsRes", [
            { no: 1, name: "summary", kind: "message", T: () => Summary }
        ]);
    }
    create(value?: PartialMessage<BulkUpsertVendorsRes>): BulkUpsertVendorsRes {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<BulkUpsertVendorsRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BulkUpsertVendorsRes): BulkUpsertVendorsRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.bub.Summary summary */ 1:
                    message.summary = Summary.internalBinaryRead(reader, reader.uint32(), options, message.summary);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BulkUpsertVendorsRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.bub.Summary summary = 1; */
        if (message.summary)
            Summary.internalBinaryWrite(message.summary, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.vendors.BulkUpsertVendorsRes
 */
export const BulkUpsertVendorsRes = new BulkUpsertVendorsRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateVendorInformationReq$Type extends MessageType<UpdateVendorInformationReq> {
    constructor() {
        super("rd.api.vendors.UpdateVendorInformationReq", [
            { no: 1, name: "id", kind: "message", T: () => UUID, options: { "rd.validator.require": true } },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "rd.validator.require": true } },
            { no: 3, name: "orders_email", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "rd.validator.is_email": true } },
            { no: 4, name: "phone", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "rd.validator.is_phone": true } },
            { no: 5, name: "address", kind: "message", T: () => Address }
        ]);
    }
    create(value?: PartialMessage<UpdateVendorInformationReq>): UpdateVendorInformationReq {
        const message = { name: "", ordersEmail: "", phone: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateVendorInformationReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateVendorInformationReq): UpdateVendorInformationReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID id */ 1:
                    message.id = UUID.internalBinaryRead(reader, reader.uint32(), options, message.id);
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* string orders_email */ 3:
                    message.ordersEmail = reader.string();
                    break;
                case /* string phone */ 4:
                    message.phone = reader.string();
                    break;
                case /* rd.proto.address.Address address */ 5:
                    message.address = Address.internalBinaryRead(reader, reader.uint32(), options, message.address);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateVendorInformationReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID id = 1; */
        if (message.id)
            UUID.internalBinaryWrite(message.id, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* string orders_email = 3; */
        if (message.ordersEmail !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.ordersEmail);
        /* string phone = 4; */
        if (message.phone !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.phone);
        /* rd.proto.address.Address address = 5; */
        if (message.address)
            Address.internalBinaryWrite(message.address, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.vendors.UpdateVendorInformationReq
 */
export const UpdateVendorInformationReq = new UpdateVendorInformationReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateVendorInformationRes$Type extends MessageType<UpdateVendorInformationRes> {
    constructor() {
        super("rd.api.vendors.UpdateVendorInformationRes", []);
    }
    create(value?: PartialMessage<UpdateVendorInformationRes>): UpdateVendorInformationRes {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateVendorInformationRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateVendorInformationRes): UpdateVendorInformationRes {
        return target ?? this.create();
    }
    internalBinaryWrite(message: UpdateVendorInformationRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.vendors.UpdateVendorInformationRes
 */
export const UpdateVendorInformationRes = new UpdateVendorInformationRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateVendorNotesReq$Type extends MessageType<UpdateVendorNotesReq> {
    constructor() {
        super("rd.api.vendors.UpdateVendorNotesReq", [
            { no: 1, name: "id", kind: "message", T: () => UUID, options: { "rd.validator.require": true } },
            { no: 2, name: "notes", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<UpdateVendorNotesReq>): UpdateVendorNotesReq {
        const message = { notes: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateVendorNotesReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateVendorNotesReq): UpdateVendorNotesReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID id */ 1:
                    message.id = UUID.internalBinaryRead(reader, reader.uint32(), options, message.id);
                    break;
                case /* string notes */ 2:
                    message.notes = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateVendorNotesReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID id = 1; */
        if (message.id)
            UUID.internalBinaryWrite(message.id, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string notes = 2; */
        if (message.notes !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.notes);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.vendors.UpdateVendorNotesReq
 */
export const UpdateVendorNotesReq = new UpdateVendorNotesReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateVendorNotesRes$Type extends MessageType<UpdateVendorNotesRes> {
    constructor() {
        super("rd.api.vendors.UpdateVendorNotesRes", []);
    }
    create(value?: PartialMessage<UpdateVendorNotesRes>): UpdateVendorNotesRes {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateVendorNotesRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateVendorNotesRes): UpdateVendorNotesRes {
        return target ?? this.create();
    }
    internalBinaryWrite(message: UpdateVendorNotesRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.vendors.UpdateVendorNotesRes
 */
export const UpdateVendorNotesRes = new UpdateVendorNotesRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateVendorIsActiveReq$Type extends MessageType<UpdateVendorIsActiveReq> {
    constructor() {
        super("rd.api.vendors.UpdateVendorIsActiveReq", [
            { no: 1, name: "id", kind: "message", T: () => UUID, options: { "rd.validator.require": true } },
            { no: 2, name: "active", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<UpdateVendorIsActiveReq>): UpdateVendorIsActiveReq {
        const message = { active: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateVendorIsActiveReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateVendorIsActiveReq): UpdateVendorIsActiveReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID id */ 1:
                    message.id = UUID.internalBinaryRead(reader, reader.uint32(), options, message.id);
                    break;
                case /* bool active */ 2:
                    message.active = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateVendorIsActiveReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID id = 1; */
        if (message.id)
            UUID.internalBinaryWrite(message.id, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* bool active = 2; */
        if (message.active !== false)
            writer.tag(2, WireType.Varint).bool(message.active);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.vendors.UpdateVendorIsActiveReq
 */
export const UpdateVendorIsActiveReq = new UpdateVendorIsActiveReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateVendorIsActiveRes$Type extends MessageType<UpdateVendorIsActiveRes> {
    constructor() {
        super("rd.api.vendors.UpdateVendorIsActiveRes", []);
    }
    create(value?: PartialMessage<UpdateVendorIsActiveRes>): UpdateVendorIsActiveRes {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateVendorIsActiveRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateVendorIsActiveRes): UpdateVendorIsActiveRes {
        return target ?? this.create();
    }
    internalBinaryWrite(message: UpdateVendorIsActiveRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.vendors.UpdateVendorIsActiveRes
 */
export const UpdateVendorIsActiveRes = new UpdateVendorIsActiveRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetVendorReq$Type extends MessageType<GetVendorReq> {
    constructor() {
        super("rd.api.vendors.GetVendorReq", [
            { no: 1, name: "id", kind: "message", T: () => UUID, options: { "rd.validator.require": true } }
        ]);
    }
    create(value?: PartialMessage<GetVendorReq>): GetVendorReq {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetVendorReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetVendorReq): GetVendorReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID id */ 1:
                    message.id = UUID.internalBinaryRead(reader, reader.uint32(), options, message.id);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetVendorReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID id = 1; */
        if (message.id)
            UUID.internalBinaryWrite(message.id, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.vendors.GetVendorReq
 */
export const GetVendorReq = new GetVendorReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetVendorRes$Type extends MessageType<GetVendorRes> {
    constructor() {
        super("rd.api.vendors.GetVendorRes", [
            { no: 1, name: "vendor", kind: "message", T: () => Vendor },
            { no: 2, name: "contacts", kind: "message", T: () => Contacts },
            { no: 3, name: "bank_payment_method", kind: "message", T: () => BankPaymentMethod }
        ]);
    }
    create(value?: PartialMessage<GetVendorRes>): GetVendorRes {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetVendorRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetVendorRes): GetVendorRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.vendors.Vendor vendor */ 1:
                    message.vendor = Vendor.internalBinaryRead(reader, reader.uint32(), options, message.vendor);
                    break;
                case /* rd.vendors.Contacts contacts */ 2:
                    message.contacts = Contacts.internalBinaryRead(reader, reader.uint32(), options, message.contacts);
                    break;
                case /* rd.payments.BankPaymentMethod bank_payment_method */ 3:
                    message.bankPaymentMethod = BankPaymentMethod.internalBinaryRead(reader, reader.uint32(), options, message.bankPaymentMethod);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetVendorRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.vendors.Vendor vendor = 1; */
        if (message.vendor)
            Vendor.internalBinaryWrite(message.vendor, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.vendors.Contacts contacts = 2; */
        if (message.contacts)
            Contacts.internalBinaryWrite(message.contacts, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* rd.payments.BankPaymentMethod bank_payment_method = 3; */
        if (message.bankPaymentMethod)
            BankPaymentMethod.internalBinaryWrite(message.bankPaymentMethod, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.vendors.GetVendorRes
 */
export const GetVendorRes = new GetVendorRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListVendorsForCompanyReq$Type extends MessageType<ListVendorsForCompanyReq> {
    constructor() {
        super("rd.api.vendors.ListVendorsForCompanyReq", [
            { no: 1, name: "filter_active", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<ListVendorsForCompanyReq>): ListVendorsForCompanyReq {
        const message = { filterActive: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ListVendorsForCompanyReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListVendorsForCompanyReq): ListVendorsForCompanyReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool filter_active */ 1:
                    message.filterActive = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListVendorsForCompanyReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool filter_active = 1; */
        if (message.filterActive !== false)
            writer.tag(1, WireType.Varint).bool(message.filterActive);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.vendors.ListVendorsForCompanyReq
 */
export const ListVendorsForCompanyReq = new ListVendorsForCompanyReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListVendorsForCompanyRes$Type extends MessageType<ListVendorsForCompanyRes> {
    constructor() {
        super("rd.api.vendors.ListVendorsForCompanyRes", [
            { no: 1, name: "vendors", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Vendor }
        ]);
    }
    create(value?: PartialMessage<ListVendorsForCompanyRes>): ListVendorsForCompanyRes {
        const message = { vendors: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ListVendorsForCompanyRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListVendorsForCompanyRes): ListVendorsForCompanyRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated rd.vendors.Vendor vendors */ 1:
                    message.vendors.push(Vendor.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListVendorsForCompanyRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated rd.vendors.Vendor vendors = 1; */
        for (let i = 0; i < message.vendors.length; i++)
            Vendor.internalBinaryWrite(message.vendors[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.vendors.ListVendorsForCompanyRes
 */
export const ListVendorsForCompanyRes = new ListVendorsForCompanyRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SaveVendorBankPaymentMethodReq$Type extends MessageType<SaveVendorBankPaymentMethodReq> {
    constructor() {
        super("rd.api.vendors.SaveVendorBankPaymentMethodReq", [
            { no: 1, name: "vendor_id", kind: "message", T: () => UUID, options: { "rd.validator.require": true } },
            { no: 2, name: "stripe_payment_method_id", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "rd.validator.require": true } }
        ]);
    }
    create(value?: PartialMessage<SaveVendorBankPaymentMethodReq>): SaveVendorBankPaymentMethodReq {
        const message = { stripePaymentMethodId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SaveVendorBankPaymentMethodReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SaveVendorBankPaymentMethodReq): SaveVendorBankPaymentMethodReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID vendor_id */ 1:
                    message.vendorId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.vendorId);
                    break;
                case /* string stripe_payment_method_id */ 2:
                    message.stripePaymentMethodId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SaveVendorBankPaymentMethodReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID vendor_id = 1; */
        if (message.vendorId)
            UUID.internalBinaryWrite(message.vendorId, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string stripe_payment_method_id = 2; */
        if (message.stripePaymentMethodId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.stripePaymentMethodId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.vendors.SaveVendorBankPaymentMethodReq
 */
export const SaveVendorBankPaymentMethodReq = new SaveVendorBankPaymentMethodReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SaveVendorBankPaymentMethodRes$Type extends MessageType<SaveVendorBankPaymentMethodRes> {
    constructor() {
        super("rd.api.vendors.SaveVendorBankPaymentMethodRes", []);
    }
    create(value?: PartialMessage<SaveVendorBankPaymentMethodRes>): SaveVendorBankPaymentMethodRes {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SaveVendorBankPaymentMethodRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SaveVendorBankPaymentMethodRes): SaveVendorBankPaymentMethodRes {
        return target ?? this.create();
    }
    internalBinaryWrite(message: SaveVendorBankPaymentMethodRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.vendors.SaveVendorBankPaymentMethodRes
 */
export const SaveVendorBankPaymentMethodRes = new SaveVendorBankPaymentMethodRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeleteVendorBankPaymentMethodReq$Type extends MessageType<DeleteVendorBankPaymentMethodReq> {
    constructor() {
        super("rd.api.vendors.DeleteVendorBankPaymentMethodReq", [
            { no: 1, name: "vendor_id", kind: "message", T: () => UUID, options: { "rd.validator.require": true } }
        ]);
    }
    create(value?: PartialMessage<DeleteVendorBankPaymentMethodReq>): DeleteVendorBankPaymentMethodReq {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DeleteVendorBankPaymentMethodReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeleteVendorBankPaymentMethodReq): DeleteVendorBankPaymentMethodReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID vendor_id */ 1:
                    message.vendorId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.vendorId);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DeleteVendorBankPaymentMethodReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID vendor_id = 1; */
        if (message.vendorId)
            UUID.internalBinaryWrite(message.vendorId, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.vendors.DeleteVendorBankPaymentMethodReq
 */
export const DeleteVendorBankPaymentMethodReq = new DeleteVendorBankPaymentMethodReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeleteVendorBankPaymentMethodRes$Type extends MessageType<DeleteVendorBankPaymentMethodRes> {
    constructor() {
        super("rd.api.vendors.DeleteVendorBankPaymentMethodRes", []);
    }
    create(value?: PartialMessage<DeleteVendorBankPaymentMethodRes>): DeleteVendorBankPaymentMethodRes {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DeleteVendorBankPaymentMethodRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeleteVendorBankPaymentMethodRes): DeleteVendorBankPaymentMethodRes {
        return target ?? this.create();
    }
    internalBinaryWrite(message: DeleteVendorBankPaymentMethodRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.vendors.DeleteVendorBankPaymentMethodRes
 */
export const DeleteVendorBankPaymentMethodRes = new DeleteVendorBankPaymentMethodRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateVendorWithBankAccountReq$Type extends MessageType<CreateVendorWithBankAccountReq> {
    constructor() {
        super("rd.api.vendors.CreateVendorWithBankAccountReq", [
            { no: 1, name: "vendor_name", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "rd.validator.require": true } },
            { no: 2, name: "stripe_payment_method_id", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "rd.validator.require": true } }
        ]);
    }
    create(value?: PartialMessage<CreateVendorWithBankAccountReq>): CreateVendorWithBankAccountReq {
        const message = { vendorName: "", stripePaymentMethodId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CreateVendorWithBankAccountReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateVendorWithBankAccountReq): CreateVendorWithBankAccountReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string vendor_name */ 1:
                    message.vendorName = reader.string();
                    break;
                case /* string stripe_payment_method_id */ 2:
                    message.stripePaymentMethodId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CreateVendorWithBankAccountReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string vendor_name = 1; */
        if (message.vendorName !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.vendorName);
        /* string stripe_payment_method_id = 2; */
        if (message.stripePaymentMethodId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.stripePaymentMethodId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.vendors.CreateVendorWithBankAccountReq
 */
export const CreateVendorWithBankAccountReq = new CreateVendorWithBankAccountReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateVendorWithBankAccountRes$Type extends MessageType<CreateVendorWithBankAccountRes> {
    constructor() {
        super("rd.api.vendors.CreateVendorWithBankAccountRes", [
            { no: 1, name: "id", kind: "message", T: () => UUID }
        ]);
    }
    create(value?: PartialMessage<CreateVendorWithBankAccountRes>): CreateVendorWithBankAccountRes {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CreateVendorWithBankAccountRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateVendorWithBankAccountRes): CreateVendorWithBankAccountRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID id */ 1:
                    message.id = UUID.internalBinaryRead(reader, reader.uint32(), options, message.id);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CreateVendorWithBankAccountRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID id = 1; */
        if (message.id)
            UUID.internalBinaryWrite(message.id, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.vendors.CreateVendorWithBankAccountRes
 */
export const CreateVendorWithBankAccountRes = new CreateVendorWithBankAccountRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpsertVendorBankAccountReq$Type extends MessageType<UpsertVendorBankAccountReq> {
    constructor() {
        super("rd.api.vendors.UpsertVendorBankAccountReq", [
            { no: 1, name: "vendor_id", kind: "message", T: () => UUID, options: { "rd.validator.require": true } },
            { no: 2, name: "stripe_payment_method_id", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "rd.validator.require": true } }
        ]);
    }
    create(value?: PartialMessage<UpsertVendorBankAccountReq>): UpsertVendorBankAccountReq {
        const message = { stripePaymentMethodId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpsertVendorBankAccountReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpsertVendorBankAccountReq): UpsertVendorBankAccountReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID vendor_id */ 1:
                    message.vendorId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.vendorId);
                    break;
                case /* string stripe_payment_method_id */ 2:
                    message.stripePaymentMethodId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpsertVendorBankAccountReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID vendor_id = 1; */
        if (message.vendorId)
            UUID.internalBinaryWrite(message.vendorId, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string stripe_payment_method_id = 2; */
        if (message.stripePaymentMethodId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.stripePaymentMethodId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.vendors.UpsertVendorBankAccountReq
 */
export const UpsertVendorBankAccountReq = new UpsertVendorBankAccountReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpsertVendorBankAccountRes$Type extends MessageType<UpsertVendorBankAccountRes> {
    constructor() {
        super("rd.api.vendors.UpsertVendorBankAccountRes", []);
    }
    create(value?: PartialMessage<UpsertVendorBankAccountRes>): UpsertVendorBankAccountRes {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpsertVendorBankAccountRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpsertVendorBankAccountRes): UpsertVendorBankAccountRes {
        return target ?? this.create();
    }
    internalBinaryWrite(message: UpsertVendorBankAccountRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.vendors.UpsertVendorBankAccountRes
 */
export const UpsertVendorBankAccountRes = new UpsertVendorBankAccountRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateVendorAccountsByLocationReq$Type extends MessageType<UpdateVendorAccountsByLocationReq> {
    constructor() {
        super("rd.api.vendors.UpdateVendorAccountsByLocationReq", [
            { no: 1, name: "id", kind: "message", T: () => UUID, options: { "rd.validator.require": true } },
            { no: 2, name: "accounts_by_location_id", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "scalar", T: 9 /*ScalarType.STRING*/ }, options: { "rd.validator.require": true } }
        ]);
    }
    create(value?: PartialMessage<UpdateVendorAccountsByLocationReq>): UpdateVendorAccountsByLocationReq {
        const message = { accountsByLocationId: {} };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateVendorAccountsByLocationReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateVendorAccountsByLocationReq): UpdateVendorAccountsByLocationReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID id */ 1:
                    message.id = UUID.internalBinaryRead(reader, reader.uint32(), options, message.id);
                    break;
                case /* map<string, string> accounts_by_location_id */ 2:
                    this.binaryReadMap2(message.accountsByLocationId, reader, options);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap2(map: UpdateVendorAccountsByLocationReq["accountsByLocationId"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof UpdateVendorAccountsByLocationReq["accountsByLocationId"] | undefined, val: UpdateVendorAccountsByLocationReq["accountsByLocationId"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = reader.string();
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.api.vendors.UpdateVendorAccountsByLocationReq.accounts_by_location_id");
            }
        }
        map[key ?? ""] = val ?? "";
    }
    internalBinaryWrite(message: UpdateVendorAccountsByLocationReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID id = 1; */
        if (message.id)
            UUID.internalBinaryWrite(message.id, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* map<string, string> accounts_by_location_id = 2; */
        for (let k of Object.keys(message.accountsByLocationId))
            writer.tag(2, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k).tag(2, WireType.LengthDelimited).string(message.accountsByLocationId[k]).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.vendors.UpdateVendorAccountsByLocationReq
 */
export const UpdateVendorAccountsByLocationReq = new UpdateVendorAccountsByLocationReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateVendorAccountsByLocationRes$Type extends MessageType<UpdateVendorAccountsByLocationRes> {
    constructor() {
        super("rd.api.vendors.UpdateVendorAccountsByLocationRes", []);
    }
    create(value?: PartialMessage<UpdateVendorAccountsByLocationRes>): UpdateVendorAccountsByLocationRes {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateVendorAccountsByLocationRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateVendorAccountsByLocationRes): UpdateVendorAccountsByLocationRes {
        return target ?? this.create();
    }
    internalBinaryWrite(message: UpdateVendorAccountsByLocationRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.vendors.UpdateVendorAccountsByLocationRes
 */
export const UpdateVendorAccountsByLocationRes = new UpdateVendorAccountsByLocationRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateVendorContactReq$Type extends MessageType<CreateVendorContactReq> {
    constructor() {
        super("rd.api.vendors.CreateVendorContactReq", [
            { no: 1, name: "vendor_id", kind: "message", T: () => UUID, options: { "rd.validator.require": true } },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "rd.validator.require": true } },
            { no: 3, name: "phone", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "rd.validator.is_phone": true } },
            { no: 4, name: "email", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "rd.validator.is_email": true } },
            { no: 5, name: "notes", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<CreateVendorContactReq>): CreateVendorContactReq {
        const message = { name: "", phone: "", email: "", notes: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CreateVendorContactReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateVendorContactReq): CreateVendorContactReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID vendor_id */ 1:
                    message.vendorId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.vendorId);
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* string phone */ 3:
                    message.phone = reader.string();
                    break;
                case /* string email */ 4:
                    message.email = reader.string();
                    break;
                case /* string notes */ 5:
                    message.notes = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CreateVendorContactReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID vendor_id = 1; */
        if (message.vendorId)
            UUID.internalBinaryWrite(message.vendorId, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* string phone = 3; */
        if (message.phone !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.phone);
        /* string email = 4; */
        if (message.email !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.email);
        /* string notes = 5; */
        if (message.notes !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.notes);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.vendors.CreateVendorContactReq
 */
export const CreateVendorContactReq = new CreateVendorContactReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateVendorContactRes$Type extends MessageType<CreateVendorContactRes> {
    constructor() {
        super("rd.api.vendors.CreateVendorContactRes", [
            { no: 1, name: "id_string", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<CreateVendorContactRes>): CreateVendorContactRes {
        const message = { idString: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CreateVendorContactRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateVendorContactRes): CreateVendorContactRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id_string */ 1:
                    message.idString = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CreateVendorContactRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id_string = 1; */
        if (message.idString !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.idString);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.vendors.CreateVendorContactRes
 */
export const CreateVendorContactRes = new CreateVendorContactRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateVendorContactReq$Type extends MessageType<UpdateVendorContactReq> {
    constructor() {
        super("rd.api.vendors.UpdateVendorContactReq", [
            { no: 1, name: "vendor_id", kind: "message", T: () => UUID, options: { "rd.validator.require": true } },
            { no: 2, name: "id_string", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "rd.validator.require": true } },
            { no: 3, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "rd.validator.require": true } },
            { no: 4, name: "phone", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "rd.validator.is_phone": true } },
            { no: 5, name: "email", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "rd.validator.is_email": true } },
            { no: 6, name: "notes", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<UpdateVendorContactReq>): UpdateVendorContactReq {
        const message = { idString: "", name: "", phone: "", email: "", notes: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateVendorContactReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateVendorContactReq): UpdateVendorContactReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID vendor_id */ 1:
                    message.vendorId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.vendorId);
                    break;
                case /* string id_string */ 2:
                    message.idString = reader.string();
                    break;
                case /* string name */ 3:
                    message.name = reader.string();
                    break;
                case /* string phone */ 4:
                    message.phone = reader.string();
                    break;
                case /* string email */ 5:
                    message.email = reader.string();
                    break;
                case /* string notes */ 6:
                    message.notes = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateVendorContactReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID vendor_id = 1; */
        if (message.vendorId)
            UUID.internalBinaryWrite(message.vendorId, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string id_string = 2; */
        if (message.idString !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.idString);
        /* string name = 3; */
        if (message.name !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.name);
        /* string phone = 4; */
        if (message.phone !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.phone);
        /* string email = 5; */
        if (message.email !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.email);
        /* string notes = 6; */
        if (message.notes !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.notes);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.vendors.UpdateVendorContactReq
 */
export const UpdateVendorContactReq = new UpdateVendorContactReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateVendorContactRes$Type extends MessageType<UpdateVendorContactRes> {
    constructor() {
        super("rd.api.vendors.UpdateVendorContactRes", []);
    }
    create(value?: PartialMessage<UpdateVendorContactRes>): UpdateVendorContactRes {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateVendorContactRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateVendorContactRes): UpdateVendorContactRes {
        return target ?? this.create();
    }
    internalBinaryWrite(message: UpdateVendorContactRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.vendors.UpdateVendorContactRes
 */
export const UpdateVendorContactRes = new UpdateVendorContactRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeactivateVendorContactReq$Type extends MessageType<DeactivateVendorContactReq> {
    constructor() {
        super("rd.api.vendors.DeactivateVendorContactReq", [
            { no: 1, name: "vendor_id", kind: "message", T: () => UUID, options: { "rd.validator.require": true } },
            { no: 2, name: "id_string", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "rd.validator.require": true } }
        ]);
    }
    create(value?: PartialMessage<DeactivateVendorContactReq>): DeactivateVendorContactReq {
        const message = { idString: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DeactivateVendorContactReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeactivateVendorContactReq): DeactivateVendorContactReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID vendor_id */ 1:
                    message.vendorId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.vendorId);
                    break;
                case /* string id_string */ 2:
                    message.idString = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DeactivateVendorContactReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID vendor_id = 1; */
        if (message.vendorId)
            UUID.internalBinaryWrite(message.vendorId, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string id_string = 2; */
        if (message.idString !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.idString);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.vendors.DeactivateVendorContactReq
 */
export const DeactivateVendorContactReq = new DeactivateVendorContactReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeactivateVendorContactRes$Type extends MessageType<DeactivateVendorContactRes> {
    constructor() {
        super("rd.api.vendors.DeactivateVendorContactRes", []);
    }
    create(value?: PartialMessage<DeactivateVendorContactRes>): DeactivateVendorContactRes {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DeactivateVendorContactRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeactivateVendorContactRes): DeactivateVendorContactRes {
        return target ?? this.create();
    }
    internalBinaryWrite(message: DeactivateVendorContactRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.vendors.DeactivateVendorContactRes
 */
export const DeactivateVendorContactRes = new DeactivateVendorContactRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DuplicateVendorReq$Type extends MessageType<DuplicateVendorReq> {
    constructor() {
        super("rd.api.vendors.DuplicateVendorReq", [
            { no: 1, name: "vendor_id", kind: "message", T: () => UUID, options: { "rd.validator.require": true } }
        ]);
    }
    create(value?: PartialMessage<DuplicateVendorReq>): DuplicateVendorReq {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DuplicateVendorReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DuplicateVendorReq): DuplicateVendorReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID vendor_id */ 1:
                    message.vendorId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.vendorId);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DuplicateVendorReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID vendor_id = 1; */
        if (message.vendorId)
            UUID.internalBinaryWrite(message.vendorId, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.vendors.DuplicateVendorReq
 */
export const DuplicateVendorReq = new DuplicateVendorReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DuplicateVendorRes$Type extends MessageType<DuplicateVendorRes> {
    constructor() {
        super("rd.api.vendors.DuplicateVendorRes", [
            { no: 1, name: "vendor_id", kind: "message", T: () => UUID }
        ]);
    }
    create(value?: PartialMessage<DuplicateVendorRes>): DuplicateVendorRes {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DuplicateVendorRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DuplicateVendorRes): DuplicateVendorRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID vendor_id */ 1:
                    message.vendorId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.vendorId);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DuplicateVendorRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID vendor_id = 1; */
        if (message.vendorId)
            UUID.internalBinaryWrite(message.vendorId, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.vendors.DuplicateVendorRes
 */
export const DuplicateVendorRes = new DuplicateVendorRes$Type();
/**
 * @generated ServiceType for protobuf service rd.api.vendors.VendorsService
 */
export const VendorsService = new ServiceType("rd.api.vendors.VendorsService", [
    { name: "CreateVendor", options: {}, I: CreateVendorReq, O: CreateVendorRes },
    { name: "BulkUpsertVendors", options: {}, I: BulkUpsertVendorsReq, O: BulkUpsertVendorsRes },
    { name: "UpdateVendorInformation", options: {}, I: UpdateVendorInformationReq, O: UpdateVendorInformationRes },
    { name: "UpdateVendorNotes", options: {}, I: UpdateVendorNotesReq, O: UpdateVendorNotesRes },
    { name: "UpdateVendorIsActive", options: {}, I: UpdateVendorIsActiveReq, O: UpdateVendorIsActiveRes },
    { name: "GetVendor", options: {}, I: GetVendorReq, O: GetVendorRes },
    { name: "ListVendorsForCompany", options: {}, I: ListVendorsForCompanyReq, O: ListVendorsForCompanyRes },
    { name: "SaveVendorBankPaymentMethod", options: {}, I: SaveVendorBankPaymentMethodReq, O: SaveVendorBankPaymentMethodRes },
    { name: "DeleteVendorBankPaymentMethod", options: {}, I: DeleteVendorBankPaymentMethodReq, O: DeleteVendorBankPaymentMethodRes },
    { name: "CreateVendorWithBankAccount", options: {}, I: CreateVendorWithBankAccountReq, O: CreateVendorWithBankAccountRes },
    { name: "UpsertVendorBankAccount", options: {}, I: UpsertVendorBankAccountReq, O: UpsertVendorBankAccountRes },
    { name: "UpdateVendorAccountsByLocation", options: {}, I: UpdateVendorAccountsByLocationReq, O: UpdateVendorAccountsByLocationRes },
    { name: "DuplicateVendor", options: {}, I: DuplicateVendorReq, O: DuplicateVendorRes },
    { name: "CreateVendorContact", options: {}, I: CreateVendorContactReq, O: CreateVendorContactRes },
    { name: "UpdateVendorContact", options: {}, I: UpdateVendorContactReq, O: UpdateVendorContactRes },
    { name: "DeactivateVendorContact", options: {}, I: DeactivateVendorContactReq, O: DeactivateVendorContactRes }
]);
