import { BaseQueryFn, createApi } from "@reduxjs/toolkit/query/react"
import type { RpcError, UnaryCall } from "@protobuf-ts/runtime-rpc"
import {
  TwirpErrorCode,
  TwirpFetchTransport,
} from "@protobuf-ts/twirp-transport"

import { Any } from "gen/google/protobuf/any_pb"
import { SerializedError } from "@reduxjs/toolkit"
import { base64decode } from "@protobuf-ts/runtime"
import { stringify } from "lib/stringify"

export const transport = new TwirpFetchTransport({
  baseUrl: `${
    process.env.NEXT_PUBLIC_RUNDOO_API_BASE_URL ?? "http://localhost:8080"
  }/twirp`,
  fetchInit: { credentials: "include" },
  sendJson: true,
  jsonOptions: { ignoreUnknownFields: true },
})

export type ApiError = Pick<
  RpcError,
  "message" | "meta" | "methodName" | "serviceName"
> & {
  code: keyof typeof TwirpErrorCode
  protoError?: Any
}

export type RtkError = ApiError | SerializedError
export function getApiErrorStatusMessage(
  error: RtkError | undefined,
  defaultMessage: string
): string {
  if (error && isApiErrorWithStatusMessage(error)) {
    return error.meta.statusMessage
  }

  return defaultMessage
}

export function isApiError(error: unknown): error is ApiError {
  return (
    !!error &&
    (error as ApiError).code !== undefined &&
    (error as ApiError).message !== undefined
  )
}

export type ApiErrorWithStatusMessage = Pick<RpcError, "message" | "meta"> & {
  code: keyof typeof TwirpErrorCode
  protoError?: Any
  meta: { statusMessage: string }
}

export function isApiErrorWithStatusMessage(
  error: RtkError
): error is ApiErrorWithStatusMessage {
  return (
    (error as ApiError).code !== undefined &&
    (error as ApiError).message !== undefined &&
    (error as ApiError).meta !== undefined &&
    (error as ApiError).meta.statusMessage !== undefined &&
    typeof (error as ApiError).meta.statusMessage === "string"
  )
}

export type BaseQuery = BaseQueryFn<UnaryCall, unknown, ApiError>

const customBaseQuery: BaseQuery = async (fn) => {
  try {
    const result = await fn
    return { data: result.response }
  } catch (error) {
    const { message, code, meta } = error as ApiError

    if (meta?.protoAnyError) {
      return {
        error: parseProtoError({ message, code, meta }),
      }
    }

    return {
      error: { message, code, meta },
    }
  }
}

// This apiSlice should remain empty. Leverage apiSlice.enhanceEndpoints and apiSlice.injectEndpoints
// from other files in this directory to add new endpoints.
export const apiSlice = createApi({
  reducerPath: "api",
  baseQuery: customBaseQuery,
  endpoints: () => ({}),
  serializeQueryArgs: ({ queryArgs, endpointName }) =>
    `${endpointName}(${stringify(queryArgs)})`,
})

// exported for testing
export const parseProtoError = (error: ApiError): ApiError => {
  const { message, code, meta } = error
  const b64 = base64decode(String(meta.protoAnyError))
  const protoError = Any.fromBinary(b64)
  return { message, code, meta, protoError }
}
