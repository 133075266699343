// @ts-strict-ignore
import {
  GetOrderShipmentRes,
  GetOrderShipmentRes_OrderShipmentProduct as OrderShipmentProduct,
} from "gen/api/order/service_pb"
import { PayloadAction, createSlice } from "@reduxjs/toolkit"

import { AppState } from "app/store"
import { OrderShipmentForm } from "./types/OrderForm"
import { UUID } from "gen/proto/uuid/models_pb"

export default interface OrderShipmentFormState extends OrderShipmentForm {
  initialState?: OrderShipmentForm

  // Auxiliary
  id?: UUID
  financialTransactionId?: UUID
  externalTransactionId?: UUID
}

const initialState: OrderShipmentFormState = {}

export const orderShipmentSlice = createSlice({
  name: "orderShipment",
  initialState,
  reducers: {
    setOrderShipmentInitialState: (
      state,
      action: PayloadAction<GetOrderShipmentRes>
    ) => {
      state.initialState = {
        orderShipmentIdentifier: action.payload.orderShipment?.identifier,
        status: action.payload.orderShipment?.status,
        order: action.payload.order,
        location: action.payload.location,
        products: action.payload.products
          ? [...action.payload.products].sort(
              (a: OrderShipmentProduct, b: OrderShipmentProduct) =>
                a?.product?.position - b?.product?.position
            )
          : undefined,
        vendor: action.payload.vendor,
        total: action.payload.total,
        subtotal: action.payload.subtotal,
        relatedOrderShipments: action.payload.relatedOrderShipments,
        additionalFeesByName: action.payload.additionalFeesByName,
        costAdjustments: action.payload.orderShipment?.costAdjustments,
      }
      state.orderShipmentIdentifier = action.payload.orderShipment?.identifier
      state.status = action.payload.orderShipment?.status
      state.costAdjustments = action.payload.orderShipment?.costAdjustments
      state.receiveNotes = action.payload.orderShipment?.notes
      state.orderNotes = action.payload.order?.orderNotes
      state.order = action.payload.order
      state.location = action.payload.location
      state.products = action.payload.products
        ? [...action.payload.products].sort(
            (a: OrderShipmentProduct, b: OrderShipmentProduct) =>
              a?.product?.position - b?.product?.position
          )
        : undefined
      state.vendor = action.payload.vendor
      state.total = action.payload.total
      state.subtotal = action.payload.subtotal
      state.relatedOrderShipments = action.payload.relatedOrderShipments
      state.additionalFeesByName = action.payload.additionalFeesByName

      // Auxiliary
      state.id = action.payload.orderShipment?.id
      state.financialTransactionId =
        action.payload.orderShipment?.financialTransactionId
      state.externalTransactionId =
        action.payload.orderShipment?.externalTransactionId
    },
  },
})

export const { setOrderShipmentInitialState } = orderShipmentSlice.actions

export const selectOrderShipmentId = (state: AppState) =>
  state.orderShipments.id
export const selectOrderShipmentIdentifier = (state: AppState) =>
  state.orderShipments.orderShipmentIdentifier
export const selectOrderNotes = (state: AppState) =>
  state.orderShipments.orderNotes
export const selectReceiveNotes = (state: AppState) =>
  state.orderShipments.receiveNotes
export const selectOrderShipmentOrder = (state: AppState) =>
  state.orderShipments.order
export const selectOrderShipmentVendor = (state: AppState) =>
  state.orderShipments.vendor
export const selectOrderShipmentProducts = (state: AppState) =>
  state.orderShipments.products
export const selectOrderShipmentLocation = (state: AppState) =>
  state.orderShipments.location
export const selectOrderShipmentTotal = (state: AppState) =>
  state.orderShipments.total
export const selectOrderShipmentSubtotal = (state: AppState) =>
  state.orderShipments.subtotal
export const selectOrderShipmentAdditionalFeesByName = (state: AppState) =>
  state.orderShipments.additionalFeesByName
export const selectOrderShipmentRelatedOrderShipments = (state: AppState) =>
  state.orderShipments.relatedOrderShipments
export const selectOrderShipmentCostAdjustments = (state: AppState) =>
  state.orderShipments.costAdjustments
export const selectOrderShipmentStatus = (state: AppState) =>
  state.orderShipments.status
export const selectOrderShipmentFinancialTransactionId = (state: AppState) =>
  state.orderShipments.financialTransactionId
