/* eslint-disable */
// @generated by protobuf-ts 2.9.0 with parameter generate_dependencies,add_pb_suffix,ts_nocheck,eslint_disable
// @generated from protobuf file "company/models.proto" (package "rd.company", syntax proto3)
// tslint:disable
// @ts-nocheck
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { Address } from "../proto/address/models_pb";
import { SearchSize } from "../config/models_pb";
import { Money } from "../proto/money/models_pb";
import { Decimal } from "../proto/decimal/models_pb";
import { Timestamp } from "../google/protobuf/timestamp_pb";
import { UUID } from "../proto/uuid/models_pb";
/**
 * Company represents a "company" a.k.a. "client" from our perspective.
 *
 * @generated from protobuf message rd.company.Company
 */
export interface Company {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID id = 1;
     */
    id?: UUID;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp created_at = 2;
     */
    createdAt?: Timestamp;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp updated_at = 3;
     */
    updatedAt?: Timestamp;
    /**
     * @generated from protobuf field: string name = 4;
     */
    name: string;
    /**
     * @generated from protobuf field: string subdomain = 5;
     */
    subdomain: string;
    /**
     * @generated from protobuf field: int32 pos_inactivity_timeout_minutes = 6;
     */
    posInactivityTimeoutMinutes: number;
    /**
     * @generated from protobuf field: string reply_to_email = 7;
     */
    replyToEmail: string;
    /**
     * @generated from protobuf field: int32 default_quote_expiry_days = 8;
     */
    defaultQuoteExpiryDays: number;
    /**
     * For every credit card charge we process on behalf of a company, there is
     * an associated fee that consists of 1) a percentage of the charge amount &
     * 2) a fixed amount. For example, 2.9% + $0.05. We have contracts with each
     * of our clients that lock in those values. credit_card_transaction_fee_rate
     * is the percentage divided by 100 and credit_card_transaction_fee is the
     * fixed amount.
     *
     * @generated from protobuf field: rd.proto.decimal.Decimal credit_card_transaction_fee_rate = 10;
     */
    creditCardTransactionFeeRate?: Decimal;
    /**
     * @generated from protobuf field: rd.proto.money.Money credit_card_transaction_fee = 11;
     */
    creditCardTransactionFee?: Money;
    /**
     * @generated from protobuf field: string logo_image_url = 12;
     */
    logoImageUrl: string;
    /**
     * @generated from protobuf field: string return_policy = 13;
     */
    returnPolicy: string;
    /**
     * @generated from protobuf field: string website_url = 14;
     */
    websiteUrl: string;
    /**
     * @generated from protobuf field: bool autolock_after_checkout = 15;
     */
    autolockAfterCheckout: boolean;
    /**
     * @generated from protobuf field: bool is_benjamin_moore_supplier = 16;
     */
    isBenjaminMooreSupplier: boolean;
    /**
     * @generated from protobuf field: bool should_auto_submit_ccp_rewards = 17;
     */
    shouldAutoSubmitCcpRewards: boolean;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID default_location_id = 18;
     */
    defaultLocationId?: UUID;
    /**
     * @generated from protobuf field: rd.company.Company.StatementDay statement_day = 19;
     */
    statementDay: Company_StatementDay;
    /**
     * @generated from protobuf field: bool is_active = 20;
     */
    isActive: boolean;
    /**
     * @generated from protobuf field: bool should_auto_submit_contractor_rewards = 21;
     */
    shouldAutoSubmitContractorRewards: boolean;
    /**
     * @generated from protobuf field: string contractor_rewards_bonus_code = 22;
     */
    contractorRewardsBonusCode: string;
    /**
     * @generated from protobuf field: string benjamin_moore_retailer_id = 23;
     */
    benjaminMooreRetailerId: string;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp activated_at = 24;
     */
    activatedAt?: Timestamp;
    /**
     * @generated from protobuf field: string statement_message = 27;
     */
    statementMessage: string;
    /**
     * @generated from protobuf field: bool is_discount_shown_to_customers = 29;
     */
    isDiscountShownToCustomers: boolean;
    /**
     * @generated from protobuf field: rd.proto.decimal.Decimal card_surcharge_rate = 30;
     */
    cardSurchargeRate?: Decimal;
    /**
     * @generated from protobuf field: bool is_color_formula_hidden = 31;
     */
    isColorFormulaHidden: boolean;
    /**
     * @generated from protobuf field: bool is_price_shown_on_will_call = 32;
     */
    isPriceShownOnWillCall: boolean;
    /**
     * @generated from protobuf field: bool is_loyalty_program_enabled = 33;
     */
    isLoyaltyProgramEnabled: boolean;
    /**
     * @generated from protobuf field: string loyalty_program_policy = 34;
     */
    loyaltyProgramPolicy: string;
    /**
     * @generated from protobuf field: bool should_auto_sign_out_after_sale = 35;
     */
    shouldAutoSignOutAfterSale: boolean;
    /**
     * @generated from protobuf field: rd.config.SearchSize search_size = 36;
     */
    searchSize: SearchSize;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID default_entity_id = 37;
     */
    defaultEntityId?: UUID;
    /**
     * @generated from protobuf field: bool is_card_surcharge_tax_exempt = 38;
     */
    isCardSurchargeTaxExempt: boolean;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID default_product_category_id = 39;
     */
    defaultProductCategoryId?: UUID;
}
/**
 * @generated from protobuf enum rd.company.Company.StatementDay
 */
export enum Company_StatementDay {
    /**
     * @generated from protobuf enum value: STATEMENT_DAY_UNDEFINED = 0;
     */
    UNDEFINED = 0,
    /**
     * @generated from protobuf enum value: STATEMENT_DAY_ONE = 1;
     */
    ONE = 1,
    /**
     * @generated from protobuf enum value: STATEMENT_DAY_TWO = 2;
     */
    TWO = 2,
    /**
     * @generated from protobuf enum value: STATEMENT_DAY_THREE = 3;
     */
    THREE = 3,
    /**
     * @generated from protobuf enum value: STATEMENT_DAY_FOUR = 4;
     */
    FOUR = 4,
    /**
     * @generated from protobuf enum value: STATEMENT_DAY_FIVE = 5;
     */
    FIVE = 5,
    /**
     * @generated from protobuf enum value: STATEMENT_DAY_SIX = 6;
     */
    SIX = 6,
    /**
     * @generated from protobuf enum value: STATEMENT_DAY_SEVEN = 7;
     */
    SEVEN = 7,
    /**
     * @generated from protobuf enum value: STATEMENT_DAY_EIGHT = 8;
     */
    EIGHT = 8,
    /**
     * @generated from protobuf enum value: STATEMENT_DAY_NINE = 9;
     */
    NINE = 9,
    /**
     * @generated from protobuf enum value: STATEMENT_DAY_TEN = 10;
     */
    TEN = 10,
    /**
     * @generated from protobuf enum value: STATEMENT_DAY_ELEVEN = 11;
     */
    ELEVEN = 11,
    /**
     * @generated from protobuf enum value: STATEMENT_DAY_TWELVE = 12;
     */
    TWELVE = 12,
    /**
     * @generated from protobuf enum value: STATEMENT_DAY_THIRTEEN = 13;
     */
    THIRTEEN = 13,
    /**
     * @generated from protobuf enum value: STATEMENT_DAY_FOURTEEN = 14;
     */
    FOURTEEN = 14,
    /**
     * @generated from protobuf enum value: STATEMENT_DAY_FIFTEEN = 15;
     */
    FIFTEEN = 15,
    /**
     * @generated from protobuf enum value: STATEMENT_DAY_SIXTEEN = 16;
     */
    SIXTEEN = 16,
    /**
     * @generated from protobuf enum value: STATEMENT_DAY_SEVENTEEN = 17;
     */
    SEVENTEEN = 17,
    /**
     * @generated from protobuf enum value: STATEMENT_DAY_EIGHTEEN = 18;
     */
    EIGHTEEN = 18,
    /**
     * @generated from protobuf enum value: STATEMENT_DAY_NINETEEN = 19;
     */
    NINETEEN = 19,
    /**
     * @generated from protobuf enum value: STATEMENT_DAY_TWENTY = 20;
     */
    TWENTY = 20,
    /**
     * @generated from protobuf enum value: STATEMENT_DAY_TWENTYONE = 21;
     */
    TWENTYONE = 21,
    /**
     * @generated from protobuf enum value: STATEMENT_DAY_TWENTYTWO = 22;
     */
    TWENTYTWO = 22,
    /**
     * @generated from protobuf enum value: STATEMENT_DAY_TWENTYTHREE = 23;
     */
    TWENTYTHREE = 23,
    /**
     * @generated from protobuf enum value: STATEMENT_DAY_TWENTYFOUR = 24;
     */
    TWENTYFOUR = 24,
    /**
     * @generated from protobuf enum value: STATEMENT_DAY_TWENTYFIVE = 25;
     */
    TWENTYFIVE = 25,
    /**
     * @generated from protobuf enum value: STATEMENT_DAY_TWENTYSIX = 26;
     */
    TWENTYSIX = 26,
    /**
     * @generated from protobuf enum value: STATEMENT_DAY_TWENTYSEVEN = 27;
     */
    TWENTYSEVEN = 27,
    /**
     * @generated from protobuf enum value: STATEMENT_DAY_TWENTYEIGHT = 28;
     */
    TWENTYEIGHT = 28,
    /**
     * @generated from protobuf enum value: STATEMENT_DAY_END_OF_MONTH = 29;
     */
    END_OF_MONTH = 29
}
/**
 * Location represents a Company store location.
 *
 * @generated from protobuf message rd.company.Location
 */
export interface Location {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID id = 1;
     */
    id?: UUID;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp created_at = 2;
     */
    createdAt?: Timestamp;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp updated_at = 3;
     */
    updatedAt?: Timestamp;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID company_id = 4;
     */
    companyId?: UUID;
    /**
     * @generated from protobuf field: string prefix = 5;
     */
    prefix: string;
    /**
     * @generated from protobuf field: string name = 6;
     */
    name: string;
    /**
     * @generated from protobuf field: string phone = 7;
     */
    phone: string;
    /**
     * @generated from protobuf field: rd.proto.address.Address address = 8;
     */
    address?: Address;
    /**
     * @generated from protobuf field: bool is_active = 9;
     */
    isActive: boolean;
    /**
     * @generated from protobuf field: string time_zone = 10;
     */
    timeZone: string;
    /**
     * @generated from protobuf field: string stripe_location_id = 11;
     */
    stripeLocationId: string;
    /**
     * @generated from protobuf field: rd.proto.decimal.Decimal sales_tax_rate = 12;
     */
    salesTaxRate?: Decimal;
    /**
     * @generated from protobuf field: string bm_outlet_number = 13;
     */
    bmOutletNumber: string;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID entity_id = 15;
     */
    entityId?: UUID;
}
/**
 * Staff represents Company staff members.
 *
 * @generated from protobuf message rd.company.Staff
 */
export interface Staff {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID id = 1;
     */
    id?: UUID;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp created_at = 2;
     */
    createdAt?: Timestamp;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp updated_at = 3;
     */
    updatedAt?: Timestamp;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID company_id = 4;
     */
    companyId?: UUID;
    /**
     * @generated from protobuf field: string first_name = 5;
     */
    firstName: string;
    /**
     * @generated from protobuf field: string last_name = 6;
     */
    lastName: string;
    /**
     * @generated from protobuf field: string phone = 7;
     */
    phone: string;
    /**
     * @generated from protobuf field: bool is_active = 8;
     */
    isActive: boolean;
    /**
     * @generated from protobuf field: string email = 9;
     */
    email: string;
    /**
     * @generated from protobuf field: rd.proto.address.Address address = 10;
     */
    address?: Address;
    /**
     * @generated from protobuf field: string stytch_user_id = 11;
     */
    stytchUserId: string;
    /**
     * @generated from protobuf field: string code = 12;
     */
    code: string;
    /**
     * @generated from protobuf field: repeated string role_ids = 13;
     */
    roleIds: string[];
    /**
     * @generated from protobuf field: string stripe_issuing_cardholder_id = 14;
     */
    stripeIssuingCardholderId: string;
    /**
     * @generated from protobuf field: repeated string webauthn_pubkey_ids = 15;
     */
    webauthnPubkeyIds: string[];
}
/**
 * PricingTiers represents a company's pricing tiers (comprised of a name).
 * that determines the price customers will be charged for a product.
 *
 * @generated from protobuf message rd.company.PricingTiers
 */
export interface PricingTiers {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID id = 1;
     */
    id?: UUID; // company id
    /**
     * @generated from protobuf field: google.protobuf.Timestamp created_at = 2;
     */
    createdAt?: Timestamp;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp updated_at = 3;
     */
    updatedAt?: Timestamp;
    /**
     * @generated from protobuf field: string default_id_string = 4;
     */
    defaultIdString: string;
    /**
     * @generated from protobuf field: map<string, rd.company.PricingTiers.Tier> tiers_by_id = 5;
     */
    tiersById: {
        [key: string]: PricingTiers_Tier;
    };
}
/**
 * @generated from protobuf message rd.company.PricingTiers.Tier
 */
export interface PricingTiers_Tier {
    /**
     * @generated from protobuf field: string id_string = 1;
     */
    idString: string;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: int32 rank = 3;
     */
    rank: number; // 1-indexed
}
/**
 * PricingTiersLog represents a change of a PricingTiers.
 *
 * @generated from protobuf message rd.company.PricingTiersLog
 */
export interface PricingTiersLog {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID id = 1;
     */
    id?: UUID;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp created_at = 2;
     */
    createdAt?: Timestamp;
    /**
     * @generated from protobuf field: rd.company.PricingTiers pricing_tiers = 3;
     */
    pricingTiers?: PricingTiers;
}
/**
 * FinancingTerms represents a company's financing terms (comprised of a
 * reference date, number of due days, and discount) that determines when
 * payment for a Sale will be due.
 *
 * @generated from protobuf message rd.company.FinancingTerms
 */
export interface FinancingTerms {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID id = 1;
     */
    id?: UUID; // company id
    /**
     * @generated from protobuf field: google.protobuf.Timestamp created_at = 2;
     */
    createdAt?: Timestamp;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp updated_at = 3;
     */
    updatedAt?: Timestamp;
    /**
     * @generated from protobuf field: string default_id_string = 4;
     */
    defaultIdString: string;
    /**
     * @generated from protobuf field: map<string, rd.company.FinancingTerms.Term> terms_by_id = 5;
     */
    termsById: {
        [key: string]: FinancingTerms_Term;
    };
}
/**
 * @generated from protobuf message rd.company.FinancingTerms.Term
 */
export interface FinancingTerms_Term {
    /**
     * @generated from protobuf field: string id_string = 1;
     */
    idString: string;
    /**
     * @generated from protobuf field: rd.company.FinancingTerms.Term.ReferenceDate reference_date = 2;
     */
    referenceDate: FinancingTerms_Term_ReferenceDate;
    /**
     * @generated from protobuf field: int32 due_days = 3;
     */
    dueDays: number;
    /**
     * @generated from protobuf field: int32 discount_days = 4;
     */
    discountDays: number;
    /**
     * @generated from protobuf field: rd.proto.decimal.Decimal discount_rate = 5;
     */
    discountRate?: Decimal;
    /**
     * @generated from protobuf field: int32 position = 6;
     */
    position: number; // 1-indexed
}
/**
 * @generated from protobuf enum rd.company.FinancingTerms.Term.ReferenceDate
 */
export enum FinancingTerms_Term_ReferenceDate {
    /**
     * @generated from protobuf enum value: REFERENCE_DATE_UNDEFINED = 0;
     */
    UNDEFINED = 0,
    /**
     * @generated from protobuf enum value: REFERENCE_DATE_INVOICE = 1;
     */
    INVOICE = 1,
    /**
     * @generated from protobuf enum value: REFERENCE_DATE_STATEMENT = 2;
     */
    STATEMENT = 2,
    /**
     * @generated from protobuf enum value: REFERENCE_DATE_END_OF_MONTH = 3;
     */
    END_OF_MONTH = 3
}
/**
 * FinancingTermsLog represents a change of a FinancingTerms.
 *
 * @generated from protobuf message rd.company.FinancingTermsLog
 */
export interface FinancingTermsLog {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID id = 1;
     */
    id?: UUID;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp created_at = 2;
     */
    createdAt?: Timestamp;
    /**
     * @generated from protobuf field: rd.company.FinancingTerms financing_terms = 3;
     */
    financingTerms?: FinancingTerms;
}
/**
 * AdditionalFees represents additional fees that a company
 * may optionally assign to a product.
 *
 * @generated from protobuf message rd.company.AdditionalFees
 */
export interface AdditionalFees {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID id = 1;
     */
    id?: UUID; // Company ID
    /**
     * @generated from protobuf field: google.protobuf.Timestamp created_at = 2;
     */
    createdAt?: Timestamp;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp updated_at = 3;
     */
    updatedAt?: Timestamp;
    /**
     * @generated from protobuf field: map<string, rd.company.AdditionalFees.Fee> fees_by_id = 4;
     */
    feesById: {
        [key: string]: AdditionalFees_Fee;
    };
}
/**
 * @generated from protobuf message rd.company.AdditionalFees.Fee
 */
export interface AdditionalFees_Fee {
    /**
     * @generated from protobuf field: string id_string = 1;
     */
    idString: string;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: rd.proto.money.Money fee = 3;
     */
    fee?: Money;
    /**
     * @generated from protobuf field: rd.proto.decimal.Decimal fee_rate = 4;
     */
    feeRate?: Decimal;
    /**
     * @generated from protobuf field: bool is_taxable = 5;
     */
    isTaxable: boolean;
    /**
     * @generated from protobuf field: int32 position = 6;
     */
    position: number; // 1-indexed
}
/**
 * AdditionalFeesLog represents a change of an AdditionalFees.
 *
 * @generated from protobuf message rd.company.AdditionalFeesLog
 */
export interface AdditionalFeesLog {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID id = 1;
     */
    id?: UUID;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp created_at = 2;
     */
    createdAt?: Timestamp;
    /**
     * @generated from protobuf field: rd.company.AdditionalFees additional_fees = 3;
     */
    additionalFees?: AdditionalFees;
}
/**
 * ProductCategory represents a category of products that can be used to group
 * products together.
 *
 * @generated from protobuf message rd.company.ProductCategory
 */
export interface ProductCategory {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID id = 1;
     */
    id?: UUID;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp created_at = 2;
     */
    createdAt?: Timestamp;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp updated_at = 3;
     */
    updatedAt?: Timestamp;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID company_id = 4;
     */
    companyId?: UUID;
    /**
     * @generated from protobuf field: string name = 5;
     */
    name: string;
    /**
     * @generated from protobuf field: rd.company.ProductCategory.Type type = 6;
     */
    type: ProductCategory_Type;
    /**
     * @generated from protobuf field: bool is_active = 7;
     */
    isActive: boolean;
}
/**
 * @generated from protobuf enum rd.company.ProductCategory.Type
 */
export enum ProductCategory_Type {
    /**
     * @generated from protobuf enum value: TYPE_UNDEFINED = 0;
     */
    UNDEFINED = 0,
    /**
     * @generated from protobuf enum value: TYPE_DEPARTMENT = 1;
     */
    DEPARTMENT = 1,
    /**
     * @generated from protobuf enum value: TYPE_CLASS = 2;
     */
    CLASS = 2,
    /**
     * @generated from protobuf enum value: TYPE_FINELINE = 3;
     */
    FINELINE = 3
}
/**
 * Roles represent groups of staff members with api endpoint permissions.
 *
 * @generated from protobuf message rd.company.Roles
 */
export interface Roles {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID id = 1;
     */
    id?: UUID; // Company ID
    /**
     * @generated from protobuf field: map<string, rd.company.Roles.Role> roles_by_id = 2;
     */
    rolesById: {
        [key: string]: Roles_Role;
    };
}
/**
 * @generated from protobuf message rd.company.Roles.Role
 */
export interface Roles_Role {
    /**
     * @generated from protobuf field: string id_string = 1;
     */
    idString: string;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * key is `<twirp service>/<twirp method>`
     *
     * @generated from protobuf field: map<string, bool> permissions = 3;
     */
    permissions: {
        [key: string]: boolean;
    };
    /**
     * @generated from protobuf field: repeated string linked_roles = 4;
     */
    linkedRoles: string[];
    /**
     * @generated from protobuf field: string category = 5;
     */
    category: string; // groups permissions groups together visually in frontend
    /**
     * @generated from protobuf field: string description = 6;
     */
    description: string; // describes permissions group in frontend
    /**
     * @generated from protobuf field: int32 position = 7;
     */
    position: number; // orders permissions groups in frontend
}
/**
 * DooerStaff is a pseudo-staff representing a Dooer.
 *
 * @generated from protobuf message rd.company.DooerStaff
 */
export interface DooerStaff {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID id = 1;
     */
    id?: UUID;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp created_at = 2;
     */
    createdAt?: Timestamp;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp updated_at = 3;
     */
    updatedAt?: Timestamp;
    /**
     * @generated from protobuf field: string phone = 5;
     */
    phone: string;
    /**
     * @generated from protobuf field: string email = 6;
     */
    email: string;
    /**
     * @generated from protobuf field: string stytch_user_id = 7;
     */
    stytchUserId: string;
    /**
     * @generated from protobuf field: string code = 8;
     */
    code: string;
}
/**
 * CashRegister represents a cash register at a company's location.
 *
 * @generated from protobuf message rd.company.CashRegister
 */
export interface CashRegister {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID id = 1;
     */
    id?: UUID; // location id
    /**
     * @generated from protobuf field: google.protobuf.Timestamp created_at = 2;
     */
    createdAt?: Timestamp;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp updated_at = 3;
     */
    updatedAt?: Timestamp;
    /**
     * @generated from protobuf field: rd.proto.money.Money balance = 4;
     */
    balance?: Money;
    /**
     * @generated from protobuf field: rd.proto.money.Money float = 5;
     */
    float?: Money;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID company_id = 6;
     */
    companyId?: UUID;
}
/**
 * CxaUser represents a user of the customer app. A CxaUser is uniquely
 * identified by their phone number and maps to one or more customer.Customers
 * by whether the Customer has a Contact with that phone number. CxaUsers are
 * automatically created the first time a user logs in with a new phone number.
 *
 * @generated from protobuf message rd.company.CxaUser
 */
export interface CxaUser {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID id = 1;
     */
    id?: UUID;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp created_at = 2;
     */
    createdAt?: Timestamp;
    /**
     * @generated from protobuf field: string phone = 3;
     */
    phone: string;
    /**
     * @generated from protobuf field: string stytch_user_id = 4;
     */
    stytchUserId: string;
}
/**
 * TaxRate represents a sales tax rate for a company. This can either be
 * location specific or a custom rate.
 *
 * @generated from protobuf message rd.company.TaxRate
 */
export interface TaxRate {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID id = 1;
     */
    id?: UUID;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp created_at = 2;
     */
    createdAt?: Timestamp;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp updated_at = 3;
     */
    updatedAt?: Timestamp;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID company_id = 4;
     */
    companyId?: UUID;
    /**
     * @generated from protobuf field: string name = 5;
     */
    name: string;
    /**
     * @generated from protobuf field: rd.proto.decimal.Decimal rate = 6;
     */
    rate?: Decimal;
    /**
     * @generated from protobuf field: int32 position = 7;
     */
    position: number; // only used for custom rates as they can be ordered
    /**
     * @generated from protobuf field: bool is_active = 8;
     */
    isActive: boolean;
    /**
     * @generated from protobuf field: rd.company.TaxRate.Type type = 9;
     */
    type: TaxRate_Type;
}
/**
 * @generated from protobuf enum rd.company.TaxRate.Type
 */
export enum TaxRate_Type {
    /**
     * @generated from protobuf enum value: TYPE_UNDEFINED = 0;
     */
    UNDEFINED = 0,
    /**
     * @generated from protobuf enum value: TYPE_CUSTOM_RATE = 1;
     */
    CUSTOM_RATE = 1,
    /**
     * @generated from protobuf enum value: TYPE_LOCATION_RATE = 2;
     */
    LOCATION_RATE = 2
}
/**
 * TaxRateLog represents a change of a TaxRate.
 *
 * @generated from protobuf message rd.company.TaxRateLog
 */
export interface TaxRateLog {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID id = 1;
     */
    id?: UUID;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp created_at = 2;
     */
    createdAt?: Timestamp;
    /**
     * @generated from protobuf field: rd.company.TaxRate tax_rate = 3;
     */
    taxRate?: TaxRate;
}
/**
 * Entity represents a legal business entity
 *
 * @generated from protobuf message rd.company.Entity
 */
export interface Entity {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID id = 1;
     */
    id?: UUID;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID company_id = 2;
     */
    companyId?: UUID;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp created_at = 3;
     */
    createdAt?: Timestamp;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp updated_at = 4;
     */
    updatedAt?: Timestamp;
    /**
     * @generated from protobuf field: string stripe_express_account_id = 5;
     */
    stripeExpressAccountId: string; // deprecated but still in use
    /**
     * @generated from protobuf field: string stripe_custom_account_id = 6;
     */
    stripeCustomAccountId: string;
    /**
     * @generated from protobuf field: string stripe_financial_account_id = 7;
     */
    stripeFinancialAccountId: string;
    /**
     * @generated from protobuf field: string stripe_bank_payment_method_id = 8;
     */
    stripeBankPaymentMethodId: string;
    /**
     * @generated from protobuf field: string name = 9;
     */
    name: string;
}
/**
 * EntityLog represents the state of an Entity after a change
 *
 * @generated from protobuf message rd.company.EntityLog
 */
export interface EntityLog {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID id = 1;
     */
    id?: UUID;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp created_at = 2;
     */
    createdAt?: Timestamp;
    /**
     * @generated from protobuf field: rd.company.Entity entity = 3;
     */
    entity?: Entity;
}
/**
 * FinanceChargeSetting represents a company's Finance Charge that,
 * combined with finance terms, determines how much
 * a customer gets charged for use of credit.
 *
 * @generated from protobuf message rd.company.FinanceChargeSetting
 */
export interface FinanceChargeSetting {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID id = 1;
     */
    id?: UUID;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID company_id = 2;
     */
    companyId?: UUID;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp created_at = 3;
     */
    createdAt?: Timestamp;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp updated_at = 4;
     */
    updatedAt?: Timestamp;
    /**
     * @generated from protobuf field: rd.proto.decimal.Decimal percentage_fee = 5;
     */
    percentageFee?: Decimal; // between 0 and 1
    /**
     * @generated from protobuf field: rd.proto.money.Money min_assessment_balance = 6;
     */
    minAssessmentBalance?: Money; // greater than or equal to 0
    /**
     * @generated from protobuf field: rd.proto.money.Money min_charge_fee = 7;
     */
    minChargeFee?: Money; // greater than or equal to 0
    /**
     * @generated from protobuf field: int32 grace_period_days = 8;
     */
    gracePeriodDays: number; // greater than or equal to 0
    /**
     * @generated from protobuf field: bool is_compounded = 9;
     */
    isCompounded: boolean;
    /**
     * @generated from protobuf field: int32 position = 10;
     */
    position: number; // 1-indexed
    /**
     * @generated from protobuf field: bool is_active = 11;
     */
    isActive: boolean;
    /**
     * @generated from protobuf field: bool is_default = 12;
     */
    isDefault: boolean;
    /**
     * @generated from protobuf field: string name = 13;
     */
    name: string; // unique
}
// @generated message type with reflection information, may provide speed optimized methods
class Company$Type extends MessageType<Company> {
    constructor() {
        super("rd.company.Company", [
            { no: 1, name: "id", kind: "message", T: () => UUID },
            { no: 2, name: "created_at", kind: "message", T: () => Timestamp },
            { no: 3, name: "updated_at", kind: "message", T: () => Timestamp },
            { no: 4, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "subdomain", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "pos_inactivity_timeout_minutes", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 7, name: "reply_to_email", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "default_quote_expiry_days", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 10, name: "credit_card_transaction_fee_rate", kind: "message", T: () => Decimal },
            { no: 11, name: "credit_card_transaction_fee", kind: "message", T: () => Money },
            { no: 12, name: "logo_image_url", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 13, name: "return_policy", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 14, name: "website_url", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 15, name: "autolock_after_checkout", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 16, name: "is_benjamin_moore_supplier", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 17, name: "should_auto_submit_ccp_rewards", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 18, name: "default_location_id", kind: "message", T: () => UUID },
            { no: 19, name: "statement_day", kind: "enum", T: () => ["rd.company.Company.StatementDay", Company_StatementDay, "STATEMENT_DAY_"] },
            { no: 20, name: "is_active", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 21, name: "should_auto_submit_contractor_rewards", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 22, name: "contractor_rewards_bonus_code", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 23, name: "benjamin_moore_retailer_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 24, name: "activated_at", kind: "message", T: () => Timestamp },
            { no: 27, name: "statement_message", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 29, name: "is_discount_shown_to_customers", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 30, name: "card_surcharge_rate", kind: "message", T: () => Decimal },
            { no: 31, name: "is_color_formula_hidden", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 32, name: "is_price_shown_on_will_call", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 33, name: "is_loyalty_program_enabled", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 34, name: "loyalty_program_policy", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 35, name: "should_auto_sign_out_after_sale", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 36, name: "search_size", kind: "enum", T: () => ["rd.config.SearchSize", SearchSize] },
            { no: 37, name: "default_entity_id", kind: "message", T: () => UUID },
            { no: 38, name: "is_card_surcharge_tax_exempt", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 39, name: "default_product_category_id", kind: "message", T: () => UUID }
        ]);
    }
    create(value?: PartialMessage<Company>): Company {
        const message = { name: "", subdomain: "", posInactivityTimeoutMinutes: 0, replyToEmail: "", defaultQuoteExpiryDays: 0, logoImageUrl: "", returnPolicy: "", websiteUrl: "", autolockAfterCheckout: false, isBenjaminMooreSupplier: false, shouldAutoSubmitCcpRewards: false, statementDay: 0, isActive: false, shouldAutoSubmitContractorRewards: false, contractorRewardsBonusCode: "", benjaminMooreRetailerId: "", statementMessage: "", isDiscountShownToCustomers: false, isColorFormulaHidden: false, isPriceShownOnWillCall: false, isLoyaltyProgramEnabled: false, loyaltyProgramPolicy: "", shouldAutoSignOutAfterSale: false, searchSize: 0, isCardSurchargeTaxExempt: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Company>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Company): Company {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID id */ 1:
                    message.id = UUID.internalBinaryRead(reader, reader.uint32(), options, message.id);
                    break;
                case /* google.protobuf.Timestamp created_at */ 2:
                    message.createdAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.createdAt);
                    break;
                case /* google.protobuf.Timestamp updated_at */ 3:
                    message.updatedAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.updatedAt);
                    break;
                case /* string name */ 4:
                    message.name = reader.string();
                    break;
                case /* string subdomain */ 5:
                    message.subdomain = reader.string();
                    break;
                case /* int32 pos_inactivity_timeout_minutes */ 6:
                    message.posInactivityTimeoutMinutes = reader.int32();
                    break;
                case /* string reply_to_email */ 7:
                    message.replyToEmail = reader.string();
                    break;
                case /* int32 default_quote_expiry_days */ 8:
                    message.defaultQuoteExpiryDays = reader.int32();
                    break;
                case /* rd.proto.decimal.Decimal credit_card_transaction_fee_rate */ 10:
                    message.creditCardTransactionFeeRate = Decimal.internalBinaryRead(reader, reader.uint32(), options, message.creditCardTransactionFeeRate);
                    break;
                case /* rd.proto.money.Money credit_card_transaction_fee */ 11:
                    message.creditCardTransactionFee = Money.internalBinaryRead(reader, reader.uint32(), options, message.creditCardTransactionFee);
                    break;
                case /* string logo_image_url */ 12:
                    message.logoImageUrl = reader.string();
                    break;
                case /* string return_policy */ 13:
                    message.returnPolicy = reader.string();
                    break;
                case /* string website_url */ 14:
                    message.websiteUrl = reader.string();
                    break;
                case /* bool autolock_after_checkout */ 15:
                    message.autolockAfterCheckout = reader.bool();
                    break;
                case /* bool is_benjamin_moore_supplier */ 16:
                    message.isBenjaminMooreSupplier = reader.bool();
                    break;
                case /* bool should_auto_submit_ccp_rewards */ 17:
                    message.shouldAutoSubmitCcpRewards = reader.bool();
                    break;
                case /* rd.proto.uuid.UUID default_location_id */ 18:
                    message.defaultLocationId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.defaultLocationId);
                    break;
                case /* rd.company.Company.StatementDay statement_day */ 19:
                    message.statementDay = reader.int32();
                    break;
                case /* bool is_active */ 20:
                    message.isActive = reader.bool();
                    break;
                case /* bool should_auto_submit_contractor_rewards */ 21:
                    message.shouldAutoSubmitContractorRewards = reader.bool();
                    break;
                case /* string contractor_rewards_bonus_code */ 22:
                    message.contractorRewardsBonusCode = reader.string();
                    break;
                case /* string benjamin_moore_retailer_id */ 23:
                    message.benjaminMooreRetailerId = reader.string();
                    break;
                case /* google.protobuf.Timestamp activated_at */ 24:
                    message.activatedAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.activatedAt);
                    break;
                case /* string statement_message */ 27:
                    message.statementMessage = reader.string();
                    break;
                case /* bool is_discount_shown_to_customers */ 29:
                    message.isDiscountShownToCustomers = reader.bool();
                    break;
                case /* rd.proto.decimal.Decimal card_surcharge_rate */ 30:
                    message.cardSurchargeRate = Decimal.internalBinaryRead(reader, reader.uint32(), options, message.cardSurchargeRate);
                    break;
                case /* bool is_color_formula_hidden */ 31:
                    message.isColorFormulaHidden = reader.bool();
                    break;
                case /* bool is_price_shown_on_will_call */ 32:
                    message.isPriceShownOnWillCall = reader.bool();
                    break;
                case /* bool is_loyalty_program_enabled */ 33:
                    message.isLoyaltyProgramEnabled = reader.bool();
                    break;
                case /* string loyalty_program_policy */ 34:
                    message.loyaltyProgramPolicy = reader.string();
                    break;
                case /* bool should_auto_sign_out_after_sale */ 35:
                    message.shouldAutoSignOutAfterSale = reader.bool();
                    break;
                case /* rd.config.SearchSize search_size */ 36:
                    message.searchSize = reader.int32();
                    break;
                case /* rd.proto.uuid.UUID default_entity_id */ 37:
                    message.defaultEntityId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.defaultEntityId);
                    break;
                case /* bool is_card_surcharge_tax_exempt */ 38:
                    message.isCardSurchargeTaxExempt = reader.bool();
                    break;
                case /* rd.proto.uuid.UUID default_product_category_id */ 39:
                    message.defaultProductCategoryId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.defaultProductCategoryId);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Company, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID id = 1; */
        if (message.id)
            UUID.internalBinaryWrite(message.id, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp created_at = 2; */
        if (message.createdAt)
            Timestamp.internalBinaryWrite(message.createdAt, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp updated_at = 3; */
        if (message.updatedAt)
            Timestamp.internalBinaryWrite(message.updatedAt, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* string name = 4; */
        if (message.name !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.name);
        /* string subdomain = 5; */
        if (message.subdomain !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.subdomain);
        /* int32 pos_inactivity_timeout_minutes = 6; */
        if (message.posInactivityTimeoutMinutes !== 0)
            writer.tag(6, WireType.Varint).int32(message.posInactivityTimeoutMinutes);
        /* string reply_to_email = 7; */
        if (message.replyToEmail !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.replyToEmail);
        /* int32 default_quote_expiry_days = 8; */
        if (message.defaultQuoteExpiryDays !== 0)
            writer.tag(8, WireType.Varint).int32(message.defaultQuoteExpiryDays);
        /* rd.proto.decimal.Decimal credit_card_transaction_fee_rate = 10; */
        if (message.creditCardTransactionFeeRate)
            Decimal.internalBinaryWrite(message.creditCardTransactionFeeRate, writer.tag(10, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money credit_card_transaction_fee = 11; */
        if (message.creditCardTransactionFee)
            Money.internalBinaryWrite(message.creditCardTransactionFee, writer.tag(11, WireType.LengthDelimited).fork(), options).join();
        /* string logo_image_url = 12; */
        if (message.logoImageUrl !== "")
            writer.tag(12, WireType.LengthDelimited).string(message.logoImageUrl);
        /* string return_policy = 13; */
        if (message.returnPolicy !== "")
            writer.tag(13, WireType.LengthDelimited).string(message.returnPolicy);
        /* string website_url = 14; */
        if (message.websiteUrl !== "")
            writer.tag(14, WireType.LengthDelimited).string(message.websiteUrl);
        /* bool autolock_after_checkout = 15; */
        if (message.autolockAfterCheckout !== false)
            writer.tag(15, WireType.Varint).bool(message.autolockAfterCheckout);
        /* bool is_benjamin_moore_supplier = 16; */
        if (message.isBenjaminMooreSupplier !== false)
            writer.tag(16, WireType.Varint).bool(message.isBenjaminMooreSupplier);
        /* bool should_auto_submit_ccp_rewards = 17; */
        if (message.shouldAutoSubmitCcpRewards !== false)
            writer.tag(17, WireType.Varint).bool(message.shouldAutoSubmitCcpRewards);
        /* rd.proto.uuid.UUID default_location_id = 18; */
        if (message.defaultLocationId)
            UUID.internalBinaryWrite(message.defaultLocationId, writer.tag(18, WireType.LengthDelimited).fork(), options).join();
        /* rd.company.Company.StatementDay statement_day = 19; */
        if (message.statementDay !== 0)
            writer.tag(19, WireType.Varint).int32(message.statementDay);
        /* bool is_active = 20; */
        if (message.isActive !== false)
            writer.tag(20, WireType.Varint).bool(message.isActive);
        /* bool should_auto_submit_contractor_rewards = 21; */
        if (message.shouldAutoSubmitContractorRewards !== false)
            writer.tag(21, WireType.Varint).bool(message.shouldAutoSubmitContractorRewards);
        /* string contractor_rewards_bonus_code = 22; */
        if (message.contractorRewardsBonusCode !== "")
            writer.tag(22, WireType.LengthDelimited).string(message.contractorRewardsBonusCode);
        /* string benjamin_moore_retailer_id = 23; */
        if (message.benjaminMooreRetailerId !== "")
            writer.tag(23, WireType.LengthDelimited).string(message.benjaminMooreRetailerId);
        /* google.protobuf.Timestamp activated_at = 24; */
        if (message.activatedAt)
            Timestamp.internalBinaryWrite(message.activatedAt, writer.tag(24, WireType.LengthDelimited).fork(), options).join();
        /* string statement_message = 27; */
        if (message.statementMessage !== "")
            writer.tag(27, WireType.LengthDelimited).string(message.statementMessage);
        /* bool is_discount_shown_to_customers = 29; */
        if (message.isDiscountShownToCustomers !== false)
            writer.tag(29, WireType.Varint).bool(message.isDiscountShownToCustomers);
        /* rd.proto.decimal.Decimal card_surcharge_rate = 30; */
        if (message.cardSurchargeRate)
            Decimal.internalBinaryWrite(message.cardSurchargeRate, writer.tag(30, WireType.LengthDelimited).fork(), options).join();
        /* bool is_color_formula_hidden = 31; */
        if (message.isColorFormulaHidden !== false)
            writer.tag(31, WireType.Varint).bool(message.isColorFormulaHidden);
        /* bool is_price_shown_on_will_call = 32; */
        if (message.isPriceShownOnWillCall !== false)
            writer.tag(32, WireType.Varint).bool(message.isPriceShownOnWillCall);
        /* bool is_loyalty_program_enabled = 33; */
        if (message.isLoyaltyProgramEnabled !== false)
            writer.tag(33, WireType.Varint).bool(message.isLoyaltyProgramEnabled);
        /* string loyalty_program_policy = 34; */
        if (message.loyaltyProgramPolicy !== "")
            writer.tag(34, WireType.LengthDelimited).string(message.loyaltyProgramPolicy);
        /* bool should_auto_sign_out_after_sale = 35; */
        if (message.shouldAutoSignOutAfterSale !== false)
            writer.tag(35, WireType.Varint).bool(message.shouldAutoSignOutAfterSale);
        /* rd.config.SearchSize search_size = 36; */
        if (message.searchSize !== 0)
            writer.tag(36, WireType.Varint).int32(message.searchSize);
        /* rd.proto.uuid.UUID default_entity_id = 37; */
        if (message.defaultEntityId)
            UUID.internalBinaryWrite(message.defaultEntityId, writer.tag(37, WireType.LengthDelimited).fork(), options).join();
        /* bool is_card_surcharge_tax_exempt = 38; */
        if (message.isCardSurchargeTaxExempt !== false)
            writer.tag(38, WireType.Varint).bool(message.isCardSurchargeTaxExempt);
        /* rd.proto.uuid.UUID default_product_category_id = 39; */
        if (message.defaultProductCategoryId)
            UUID.internalBinaryWrite(message.defaultProductCategoryId, writer.tag(39, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.company.Company
 */
export const Company = new Company$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Location$Type extends MessageType<Location> {
    constructor() {
        super("rd.company.Location", [
            { no: 1, name: "id", kind: "message", T: () => UUID },
            { no: 2, name: "created_at", kind: "message", T: () => Timestamp },
            { no: 3, name: "updated_at", kind: "message", T: () => Timestamp },
            { no: 4, name: "company_id", kind: "message", T: () => UUID },
            { no: 5, name: "prefix", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "phone", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "address", kind: "message", T: () => Address },
            { no: 9, name: "is_active", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 10, name: "time_zone", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "stripe_location_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 12, name: "sales_tax_rate", kind: "message", T: () => Decimal },
            { no: 13, name: "bm_outlet_number", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 15, name: "entity_id", kind: "message", T: () => UUID }
        ]);
    }
    create(value?: PartialMessage<Location>): Location {
        const message = { prefix: "", name: "", phone: "", isActive: false, timeZone: "", stripeLocationId: "", bmOutletNumber: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Location>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Location): Location {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID id */ 1:
                    message.id = UUID.internalBinaryRead(reader, reader.uint32(), options, message.id);
                    break;
                case /* google.protobuf.Timestamp created_at */ 2:
                    message.createdAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.createdAt);
                    break;
                case /* google.protobuf.Timestamp updated_at */ 3:
                    message.updatedAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.updatedAt);
                    break;
                case /* rd.proto.uuid.UUID company_id */ 4:
                    message.companyId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.companyId);
                    break;
                case /* string prefix */ 5:
                    message.prefix = reader.string();
                    break;
                case /* string name */ 6:
                    message.name = reader.string();
                    break;
                case /* string phone */ 7:
                    message.phone = reader.string();
                    break;
                case /* rd.proto.address.Address address */ 8:
                    message.address = Address.internalBinaryRead(reader, reader.uint32(), options, message.address);
                    break;
                case /* bool is_active */ 9:
                    message.isActive = reader.bool();
                    break;
                case /* string time_zone */ 10:
                    message.timeZone = reader.string();
                    break;
                case /* string stripe_location_id */ 11:
                    message.stripeLocationId = reader.string();
                    break;
                case /* rd.proto.decimal.Decimal sales_tax_rate */ 12:
                    message.salesTaxRate = Decimal.internalBinaryRead(reader, reader.uint32(), options, message.salesTaxRate);
                    break;
                case /* string bm_outlet_number */ 13:
                    message.bmOutletNumber = reader.string();
                    break;
                case /* rd.proto.uuid.UUID entity_id */ 15:
                    message.entityId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.entityId);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Location, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID id = 1; */
        if (message.id)
            UUID.internalBinaryWrite(message.id, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp created_at = 2; */
        if (message.createdAt)
            Timestamp.internalBinaryWrite(message.createdAt, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp updated_at = 3; */
        if (message.updatedAt)
            Timestamp.internalBinaryWrite(message.updatedAt, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID company_id = 4; */
        if (message.companyId)
            UUID.internalBinaryWrite(message.companyId, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* string prefix = 5; */
        if (message.prefix !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.prefix);
        /* string name = 6; */
        if (message.name !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.name);
        /* string phone = 7; */
        if (message.phone !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.phone);
        /* rd.proto.address.Address address = 8; */
        if (message.address)
            Address.internalBinaryWrite(message.address, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* bool is_active = 9; */
        if (message.isActive !== false)
            writer.tag(9, WireType.Varint).bool(message.isActive);
        /* string time_zone = 10; */
        if (message.timeZone !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.timeZone);
        /* string stripe_location_id = 11; */
        if (message.stripeLocationId !== "")
            writer.tag(11, WireType.LengthDelimited).string(message.stripeLocationId);
        /* rd.proto.decimal.Decimal sales_tax_rate = 12; */
        if (message.salesTaxRate)
            Decimal.internalBinaryWrite(message.salesTaxRate, writer.tag(12, WireType.LengthDelimited).fork(), options).join();
        /* string bm_outlet_number = 13; */
        if (message.bmOutletNumber !== "")
            writer.tag(13, WireType.LengthDelimited).string(message.bmOutletNumber);
        /* rd.proto.uuid.UUID entity_id = 15; */
        if (message.entityId)
            UUID.internalBinaryWrite(message.entityId, writer.tag(15, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.company.Location
 */
export const Location = new Location$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Staff$Type extends MessageType<Staff> {
    constructor() {
        super("rd.company.Staff", [
            { no: 1, name: "id", kind: "message", T: () => UUID },
            { no: 2, name: "created_at", kind: "message", T: () => Timestamp },
            { no: 3, name: "updated_at", kind: "message", T: () => Timestamp },
            { no: 4, name: "company_id", kind: "message", T: () => UUID },
            { no: 5, name: "first_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "last_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "phone", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "is_active", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 9, name: "email", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "rd.sanitize.log": true } },
            { no: 10, name: "address", kind: "message", T: () => Address, options: { "rd.sanitize.log": true } },
            { no: 11, name: "stytch_user_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 12, name: "code", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 13, name: "role_ids", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 14, name: "stripe_issuing_cardholder_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 15, name: "webauthn_pubkey_ids", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Staff>): Staff {
        const message = { firstName: "", lastName: "", phone: "", isActive: false, email: "", stytchUserId: "", code: "", roleIds: [], stripeIssuingCardholderId: "", webauthnPubkeyIds: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Staff>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Staff): Staff {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID id */ 1:
                    message.id = UUID.internalBinaryRead(reader, reader.uint32(), options, message.id);
                    break;
                case /* google.protobuf.Timestamp created_at */ 2:
                    message.createdAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.createdAt);
                    break;
                case /* google.protobuf.Timestamp updated_at */ 3:
                    message.updatedAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.updatedAt);
                    break;
                case /* rd.proto.uuid.UUID company_id */ 4:
                    message.companyId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.companyId);
                    break;
                case /* string first_name */ 5:
                    message.firstName = reader.string();
                    break;
                case /* string last_name */ 6:
                    message.lastName = reader.string();
                    break;
                case /* string phone */ 7:
                    message.phone = reader.string();
                    break;
                case /* bool is_active */ 8:
                    message.isActive = reader.bool();
                    break;
                case /* string email */ 9:
                    message.email = reader.string();
                    break;
                case /* rd.proto.address.Address address */ 10:
                    message.address = Address.internalBinaryRead(reader, reader.uint32(), options, message.address);
                    break;
                case /* string stytch_user_id */ 11:
                    message.stytchUserId = reader.string();
                    break;
                case /* string code */ 12:
                    message.code = reader.string();
                    break;
                case /* repeated string role_ids */ 13:
                    message.roleIds.push(reader.string());
                    break;
                case /* string stripe_issuing_cardholder_id */ 14:
                    message.stripeIssuingCardholderId = reader.string();
                    break;
                case /* repeated string webauthn_pubkey_ids */ 15:
                    message.webauthnPubkeyIds.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Staff, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID id = 1; */
        if (message.id)
            UUID.internalBinaryWrite(message.id, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp created_at = 2; */
        if (message.createdAt)
            Timestamp.internalBinaryWrite(message.createdAt, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp updated_at = 3; */
        if (message.updatedAt)
            Timestamp.internalBinaryWrite(message.updatedAt, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID company_id = 4; */
        if (message.companyId)
            UUID.internalBinaryWrite(message.companyId, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* string first_name = 5; */
        if (message.firstName !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.firstName);
        /* string last_name = 6; */
        if (message.lastName !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.lastName);
        /* string phone = 7; */
        if (message.phone !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.phone);
        /* bool is_active = 8; */
        if (message.isActive !== false)
            writer.tag(8, WireType.Varint).bool(message.isActive);
        /* string email = 9; */
        if (message.email !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.email);
        /* rd.proto.address.Address address = 10; */
        if (message.address)
            Address.internalBinaryWrite(message.address, writer.tag(10, WireType.LengthDelimited).fork(), options).join();
        /* string stytch_user_id = 11; */
        if (message.stytchUserId !== "")
            writer.tag(11, WireType.LengthDelimited).string(message.stytchUserId);
        /* string code = 12; */
        if (message.code !== "")
            writer.tag(12, WireType.LengthDelimited).string(message.code);
        /* repeated string role_ids = 13; */
        for (let i = 0; i < message.roleIds.length; i++)
            writer.tag(13, WireType.LengthDelimited).string(message.roleIds[i]);
        /* string stripe_issuing_cardholder_id = 14; */
        if (message.stripeIssuingCardholderId !== "")
            writer.tag(14, WireType.LengthDelimited).string(message.stripeIssuingCardholderId);
        /* repeated string webauthn_pubkey_ids = 15; */
        for (let i = 0; i < message.webauthnPubkeyIds.length; i++)
            writer.tag(15, WireType.LengthDelimited).string(message.webauthnPubkeyIds[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.company.Staff
 */
export const Staff = new Staff$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PricingTiers$Type extends MessageType<PricingTiers> {
    constructor() {
        super("rd.company.PricingTiers", [
            { no: 1, name: "id", kind: "message", T: () => UUID },
            { no: 2, name: "created_at", kind: "message", T: () => Timestamp },
            { no: 3, name: "updated_at", kind: "message", T: () => Timestamp },
            { no: 4, name: "default_id_string", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "tiers_by_id", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => PricingTiers_Tier } }
        ]);
    }
    create(value?: PartialMessage<PricingTiers>): PricingTiers {
        const message = { defaultIdString: "", tiersById: {} };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PricingTiers>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PricingTiers): PricingTiers {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID id */ 1:
                    message.id = UUID.internalBinaryRead(reader, reader.uint32(), options, message.id);
                    break;
                case /* google.protobuf.Timestamp created_at */ 2:
                    message.createdAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.createdAt);
                    break;
                case /* google.protobuf.Timestamp updated_at */ 3:
                    message.updatedAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.updatedAt);
                    break;
                case /* string default_id_string */ 4:
                    message.defaultIdString = reader.string();
                    break;
                case /* map<string, rd.company.PricingTiers.Tier> tiers_by_id */ 5:
                    this.binaryReadMap5(message.tiersById, reader, options);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap5(map: PricingTiers["tiersById"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof PricingTiers["tiersById"] | undefined, val: PricingTiers["tiersById"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = PricingTiers_Tier.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.company.PricingTiers.tiers_by_id");
            }
        }
        map[key ?? ""] = val ?? PricingTiers_Tier.create();
    }
    internalBinaryWrite(message: PricingTiers, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID id = 1; */
        if (message.id)
            UUID.internalBinaryWrite(message.id, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp created_at = 2; */
        if (message.createdAt)
            Timestamp.internalBinaryWrite(message.createdAt, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp updated_at = 3; */
        if (message.updatedAt)
            Timestamp.internalBinaryWrite(message.updatedAt, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* string default_id_string = 4; */
        if (message.defaultIdString !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.defaultIdString);
        /* map<string, rd.company.PricingTiers.Tier> tiers_by_id = 5; */
        for (let k of Object.keys(message.tiersById)) {
            writer.tag(5, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            PricingTiers_Tier.internalBinaryWrite(message.tiersById[k], writer, options);
            writer.join().join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.company.PricingTiers
 */
export const PricingTiers = new PricingTiers$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PricingTiers_Tier$Type extends MessageType<PricingTiers_Tier> {
    constructor() {
        super("rd.company.PricingTiers.Tier", [
            { no: 1, name: "id_string", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "rank", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<PricingTiers_Tier>): PricingTiers_Tier {
        const message = { idString: "", name: "", rank: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PricingTiers_Tier>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PricingTiers_Tier): PricingTiers_Tier {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id_string */ 1:
                    message.idString = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* int32 rank */ 3:
                    message.rank = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PricingTiers_Tier, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id_string = 1; */
        if (message.idString !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.idString);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* int32 rank = 3; */
        if (message.rank !== 0)
            writer.tag(3, WireType.Varint).int32(message.rank);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.company.PricingTiers.Tier
 */
export const PricingTiers_Tier = new PricingTiers_Tier$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PricingTiersLog$Type extends MessageType<PricingTiersLog> {
    constructor() {
        super("rd.company.PricingTiersLog", [
            { no: 1, name: "id", kind: "message", T: () => UUID },
            { no: 2, name: "created_at", kind: "message", T: () => Timestamp },
            { no: 3, name: "pricing_tiers", kind: "message", T: () => PricingTiers }
        ]);
    }
    create(value?: PartialMessage<PricingTiersLog>): PricingTiersLog {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PricingTiersLog>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PricingTiersLog): PricingTiersLog {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID id */ 1:
                    message.id = UUID.internalBinaryRead(reader, reader.uint32(), options, message.id);
                    break;
                case /* google.protobuf.Timestamp created_at */ 2:
                    message.createdAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.createdAt);
                    break;
                case /* rd.company.PricingTiers pricing_tiers */ 3:
                    message.pricingTiers = PricingTiers.internalBinaryRead(reader, reader.uint32(), options, message.pricingTiers);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PricingTiersLog, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID id = 1; */
        if (message.id)
            UUID.internalBinaryWrite(message.id, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp created_at = 2; */
        if (message.createdAt)
            Timestamp.internalBinaryWrite(message.createdAt, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* rd.company.PricingTiers pricing_tiers = 3; */
        if (message.pricingTiers)
            PricingTiers.internalBinaryWrite(message.pricingTiers, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.company.PricingTiersLog
 */
export const PricingTiersLog = new PricingTiersLog$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FinancingTerms$Type extends MessageType<FinancingTerms> {
    constructor() {
        super("rd.company.FinancingTerms", [
            { no: 1, name: "id", kind: "message", T: () => UUID },
            { no: 2, name: "created_at", kind: "message", T: () => Timestamp },
            { no: 3, name: "updated_at", kind: "message", T: () => Timestamp },
            { no: 4, name: "default_id_string", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "terms_by_id", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => FinancingTerms_Term } }
        ]);
    }
    create(value?: PartialMessage<FinancingTerms>): FinancingTerms {
        const message = { defaultIdString: "", termsById: {} };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<FinancingTerms>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FinancingTerms): FinancingTerms {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID id */ 1:
                    message.id = UUID.internalBinaryRead(reader, reader.uint32(), options, message.id);
                    break;
                case /* google.protobuf.Timestamp created_at */ 2:
                    message.createdAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.createdAt);
                    break;
                case /* google.protobuf.Timestamp updated_at */ 3:
                    message.updatedAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.updatedAt);
                    break;
                case /* string default_id_string */ 4:
                    message.defaultIdString = reader.string();
                    break;
                case /* map<string, rd.company.FinancingTerms.Term> terms_by_id */ 5:
                    this.binaryReadMap5(message.termsById, reader, options);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap5(map: FinancingTerms["termsById"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof FinancingTerms["termsById"] | undefined, val: FinancingTerms["termsById"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = FinancingTerms_Term.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.company.FinancingTerms.terms_by_id");
            }
        }
        map[key ?? ""] = val ?? FinancingTerms_Term.create();
    }
    internalBinaryWrite(message: FinancingTerms, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID id = 1; */
        if (message.id)
            UUID.internalBinaryWrite(message.id, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp created_at = 2; */
        if (message.createdAt)
            Timestamp.internalBinaryWrite(message.createdAt, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp updated_at = 3; */
        if (message.updatedAt)
            Timestamp.internalBinaryWrite(message.updatedAt, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* string default_id_string = 4; */
        if (message.defaultIdString !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.defaultIdString);
        /* map<string, rd.company.FinancingTerms.Term> terms_by_id = 5; */
        for (let k of Object.keys(message.termsById)) {
            writer.tag(5, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            FinancingTerms_Term.internalBinaryWrite(message.termsById[k], writer, options);
            writer.join().join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.company.FinancingTerms
 */
export const FinancingTerms = new FinancingTerms$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FinancingTerms_Term$Type extends MessageType<FinancingTerms_Term> {
    constructor() {
        super("rd.company.FinancingTerms.Term", [
            { no: 1, name: "id_string", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "reference_date", kind: "enum", T: () => ["rd.company.FinancingTerms.Term.ReferenceDate", FinancingTerms_Term_ReferenceDate, "REFERENCE_DATE_"] },
            { no: 3, name: "due_days", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "discount_days", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "discount_rate", kind: "message", T: () => Decimal },
            { no: 6, name: "position", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<FinancingTerms_Term>): FinancingTerms_Term {
        const message = { idString: "", referenceDate: 0, dueDays: 0, discountDays: 0, position: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<FinancingTerms_Term>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FinancingTerms_Term): FinancingTerms_Term {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id_string */ 1:
                    message.idString = reader.string();
                    break;
                case /* rd.company.FinancingTerms.Term.ReferenceDate reference_date */ 2:
                    message.referenceDate = reader.int32();
                    break;
                case /* int32 due_days */ 3:
                    message.dueDays = reader.int32();
                    break;
                case /* int32 discount_days */ 4:
                    message.discountDays = reader.int32();
                    break;
                case /* rd.proto.decimal.Decimal discount_rate */ 5:
                    message.discountRate = Decimal.internalBinaryRead(reader, reader.uint32(), options, message.discountRate);
                    break;
                case /* int32 position */ 6:
                    message.position = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FinancingTerms_Term, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id_string = 1; */
        if (message.idString !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.idString);
        /* rd.company.FinancingTerms.Term.ReferenceDate reference_date = 2; */
        if (message.referenceDate !== 0)
            writer.tag(2, WireType.Varint).int32(message.referenceDate);
        /* int32 due_days = 3; */
        if (message.dueDays !== 0)
            writer.tag(3, WireType.Varint).int32(message.dueDays);
        /* int32 discount_days = 4; */
        if (message.discountDays !== 0)
            writer.tag(4, WireType.Varint).int32(message.discountDays);
        /* rd.proto.decimal.Decimal discount_rate = 5; */
        if (message.discountRate)
            Decimal.internalBinaryWrite(message.discountRate, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* int32 position = 6; */
        if (message.position !== 0)
            writer.tag(6, WireType.Varint).int32(message.position);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.company.FinancingTerms.Term
 */
export const FinancingTerms_Term = new FinancingTerms_Term$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FinancingTermsLog$Type extends MessageType<FinancingTermsLog> {
    constructor() {
        super("rd.company.FinancingTermsLog", [
            { no: 1, name: "id", kind: "message", T: () => UUID },
            { no: 2, name: "created_at", kind: "message", T: () => Timestamp },
            { no: 3, name: "financing_terms", kind: "message", T: () => FinancingTerms }
        ]);
    }
    create(value?: PartialMessage<FinancingTermsLog>): FinancingTermsLog {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<FinancingTermsLog>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FinancingTermsLog): FinancingTermsLog {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID id */ 1:
                    message.id = UUID.internalBinaryRead(reader, reader.uint32(), options, message.id);
                    break;
                case /* google.protobuf.Timestamp created_at */ 2:
                    message.createdAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.createdAt);
                    break;
                case /* rd.company.FinancingTerms financing_terms */ 3:
                    message.financingTerms = FinancingTerms.internalBinaryRead(reader, reader.uint32(), options, message.financingTerms);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FinancingTermsLog, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID id = 1; */
        if (message.id)
            UUID.internalBinaryWrite(message.id, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp created_at = 2; */
        if (message.createdAt)
            Timestamp.internalBinaryWrite(message.createdAt, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* rd.company.FinancingTerms financing_terms = 3; */
        if (message.financingTerms)
            FinancingTerms.internalBinaryWrite(message.financingTerms, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.company.FinancingTermsLog
 */
export const FinancingTermsLog = new FinancingTermsLog$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AdditionalFees$Type extends MessageType<AdditionalFees> {
    constructor() {
        super("rd.company.AdditionalFees", [
            { no: 1, name: "id", kind: "message", T: () => UUID },
            { no: 2, name: "created_at", kind: "message", T: () => Timestamp },
            { no: 3, name: "updated_at", kind: "message", T: () => Timestamp },
            { no: 4, name: "fees_by_id", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => AdditionalFees_Fee } }
        ]);
    }
    create(value?: PartialMessage<AdditionalFees>): AdditionalFees {
        const message = { feesById: {} };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AdditionalFees>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AdditionalFees): AdditionalFees {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID id */ 1:
                    message.id = UUID.internalBinaryRead(reader, reader.uint32(), options, message.id);
                    break;
                case /* google.protobuf.Timestamp created_at */ 2:
                    message.createdAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.createdAt);
                    break;
                case /* google.protobuf.Timestamp updated_at */ 3:
                    message.updatedAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.updatedAt);
                    break;
                case /* map<string, rd.company.AdditionalFees.Fee> fees_by_id */ 4:
                    this.binaryReadMap4(message.feesById, reader, options);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap4(map: AdditionalFees["feesById"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof AdditionalFees["feesById"] | undefined, val: AdditionalFees["feesById"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = AdditionalFees_Fee.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.company.AdditionalFees.fees_by_id");
            }
        }
        map[key ?? ""] = val ?? AdditionalFees_Fee.create();
    }
    internalBinaryWrite(message: AdditionalFees, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID id = 1; */
        if (message.id)
            UUID.internalBinaryWrite(message.id, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp created_at = 2; */
        if (message.createdAt)
            Timestamp.internalBinaryWrite(message.createdAt, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp updated_at = 3; */
        if (message.updatedAt)
            Timestamp.internalBinaryWrite(message.updatedAt, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* map<string, rd.company.AdditionalFees.Fee> fees_by_id = 4; */
        for (let k of Object.keys(message.feesById)) {
            writer.tag(4, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            AdditionalFees_Fee.internalBinaryWrite(message.feesById[k], writer, options);
            writer.join().join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.company.AdditionalFees
 */
export const AdditionalFees = new AdditionalFees$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AdditionalFees_Fee$Type extends MessageType<AdditionalFees_Fee> {
    constructor() {
        super("rd.company.AdditionalFees.Fee", [
            { no: 1, name: "id_string", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "fee", kind: "message", T: () => Money },
            { no: 4, name: "fee_rate", kind: "message", T: () => Decimal },
            { no: 5, name: "is_taxable", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 6, name: "position", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<AdditionalFees_Fee>): AdditionalFees_Fee {
        const message = { idString: "", name: "", isTaxable: false, position: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AdditionalFees_Fee>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AdditionalFees_Fee): AdditionalFees_Fee {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id_string */ 1:
                    message.idString = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* rd.proto.money.Money fee */ 3:
                    message.fee = Money.internalBinaryRead(reader, reader.uint32(), options, message.fee);
                    break;
                case /* rd.proto.decimal.Decimal fee_rate */ 4:
                    message.feeRate = Decimal.internalBinaryRead(reader, reader.uint32(), options, message.feeRate);
                    break;
                case /* bool is_taxable */ 5:
                    message.isTaxable = reader.bool();
                    break;
                case /* int32 position */ 6:
                    message.position = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AdditionalFees_Fee, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id_string = 1; */
        if (message.idString !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.idString);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* rd.proto.money.Money fee = 3; */
        if (message.fee)
            Money.internalBinaryWrite(message.fee, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.decimal.Decimal fee_rate = 4; */
        if (message.feeRate)
            Decimal.internalBinaryWrite(message.feeRate, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* bool is_taxable = 5; */
        if (message.isTaxable !== false)
            writer.tag(5, WireType.Varint).bool(message.isTaxable);
        /* int32 position = 6; */
        if (message.position !== 0)
            writer.tag(6, WireType.Varint).int32(message.position);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.company.AdditionalFees.Fee
 */
export const AdditionalFees_Fee = new AdditionalFees_Fee$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AdditionalFeesLog$Type extends MessageType<AdditionalFeesLog> {
    constructor() {
        super("rd.company.AdditionalFeesLog", [
            { no: 1, name: "id", kind: "message", T: () => UUID },
            { no: 2, name: "created_at", kind: "message", T: () => Timestamp },
            { no: 3, name: "additional_fees", kind: "message", T: () => AdditionalFees }
        ]);
    }
    create(value?: PartialMessage<AdditionalFeesLog>): AdditionalFeesLog {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AdditionalFeesLog>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AdditionalFeesLog): AdditionalFeesLog {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID id */ 1:
                    message.id = UUID.internalBinaryRead(reader, reader.uint32(), options, message.id);
                    break;
                case /* google.protobuf.Timestamp created_at */ 2:
                    message.createdAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.createdAt);
                    break;
                case /* rd.company.AdditionalFees additional_fees */ 3:
                    message.additionalFees = AdditionalFees.internalBinaryRead(reader, reader.uint32(), options, message.additionalFees);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AdditionalFeesLog, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID id = 1; */
        if (message.id)
            UUID.internalBinaryWrite(message.id, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp created_at = 2; */
        if (message.createdAt)
            Timestamp.internalBinaryWrite(message.createdAt, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* rd.company.AdditionalFees additional_fees = 3; */
        if (message.additionalFees)
            AdditionalFees.internalBinaryWrite(message.additionalFees, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.company.AdditionalFeesLog
 */
export const AdditionalFeesLog = new AdditionalFeesLog$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ProductCategory$Type extends MessageType<ProductCategory> {
    constructor() {
        super("rd.company.ProductCategory", [
            { no: 1, name: "id", kind: "message", T: () => UUID },
            { no: 2, name: "created_at", kind: "message", T: () => Timestamp },
            { no: 3, name: "updated_at", kind: "message", T: () => Timestamp },
            { no: 4, name: "company_id", kind: "message", T: () => UUID },
            { no: 5, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "type", kind: "enum", T: () => ["rd.company.ProductCategory.Type", ProductCategory_Type, "TYPE_"] },
            { no: 7, name: "is_active", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<ProductCategory>): ProductCategory {
        const message = { name: "", type: 0, isActive: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ProductCategory>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ProductCategory): ProductCategory {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID id */ 1:
                    message.id = UUID.internalBinaryRead(reader, reader.uint32(), options, message.id);
                    break;
                case /* google.protobuf.Timestamp created_at */ 2:
                    message.createdAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.createdAt);
                    break;
                case /* google.protobuf.Timestamp updated_at */ 3:
                    message.updatedAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.updatedAt);
                    break;
                case /* rd.proto.uuid.UUID company_id */ 4:
                    message.companyId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.companyId);
                    break;
                case /* string name */ 5:
                    message.name = reader.string();
                    break;
                case /* rd.company.ProductCategory.Type type */ 6:
                    message.type = reader.int32();
                    break;
                case /* bool is_active */ 7:
                    message.isActive = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ProductCategory, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID id = 1; */
        if (message.id)
            UUID.internalBinaryWrite(message.id, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp created_at = 2; */
        if (message.createdAt)
            Timestamp.internalBinaryWrite(message.createdAt, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp updated_at = 3; */
        if (message.updatedAt)
            Timestamp.internalBinaryWrite(message.updatedAt, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID company_id = 4; */
        if (message.companyId)
            UUID.internalBinaryWrite(message.companyId, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* string name = 5; */
        if (message.name !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.name);
        /* rd.company.ProductCategory.Type type = 6; */
        if (message.type !== 0)
            writer.tag(6, WireType.Varint).int32(message.type);
        /* bool is_active = 7; */
        if (message.isActive !== false)
            writer.tag(7, WireType.Varint).bool(message.isActive);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.company.ProductCategory
 */
export const ProductCategory = new ProductCategory$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Roles$Type extends MessageType<Roles> {
    constructor() {
        super("rd.company.Roles", [
            { no: 1, name: "id", kind: "message", T: () => UUID },
            { no: 2, name: "roles_by_id", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => Roles_Role } }
        ]);
    }
    create(value?: PartialMessage<Roles>): Roles {
        const message = { rolesById: {} };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Roles>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Roles): Roles {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID id */ 1:
                    message.id = UUID.internalBinaryRead(reader, reader.uint32(), options, message.id);
                    break;
                case /* map<string, rd.company.Roles.Role> roles_by_id */ 2:
                    this.binaryReadMap2(message.rolesById, reader, options);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap2(map: Roles["rolesById"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof Roles["rolesById"] | undefined, val: Roles["rolesById"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = Roles_Role.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.company.Roles.roles_by_id");
            }
        }
        map[key ?? ""] = val ?? Roles_Role.create();
    }
    internalBinaryWrite(message: Roles, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID id = 1; */
        if (message.id)
            UUID.internalBinaryWrite(message.id, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* map<string, rd.company.Roles.Role> roles_by_id = 2; */
        for (let k of Object.keys(message.rolesById)) {
            writer.tag(2, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            Roles_Role.internalBinaryWrite(message.rolesById[k], writer, options);
            writer.join().join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.company.Roles
 */
export const Roles = new Roles$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Roles_Role$Type extends MessageType<Roles_Role> {
    constructor() {
        super("rd.company.Roles.Role", [
            { no: 1, name: "id_string", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "permissions", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "scalar", T: 8 /*ScalarType.BOOL*/ } },
            { no: 4, name: "linked_roles", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "category", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "position", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<Roles_Role>): Roles_Role {
        const message = { idString: "", name: "", permissions: {}, linkedRoles: [], category: "", description: "", position: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Roles_Role>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Roles_Role): Roles_Role {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id_string */ 1:
                    message.idString = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* map<string, bool> permissions */ 3:
                    this.binaryReadMap3(message.permissions, reader, options);
                    break;
                case /* repeated string linked_roles */ 4:
                    message.linkedRoles.push(reader.string());
                    break;
                case /* string category */ 5:
                    message.category = reader.string();
                    break;
                case /* string description */ 6:
                    message.description = reader.string();
                    break;
                case /* int32 position */ 7:
                    message.position = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap3(map: Roles_Role["permissions"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof Roles_Role["permissions"] | undefined, val: Roles_Role["permissions"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = reader.bool();
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.company.Roles.Role.permissions");
            }
        }
        map[key ?? ""] = val ?? false;
    }
    internalBinaryWrite(message: Roles_Role, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id_string = 1; */
        if (message.idString !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.idString);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* map<string, bool> permissions = 3; */
        for (let k of Object.keys(message.permissions))
            writer.tag(3, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k).tag(2, WireType.Varint).bool(message.permissions[k]).join();
        /* repeated string linked_roles = 4; */
        for (let i = 0; i < message.linkedRoles.length; i++)
            writer.tag(4, WireType.LengthDelimited).string(message.linkedRoles[i]);
        /* string category = 5; */
        if (message.category !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.category);
        /* string description = 6; */
        if (message.description !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.description);
        /* int32 position = 7; */
        if (message.position !== 0)
            writer.tag(7, WireType.Varint).int32(message.position);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.company.Roles.Role
 */
export const Roles_Role = new Roles_Role$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DooerStaff$Type extends MessageType<DooerStaff> {
    constructor() {
        super("rd.company.DooerStaff", [
            { no: 1, name: "id", kind: "message", T: () => UUID },
            { no: 2, name: "created_at", kind: "message", T: () => Timestamp },
            { no: 3, name: "updated_at", kind: "message", T: () => Timestamp },
            { no: 5, name: "phone", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "email", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "stytch_user_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "code", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<DooerStaff>): DooerStaff {
        const message = { phone: "", email: "", stytchUserId: "", code: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DooerStaff>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DooerStaff): DooerStaff {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID id */ 1:
                    message.id = UUID.internalBinaryRead(reader, reader.uint32(), options, message.id);
                    break;
                case /* google.protobuf.Timestamp created_at */ 2:
                    message.createdAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.createdAt);
                    break;
                case /* google.protobuf.Timestamp updated_at */ 3:
                    message.updatedAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.updatedAt);
                    break;
                case /* string phone */ 5:
                    message.phone = reader.string();
                    break;
                case /* string email */ 6:
                    message.email = reader.string();
                    break;
                case /* string stytch_user_id */ 7:
                    message.stytchUserId = reader.string();
                    break;
                case /* string code */ 8:
                    message.code = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DooerStaff, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID id = 1; */
        if (message.id)
            UUID.internalBinaryWrite(message.id, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp created_at = 2; */
        if (message.createdAt)
            Timestamp.internalBinaryWrite(message.createdAt, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp updated_at = 3; */
        if (message.updatedAt)
            Timestamp.internalBinaryWrite(message.updatedAt, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* string phone = 5; */
        if (message.phone !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.phone);
        /* string email = 6; */
        if (message.email !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.email);
        /* string stytch_user_id = 7; */
        if (message.stytchUserId !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.stytchUserId);
        /* string code = 8; */
        if (message.code !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.code);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.company.DooerStaff
 */
export const DooerStaff = new DooerStaff$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CashRegister$Type extends MessageType<CashRegister> {
    constructor() {
        super("rd.company.CashRegister", [
            { no: 1, name: "id", kind: "message", T: () => UUID },
            { no: 2, name: "created_at", kind: "message", T: () => Timestamp },
            { no: 3, name: "updated_at", kind: "message", T: () => Timestamp },
            { no: 4, name: "balance", kind: "message", T: () => Money },
            { no: 5, name: "float", kind: "message", T: () => Money },
            { no: 6, name: "company_id", kind: "message", T: () => UUID }
        ]);
    }
    create(value?: PartialMessage<CashRegister>): CashRegister {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CashRegister>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CashRegister): CashRegister {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID id */ 1:
                    message.id = UUID.internalBinaryRead(reader, reader.uint32(), options, message.id);
                    break;
                case /* google.protobuf.Timestamp created_at */ 2:
                    message.createdAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.createdAt);
                    break;
                case /* google.protobuf.Timestamp updated_at */ 3:
                    message.updatedAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.updatedAt);
                    break;
                case /* rd.proto.money.Money balance */ 4:
                    message.balance = Money.internalBinaryRead(reader, reader.uint32(), options, message.balance);
                    break;
                case /* rd.proto.money.Money float */ 5:
                    message.float = Money.internalBinaryRead(reader, reader.uint32(), options, message.float);
                    break;
                case /* rd.proto.uuid.UUID company_id */ 6:
                    message.companyId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.companyId);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CashRegister, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID id = 1; */
        if (message.id)
            UUID.internalBinaryWrite(message.id, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp created_at = 2; */
        if (message.createdAt)
            Timestamp.internalBinaryWrite(message.createdAt, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp updated_at = 3; */
        if (message.updatedAt)
            Timestamp.internalBinaryWrite(message.updatedAt, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money balance = 4; */
        if (message.balance)
            Money.internalBinaryWrite(message.balance, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money float = 5; */
        if (message.float)
            Money.internalBinaryWrite(message.float, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID company_id = 6; */
        if (message.companyId)
            UUID.internalBinaryWrite(message.companyId, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.company.CashRegister
 */
export const CashRegister = new CashRegister$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CxaUser$Type extends MessageType<CxaUser> {
    constructor() {
        super("rd.company.CxaUser", [
            { no: 1, name: "id", kind: "message", T: () => UUID },
            { no: 2, name: "created_at", kind: "message", T: () => Timestamp },
            { no: 3, name: "phone", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "stytch_user_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<CxaUser>): CxaUser {
        const message = { phone: "", stytchUserId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CxaUser>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CxaUser): CxaUser {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID id */ 1:
                    message.id = UUID.internalBinaryRead(reader, reader.uint32(), options, message.id);
                    break;
                case /* google.protobuf.Timestamp created_at */ 2:
                    message.createdAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.createdAt);
                    break;
                case /* string phone */ 3:
                    message.phone = reader.string();
                    break;
                case /* string stytch_user_id */ 4:
                    message.stytchUserId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CxaUser, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID id = 1; */
        if (message.id)
            UUID.internalBinaryWrite(message.id, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp created_at = 2; */
        if (message.createdAt)
            Timestamp.internalBinaryWrite(message.createdAt, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* string phone = 3; */
        if (message.phone !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.phone);
        /* string stytch_user_id = 4; */
        if (message.stytchUserId !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.stytchUserId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.company.CxaUser
 */
export const CxaUser = new CxaUser$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TaxRate$Type extends MessageType<TaxRate> {
    constructor() {
        super("rd.company.TaxRate", [
            { no: 1, name: "id", kind: "message", T: () => UUID },
            { no: 2, name: "created_at", kind: "message", T: () => Timestamp },
            { no: 3, name: "updated_at", kind: "message", T: () => Timestamp },
            { no: 4, name: "company_id", kind: "message", T: () => UUID },
            { no: 5, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "rate", kind: "message", T: () => Decimal },
            { no: 7, name: "position", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 8, name: "is_active", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 9, name: "type", kind: "enum", T: () => ["rd.company.TaxRate.Type", TaxRate_Type, "TYPE_"] }
        ]);
    }
    create(value?: PartialMessage<TaxRate>): TaxRate {
        const message = { name: "", position: 0, isActive: false, type: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<TaxRate>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TaxRate): TaxRate {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID id */ 1:
                    message.id = UUID.internalBinaryRead(reader, reader.uint32(), options, message.id);
                    break;
                case /* google.protobuf.Timestamp created_at */ 2:
                    message.createdAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.createdAt);
                    break;
                case /* google.protobuf.Timestamp updated_at */ 3:
                    message.updatedAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.updatedAt);
                    break;
                case /* rd.proto.uuid.UUID company_id */ 4:
                    message.companyId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.companyId);
                    break;
                case /* string name */ 5:
                    message.name = reader.string();
                    break;
                case /* rd.proto.decimal.Decimal rate */ 6:
                    message.rate = Decimal.internalBinaryRead(reader, reader.uint32(), options, message.rate);
                    break;
                case /* int32 position */ 7:
                    message.position = reader.int32();
                    break;
                case /* bool is_active */ 8:
                    message.isActive = reader.bool();
                    break;
                case /* rd.company.TaxRate.Type type */ 9:
                    message.type = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TaxRate, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID id = 1; */
        if (message.id)
            UUID.internalBinaryWrite(message.id, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp created_at = 2; */
        if (message.createdAt)
            Timestamp.internalBinaryWrite(message.createdAt, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp updated_at = 3; */
        if (message.updatedAt)
            Timestamp.internalBinaryWrite(message.updatedAt, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID company_id = 4; */
        if (message.companyId)
            UUID.internalBinaryWrite(message.companyId, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* string name = 5; */
        if (message.name !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.name);
        /* rd.proto.decimal.Decimal rate = 6; */
        if (message.rate)
            Decimal.internalBinaryWrite(message.rate, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* int32 position = 7; */
        if (message.position !== 0)
            writer.tag(7, WireType.Varint).int32(message.position);
        /* bool is_active = 8; */
        if (message.isActive !== false)
            writer.tag(8, WireType.Varint).bool(message.isActive);
        /* rd.company.TaxRate.Type type = 9; */
        if (message.type !== 0)
            writer.tag(9, WireType.Varint).int32(message.type);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.company.TaxRate
 */
export const TaxRate = new TaxRate$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TaxRateLog$Type extends MessageType<TaxRateLog> {
    constructor() {
        super("rd.company.TaxRateLog", [
            { no: 1, name: "id", kind: "message", T: () => UUID },
            { no: 2, name: "created_at", kind: "message", T: () => Timestamp },
            { no: 3, name: "tax_rate", kind: "message", T: () => TaxRate }
        ]);
    }
    create(value?: PartialMessage<TaxRateLog>): TaxRateLog {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<TaxRateLog>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TaxRateLog): TaxRateLog {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID id */ 1:
                    message.id = UUID.internalBinaryRead(reader, reader.uint32(), options, message.id);
                    break;
                case /* google.protobuf.Timestamp created_at */ 2:
                    message.createdAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.createdAt);
                    break;
                case /* rd.company.TaxRate tax_rate */ 3:
                    message.taxRate = TaxRate.internalBinaryRead(reader, reader.uint32(), options, message.taxRate);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TaxRateLog, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID id = 1; */
        if (message.id)
            UUID.internalBinaryWrite(message.id, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp created_at = 2; */
        if (message.createdAt)
            Timestamp.internalBinaryWrite(message.createdAt, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* rd.company.TaxRate tax_rate = 3; */
        if (message.taxRate)
            TaxRate.internalBinaryWrite(message.taxRate, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.company.TaxRateLog
 */
export const TaxRateLog = new TaxRateLog$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Entity$Type extends MessageType<Entity> {
    constructor() {
        super("rd.company.Entity", [
            { no: 1, name: "id", kind: "message", T: () => UUID },
            { no: 2, name: "company_id", kind: "message", T: () => UUID },
            { no: 3, name: "created_at", kind: "message", T: () => Timestamp },
            { no: 4, name: "updated_at", kind: "message", T: () => Timestamp },
            { no: 5, name: "stripe_express_account_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "stripe_custom_account_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "stripe_financial_account_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "stripe_bank_payment_method_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Entity>): Entity {
        const message = { stripeExpressAccountId: "", stripeCustomAccountId: "", stripeFinancialAccountId: "", stripeBankPaymentMethodId: "", name: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Entity>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Entity): Entity {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID id */ 1:
                    message.id = UUID.internalBinaryRead(reader, reader.uint32(), options, message.id);
                    break;
                case /* rd.proto.uuid.UUID company_id */ 2:
                    message.companyId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.companyId);
                    break;
                case /* google.protobuf.Timestamp created_at */ 3:
                    message.createdAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.createdAt);
                    break;
                case /* google.protobuf.Timestamp updated_at */ 4:
                    message.updatedAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.updatedAt);
                    break;
                case /* string stripe_express_account_id */ 5:
                    message.stripeExpressAccountId = reader.string();
                    break;
                case /* string stripe_custom_account_id */ 6:
                    message.stripeCustomAccountId = reader.string();
                    break;
                case /* string stripe_financial_account_id */ 7:
                    message.stripeFinancialAccountId = reader.string();
                    break;
                case /* string stripe_bank_payment_method_id */ 8:
                    message.stripeBankPaymentMethodId = reader.string();
                    break;
                case /* string name */ 9:
                    message.name = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Entity, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID id = 1; */
        if (message.id)
            UUID.internalBinaryWrite(message.id, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID company_id = 2; */
        if (message.companyId)
            UUID.internalBinaryWrite(message.companyId, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp created_at = 3; */
        if (message.createdAt)
            Timestamp.internalBinaryWrite(message.createdAt, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp updated_at = 4; */
        if (message.updatedAt)
            Timestamp.internalBinaryWrite(message.updatedAt, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* string stripe_express_account_id = 5; */
        if (message.stripeExpressAccountId !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.stripeExpressAccountId);
        /* string stripe_custom_account_id = 6; */
        if (message.stripeCustomAccountId !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.stripeCustomAccountId);
        /* string stripe_financial_account_id = 7; */
        if (message.stripeFinancialAccountId !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.stripeFinancialAccountId);
        /* string stripe_bank_payment_method_id = 8; */
        if (message.stripeBankPaymentMethodId !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.stripeBankPaymentMethodId);
        /* string name = 9; */
        if (message.name !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.name);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.company.Entity
 */
export const Entity = new Entity$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EntityLog$Type extends MessageType<EntityLog> {
    constructor() {
        super("rd.company.EntityLog", [
            { no: 1, name: "id", kind: "message", T: () => UUID },
            { no: 2, name: "created_at", kind: "message", T: () => Timestamp },
            { no: 3, name: "entity", kind: "message", T: () => Entity }
        ]);
    }
    create(value?: PartialMessage<EntityLog>): EntityLog {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<EntityLog>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: EntityLog): EntityLog {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID id */ 1:
                    message.id = UUID.internalBinaryRead(reader, reader.uint32(), options, message.id);
                    break;
                case /* google.protobuf.Timestamp created_at */ 2:
                    message.createdAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.createdAt);
                    break;
                case /* rd.company.Entity entity */ 3:
                    message.entity = Entity.internalBinaryRead(reader, reader.uint32(), options, message.entity);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: EntityLog, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID id = 1; */
        if (message.id)
            UUID.internalBinaryWrite(message.id, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp created_at = 2; */
        if (message.createdAt)
            Timestamp.internalBinaryWrite(message.createdAt, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* rd.company.Entity entity = 3; */
        if (message.entity)
            Entity.internalBinaryWrite(message.entity, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.company.EntityLog
 */
export const EntityLog = new EntityLog$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FinanceChargeSetting$Type extends MessageType<FinanceChargeSetting> {
    constructor() {
        super("rd.company.FinanceChargeSetting", [
            { no: 1, name: "id", kind: "message", T: () => UUID },
            { no: 2, name: "company_id", kind: "message", T: () => UUID },
            { no: 3, name: "created_at", kind: "message", T: () => Timestamp },
            { no: 4, name: "updated_at", kind: "message", T: () => Timestamp },
            { no: 5, name: "percentage_fee", kind: "message", T: () => Decimal },
            { no: 6, name: "min_assessment_balance", kind: "message", T: () => Money },
            { no: 7, name: "min_charge_fee", kind: "message", T: () => Money },
            { no: 8, name: "grace_period_days", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 9, name: "is_compounded", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 10, name: "position", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 11, name: "is_active", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 12, name: "is_default", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 13, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<FinanceChargeSetting>): FinanceChargeSetting {
        const message = { gracePeriodDays: 0, isCompounded: false, position: 0, isActive: false, isDefault: false, name: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<FinanceChargeSetting>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FinanceChargeSetting): FinanceChargeSetting {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID id */ 1:
                    message.id = UUID.internalBinaryRead(reader, reader.uint32(), options, message.id);
                    break;
                case /* rd.proto.uuid.UUID company_id */ 2:
                    message.companyId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.companyId);
                    break;
                case /* google.protobuf.Timestamp created_at */ 3:
                    message.createdAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.createdAt);
                    break;
                case /* google.protobuf.Timestamp updated_at */ 4:
                    message.updatedAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.updatedAt);
                    break;
                case /* rd.proto.decimal.Decimal percentage_fee */ 5:
                    message.percentageFee = Decimal.internalBinaryRead(reader, reader.uint32(), options, message.percentageFee);
                    break;
                case /* rd.proto.money.Money min_assessment_balance */ 6:
                    message.minAssessmentBalance = Money.internalBinaryRead(reader, reader.uint32(), options, message.minAssessmentBalance);
                    break;
                case /* rd.proto.money.Money min_charge_fee */ 7:
                    message.minChargeFee = Money.internalBinaryRead(reader, reader.uint32(), options, message.minChargeFee);
                    break;
                case /* int32 grace_period_days */ 8:
                    message.gracePeriodDays = reader.int32();
                    break;
                case /* bool is_compounded */ 9:
                    message.isCompounded = reader.bool();
                    break;
                case /* int32 position */ 10:
                    message.position = reader.int32();
                    break;
                case /* bool is_active */ 11:
                    message.isActive = reader.bool();
                    break;
                case /* bool is_default */ 12:
                    message.isDefault = reader.bool();
                    break;
                case /* string name */ 13:
                    message.name = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FinanceChargeSetting, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID id = 1; */
        if (message.id)
            UUID.internalBinaryWrite(message.id, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID company_id = 2; */
        if (message.companyId)
            UUID.internalBinaryWrite(message.companyId, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp created_at = 3; */
        if (message.createdAt)
            Timestamp.internalBinaryWrite(message.createdAt, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp updated_at = 4; */
        if (message.updatedAt)
            Timestamp.internalBinaryWrite(message.updatedAt, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.decimal.Decimal percentage_fee = 5; */
        if (message.percentageFee)
            Decimal.internalBinaryWrite(message.percentageFee, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money min_assessment_balance = 6; */
        if (message.minAssessmentBalance)
            Money.internalBinaryWrite(message.minAssessmentBalance, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money min_charge_fee = 7; */
        if (message.minChargeFee)
            Money.internalBinaryWrite(message.minChargeFee, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* int32 grace_period_days = 8; */
        if (message.gracePeriodDays !== 0)
            writer.tag(8, WireType.Varint).int32(message.gracePeriodDays);
        /* bool is_compounded = 9; */
        if (message.isCompounded !== false)
            writer.tag(9, WireType.Varint).bool(message.isCompounded);
        /* int32 position = 10; */
        if (message.position !== 0)
            writer.tag(10, WireType.Varint).int32(message.position);
        /* bool is_active = 11; */
        if (message.isActive !== false)
            writer.tag(11, WireType.Varint).bool(message.isActive);
        /* bool is_default = 12; */
        if (message.isDefault !== false)
            writer.tag(12, WireType.Varint).bool(message.isDefault);
        /* string name = 13; */
        if (message.name !== "")
            writer.tag(13, WireType.LengthDelimited).string(message.name);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.company.FinanceChargeSetting
 */
export const FinanceChargeSetting = new FinanceChargeSetting$Type();
