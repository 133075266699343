import { Timestamp as TimestampPB } from "gen/google/protobuf/timestamp_pb"
import { format } from "date-fns"

export class Timestamp {
  static format(f: Format, t?: TimestampPB): string {
    if (!t) {
      return ""
    }

    return format(TimestampPB.toDate(t), f)
  }

  // eqFromDate is a helper function to check if two possibly undefined Dates are equal
  static eqFromDate(dateA?: Date, dateB?: Date): boolean {
    if (!dateA && !dateB) {
      return true
    }

    if (!!dateA && !!dateB) {
      return TimestampPB.equals(
        TimestampPB.fromDate(dateA),
        TimestampPB.fromDate(dateB)
      )
    }

    return false
  }
}

export enum Format {
  DATE = "MMM d, yyyy",
  DATE_SHORT = "M/d/yy",
  TIME = "h:mm aaa",
  DATE_TIME = "MMM d, yyyy 'at' h:mm aaa",
  DAY_DATE_TIME = "E M/d/yy h:mm aaa",
  DATE_MONTH_YEAR = "MMMM yyyy",
  DATE_MONTH_YEAR_SHORT = "MMM yyyy",
  DATE_YEAR_MONTH_DAY = "yyyy_MM_dd",
}
