/* eslint-disable */
// @generated by protobuf-ts 2.9.0 with parameter generate_dependencies,add_pb_suffix,ts_nocheck,eslint_disable
// @generated from protobuf file "search/service/service.proto" (package "rd.search.service", syntax proto3)
// tslint:disable
// @ts-nocheck
import { GetSalesByDayCSVRes } from "../../api/analytics/service_pb";
import { GetSalesByDayCSVReq } from "../../api/analytics/service_pb";
import { GetSalesByDayPDFRes } from "../../api/analytics/service_pb";
import { GetSalesByDayPDFReq } from "../../api/analytics/service_pb";
import { GetSalesByDayRes } from "../../api/analytics/service_pb";
import { GetSalesByDayReq } from "../../api/analytics/service_pb";
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { InventoryChange } from "../../product/models_pb";
import { ExternalTransaction } from "../../accounting/models_pb";
import { ExternalTransaction_PaymentMethod } from "../../accounting/models_pb";
import { ExternalTransaction_Type } from "../../accounting/models_pb";
import { InventoryCountProduct_Status } from "../../product/models_pb";
import { Account } from "../../coa/models_pb";
import { FinancialTransaction } from "../../payments/models_pb";
import { FinancialTransaction_Status } from "../../payments/models_pb";
import { FinancialTransaction_Type } from "../../payments/models_pb";
import { CloseOut } from "../../txn/models_pb";
import { PettyCash } from "../../txn/models_pb";
import { Update } from "../cache/models_pb";
import { Any } from "../../google/protobuf/any_pb";
import { Transfer } from "../../txn/models_pb";
import { Transfer_Status } from "../../txn/models_pb";
import { Sale_Return } from "../../txn/models_pb";
import { SaleProduct } from "../../txn/models_pb";
import { PurchaseMethod } from "../../txn/models_pb";
import { Contacts_Contact } from "../../customer/models_pb";
import { TintColor } from "../../tintcolor/models_pb";
import { Sale_Status } from "../../txn/models_pb";
import { Tier } from "../../price/models_pb";
import { ProductCategory } from "../../company/models_pb";
import { Product as Product$ } from "../../catalog/models_pb";
import { ProductSalesAnalytics } from "../../txn/models_pb";
import { AdditionalFees_Fee } from "../../company/models_pb";
import { Inventories } from "../../product/models_pb";
import { PricingTiers } from "../../company/models_pb";
import { Product_TaxStatus } from "../../product/models_pb";
import { OrderShipment } from "../../txn/models_pb";
import { Vendor } from "../../vendors/models_pb";
import { Order } from "../../txn/models_pb";
import { Order_Status } from "../../txn/models_pb";
import { Product } from "../../product/models_pb";
import { CustomPrice } from "../../product/models_pb";
import { FinanceChargeSetting } from "../../company/models_pb";
import { CustomerSalesAnalytics } from "../../txn/models_pb";
import { Staff } from "../../company/models_pb";
import { Interaction } from "../../customer/models_pb";
import { CustomerBalance } from "../../txn/models_pb";
import { PricingTiers_Tier } from "../../company/models_pb";
import { FinancingTerms_Term } from "../../company/models_pb";
import { Contacts } from "../../customer/models_pb";
import { Card } from "../../payments/models_pb";
import { Customer } from "../../customer/models_pb";
import { Location } from "../../company/models_pb";
import { JobSite } from "../../customer/models_pb";
import { PurchaseOrder } from "../../customer/models_pb";
import { Money } from "../../proto/money/models_pb";
import { Sale } from "../../txn/models_pb";
import { LedgerEntry } from "../../txn/models_pb";
import { BillPayment } from "../../txn/models_pb";
import { LedgerEntry_Type } from "../../txn/models_pb";
import { PurchaseMethod_Method } from "../../txn/models_pb";
import { UUID } from "../../proto/uuid/models_pb";
import { TimestampRange } from "../../proto/timestamprange/models_pb";
/**
 * @generated from protobuf message rd.search.service.SearchBillPaymentsReq
 */
export interface SearchBillPaymentsReq {
    /**
     * @generated from protobuf field: string query = 1;
     */
    query: string;
    /**
     * @generated from protobuf field: rd.proto.timestamprange.TimestampRange created_at_range = 2;
     */
    createdAtRange?: TimestampRange;
    /**
     * @generated from protobuf field: repeated rd.proto.uuid.UUID customer_ids = 3;
     */
    customerIds: UUID[];
    /**
     * @generated from protobuf field: repeated rd.proto.uuid.UUID created_by_ids = 4;
     */
    createdByIds: UUID[];
    /**
     * @generated from protobuf field: repeated rd.proto.uuid.UUID location_ids = 5;
     */
    locationIds: UUID[];
    /**
     * @generated from protobuf field: string cursor = 6;
     */
    cursor: string;
    /**
     * @generated from protobuf field: int32 offset = 7;
     */
    offset: number;
    /**
     * @generated from protobuf field: int32 limit = 8;
     */
    limit: number;
    /**
     * @generated from protobuf field: repeated rd.txn.PurchaseMethod.Method purchase_methods = 9;
     */
    purchaseMethods: PurchaseMethod_Method[];
    /**
     * @generated from protobuf field: repeated rd.txn.LedgerEntry.Type bill_payment_types = 10;
     */
    billPaymentTypes: LedgerEntry_Type[];
    /**
     * @generated from protobuf field: repeated rd.search.service.SearchBillPaymentsReq.CreditFilter credit_filters = 11;
     */
    creditFilters: SearchBillPaymentsReq_CreditFilter[];
}
/**
 * @generated from protobuf enum rd.search.service.SearchBillPaymentsReq.CreditFilter
 */
export enum SearchBillPaymentsReq_CreditFilter {
    /**
     * @generated from protobuf enum value: NO_CREDIT = 0;
     */
    NO_CREDIT = 0,
    /**
     * @generated from protobuf enum value: ADDS_CREDIT = 1;
     */
    ADDS_CREDIT = 1,
    /**
     * @generated from protobuf enum value: USES_CREDIT = 2;
     */
    USES_CREDIT = 2
}
/**
 * @generated from protobuf message rd.search.service.SearchBillPaymentsRes
 */
export interface SearchBillPaymentsRes {
    /**
     * @generated from protobuf field: repeated rd.search.service.SearchBillPaymentsRes.Result results = 1;
     */
    results: SearchBillPaymentsRes_Result[];
    /**
     * @generated from protobuf field: string cursor = 2;
     */
    cursor: string;
}
/**
 * @generated from protobuf message rd.search.service.SearchBillPaymentsRes.Result
 */
export interface SearchBillPaymentsRes_Result {
    /**
     * @generated from protobuf field: rd.txn.BillPayment bill_payment = 1;
     */
    billPayment?: BillPayment;
    /**
     * @generated from protobuf field: rd.txn.LedgerEntry payment_ledger_entry = 2;
     */
    paymentLedgerEntry?: LedgerEntry;
    /**
     * @generated from protobuf field: rd.txn.LedgerEntry write_off_ledger_entry = 3;
     */
    writeOffLedgerEntry?: LedgerEntry;
    /**
     * @generated from protobuf field: map<string, rd.txn.Sale> sales_by_id = 4;
     */
    salesById: {
        [key: string]: Sale;
    };
    /**
     * @generated from protobuf field: map<string, rd.proto.money.Money> allocations_by_sale_id = 5;
     */
    allocationsBySaleId: {
        [key: string]: Money;
    };
    /**
     * @generated from protobuf field: map<string, rd.proto.money.Money> totals_by_sale_id = 6;
     */
    totalsBySaleId: {
        [key: string]: Money;
    };
    /**
     * @generated from protobuf field: map<string, rd.customer.PurchaseOrder> purchase_orders_by_sale_id = 7;
     */
    purchaseOrdersBySaleId: {
        [key: string]: PurchaseOrder;
    };
    /**
     * @generated from protobuf field: map<string, rd.customer.JobSite> job_sites_by_sale_id = 8;
     */
    jobSitesBySaleId: {
        [key: string]: JobSite;
    };
    /**
     * @generated from protobuf field: map<string, rd.company.Location> locations_by_sale_id = 9;
     */
    locationsBySaleId: {
        [key: string]: Location;
    };
    /**
     * @generated from protobuf field: rd.customer.Customer customer = 10;
     */
    customer?: Customer;
    /**
     * @generated from protobuf field: rd.payments.Card payment_card = 11;
     */
    paymentCard?: Card;
}
/**
 * @generated from protobuf message rd.search.service.SearchRefundedBillPaymentsReq
 */
export interface SearchRefundedBillPaymentsReq {
    /**
     * @generated from protobuf field: string query = 1;
     */
    query: string;
    /**
     * @generated from protobuf field: rd.proto.timestamprange.TimestampRange created_at_range = 2;
     */
    createdAtRange?: TimestampRange;
    /**
     * @generated from protobuf field: repeated rd.proto.uuid.UUID customer_ids = 3;
     */
    customerIds: UUID[];
    /**
     * @generated from protobuf field: repeated rd.proto.uuid.UUID created_by_ids = 4;
     */
    createdByIds: UUID[];
    /**
     * @generated from protobuf field: repeated rd.proto.uuid.UUID location_ids = 5;
     */
    locationIds: UUID[];
    /**
     * @generated from protobuf field: string cursor = 6;
     */
    cursor: string;
    /**
     * @generated from protobuf field: int32 offset = 7;
     */
    offset: number;
    /**
     * @generated from protobuf field: int32 limit = 8;
     */
    limit: number;
}
/**
 * @generated from protobuf message rd.search.service.SearchRefundedBillPaymentsRes
 */
export interface SearchRefundedBillPaymentsRes {
    /**
     * @generated from protobuf field: repeated rd.search.service.SearchRefundedBillPaymentsRes.Result results = 1;
     */
    results: SearchRefundedBillPaymentsRes_Result[];
    /**
     * @generated from protobuf field: string cursor = 2;
     */
    cursor: string;
}
/**
 * @generated from protobuf message rd.search.service.SearchRefundedBillPaymentsRes.Result
 */
export interface SearchRefundedBillPaymentsRes_Result {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID payment_ledger_entry_id = 1;
     */
    paymentLedgerEntryId?: UUID;
    /**
     * @generated from protobuf field: rd.txn.BillPayment bill_payment = 2;
     */
    billPayment?: BillPayment;
    /**
     * @generated from protobuf field: rd.txn.LedgerEntry payment_ledger_entry = 3;
     */
    paymentLedgerEntry?: LedgerEntry;
    /**
     * @generated from protobuf field: map<string, rd.txn.Sale> sales_by_id = 4;
     */
    salesById: {
        [key: string]: Sale;
    };
    /**
     * @generated from protobuf field: map<string, rd.proto.money.Money> allocations_by_sale_id = 5;
     */
    allocationsBySaleId: {
        [key: string]: Money;
    };
    /**
     * @generated from protobuf field: map<string, rd.proto.money.Money> totals_by_sale_id = 6;
     */
    totalsBySaleId: {
        [key: string]: Money;
    };
    /**
     * @generated from protobuf field: map<string, rd.customer.PurchaseOrder> purchase_orders_by_sale_id = 7;
     */
    purchaseOrdersBySaleId: {
        [key: string]: PurchaseOrder;
    };
    /**
     * @generated from protobuf field: map<string, rd.customer.JobSite> job_sites_by_sale_id = 8;
     */
    jobSitesBySaleId: {
        [key: string]: JobSite;
    };
    /**
     * @generated from protobuf field: map<string, rd.company.Location> locations_by_sale_id = 9;
     */
    locationsBySaleId: {
        [key: string]: Location;
    };
    /**
     * @generated from protobuf field: rd.customer.Customer customer = 10;
     */
    customer?: Customer;
    /**
     * @generated from protobuf field: rd.payments.Card payment_card = 11;
     */
    paymentCard?: Card;
}
/**
 * @generated from protobuf message rd.search.service.SearchCustomersV2Req
 */
export interface SearchCustomersV2Req {
    /**
     * @generated from protobuf field: string query = 1;
     */
    query: string;
    /**
     * @generated from protobuf field: repeated string financing_term_id_strings = 3;
     */
    financingTermIdStrings: string[];
    /**
     * @generated from protobuf field: repeated string pricing_tier_id_strings = 4;
     */
    pricingTierIdStrings: string[];
    /**
     * @generated from protobuf field: rd.search.service.BoolFilter is_on_credit_hold_filter = 5;
     */
    isOnCreditHoldFilter: BoolFilter;
    /**
     * @generated from protobuf field: repeated rd.proto.uuid.UUID rep_staff_ids = 6;
     */
    repStaffIds: UUID[];
    /**
     * @generated from protobuf field: rd.search.service.BoolFilter is_active_filter = 7;
     */
    isActiveFilter: BoolFilter;
    /**
     * @generated from protobuf field: rd.search.service.SortOrder sort_order = 9;
     */
    sortOrder: SortOrder;
    /**
     * @generated from protobuf field: string cursor = 10;
     */
    cursor: string;
    /**
     * @generated from protobuf field: int32 offset = 11;
     */
    offset: number;
    /**
     * @generated from protobuf field: int32 limit = 12;
     */
    limit: number;
    /**
     * @generated from protobuf field: rd.search.service.OrderCustomersBy order_by = 13;
     */
    orderBy: OrderCustomersBy;
    /**
     * @generated from protobuf field: repeated rd.proto.uuid.UUID finance_charge_settings_ids = 14;
     */
    financeChargeSettingsIds: UUID[];
}
/**
 * @generated from protobuf message rd.search.service.SearchCustomersV2Res
 */
export interface SearchCustomersV2Res {
    /**
     * @generated from protobuf field: repeated rd.search.service.SearchCustomersV2Res.Result results = 1;
     */
    results: SearchCustomersV2Res_Result[];
    /**
     * @generated from protobuf field: string cursor = 2;
     */
    cursor: string;
}
/**
 * @generated from protobuf message rd.search.service.SearchCustomersV2Res.Result
 */
export interface SearchCustomersV2Res_Result {
    /**
     * @generated from protobuf field: rd.customer.Customer customer = 1;
     */
    customer?: Customer;
    /**
     * @generated from protobuf field: rd.customer.Contacts contacts = 2;
     */
    contacts?: Contacts;
    /**
     * @generated from protobuf field: rd.company.FinancingTerms.Term financing_term = 4;
     */
    financingTerm?: FinancingTerms_Term;
    /**
     * @generated from protobuf field: rd.company.PricingTiers.Tier pricing_tier = 5;
     */
    pricingTier?: PricingTiers_Tier;
    /**
     * @generated from protobuf field: rd.txn.CustomerBalance balance = 6;
     */
    balance?: CustomerBalance;
    /**
     * @generated from protobuf field: rd.proto.money.Money total_balance = 7;
     */
    totalBalance?: Money;
    /**
     * @generated from protobuf field: string financing_term_name = 8;
     */
    financingTermName: string;
    /**
     * @generated from protobuf field: rd.customer.Interaction last_interaction = 10;
     */
    lastInteraction?: Interaction;
    /**
     * @generated from protobuf field: rd.company.Staff rep_staff = 11;
     */
    repStaff?: Staff;
    /**
     * @generated from protobuf field: rd.txn.CustomerSalesAnalytics analytics = 13;
     */
    analytics?: CustomerSalesAnalytics;
    /**
     * @generated from protobuf field: rd.company.FinanceChargeSetting finance_charge_setting = 14;
     */
    financeChargeSetting?: FinanceChargeSetting;
}
/**
 * @generated from protobuf message rd.search.service.BulkExportCustomersReq
 */
export interface BulkExportCustomersReq {
    /**
     * @generated from protobuf field: string query = 1;
     */
    query: string;
    /**
     * @generated from protobuf field: repeated string financing_term_id_strings = 3;
     */
    financingTermIdStrings: string[];
    /**
     * @generated from protobuf field: repeated string pricing_tier_id_strings = 4;
     */
    pricingTierIdStrings: string[];
    /**
     * @generated from protobuf field: rd.search.service.BoolFilter is_on_credit_hold_filter = 5;
     */
    isOnCreditHoldFilter: BoolFilter;
    /**
     * @generated from protobuf field: repeated rd.proto.uuid.UUID rep_staff_ids = 6;
     */
    repStaffIds: UUID[];
    /**
     * @generated from protobuf field: rd.search.service.BoolFilter is_active_filter = 7;
     */
    isActiveFilter: BoolFilter;
    /**
     * @generated from protobuf field: rd.search.service.OrderCustomersBy order_by = 8;
     */
    orderBy: OrderCustomersBy;
    /**
     * @generated from protobuf field: rd.search.service.SortOrder sort_order = 9;
     */
    sortOrder: SortOrder;
    /**
     * @generated from protobuf field: bool is_template = 10;
     */
    isTemplate: boolean;
    /**
     * @generated from protobuf field: repeated rd.proto.uuid.UUID finance_charge_setting_ids = 11;
     */
    financeChargeSettingIds: UUID[];
}
/**
 * @generated from protobuf message rd.search.service.BulkExportCustomersRes
 */
export interface BulkExportCustomersRes {
    /**
     * @generated from protobuf field: bytes excel_bytes = 1;
     */
    excelBytes: Uint8Array;
}
/**
 * @generated from protobuf message rd.search.service.SearchCustomPricesReq
 */
export interface SearchCustomPricesReq {
    /**
     * @generated from protobuf field: string query = 1;
     */
    query: string;
}
/**
 * @generated from protobuf message rd.search.service.SearchCustomPricesRes
 */
export interface SearchCustomPricesRes {
    /**
     * @generated from protobuf field: repeated rd.search.service.SearchCustomPricesRes.Result results = 1;
     */
    results: SearchCustomPricesRes_Result[];
}
/**
 * @generated from protobuf message rd.search.service.SearchCustomPricesRes.Result
 */
export interface SearchCustomPricesRes_Result {
    /**
     * @generated from protobuf field: rd.customer.Customer customer = 1;
     */
    customer?: Customer;
    /**
     * @generated from protobuf field: repeated rd.product.CustomPrice custom_prices = 2;
     */
    customPrices: CustomPrice[];
    /**
     * @generated from protobuf field: map<string, rd.product.Product> products_by_id = 3;
     */
    productsById: {
        [key: string]: Product;
    };
}
/**
 * @generated from protobuf message rd.search.service.SearchOrdersReq
 */
export interface SearchOrdersReq {
    /**
     * @generated from protobuf field: string query = 1;
     */
    query: string;
    /**
     * @generated from protobuf field: rd.proto.timestamprange.TimestampRange created_at_range = 2;
     */
    createdAtRange?: TimestampRange;
    /**
     * @generated from protobuf field: rd.proto.timestamprange.TimestampRange ordered_at_range = 3;
     */
    orderedAtRange?: TimestampRange;
    /**
     * @generated from protobuf field: rd.proto.timestamprange.TimestampRange received_at_range = 4;
     */
    receivedAtRange?: TimestampRange;
    /**
     * @generated from protobuf field: rd.proto.timestamprange.TimestampRange voided_at_range = 5;
     */
    voidedAtRange?: TimestampRange;
    /**
     * @generated from protobuf field: repeated rd.proto.uuid.UUID location_ids = 6;
     */
    locationIds: UUID[];
    /**
     * @generated from protobuf field: repeated rd.txn.Order.Status statuses = 7;
     */
    statuses: Order_Status[];
    /**
     * @generated from protobuf field: string cursor = 8;
     */
    cursor: string;
    /**
     * @generated from protobuf field: int32 offset = 9;
     */
    offset: number;
    /**
     * @generated from protobuf field: int32 limit = 10;
     */
    limit: number;
}
/**
 * @generated from protobuf message rd.search.service.SearchOrdersRes
 */
export interface SearchOrdersRes {
    /**
     * @generated from protobuf field: repeated rd.search.service.SearchOrdersRes.Result results = 1;
     */
    results: SearchOrdersRes_Result[];
    /**
     * @generated from protobuf field: string cursor = 2;
     */
    cursor: string;
}
/**
 * @generated from protobuf message rd.search.service.SearchOrdersRes.Result
 */
export interface SearchOrdersRes_Result {
    /**
     * @generated from protobuf field: rd.txn.Order order = 1;
     */
    order?: Order;
    /**
     * @generated from protobuf field: rd.vendors.Vendor vendor = 2;
     */
    vendor?: Vendor;
    /**
     * @generated from protobuf field: rd.company.Location location = 3;
     */
    location?: Location;
    /**
     * @generated from protobuf field: map<string, rd.product.Product> products_by_id = 4;
     */
    productsById: {
        [key: string]: Product;
    };
    /**
     * @generated from protobuf field: map<string, rd.product.Product> matched_products_by_id = 5;
     */
    matchedProductsById: {
        [key: string]: Product;
    };
    /**
     * @generated from protobuf field: rd.proto.money.Money total = 6;
     */
    total?: Money;
    /**
     * @generated from protobuf field: repeated rd.txn.OrderShipment order_shipments = 7;
     */
    orderShipments: OrderShipment[];
}
/**
 * @generated from protobuf message rd.search.service.SearchOrderShipmentsReq
 */
export interface SearchOrderShipmentsReq {
    /**
     * @generated from protobuf field: string query = 1;
     */
    query: string;
    /**
     * @generated from protobuf field: repeated rd.proto.uuid.UUID location_ids = 3;
     */
    locationIds: UUID[];
    /**
     * @generated from protobuf field: string cursor = 4;
     */
    cursor: string;
    /**
     * @generated from protobuf field: int32 offset = 5;
     */
    offset: number;
    /**
     * @generated from protobuf field: int32 limit = 6;
     */
    limit: number;
    /**
     * @generated from protobuf field: rd.search.service.BoolFilter isPaid = 10;
     */
    isPaid: BoolFilter;
    /**
     * @generated from protobuf field: rd.proto.timestamprange.TimestampRange timestamp_range = 11;
     */
    timestampRange?: TimestampRange;
}
/**
 * @generated from protobuf message rd.search.service.SearchOrderShipmentsRes
 */
export interface SearchOrderShipmentsRes {
    /**
     * @generated from protobuf field: repeated rd.search.service.SearchOrderShipmentsRes.Result results = 1;
     */
    results: SearchOrderShipmentsRes_Result[];
    /**
     * @generated from protobuf field: string cursor = 2;
     */
    cursor: string;
}
/**
 * @generated from protobuf message rd.search.service.SearchOrderShipmentsRes.Result
 */
export interface SearchOrderShipmentsRes_Result {
    /**
     * @generated from protobuf field: rd.txn.OrderShipment order_shipment = 1;
     */
    orderShipment?: OrderShipment;
    /**
     * @generated from protobuf field: rd.vendors.Vendor vendor = 2;
     */
    vendor?: Vendor;
    /**
     * @generated from protobuf field: rd.company.Location location = 3;
     */
    location?: Location;
    /**
     * @generated from protobuf field: map<string, rd.product.Product> products_by_id = 4;
     */
    productsById: {
        [key: string]: Product;
    };
    /**
     * @generated from protobuf field: map<string, rd.product.Product> matched_products_by_id = 5;
     */
    matchedProductsById: {
        [key: string]: Product;
    };
    /**
     * @generated from protobuf field: rd.proto.money.Money total = 6;
     */
    total?: Money;
}
/**
 * @generated from protobuf message rd.search.service.SearchProductsV2Req
 */
export interface SearchProductsV2Req {
    /**
     * @generated from protobuf field: string query = 1;
     */
    query: string;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID inv_sort_location_id = 2;
     */
    invSortLocationId?: UUID;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID current_location_id = 3;
     */
    currentLocationId?: UUID;
    /**
     * @generated from protobuf field: repeated rd.proto.uuid.UUID vendor_ids = 5;
     */
    vendorIds: UUID[];
    /**
     * @generated from protobuf field: repeated string additional_fee_id_strings = 6;
     */
    additionalFeeIdStrings: string[];
    /**
     * @generated from protobuf field: rd.search.service.BoolFilter is_active_filter = 7;
     */
    isActiveFilter: BoolFilter;
    /**
     * @generated from protobuf field: rd.search.service.BoolFilter is_tintable_filter = 9;
     */
    isTintableFilter: BoolFilter;
    /**
     * @generated from protobuf field: string cursor = 10;
     */
    cursor: string;
    /**
     * @generated from protobuf field: int32 offset = 11;
     */
    offset: number;
    /**
     * @generated from protobuf field: int32 limit = 12;
     */
    limit: number;
    /**
     * @generated from protobuf field: rd.search.service.SearchProductsV2Req.OrderBy order_by = 13;
     */
    orderBy: SearchProductsV2Req_OrderBy;
    /**
     * @generated from protobuf field: rd.search.service.SortOrder sort_order = 14;
     */
    sortOrder: SortOrder;
    /**
     * @generated from protobuf field: repeated rd.product.Product.TaxStatus tax_statuses = 15;
     */
    taxStatuses: Product_TaxStatus[];
    /**
     * @generated from protobuf field: repeated rd.proto.uuid.UUID department_ids = 16;
     */
    departmentIds: UUID[];
    /**
     * @generated from protobuf field: repeated rd.proto.uuid.UUID class_ids = 17;
     */
    classIds: UUID[];
    /**
     * @generated from protobuf field: repeated rd.proto.uuid.UUID fineline_ids = 18;
     */
    finelineIds: UUID[];
}
/**
 * @generated from protobuf enum rd.search.service.SearchProductsV2Req.OrderBy
 */
export enum SearchProductsV2Req_OrderBy {
    /**
     * @generated from protobuf enum value: ORDER_BY_NONE = 0;
     */
    NONE = 0,
    /**
     * @generated from protobuf enum value: ORDER_BY_NAME = 1;
     */
    NAME = 1,
    /**
     * @generated from protobuf enum value: ORDER_BY_INVENTORY_LOCATION_ID = 2;
     */
    INVENTORY_LOCATION_ID = 2,
    /**
     * @generated from protobuf enum value: ORDER_BY_TARGET = 3;
     */
    TARGET = 3,
    /**
     * @generated from protobuf enum value: ORDER_BY_CURRENT = 4;
     */
    CURRENT = 4,
    /**
     * @generated from protobuf enum value: ORDER_BY_REORDER = 5;
     */
    REORDER = 5,
    /**
     * @generated from protobuf enum value: ORDER_BY_DELTA = 6;
     */
    DELTA = 6,
    /**
     * @generated from protobuf enum value: ORDER_BY_UNITS_SOLD_YTD = 7;
     */
    UNITS_SOLD_YTD = 7,
    /**
     * @generated from protobuf enum value: ORDER_BY_UNITS_VS_LAST_YEAR = 8;
     */
    UNITS_VS_LAST_YEAR = 8,
    /**
     * @generated from protobuf enum value: ORDER_BY_MARGIN = 9;
     */
    MARGIN = 9,
    /**
     * @generated from protobuf enum value: ORDER_BY_UNITS_LAST_12MO = 10;
     */
    UNITS_LAST_12MO = 10,
    /**
     * @generated from protobuf enum value: ORDER_BY_UNITS_LAST_3MO = 11;
     */
    UNITS_LAST_3MO = 11,
    /**
     * @generated from protobuf enum value: ORDER_BY_UNITS_LAST_1MO = 12;
     */
    UNITS_LAST_1MO = 12,
    /**
     * @generated from protobuf enum value: ORDER_BY_LAST_SALE = 13;
     */
    LAST_SALE = 13,
    /**
     * @generated from protobuf enum value: ORDER_BY_TURNS = 14;
     */
    TURNS = 14
}
/**
 * @generated from protobuf message rd.search.service.SearchProductsV2Res
 */
export interface SearchProductsV2Res {
    /**
     * @generated from protobuf field: repeated rd.search.service.SearchProductsV2Res.Result results = 1;
     */
    results: SearchProductsV2Res_Result[];
    /**
     * @generated from protobuf field: map<string, rd.company.Location> locations_by_id = 2;
     */
    locationsById: {
        [key: string]: Location;
    };
    /**
     * @generated from protobuf field: rd.company.PricingTiers pricing_tiers = 3;
     */
    pricingTiers?: PricingTiers;
    /**
     * @generated from protobuf field: string cursor = 4;
     */
    cursor: string;
}
/**
 * @generated from protobuf message rd.search.service.SearchProductsV2Res.Result
 */
export interface SearchProductsV2Res_Result {
    /**
     * @generated from protobuf field: rd.product.Product product = 1;
     */
    product?: Product;
    /**
     * @generated from protobuf field: rd.product.Product matched_price_product = 2;
     */
    matchedPriceProduct?: Product;
    /**
     * @generated from protobuf field: rd.product.Product matched_cost_product = 3;
     */
    matchedCostProduct?: Product;
    /**
     * @generated from protobuf field: rd.product.Inventories inventories = 4;
     */
    inventories?: Inventories;
    /**
     * @generated from protobuf field: rd.vendors.Vendor primary_cost_vendor = 5;
     */
    primaryCostVendor?: Vendor;
    /**
     * @generated from protobuf field: rd.company.AdditionalFees.Fee additional_fee = 7;
     */
    additionalFee?: AdditionalFees_Fee;
    /**
     * @generated from protobuf field: int32 delta = 9;
     */
    delta: number;
    /**
     * @generated from protobuf field: rd.txn.ProductSalesAnalytics analytics = 10;
     */
    analytics?: ProductSalesAnalytics;
    /**
     * @generated from protobuf field: rd.catalog.Product catalog_product = 11;
     */
    catalogProduct?: Product$;
    /**
     * @generated from protobuf field: rd.company.ProductCategory department = 12;
     */
    department?: ProductCategory;
    /**
     * @generated from protobuf field: rd.company.ProductCategory class = 13;
     */
    class?: ProductCategory;
    /**
     * @generated from protobuf field: rd.company.ProductCategory fineline = 14;
     */
    fineline?: ProductCategory;
    /**
     * @generated from protobuf field: repeated rd.price.Tier tier_prices = 15;
     */
    tierPrices: Tier[];
}
/**
 * @generated from protobuf message rd.search.service.BulkExportProductsReq
 */
export interface BulkExportProductsReq {
    /**
     * @generated from protobuf field: string query = 1;
     */
    query: string;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID inv_sort_location_id = 2;
     */
    invSortLocationId?: UUID;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID current_location_id = 3;
     */
    currentLocationId?: UUID;
    /**
     * @generated from protobuf field: repeated rd.proto.uuid.UUID vendor_ids = 5;
     */
    vendorIds: UUID[];
    /**
     * @generated from protobuf field: repeated string additional_fee_id_strings = 6;
     */
    additionalFeeIdStrings: string[];
    /**
     * @generated from protobuf field: rd.search.service.BoolFilter is_active_filter = 7;
     */
    isActiveFilter: BoolFilter;
    /**
     * @generated from protobuf field: rd.search.service.BoolFilter is_tintable_filter = 8;
     */
    isTintableFilter: BoolFilter;
    /**
     * @generated from protobuf field: rd.search.service.SearchProductOrderBy order_by = 9;
     */
    orderBy: SearchProductOrderBy;
    /**
     * @generated from protobuf field: rd.search.service.SortOrder sort_order = 10;
     */
    sortOrder: SortOrder;
    /**
     * @generated from protobuf field: repeated rd.product.Product.TaxStatus tax_statuses = 11;
     */
    taxStatuses: Product_TaxStatus[];
    /**
     * @generated from protobuf field: bool is_template = 16;
     */
    isTemplate: boolean;
    /**
     * @generated from protobuf field: repeated rd.proto.uuid.UUID department_ids = 17;
     */
    departmentIds: UUID[];
    /**
     * @generated from protobuf field: repeated rd.proto.uuid.UUID class_ids = 18;
     */
    classIds: UUID[];
    /**
     * @generated from protobuf field: repeated rd.proto.uuid.UUID fineline_ids = 19;
     */
    finelineIds: UUID[];
}
/**
 * @generated from protobuf message rd.search.service.BulkExportProductsRes
 */
export interface BulkExportProductsRes {
    /**
     * @generated from protobuf field: bytes excel_bytes = 1;
     */
    excelBytes: Uint8Array;
}
/**
 * @generated from protobuf message rd.search.service.SearchSalesV2Req
 */
export interface SearchSalesV2Req {
    /**
     * @generated from protobuf field: string query = 1;
     */
    query: string;
    /**
     * @generated from protobuf field: rd.proto.timestamprange.TimestampRange created_at_range = 2;
     */
    createdAtRange?: TimestampRange;
    /**
     * @generated from protobuf field: rd.proto.timestamprange.TimestampRange quoted_at_range = 3;
     */
    quotedAtRange?: TimestampRange;
    /**
     * @generated from protobuf field: rd.proto.timestamprange.TimestampRange will_called_at_range = 4;
     */
    willCalledAtRange?: TimestampRange;
    /**
     * @generated from protobuf field: rd.proto.timestamprange.TimestampRange sold_at_range = 5;
     */
    soldAtRange?: TimestampRange;
    /**
     * @generated from protobuf field: rd.proto.timestamprange.TimestampRange voided_at_range = 6;
     */
    voidedAtRange?: TimestampRange;
    /**
     * @generated from protobuf field: repeated rd.proto.uuid.UUID created_by_ids = 7;
     */
    createdByIds: UUID[];
    /**
     * @generated from protobuf field: repeated rd.proto.uuid.UUID quoted_by_ids = 8;
     */
    quotedByIds: UUID[];
    /**
     * @generated from protobuf field: repeated rd.proto.uuid.UUID will_called_by_ids = 9;
     */
    willCalledByIds: UUID[];
    /**
     * @generated from protobuf field: repeated rd.proto.uuid.UUID sold_by_ids = 10;
     */
    soldByIds: UUID[];
    /**
     * @generated from protobuf field: repeated rd.proto.uuid.UUID voided_by_ids = 11;
     */
    voidedByIds: UUID[];
    /**
     * @generated from protobuf field: repeated rd.proto.uuid.UUID customer_ids = 12;
     */
    customerIds: UUID[];
    /**
     * @generated from protobuf field: repeated rd.proto.uuid.UUID location_ids = 13;
     */
    locationIds: UUID[];
    /**
     * @generated from protobuf field: repeated rd.txn.Sale.Status statuses = 14;
     */
    statuses: Sale_Status[];
    /**
     * @generated from protobuf field: string cursor = 15;
     */
    cursor: string;
    /**
     * @generated from protobuf field: int32 offset = 16;
     */
    offset: number;
    /**
     * @generated from protobuf field: int32 limit = 17;
     */
    limit: number;
    /**
     * @generated from protobuf field: repeated rd.proto.uuid.UUID purchase_order_ids = 18;
     */
    purchaseOrderIds: UUID[];
    /**
     * @generated from protobuf field: repeated rd.proto.uuid.UUID tint_color_ids = 19;
     */
    tintColorIds: UUID[];
    /**
     * @generated from protobuf field: rd.search.service.BoolFilter contains_non_taxable_products_filter = 20;
     */
    containsNonTaxableProductsFilter: BoolFilter;
    /**
     * @generated from protobuf field: rd.txn.PurchaseMethod.Method purchase_method = 21;
     */
    purchaseMethod: PurchaseMethod_Method;
}
/**
 * @generated from protobuf message rd.search.service.SearchSalesV2Res
 */
export interface SearchSalesV2Res {
    /**
     * @generated from protobuf field: repeated rd.search.service.SearchSalesV2Res.Result results = 1;
     */
    results: SearchSalesV2Res_Result[];
    /**
     * @generated from protobuf field: string cursor = 2;
     */
    cursor: string;
}
/**
 * @generated from protobuf message rd.search.service.SearchSalesV2Res.Result
 */
export interface SearchSalesV2Res_Result {
    /**
     * @generated from protobuf field: rd.txn.Sale sale = 1;
     */
    sale?: Sale;
    /**
     * @generated from protobuf field: rd.company.Location location = 2;
     */
    location?: Location;
    /**
     * @generated from protobuf field: rd.customer.Customer customer = 3;
     */
    customer?: Customer;
    /**
     * @generated from protobuf field: rd.customer.PurchaseOrder purchase_order = 4;
     */
    purchaseOrder?: PurchaseOrder;
    /**
     * @generated from protobuf field: rd.customer.JobSite job_site = 5;
     */
    jobSite?: JobSite;
    /**
     * @generated from protobuf field: map<string, rd.product.Product> products_by_id = 6;
     */
    productsById: {
        [key: string]: Product;
    };
    /**
     * @generated from protobuf field: map<string, rd.product.Product> matched_products_by_id = 7;
     */
    matchedProductsById: {
        [key: string]: Product;
    };
    /**
     * @generated from protobuf field: map<string, rd.tintcolor.TintColor> tint_colors_by_id = 8;
     */
    tintColorsById: {
        [key: string]: TintColor;
    };
    /**
     * @generated from protobuf field: rd.proto.money.Money total = 9;
     */
    total?: Money;
    /**
     * @generated from protobuf field: rd.customer.Contacts.Contact customer_contact = 10;
     */
    customerContact?: Contacts_Contact;
    /**
     * @generated from protobuf field: repeated rd.txn.PurchaseMethod purchase_methods = 11;
     */
    purchaseMethods: PurchaseMethod[];
    /**
     * @generated from protobuf field: repeated rd.txn.SaleProduct sale_products = 12;
     */
    saleProducts: SaleProduct[];
}
/**
 * @generated from protobuf message rd.search.service.SearchReturnsReq
 */
export interface SearchReturnsReq {
    /**
     * @generated from protobuf field: string query = 1;
     */
    query: string;
    /**
     * @generated from protobuf field: rd.proto.timestamprange.TimestampRange created_at_range = 2;
     */
    createdAtRange?: TimestampRange;
    /**
     * @generated from protobuf field: repeated rd.proto.uuid.UUID created_by_ids = 3;
     */
    createdByIds: UUID[];
    /**
     * @generated from protobuf field: repeated rd.proto.uuid.UUID customer_ids = 4;
     */
    customerIds: UUID[];
    /**
     * @generated from protobuf field: repeated rd.proto.uuid.UUID location_ids = 5;
     */
    locationIds: UUID[];
    /**
     * @generated from protobuf field: string cursor = 15;
     */
    cursor: string;
    /**
     * @generated from protobuf field: int32 offset = 16;
     */
    offset: number;
    /**
     * @generated from protobuf field: int32 limit = 17;
     */
    limit: number;
}
/**
 * @generated from protobuf message rd.search.service.SearchReturnsRes
 */
export interface SearchReturnsRes {
    /**
     * @generated from protobuf field: repeated rd.search.service.SearchReturnsRes.Result results = 1;
     */
    results: SearchReturnsRes_Result[];
    /**
     * @generated from protobuf field: string cursor = 2;
     */
    cursor: string;
}
/**
 * @generated from protobuf message rd.search.service.SearchReturnsRes.Result
 */
export interface SearchReturnsRes_Result {
    /**
     * @generated from protobuf field: rd.txn.LedgerEntry return_ledger_entry = 1;
     */
    returnLedgerEntry?: LedgerEntry;
    /**
     * @generated from protobuf field: rd.txn.Sale sale = 2;
     */
    sale?: Sale;
    /**
     * @generated from protobuf field: rd.company.Location location = 3;
     */
    location?: Location;
    /**
     * @generated from protobuf field: rd.customer.Customer customer = 4;
     */
    customer?: Customer;
    /**
     * @generated from protobuf field: rd.customer.PurchaseOrder purchase_order = 5;
     */
    purchaseOrder?: PurchaseOrder;
    /**
     * @generated from protobuf field: rd.customer.JobSite job_site = 6;
     */
    jobSite?: JobSite;
    /**
     * @generated from protobuf field: map<string, rd.product.Product> products_by_id = 7;
     */
    productsById: {
        [key: string]: Product;
    };
    /**
     * @generated from protobuf field: map<string, rd.product.Product> matched_products_by_id = 8;
     */
    matchedProductsById: {
        [key: string]: Product;
    };
    /**
     * @generated from protobuf field: map<string, rd.tintcolor.TintColor> tint_colors_by_id = 9;
     */
    tintColorsById: {
        [key: string]: TintColor;
    };
    /**
     * @generated from protobuf field: rd.customer.Contacts.Contact customer_contact = 10;
     */
    customerContact?: Contacts_Contact;
    /**
     * @generated from protobuf field: rd.txn.Sale.Return sale_return = 13;
     */
    saleReturn?: Sale_Return;
    /**
     * @generated from protobuf field: string return_method = 14;
     */
    returnMethod: string;
}
/**
 * @generated from protobuf message rd.search.service.SearchOutstandingSalesReq
 */
export interface SearchOutstandingSalesReq {
    /**
     * @generated from protobuf field: string query = 1;
     */
    query: string;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID customer_id = 2;
     */
    customerId?: UUID;
}
/**
 * @generated from protobuf message rd.search.service.SearchOutstandingSalesRes
 */
export interface SearchOutstandingSalesRes {
    /**
     * @generated from protobuf field: repeated rd.search.service.SearchOutstandingSalesRes.Result results = 1;
     */
    results: SearchOutstandingSalesRes_Result[];
}
/**
 * @generated from protobuf message rd.search.service.SearchOutstandingSalesRes.Result
 */
export interface SearchOutstandingSalesRes_Result {
    /**
     * @generated from protobuf field: rd.txn.Sale sale = 1;
     */
    sale?: Sale;
    /**
     * @generated from protobuf field: rd.proto.money.Money sale_balance = 2;
     */
    saleBalance?: Money;
    /**
     * @generated from protobuf field: rd.company.Location location = 3;
     */
    location?: Location;
    /**
     * @generated from protobuf field: rd.customer.Customer customer = 4;
     */
    customer?: Customer;
    /**
     * @generated from protobuf field: rd.customer.PurchaseOrder purchase_order = 5;
     */
    purchaseOrder?: PurchaseOrder;
    /**
     * @generated from protobuf field: rd.customer.JobSite job_site = 6;
     */
    jobSite?: JobSite;
    /**
     * @generated from protobuf field: rd.customer.Contacts.Contact customer_contact = 7;
     */
    customerContact?: Contacts_Contact;
}
/**
 * @generated from protobuf message rd.search.service.SearchTintColorsReq
 */
export interface SearchTintColorsReq {
    /**
     * @generated from protobuf field: string query = 1;
     */
    query: string;
}
/**
 * @generated from protobuf message rd.search.service.SearchTintColorsRes
 */
export interface SearchTintColorsRes {
    /**
     * @generated from protobuf field: repeated rd.search.service.SearchTintColorsRes.Result results = 2;
     */
    results: SearchTintColorsRes_Result[];
}
/**
 * @generated from protobuf message rd.search.service.SearchTintColorsRes.Result
 */
export interface SearchTintColorsRes_Result {
    /**
     * @generated from protobuf field: rd.tintcolor.TintColor tint_color = 1;
     */
    tintColor?: TintColor;
}
/**
 * @generated from protobuf message rd.search.service.SearchTransfersReq
 */
export interface SearchTransfersReq {
    /**
     * @generated from protobuf field: string query = 1;
     */
    query: string;
    /**
     * @generated from protobuf field: rd.proto.timestamprange.TimestampRange created_at_range = 2;
     */
    createdAtRange?: TimestampRange;
    /**
     * @generated from protobuf field: rd.proto.timestamprange.TimestampRange sent_at_range = 3;
     */
    sentAtRange?: TimestampRange;
    /**
     * @generated from protobuf field: rd.proto.timestamprange.TimestampRange received_at_range = 4;
     */
    receivedAtRange?: TimestampRange;
    /**
     * @generated from protobuf field: rd.proto.timestamprange.TimestampRange voided_at_range = 5;
     */
    voidedAtRange?: TimestampRange;
    /**
     * @generated from protobuf field: repeated rd.proto.uuid.UUID from_location_ids = 6;
     */
    fromLocationIds: UUID[];
    /**
     * @generated from protobuf field: repeated rd.proto.uuid.UUID to_location_ids = 7;
     */
    toLocationIds: UUID[];
    /**
     * @generated from protobuf field: repeated rd.txn.Transfer.Status statuses = 8;
     */
    statuses: Transfer_Status[];
    /**
     * @generated from protobuf field: string cursor = 9;
     */
    cursor: string;
    /**
     * @generated from protobuf field: int32 offset = 10;
     */
    offset: number;
    /**
     * @generated from protobuf field: int32 limit = 11;
     */
    limit: number;
}
/**
 * @generated from protobuf message rd.search.service.SearchTransfersRes
 */
export interface SearchTransfersRes {
    /**
     * @generated from protobuf field: repeated rd.search.service.SearchTransfersRes.Result results = 1;
     */
    results: SearchTransfersRes_Result[];
    /**
     * @generated from protobuf field: string cursor = 2;
     */
    cursor: string;
}
/**
 * @generated from protobuf message rd.search.service.SearchTransfersRes.Result
 */
export interface SearchTransfersRes_Result {
    /**
     * @generated from protobuf field: rd.txn.Transfer transfer = 1;
     */
    transfer?: Transfer;
    /**
     * @generated from protobuf field: rd.company.Location from_location = 2;
     */
    fromLocation?: Location;
    /**
     * @generated from protobuf field: rd.company.Location to_location = 3;
     */
    toLocation?: Location;
    /**
     * @generated from protobuf field: map<string, rd.product.Product> products_by_id = 4;
     */
    productsById: {
        [key: string]: Product;
    };
    /**
     * @generated from protobuf field: map<string, rd.product.Product> matched_products_by_id = 5;
     */
    matchedProductsById: {
        [key: string]: Product;
    };
    /**
     * @generated from protobuf field: rd.proto.money.Money total = 6;
     */
    total?: Money;
}
/**
 * @generated from protobuf message rd.search.service.SearchVendorsReq
 */
export interface SearchVendorsReq {
    /**
     * @generated from protobuf field: string query = 1;
     */
    query: string;
    /**
     * @generated from protobuf field: rd.search.service.BoolFilter is_active_filter = 2;
     */
    isActiveFilter: BoolFilter;
    /**
     * @generated from protobuf field: string cursor = 3;
     */
    cursor: string;
    /**
     * @generated from protobuf field: int32 offset = 4;
     */
    offset: number;
    /**
     * @generated from protobuf field: int32 limit = 5;
     */
    limit: number;
}
/**
 * @generated from protobuf message rd.search.service.SearchVendorsRes
 */
export interface SearchVendorsRes {
    /**
     * @generated from protobuf field: string cursor = 2;
     */
    cursor: string;
    /**
     * @generated from protobuf field: repeated rd.search.service.SearchVendorsRes.Result results = 3;
     */
    results: SearchVendorsRes_Result[];
}
/**
 * @generated from protobuf message rd.search.service.SearchVendorsRes.Result
 */
export interface SearchVendorsRes_Result {
    /**
     * @generated from protobuf field: rd.vendors.Vendor vendor = 1;
     */
    vendor?: Vendor;
}
/**
 * @generated from protobuf message rd.search.service.BulkExportVendorsReq
 */
export interface BulkExportVendorsReq {
    /**
     * @generated from protobuf field: string query = 1;
     */
    query: string;
    /**
     * @generated from protobuf field: rd.search.service.BoolFilter is_active_filter = 2;
     */
    isActiveFilter: BoolFilter;
    /**
     * @generated from protobuf field: bool is_template = 3;
     */
    isTemplate: boolean;
}
/**
 * @generated from protobuf message rd.search.service.BulkExportVendorsRes
 */
export interface BulkExportVendorsRes {
    /**
     * @generated from protobuf field: bytes excel_bytes = 1;
     */
    excelBytes: Uint8Array;
}
/**
 * @generated from protobuf message rd.search.service.UpdateRecordReq
 */
export interface UpdateRecordReq {
    /**
     * Deprecated
     *
     * @generated from protobuf field: google.protobuf.Any record = 1;
     */
    record?: Any;
    /**
     * Deprecated
     *
     * @generated from protobuf field: rd.proto.uuid.UUID company_id = 2;
     */
    companyId?: UUID;
    /**
     * Deprecated
     *
     * @generated from protobuf field: bool delete = 3;
     */
    delete: boolean;
    /**
     * Deprecated
     *
     * @generated from protobuf field: string pubsub_token = 4;
     */
    pubsubToken: string;
    /**
     * Deprecated
     *
     * @generated from protobuf field: string pubsub_origin = 5;
     */
    pubsubOrigin: string;
    /**
     * @generated from protobuf field: rd.search.cache.Update update = 6;
     */
    update?: Update;
}
/**
 * @generated from protobuf message rd.search.service.UpdateRecordRes
 */
export interface UpdateRecordRes {
}
/**
 * @generated from protobuf message rd.search.service.ReloadReq
 */
export interface ReloadReq {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID company_id = 1;
     */
    companyId?: UUID;
    /**
     * @generated from protobuf field: bool full_reload = 2;
     */
    fullReload: boolean;
    /**
     * @generated from protobuf field: repeated string table_names = 3;
     */
    tableNames: string[];
}
/**
 * @generated from protobuf message rd.search.service.ReloadRes
 */
export interface ReloadRes {
}
/**
 * @generated from protobuf message rd.search.service.SearchCashTransactionsReq
 */
export interface SearchCashTransactionsReq {
    /**
     * @generated from protobuf field: string query = 1;
     */
    query: string;
    /**
     * @generated from protobuf field: repeated rd.proto.uuid.UUID location_ids = 2;
     */
    locationIds: UUID[];
    /**
     * @generated from protobuf field: rd.proto.timestamprange.TimestampRange created_at_range = 3;
     */
    createdAtRange?: TimestampRange;
    /**
     * @generated from protobuf field: repeated rd.search.service.SearchCashTransactionsReq.Type types = 4;
     */
    types: SearchCashTransactionsReq_Type[];
    /**
     * @generated from protobuf field: repeated rd.proto.uuid.UUID created_by_ids = 5;
     */
    createdByIds: UUID[];
    /**
     * @generated from protobuf field: string cursor = 6;
     */
    cursor: string;
    /**
     * @generated from protobuf field: int32 offset = 7;
     */
    offset: number;
    /**
     * @generated from protobuf field: int32 limit = 8;
     */
    limit: number;
}
/**
 * @generated from protobuf enum rd.search.service.SearchCashTransactionsReq.Type
 */
export enum SearchCashTransactionsReq_Type {
    /**
     * @generated from protobuf enum value: TYPE_NONE = 0;
     */
    NONE = 0,
    /**
     * @generated from protobuf enum value: TYPE_SALE = 1;
     */
    SALE = 1,
    /**
     * @generated from protobuf enum value: TYPE_BILL_PAYMENT = 2;
     */
    BILL_PAYMENT = 2,
    /**
     * @generated from protobuf enum value: TYPE_REFUND = 3;
     */
    REFUND = 3,
    /**
     * @generated from protobuf enum value: TYPE_PETTY_CASH = 4;
     */
    PETTY_CASH = 4,
    /**
     * @generated from protobuf enum value: TYPE_CLOSE_OUT = 5;
     */
    CLOSE_OUT = 5
}
/**
 * @generated from protobuf message rd.search.service.SearchCashTransactionsRes
 */
export interface SearchCashTransactionsRes {
    /**
     * @generated from protobuf field: repeated rd.search.service.SearchCashTransactionsRes.Result results = 1;
     */
    results: SearchCashTransactionsRes_Result[];
    /**
     * @generated from protobuf field: string cursor = 2;
     */
    cursor: string;
}
/**
 * @generated from protobuf message rd.search.service.SearchCashTransactionsRes.Result
 */
export interface SearchCashTransactionsRes_Result {
    /**
     * @generated from protobuf field: rd.txn.LedgerEntry ledger_entry = 1;
     */
    ledgerEntry?: LedgerEntry;
    /**
     * @generated from protobuf field: rd.txn.Sale sale = 2;
     */
    sale?: Sale;
    /**
     * @generated from protobuf field: rd.txn.BillPayment bill_payment = 3;
     */
    billPayment?: BillPayment;
    /**
     * @generated from protobuf field: rd.txn.PettyCash petty_cash = 4;
     */
    pettyCash?: PettyCash;
    /**
     * @generated from protobuf field: rd.txn.CloseOut close_out = 5;
     */
    closeOut?: CloseOut;
    /**
     * @generated from protobuf field: rd.txn.Sale.Return sale_return = 6;
     */
    saleReturn?: Sale_Return;
}
/**
 * @generated from protobuf message rd.search.service.SearchFinancialTransactionsReq
 */
export interface SearchFinancialTransactionsReq {
    /**
     * @generated from protobuf field: string query = 1;
     */
    query: string;
    /**
     * @generated from protobuf field: repeated rd.proto.uuid.UUID vendor_ids = 2;
     */
    vendorIds: UUID[];
    /**
     * @generated from protobuf field: repeated rd.payments.FinancialTransaction.Type types = 3;
     */
    types: FinancialTransaction_Type[];
    /**
     * @generated from protobuf field: repeated rd.payments.FinancialTransaction.Status statuses = 4;
     */
    statuses: FinancialTransaction_Status[];
    /**
     * @generated from protobuf field: rd.proto.timestamprange.TimestampRange range = 5;
     */
    range?: TimestampRange;
    /**
     * @generated from protobuf field: string cursor = 6;
     */
    cursor: string;
    /**
     * @generated from protobuf field: int32 offset = 7;
     */
    offset: number;
    /**
     * @generated from protobuf field: int32 limit = 8;
     */
    limit: number;
    /**
     * @generated from protobuf field: repeated rd.proto.uuid.UUID chart_of_account_ids = 9;
     */
    chartOfAccountIds: UUID[];
}
/**
 * @generated from protobuf message rd.search.service.SearchFinancialTransactionsRes
 */
export interface SearchFinancialTransactionsRes {
    /**
     * @generated from protobuf field: repeated rd.search.service.SearchFinancialTransactionsRes.Result results = 1;
     */
    results: SearchFinancialTransactionsRes_Result[];
    /**
     * @generated from protobuf field: string cursor = 2;
     */
    cursor: string;
}
/**
 * @generated from protobuf message rd.search.service.SearchFinancialTransactionsRes.Result
 */
export interface SearchFinancialTransactionsRes_Result {
    /**
     * @generated from protobuf field: rd.payments.FinancialTransaction financial_transaction = 1;
     */
    financialTransaction?: FinancialTransaction;
    /**
     * @generated from protobuf field: rd.vendors.Vendor vendor = 2;
     */
    vendor?: Vendor;
    /**
     * @generated from protobuf field: map<string, rd.coa.Account> accounts_by_id = 5;
     */
    accountsById: {
        [key: string]: Account;
    };
    /**
     * @generated from protobuf field: map<string, rd.search.service.SearchFinancialTransactionsRes.Result.OrderShipmentData> order_shipment_data_by_id = 6;
     */
    orderShipmentDataById: {
        [key: string]: SearchFinancialTransactionsRes_Result_OrderShipmentData;
    };
    /**
     * @generated from protobuf field: rd.company.Location location = 7;
     */
    location?: Location;
}
/**
 * @generated from protobuf message rd.search.service.SearchFinancialTransactionsRes.Result.OrderShipmentData
 */
export interface SearchFinancialTransactionsRes_Result_OrderShipmentData {
    /**
     * @generated from protobuf field: rd.txn.OrderShipment order_shipment = 1;
     */
    orderShipment?: OrderShipment;
    /**
     * @generated from protobuf field: rd.vendors.Vendor vendor = 2;
     */
    vendor?: Vendor;
    /**
     * @generated from protobuf field: rd.proto.money.Money total = 3;
     */
    total?: Money;
}
/**
 * @generated from protobuf message rd.search.service.SearchInventoryCountProductsReq
 */
export interface SearchInventoryCountProductsReq {
    /**
     * @generated from protobuf field: string query = 1;
     */
    query: string;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID inventory_location_id = 2;
     */
    inventoryLocationId?: UUID;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID inventory_count_id = 3;
     */
    inventoryCountId?: UUID;
    /**
     * @generated from protobuf field: rd.product.InventoryCountProduct.Status product_status = 4;
     */
    productStatus: InventoryCountProduct_Status;
    /**
     * @generated from protobuf field: string cursor = 5;
     */
    cursor: string;
    /**
     * @generated from protobuf field: int32 offset = 6;
     */
    offset: number;
    /**
     * @generated from protobuf field: int32 limit = 7;
     */
    limit: number;
    /**
     * @generated from protobuf field: rd.search.service.SearchInventoryCountProductsReq.OrderBy order_by = 13;
     */
    orderBy: SearchInventoryCountProductsReq_OrderBy;
    /**
     * @generated from protobuf field: rd.search.service.SortOrder sort_order = 14;
     */
    sortOrder: SortOrder;
}
/**
 * @generated from protobuf enum rd.search.service.SearchInventoryCountProductsReq.OrderBy
 */
export enum SearchInventoryCountProductsReq_OrderBy {
    /**
     * @generated from protobuf enum value: ORDER_BY_NONE = 0;
     */
    NONE = 0,
    /**
     * @generated from protobuf enum value: ORDER_BY_NAME = 1;
     */
    NAME = 1,
    /**
     * @generated from protobuf enum value: ORDER_BY_ON_HAND = 2;
     */
    ON_HAND = 2,
    /**
     * @generated from protobuf enum value: ORDER_BY_VARIANCE = 3;
     */
    VARIANCE = 3,
    /**
     * @generated from protobuf enum value: ORDER_BY_DOLLAR_VARIANCE = 4;
     */
    DOLLAR_VARIANCE = 4
}
/**
 * @generated from protobuf message rd.search.service.SearchInventoryCountProductsRes
 */
export interface SearchInventoryCountProductsRes {
    /**
     * @generated from protobuf field: repeated rd.search.service.SearchInventoryCountProductsRes.Result results = 1;
     */
    results: SearchInventoryCountProductsRes_Result[];
    /**
     * @generated from protobuf field: string cursor = 2;
     */
    cursor: string;
}
/**
 * @generated from protobuf message rd.search.service.SearchInventoryCountProductsRes.Result
 */
export interface SearchInventoryCountProductsRes_Result {
    /**
     * @generated from protobuf field: rd.product.Product product = 1;
     */
    product?: Product;
    /**
     * @generated from protobuf field: int32 on_hand = 2;
     */
    onHand: number;
    /**
     * @generated from protobuf field: int32 counted = 3;
     */
    counted: number;
    /**
     * @generated from protobuf field: int32 variance = 4;
     */
    variance: number;
    /**
     * @generated from protobuf field: rd.proto.money.Money dollar_variance = 5;
     */
    dollarVariance?: Money;
}
/**
 * @generated from protobuf message rd.search.service.SearchExcludedInventoryCountProductsReq
 */
export interface SearchExcludedInventoryCountProductsReq {
    /**
     * @generated from protobuf field: string query = 1;
     */
    query: string;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID location_id = 2;
     */
    locationId?: UUID;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID inventory_count_id = 3;
     */
    inventoryCountId?: UUID;
    /**
     * @generated from protobuf field: string cursor = 4;
     */
    cursor: string;
    /**
     * @generated from protobuf field: int32 offset = 5;
     */
    offset: number;
    /**
     * @generated from protobuf field: int32 limit = 6;
     */
    limit: number;
}
/**
 * @generated from protobuf message rd.search.service.SearchExcludedInventoryCountProductsRes
 */
export interface SearchExcludedInventoryCountProductsRes {
    /**
     * @generated from protobuf field: repeated rd.search.service.SearchExcludedInventoryCountProductsRes.Result results = 1;
     */
    results: SearchExcludedInventoryCountProductsRes_Result[];
    /**
     * @generated from protobuf field: string cursor = 2;
     */
    cursor: string;
}
/**
 * @generated from protobuf message rd.search.service.SearchExcludedInventoryCountProductsRes.Result
 */
export interface SearchExcludedInventoryCountProductsRes_Result {
    /**
     * @generated from protobuf field: rd.product.Product product = 1;
     */
    product?: Product;
}
/**
 * @generated from protobuf message rd.search.service.SearchExternalTransactionsReq
 */
export interface SearchExternalTransactionsReq {
    /**
     * @generated from protobuf field: string query = 1;
     */
    query: string;
    /**
     * @generated from protobuf field: rd.proto.timestamprange.TimestampRange transacted_at_range = 2;
     */
    transactedAtRange?: TimestampRange;
    /**
     * @generated from protobuf field: repeated rd.proto.uuid.UUID location_ids = 3;
     */
    locationIds: UUID[];
    /**
     * @generated from protobuf field: repeated rd.proto.uuid.UUID customer_ids = 4;
     */
    customerIds: UUID[];
    /**
     * @generated from protobuf field: repeated rd.proto.uuid.UUID vendor_ids = 5;
     */
    vendorIds: UUID[];
    /**
     * @generated from protobuf field: repeated rd.proto.uuid.UUID chart_of_account_ids = 6;
     */
    chartOfAccountIds: UUID[];
    /**
     * @generated from protobuf field: repeated rd.accounting.ExternalTransaction.Type types = 7;
     */
    types: ExternalTransaction_Type[];
    /**
     * @generated from protobuf field: repeated rd.accounting.ExternalTransaction.PaymentMethod payment_methods = 8;
     */
    paymentMethods: ExternalTransaction_PaymentMethod[];
    /**
     * @generated from protobuf field: string cursor = 9;
     */
    cursor: string;
    /**
     * @generated from protobuf field: int32 offset = 10;
     */
    offset: number;
    /**
     * @generated from protobuf field: int32 limit = 11;
     */
    limit: number;
}
/**
 * @generated from protobuf message rd.search.service.SearchExternalTransactionsRes
 */
export interface SearchExternalTransactionsRes {
    /**
     * @generated from protobuf field: repeated rd.search.service.SearchExternalTransactionsRes.Result results = 1;
     */
    results: SearchExternalTransactionsRes_Result[];
    /**
     * @generated from protobuf field: string cursor = 2;
     */
    cursor: string;
}
/**
 * @generated from protobuf message rd.search.service.SearchExternalTransactionsRes.Result
 */
export interface SearchExternalTransactionsRes_Result {
    /**
     * @generated from protobuf field: rd.accounting.ExternalTransaction external_transaction = 1;
     */
    externalTransaction?: ExternalTransaction;
    /**
     * @generated from protobuf field: rd.company.Location location = 2;
     */
    location?: Location;
    /**
     * @generated from protobuf field: rd.customer.Customer customer = 3;
     */
    customer?: Customer;
    /**
     * @generated from protobuf field: rd.vendors.Vendor vendor = 4;
     */
    vendor?: Vendor;
    /**
     * @generated from protobuf field: map<string, rd.coa.Account> accounts_by_id = 7;
     */
    accountsById: {
        [key: string]: Account;
    };
    /**
     * @generated from protobuf field: map<string, rd.search.service.SearchExternalTransactionsRes.Result.OrderShipmentData> order_shipment_data_by_id = 8;
     */
    orderShipmentDataById: {
        [key: string]: SearchExternalTransactionsRes_Result_OrderShipmentData;
    };
}
/**
 * @generated from protobuf message rd.search.service.SearchExternalTransactionsRes.Result.OrderShipmentData
 */
export interface SearchExternalTransactionsRes_Result_OrderShipmentData {
    /**
     * @generated from protobuf field: rd.txn.OrderShipment order_shipment = 1;
     */
    orderShipment?: OrderShipment;
    /**
     * @generated from protobuf field: rd.vendors.Vendor vendor = 2;
     */
    vendor?: Vendor;
    /**
     * @generated from protobuf field: rd.proto.money.Money total = 3;
     */
    total?: Money;
}
/**
 * @generated from protobuf message rd.search.service.SearchOrderShipmentsForReconciliationReq
 */
export interface SearchOrderShipmentsForReconciliationReq {
    /**
     * @generated from protobuf field: string cursor = 1;
     */
    cursor: string;
    /**
     * @generated from protobuf field: int32 offset = 2;
     */
    offset: number;
    /**
     * @generated from protobuf field: int32 limit = 3;
     */
    limit: number;
    /**
     * @generated from protobuf field: string query = 4;
     */
    query: string;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID transaction_id = 5;
     */
    transactionId?: UUID;
    /**
     * @generated from protobuf field: string stripe_transaction_id = 6;
     */
    stripeTransactionId: string; // if present, will be used to generate a transaction id uuid
}
/**
 * @generated from protobuf message rd.search.service.SearchOrderShipmentsForReconciliationRes
 */
export interface SearchOrderShipmentsForReconciliationRes {
    /**
     * @generated from protobuf field: repeated rd.search.service.SearchOrderShipmentsForReconciliationRes.Result results = 1;
     */
    results: SearchOrderShipmentsForReconciliationRes_Result[];
    /**
     * @generated from protobuf field: string cursor = 2;
     */
    cursor: string;
}
/**
 * @generated from protobuf message rd.search.service.SearchOrderShipmentsForReconciliationRes.Result
 */
export interface SearchOrderShipmentsForReconciliationRes_Result {
    /**
     * @generated from protobuf field: rd.txn.OrderShipment order_shipment = 1;
     */
    orderShipment?: OrderShipment;
    /**
     * @generated from protobuf field: rd.vendors.Vendor vendor = 2;
     */
    vendor?: Vendor;
    /**
     * @generated from protobuf field: rd.proto.money.Money total = 3;
     */
    total?: Money;
}
/**
 * @generated from protobuf message rd.search.service.SearchInventoryChangesReq
 */
export interface SearchInventoryChangesReq {
    /**
     * @generated from protobuf field: string query = 1;
     */
    query: string;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID product_id = 2;
     */
    productId?: UUID;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID current_location_id = 3;
     */
    currentLocationId?: UUID;
    /**
     * @generated from protobuf field: repeated rd.proto.uuid.UUID staff_ids = 4;
     */
    staffIds: UUID[];
    /**
     * @generated from protobuf field: repeated rd.search.service.SearchInventoryChangesReq.Type types = 5;
     */
    types: SearchInventoryChangesReq_Type[];
    /**
     * @generated from protobuf field: rd.proto.timestamprange.TimestampRange range = 6;
     */
    range?: TimestampRange;
    /**
     * @generated from protobuf field: string cursor = 7;
     */
    cursor: string;
    /**
     * @generated from protobuf field: int32 offset = 8;
     */
    offset: number;
    /**
     * @generated from protobuf field: int32 limit = 9;
     */
    limit: number;
}
/**
 * @generated from protobuf enum rd.search.service.SearchInventoryChangesReq.Type
 */
export enum SearchInventoryChangesReq_Type {
    /**
     * @generated from protobuf enum value: TYPE_NONE = 0;
     */
    NONE = 0,
    /**
     * @generated from protobuf enum value: TYPE_SALE = 1;
     */
    SALE = 1,
    /**
     * @generated from protobuf enum value: TYPE_RETURN = 2;
     */
    RETURN = 2,
    /**
     * @generated from protobuf enum value: TYPE_ORDER_SHIPMENT = 3;
     */
    ORDER_SHIPMENT = 3,
    /**
     * @generated from protobuf enum value: TYPE_TRANSFER = 4;
     */
    TRANSFER = 4,
    /**
     * @generated from protobuf enum value: TYPE_COUNT = 5;
     */
    COUNT = 5,
    /**
     * @generated from protobuf enum value: TYPE_MANUAL_EDIT = 6;
     */
    MANUAL_EDIT = 6
}
/**
 * @generated from protobuf message rd.search.service.SearchInventoryChangesRes
 */
export interface SearchInventoryChangesRes {
    /**
     * @generated from protobuf field: repeated rd.search.service.SearchInventoryChangesRes.Result results = 1;
     */
    results: SearchInventoryChangesRes_Result[];
    /**
     * @generated from protobuf field: string cursor = 2;
     */
    cursor: string;
}
/**
 * @generated from protobuf message rd.search.service.SearchInventoryChangesRes.Result
 */
export interface SearchInventoryChangesRes_Result {
    /**
     * @generated from protobuf field: rd.product.InventoryChange inventory_change = 1;
     */
    inventoryChange?: InventoryChange;
}
/**
 * @generated from protobuf message rd.search.service.SearchCustomPricesV2Req
 */
export interface SearchCustomPricesV2Req {
    /**
     * @generated from protobuf field: string query = 1;
     */
    query: string;
    /**
     * @generated from protobuf field: string cursor = 2;
     */
    cursor: string;
    /**
     * @generated from protobuf field: int32 offset = 3;
     */
    offset: number;
    /**
     * @generated from protobuf field: int32 limit = 4;
     */
    limit: number;
}
/**
 * @generated from protobuf message rd.search.service.SearchCustomPricesV2Res
 */
export interface SearchCustomPricesV2Res {
    /**
     * @generated from protobuf field: repeated rd.search.service.SearchCustomPricesV2Res.Result results = 1;
     */
    results: SearchCustomPricesV2Res_Result[];
    /**
     * @generated from protobuf field: string cursor = 2;
     */
    cursor: string;
}
/**
 * @generated from protobuf message rd.search.service.SearchCustomPricesV2Res.Result
 */
export interface SearchCustomPricesV2Res_Result {
    /**
     * @generated from protobuf field: rd.product.CustomPrice custom_price = 1;
     */
    customPrice?: CustomPrice;
    /**
     * @generated from protobuf field: rd.customer.Customer customer = 2;
     */
    customer?: Customer;
    /**
     * @generated from protobuf field: rd.product.Product product = 3;
     */
    product?: Product;
}
/**
 * @generated from protobuf message rd.search.service.CxaSearchSalesReq
 */
export interface CxaSearchSalesReq {
    /**
     * @generated from protobuf field: string query = 1;
     */
    query: string;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID customer_id = 2;
     */
    customerId?: UUID;
    /**
     * @generated from protobuf field: repeated rd.proto.uuid.UUID location_ids = 3;
     */
    locationIds: UUID[];
    /**
     * @generated from protobuf field: repeated rd.txn.Sale.Status statuses = 4;
     */
    statuses: Sale_Status[];
    /**
     * @generated from protobuf field: string cursor = 5;
     */
    cursor: string;
    /**
     * @generated from protobuf field: int32 offset = 6;
     */
    offset: number;
    /**
     * @generated from protobuf field: int32 limit = 7;
     */
    limit: number;
}
/**
 * @generated from protobuf message rd.search.service.CxaSearchSalesRes
 */
export interface CxaSearchSalesRes {
    /**
     * @generated from protobuf field: repeated rd.search.service.CxaSearchSalesRes.Result results = 1;
     */
    results: CxaSearchSalesRes_Result[];
    /**
     * @generated from protobuf field: string cursor = 2;
     */
    cursor: string;
}
/**
 * @generated from protobuf message rd.search.service.CxaSearchSalesRes.Result
 */
export interface CxaSearchSalesRes_Result {
    /**
     * @generated from protobuf field: rd.txn.Sale sale = 1;
     */
    sale?: Sale;
    /**
     * @generated from protobuf field: rd.company.Location location = 2;
     */
    location?: Location;
    /**
     * @generated from protobuf field: rd.customer.PurchaseOrder purchase_order = 3;
     */
    purchaseOrder?: PurchaseOrder;
    /**
     * @generated from protobuf field: rd.customer.JobSite job_site = 4;
     */
    jobSite?: JobSite;
    /**
     * @generated from protobuf field: map<string, rd.product.Product> products_by_id = 5;
     */
    productsById: {
        [key: string]: Product;
    };
    /**
     * @generated from protobuf field: map<string, rd.product.Product> matched_products_by_id = 6;
     */
    matchedProductsById: {
        [key: string]: Product;
    };
    /**
     * @generated from protobuf field: map<string, rd.tintcolor.TintColor> tint_colors_by_id = 7;
     */
    tintColorsById: {
        [key: string]: TintColor;
    };
    /**
     * @generated from protobuf field: rd.proto.money.Money total = 8;
     */
    total?: Money;
    /**
     * @generated from protobuf field: repeated rd.txn.PurchaseMethod purchase_methods = 9;
     */
    purchaseMethods: PurchaseMethod[];
    /**
     * @generated from protobuf field: string customer_contact_name = 10;
     */
    customerContactName: string;
    /**
     * @generated from protobuf field: string customer_identifier = 11;
     */
    customerIdentifier: string;
}
/**
 * @generated from protobuf message rd.search.service.CxaSearchOutstandingSalesReq
 */
export interface CxaSearchOutstandingSalesReq {
    /**
     * @generated from protobuf field: string query = 1;
     */
    query: string;
}
/**
 * @generated from protobuf message rd.search.service.CxaSearchOutstandingSalesRes
 */
export interface CxaSearchOutstandingSalesRes {
    /**
     * @generated from protobuf field: repeated rd.search.service.CxaSearchOutstandingSalesRes.Result results = 1;
     */
    results: CxaSearchOutstandingSalesRes_Result[];
    /**
     * @generated from protobuf field: rd.txn.CustomerBalance customer_balance = 2;
     */
    customerBalance?: CustomerBalance;
}
/**
 * @generated from protobuf message rd.search.service.CxaSearchOutstandingSalesRes.Result
 */
export interface CxaSearchOutstandingSalesRes_Result {
    /**
     * @generated from protobuf field: rd.txn.Sale sale = 1;
     */
    sale?: Sale;
    /**
     * @generated from protobuf field: rd.proto.money.Money sale_balance = 2;
     */
    saleBalance?: Money;
    /**
     * @generated from protobuf field: rd.company.Location location = 3;
     */
    location?: Location;
    /**
     * @generated from protobuf field: rd.customer.PurchaseOrder purchase_order = 4;
     */
    purchaseOrder?: PurchaseOrder;
    /**
     * @generated from protobuf field: rd.customer.JobSite job_site = 5;
     */
    jobSite?: JobSite;
    /**
     * @generated from protobuf field: string customer_contact_name = 6;
     */
    customerContactName: string;
}
/**
 * @generated from protobuf message rd.search.service.CxaSearchReturnsReq
 */
export interface CxaSearchReturnsReq {
    /**
     * @generated from protobuf field: string query = 1;
     */
    query: string;
    /**
     * @generated from protobuf field: string cursor = 2;
     */
    cursor: string;
    /**
     * @generated from protobuf field: int32 offset = 3;
     */
    offset: number;
    /**
     * @generated from protobuf field: int32 limit = 4;
     */
    limit: number;
}
/**
 * @generated from protobuf message rd.search.service.CxaSearchReturnsRes
 */
export interface CxaSearchReturnsRes {
    /**
     * @generated from protobuf field: repeated rd.search.service.CxaSearchReturnsRes.Result results = 1;
     */
    results: CxaSearchReturnsRes_Result[];
    /**
     * @generated from protobuf field: string cursor = 2;
     */
    cursor: string;
}
/**
 * @generated from protobuf message rd.search.service.CxaSearchReturnsRes.Result
 */
export interface CxaSearchReturnsRes_Result {
    /**
     * @generated from protobuf field: rd.txn.Sale sale = 1;
     */
    sale?: Sale;
    /**
     * @generated from protobuf field: rd.txn.Sale.Return sale_return = 2;
     */
    saleReturn?: Sale_Return;
    /**
     * @generated from protobuf field: rd.txn.LedgerEntry return_ledger_entry = 3;
     */
    returnLedgerEntry?: LedgerEntry;
    /**
     * @generated from protobuf field: rd.customer.Customer customer = 4;
     */
    customer?: Customer;
    /**
     * @generated from protobuf field: rd.company.Location location = 5;
     */
    location?: Location;
    /**
     * @generated from protobuf field: rd.customer.JobSite job_site = 6;
     */
    jobSite?: JobSite;
    /**
     * @generated from protobuf field: rd.customer.PurchaseOrder purchase_order = 7;
     */
    purchaseOrder?: PurchaseOrder;
    /**
     * @generated from protobuf field: rd.customer.Contacts.Contact customer_contact = 8;
     */
    customerContact?: Contacts_Contact;
    /**
     * @generated from protobuf field: string return_method = 9;
     */
    returnMethod: string;
}
/**
 * @generated from protobuf enum rd.search.service.SortOrder
 */
export enum SortOrder {
    /**
     * @generated from protobuf enum value: SORT_ORDER_NONE = 0;
     */
    NONE = 0,
    /**
     * @generated from protobuf enum value: SORT_ORDER_DESC = 1;
     */
    DESC = 1,
    /**
     * @generated from protobuf enum value: SORT_ORDER_ASC = 2;
     */
    ASC = 2
}
/**
 * @generated from protobuf enum rd.search.service.BoolFilter
 */
export enum BoolFilter {
    /**
     * @generated from protobuf enum value: BOOL_FILTER_NONE = 0;
     */
    NONE = 0,
    /**
     * @generated from protobuf enum value: BOOL_FILTER_FALSE = 1;
     */
    FALSE = 1,
    /**
     * @generated from protobuf enum value: BOOL_FILTER_TRUE = 2;
     */
    TRUE = 2
}
/**
 * @generated from protobuf enum rd.search.service.OrderCustomersBy
 */
export enum OrderCustomersBy {
    /**
     * @generated from protobuf enum value: ORDER_CUSTOMERS_BY_NONE = 0;
     */
    NONE = 0,
    /**
     * @generated from protobuf enum value: ORDER_CUSTOMERS_BY_NAME = 1;
     */
    NAME = 1,
    /**
     * @generated from protobuf enum value: ORDER_CUSTOMERS_BY_CREDIT_LIMIT = 2;
     */
    CREDIT_LIMIT = 2,
    /**
     * @generated from protobuf enum value: ORDER_CUSTOMERS_BY_TOTAL_BALANCE = 3;
     */
    TOTAL_BALANCE = 3,
    /**
     * @generated from protobuf enum value: ORDER_CUSTOMERS_BY_CURRENT_BALANCE = 4;
     */
    CURRENT_BALANCE = 4,
    /**
     * @generated from protobuf enum value: ORDER_CUSTOMERS_BY_LATE_1_TO_30_BALANCE = 5;
     */
    LATE_1_TO_30_BALANCE = 5,
    /**
     * @generated from protobuf enum value: ORDER_CUSTOMERS_BY_LATE_31_TO_60_BALANCE = 6;
     */
    LATE_31_TO_60_BALANCE = 6,
    /**
     * @generated from protobuf enum value: ORDER_CUSTOMERS_BY_LATE_61_PLUS_BALANCE = 7;
     */
    LATE_61_PLUS_BALANCE = 7,
    /**
     * @generated from protobuf enum value: ORDER_CUSTOMERS_BY_CREDITS_BALANCE = 8;
     */
    CREDITS_BALANCE = 8,
    /**
     * @generated from protobuf enum value: ORDER_CUSTOMERS_BY_FINANCING_CHARGES_BALANCE = 9;
     */
    FINANCING_CHARGES_BALANCE = 9,
    /**
     * @generated from protobuf enum value: ORDER_CUSTOMERS_BY_SALES_YTD = 10;
     */
    SALES_YTD = 10,
    /**
     * @generated from protobuf enum value: ORDER_CUSTOMERS_BY_SALES_VS_LAST_YEAR = 11;
     */
    SALES_VS_LAST_YEAR = 11,
    /**
     * @generated from protobuf enum value: ORDER_CUSTOMERS_BY_MARGIN_YTD = 12;
     */
    MARGIN_YTD = 12,
    /**
     * @generated from protobuf enum value: ORDER_CUSTOMERS_BY_SALES_LAST_12MO = 13;
     */
    SALES_LAST_12MO = 13,
    /**
     * @generated from protobuf enum value: ORDER_CUSTOMERS_BY_SALES_LAST_3MO = 14;
     */
    SALES_LAST_3MO = 14,
    /**
     * @generated from protobuf enum value: ORDER_CUSTOMERS_BY_SALES_LAST_1MO = 15;
     */
    SALES_LAST_1MO = 15,
    /**
     * @generated from protobuf enum value: ORDER_CUSTOMERS_BY_LAST_SALE = 16;
     */
    LAST_SALE = 16,
    /**
     * @generated from protobuf enum value: ORDER_CUSTOMERS_BY_LAST_INTERACTION_DATE = 17;
     */
    LAST_INTERACTION_DATE = 17
}
/**
 * @generated from protobuf enum rd.search.service.SearchProductOrderBy
 */
export enum SearchProductOrderBy {
    /**
     * @generated from protobuf enum value: ORDER_BY_NONE = 0;
     */
    ORDER_BY_NONE = 0,
    /**
     * @generated from protobuf enum value: ORDER_BY_NAME = 1;
     */
    ORDER_BY_NAME = 1,
    /**
     * @generated from protobuf enum value: ORDER_BY_INVENTORY_LOCATION_ID = 2;
     */
    ORDER_BY_INVENTORY_LOCATION_ID = 2,
    /**
     * @generated from protobuf enum value: ORDER_BY_TARGET = 3;
     */
    ORDER_BY_TARGET = 3,
    /**
     * @generated from protobuf enum value: ORDER_BY_CURRENT = 4;
     */
    ORDER_BY_CURRENT = 4,
    /**
     * @generated from protobuf enum value: ORDER_BY_REORDER = 5;
     */
    ORDER_BY_REORDER = 5,
    /**
     * @generated from protobuf enum value: ORDER_BY_DELTA = 6;
     */
    ORDER_BY_DELTA = 6,
    /**
     * @generated from protobuf enum value: ORDER_BY_UNITS_SOLD_YTD = 7;
     */
    ORDER_BY_UNITS_SOLD_YTD = 7,
    /**
     * @generated from protobuf enum value: ORDER_BY_UNITS_VS_LAST_YEAR = 8;
     */
    ORDER_BY_UNITS_VS_LAST_YEAR = 8,
    /**
     * @generated from protobuf enum value: ORDER_BY_MARGIN = 9;
     */
    ORDER_BY_MARGIN = 9,
    /**
     * @generated from protobuf enum value: ORDER_BY_UNITS_LAST_12MO = 10;
     */
    ORDER_BY_UNITS_LAST_12MO = 10,
    /**
     * @generated from protobuf enum value: ORDER_BY_UNITS_LAST_3MO = 11;
     */
    ORDER_BY_UNITS_LAST_3MO = 11,
    /**
     * @generated from protobuf enum value: ORDER_BY_UNITS_LAST_1MO = 12;
     */
    ORDER_BY_UNITS_LAST_1MO = 12,
    /**
     * @generated from protobuf enum value: ORDER_BY_LAST_SALE = 13;
     */
    ORDER_BY_LAST_SALE = 13,
    /**
     * @generated from protobuf enum value: ORDER_BY_TURNS = 14;
     */
    ORDER_BY_TURNS = 14
}
// @generated message type with reflection information, may provide speed optimized methods
class SearchBillPaymentsReq$Type extends MessageType<SearchBillPaymentsReq> {
    constructor() {
        super("rd.search.service.SearchBillPaymentsReq", [
            { no: 1, name: "query", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "created_at_range", kind: "message", T: () => TimestampRange },
            { no: 3, name: "customer_ids", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UUID },
            { no: 4, name: "created_by_ids", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UUID },
            { no: 5, name: "location_ids", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UUID },
            { no: 6, name: "cursor", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "offset", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 8, name: "limit", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 9, name: "purchase_methods", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["rd.txn.PurchaseMethod.Method", PurchaseMethod_Method, "METHOD_"] },
            { no: 10, name: "bill_payment_types", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["rd.txn.LedgerEntry.Type", LedgerEntry_Type, "TYPE_"] },
            { no: 11, name: "credit_filters", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["rd.search.service.SearchBillPaymentsReq.CreditFilter", SearchBillPaymentsReq_CreditFilter] }
        ]);
    }
    create(value?: PartialMessage<SearchBillPaymentsReq>): SearchBillPaymentsReq {
        const message = { query: "", customerIds: [], createdByIds: [], locationIds: [], cursor: "", offset: 0, limit: 0, purchaseMethods: [], billPaymentTypes: [], creditFilters: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchBillPaymentsReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchBillPaymentsReq): SearchBillPaymentsReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string query */ 1:
                    message.query = reader.string();
                    break;
                case /* rd.proto.timestamprange.TimestampRange created_at_range */ 2:
                    message.createdAtRange = TimestampRange.internalBinaryRead(reader, reader.uint32(), options, message.createdAtRange);
                    break;
                case /* repeated rd.proto.uuid.UUID customer_ids */ 3:
                    message.customerIds.push(UUID.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated rd.proto.uuid.UUID created_by_ids */ 4:
                    message.createdByIds.push(UUID.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated rd.proto.uuid.UUID location_ids */ 5:
                    message.locationIds.push(UUID.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string cursor */ 6:
                    message.cursor = reader.string();
                    break;
                case /* int32 offset */ 7:
                    message.offset = reader.int32();
                    break;
                case /* int32 limit */ 8:
                    message.limit = reader.int32();
                    break;
                case /* repeated rd.txn.PurchaseMethod.Method purchase_methods */ 9:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.purchaseMethods.push(reader.int32());
                    else
                        message.purchaseMethods.push(reader.int32());
                    break;
                case /* repeated rd.txn.LedgerEntry.Type bill_payment_types */ 10:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.billPaymentTypes.push(reader.int32());
                    else
                        message.billPaymentTypes.push(reader.int32());
                    break;
                case /* repeated rd.search.service.SearchBillPaymentsReq.CreditFilter credit_filters */ 11:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.creditFilters.push(reader.int32());
                    else
                        message.creditFilters.push(reader.int32());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SearchBillPaymentsReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string query = 1; */
        if (message.query !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.query);
        /* rd.proto.timestamprange.TimestampRange created_at_range = 2; */
        if (message.createdAtRange)
            TimestampRange.internalBinaryWrite(message.createdAtRange, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* repeated rd.proto.uuid.UUID customer_ids = 3; */
        for (let i = 0; i < message.customerIds.length; i++)
            UUID.internalBinaryWrite(message.customerIds[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* repeated rd.proto.uuid.UUID created_by_ids = 4; */
        for (let i = 0; i < message.createdByIds.length; i++)
            UUID.internalBinaryWrite(message.createdByIds[i], writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* repeated rd.proto.uuid.UUID location_ids = 5; */
        for (let i = 0; i < message.locationIds.length; i++)
            UUID.internalBinaryWrite(message.locationIds[i], writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* string cursor = 6; */
        if (message.cursor !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.cursor);
        /* int32 offset = 7; */
        if (message.offset !== 0)
            writer.tag(7, WireType.Varint).int32(message.offset);
        /* int32 limit = 8; */
        if (message.limit !== 0)
            writer.tag(8, WireType.Varint).int32(message.limit);
        /* repeated rd.txn.PurchaseMethod.Method purchase_methods = 9; */
        if (message.purchaseMethods.length) {
            writer.tag(9, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.purchaseMethods.length; i++)
                writer.int32(message.purchaseMethods[i]);
            writer.join();
        }
        /* repeated rd.txn.LedgerEntry.Type bill_payment_types = 10; */
        if (message.billPaymentTypes.length) {
            writer.tag(10, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.billPaymentTypes.length; i++)
                writer.int32(message.billPaymentTypes[i]);
            writer.join();
        }
        /* repeated rd.search.service.SearchBillPaymentsReq.CreditFilter credit_filters = 11; */
        if (message.creditFilters.length) {
            writer.tag(11, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.creditFilters.length; i++)
                writer.int32(message.creditFilters[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.SearchBillPaymentsReq
 */
export const SearchBillPaymentsReq = new SearchBillPaymentsReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchBillPaymentsRes$Type extends MessageType<SearchBillPaymentsRes> {
    constructor() {
        super("rd.search.service.SearchBillPaymentsRes", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SearchBillPaymentsRes_Result },
            { no: 2, name: "cursor", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<SearchBillPaymentsRes>): SearchBillPaymentsRes {
        const message = { results: [], cursor: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchBillPaymentsRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchBillPaymentsRes): SearchBillPaymentsRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated rd.search.service.SearchBillPaymentsRes.Result results */ 1:
                    message.results.push(SearchBillPaymentsRes_Result.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string cursor */ 2:
                    message.cursor = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SearchBillPaymentsRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated rd.search.service.SearchBillPaymentsRes.Result results = 1; */
        for (let i = 0; i < message.results.length; i++)
            SearchBillPaymentsRes_Result.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string cursor = 2; */
        if (message.cursor !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.cursor);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.SearchBillPaymentsRes
 */
export const SearchBillPaymentsRes = new SearchBillPaymentsRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchBillPaymentsRes_Result$Type extends MessageType<SearchBillPaymentsRes_Result> {
    constructor() {
        super("rd.search.service.SearchBillPaymentsRes.Result", [
            { no: 1, name: "bill_payment", kind: "message", T: () => BillPayment },
            { no: 2, name: "payment_ledger_entry", kind: "message", T: () => LedgerEntry },
            { no: 3, name: "write_off_ledger_entry", kind: "message", T: () => LedgerEntry },
            { no: 4, name: "sales_by_id", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => Sale } },
            { no: 5, name: "allocations_by_sale_id", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => Money } },
            { no: 6, name: "totals_by_sale_id", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => Money } },
            { no: 7, name: "purchase_orders_by_sale_id", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => PurchaseOrder } },
            { no: 8, name: "job_sites_by_sale_id", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => JobSite } },
            { no: 9, name: "locations_by_sale_id", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => Location } },
            { no: 10, name: "customer", kind: "message", T: () => Customer },
            { no: 11, name: "payment_card", kind: "message", T: () => Card }
        ]);
    }
    create(value?: PartialMessage<SearchBillPaymentsRes_Result>): SearchBillPaymentsRes_Result {
        const message = { salesById: {}, allocationsBySaleId: {}, totalsBySaleId: {}, purchaseOrdersBySaleId: {}, jobSitesBySaleId: {}, locationsBySaleId: {} };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchBillPaymentsRes_Result>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchBillPaymentsRes_Result): SearchBillPaymentsRes_Result {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.txn.BillPayment bill_payment */ 1:
                    message.billPayment = BillPayment.internalBinaryRead(reader, reader.uint32(), options, message.billPayment);
                    break;
                case /* rd.txn.LedgerEntry payment_ledger_entry */ 2:
                    message.paymentLedgerEntry = LedgerEntry.internalBinaryRead(reader, reader.uint32(), options, message.paymentLedgerEntry);
                    break;
                case /* rd.txn.LedgerEntry write_off_ledger_entry */ 3:
                    message.writeOffLedgerEntry = LedgerEntry.internalBinaryRead(reader, reader.uint32(), options, message.writeOffLedgerEntry);
                    break;
                case /* map<string, rd.txn.Sale> sales_by_id */ 4:
                    this.binaryReadMap4(message.salesById, reader, options);
                    break;
                case /* map<string, rd.proto.money.Money> allocations_by_sale_id */ 5:
                    this.binaryReadMap5(message.allocationsBySaleId, reader, options);
                    break;
                case /* map<string, rd.proto.money.Money> totals_by_sale_id */ 6:
                    this.binaryReadMap6(message.totalsBySaleId, reader, options);
                    break;
                case /* map<string, rd.customer.PurchaseOrder> purchase_orders_by_sale_id */ 7:
                    this.binaryReadMap7(message.purchaseOrdersBySaleId, reader, options);
                    break;
                case /* map<string, rd.customer.JobSite> job_sites_by_sale_id */ 8:
                    this.binaryReadMap8(message.jobSitesBySaleId, reader, options);
                    break;
                case /* map<string, rd.company.Location> locations_by_sale_id */ 9:
                    this.binaryReadMap9(message.locationsBySaleId, reader, options);
                    break;
                case /* rd.customer.Customer customer */ 10:
                    message.customer = Customer.internalBinaryRead(reader, reader.uint32(), options, message.customer);
                    break;
                case /* rd.payments.Card payment_card */ 11:
                    message.paymentCard = Card.internalBinaryRead(reader, reader.uint32(), options, message.paymentCard);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap4(map: SearchBillPaymentsRes_Result["salesById"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof SearchBillPaymentsRes_Result["salesById"] | undefined, val: SearchBillPaymentsRes_Result["salesById"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = Sale.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.search.service.SearchBillPaymentsRes.Result.sales_by_id");
            }
        }
        map[key ?? ""] = val ?? Sale.create();
    }
    private binaryReadMap5(map: SearchBillPaymentsRes_Result["allocationsBySaleId"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof SearchBillPaymentsRes_Result["allocationsBySaleId"] | undefined, val: SearchBillPaymentsRes_Result["allocationsBySaleId"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = Money.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.search.service.SearchBillPaymentsRes.Result.allocations_by_sale_id");
            }
        }
        map[key ?? ""] = val ?? Money.create();
    }
    private binaryReadMap6(map: SearchBillPaymentsRes_Result["totalsBySaleId"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof SearchBillPaymentsRes_Result["totalsBySaleId"] | undefined, val: SearchBillPaymentsRes_Result["totalsBySaleId"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = Money.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.search.service.SearchBillPaymentsRes.Result.totals_by_sale_id");
            }
        }
        map[key ?? ""] = val ?? Money.create();
    }
    private binaryReadMap7(map: SearchBillPaymentsRes_Result["purchaseOrdersBySaleId"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof SearchBillPaymentsRes_Result["purchaseOrdersBySaleId"] | undefined, val: SearchBillPaymentsRes_Result["purchaseOrdersBySaleId"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = PurchaseOrder.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.search.service.SearchBillPaymentsRes.Result.purchase_orders_by_sale_id");
            }
        }
        map[key ?? ""] = val ?? PurchaseOrder.create();
    }
    private binaryReadMap8(map: SearchBillPaymentsRes_Result["jobSitesBySaleId"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof SearchBillPaymentsRes_Result["jobSitesBySaleId"] | undefined, val: SearchBillPaymentsRes_Result["jobSitesBySaleId"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = JobSite.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.search.service.SearchBillPaymentsRes.Result.job_sites_by_sale_id");
            }
        }
        map[key ?? ""] = val ?? JobSite.create();
    }
    private binaryReadMap9(map: SearchBillPaymentsRes_Result["locationsBySaleId"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof SearchBillPaymentsRes_Result["locationsBySaleId"] | undefined, val: SearchBillPaymentsRes_Result["locationsBySaleId"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = Location.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.search.service.SearchBillPaymentsRes.Result.locations_by_sale_id");
            }
        }
        map[key ?? ""] = val ?? Location.create();
    }
    internalBinaryWrite(message: SearchBillPaymentsRes_Result, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.txn.BillPayment bill_payment = 1; */
        if (message.billPayment)
            BillPayment.internalBinaryWrite(message.billPayment, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.txn.LedgerEntry payment_ledger_entry = 2; */
        if (message.paymentLedgerEntry)
            LedgerEntry.internalBinaryWrite(message.paymentLedgerEntry, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* rd.txn.LedgerEntry write_off_ledger_entry = 3; */
        if (message.writeOffLedgerEntry)
            LedgerEntry.internalBinaryWrite(message.writeOffLedgerEntry, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* map<string, rd.txn.Sale> sales_by_id = 4; */
        for (let k of Object.keys(message.salesById)) {
            writer.tag(4, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            Sale.internalBinaryWrite(message.salesById[k], writer, options);
            writer.join().join();
        }
        /* map<string, rd.proto.money.Money> allocations_by_sale_id = 5; */
        for (let k of Object.keys(message.allocationsBySaleId)) {
            writer.tag(5, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            Money.internalBinaryWrite(message.allocationsBySaleId[k], writer, options);
            writer.join().join();
        }
        /* map<string, rd.proto.money.Money> totals_by_sale_id = 6; */
        for (let k of Object.keys(message.totalsBySaleId)) {
            writer.tag(6, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            Money.internalBinaryWrite(message.totalsBySaleId[k], writer, options);
            writer.join().join();
        }
        /* map<string, rd.customer.PurchaseOrder> purchase_orders_by_sale_id = 7; */
        for (let k of Object.keys(message.purchaseOrdersBySaleId)) {
            writer.tag(7, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            PurchaseOrder.internalBinaryWrite(message.purchaseOrdersBySaleId[k], writer, options);
            writer.join().join();
        }
        /* map<string, rd.customer.JobSite> job_sites_by_sale_id = 8; */
        for (let k of Object.keys(message.jobSitesBySaleId)) {
            writer.tag(8, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            JobSite.internalBinaryWrite(message.jobSitesBySaleId[k], writer, options);
            writer.join().join();
        }
        /* map<string, rd.company.Location> locations_by_sale_id = 9; */
        for (let k of Object.keys(message.locationsBySaleId)) {
            writer.tag(9, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            Location.internalBinaryWrite(message.locationsBySaleId[k], writer, options);
            writer.join().join();
        }
        /* rd.customer.Customer customer = 10; */
        if (message.customer)
            Customer.internalBinaryWrite(message.customer, writer.tag(10, WireType.LengthDelimited).fork(), options).join();
        /* rd.payments.Card payment_card = 11; */
        if (message.paymentCard)
            Card.internalBinaryWrite(message.paymentCard, writer.tag(11, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.SearchBillPaymentsRes.Result
 */
export const SearchBillPaymentsRes_Result = new SearchBillPaymentsRes_Result$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchRefundedBillPaymentsReq$Type extends MessageType<SearchRefundedBillPaymentsReq> {
    constructor() {
        super("rd.search.service.SearchRefundedBillPaymentsReq", [
            { no: 1, name: "query", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "created_at_range", kind: "message", T: () => TimestampRange },
            { no: 3, name: "customer_ids", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UUID },
            { no: 4, name: "created_by_ids", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UUID },
            { no: 5, name: "location_ids", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UUID },
            { no: 6, name: "cursor", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "offset", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 8, name: "limit", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<SearchRefundedBillPaymentsReq>): SearchRefundedBillPaymentsReq {
        const message = { query: "", customerIds: [], createdByIds: [], locationIds: [], cursor: "", offset: 0, limit: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchRefundedBillPaymentsReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchRefundedBillPaymentsReq): SearchRefundedBillPaymentsReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string query */ 1:
                    message.query = reader.string();
                    break;
                case /* rd.proto.timestamprange.TimestampRange created_at_range */ 2:
                    message.createdAtRange = TimestampRange.internalBinaryRead(reader, reader.uint32(), options, message.createdAtRange);
                    break;
                case /* repeated rd.proto.uuid.UUID customer_ids */ 3:
                    message.customerIds.push(UUID.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated rd.proto.uuid.UUID created_by_ids */ 4:
                    message.createdByIds.push(UUID.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated rd.proto.uuid.UUID location_ids */ 5:
                    message.locationIds.push(UUID.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string cursor */ 6:
                    message.cursor = reader.string();
                    break;
                case /* int32 offset */ 7:
                    message.offset = reader.int32();
                    break;
                case /* int32 limit */ 8:
                    message.limit = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SearchRefundedBillPaymentsReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string query = 1; */
        if (message.query !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.query);
        /* rd.proto.timestamprange.TimestampRange created_at_range = 2; */
        if (message.createdAtRange)
            TimestampRange.internalBinaryWrite(message.createdAtRange, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* repeated rd.proto.uuid.UUID customer_ids = 3; */
        for (let i = 0; i < message.customerIds.length; i++)
            UUID.internalBinaryWrite(message.customerIds[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* repeated rd.proto.uuid.UUID created_by_ids = 4; */
        for (let i = 0; i < message.createdByIds.length; i++)
            UUID.internalBinaryWrite(message.createdByIds[i], writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* repeated rd.proto.uuid.UUID location_ids = 5; */
        for (let i = 0; i < message.locationIds.length; i++)
            UUID.internalBinaryWrite(message.locationIds[i], writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* string cursor = 6; */
        if (message.cursor !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.cursor);
        /* int32 offset = 7; */
        if (message.offset !== 0)
            writer.tag(7, WireType.Varint).int32(message.offset);
        /* int32 limit = 8; */
        if (message.limit !== 0)
            writer.tag(8, WireType.Varint).int32(message.limit);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.SearchRefundedBillPaymentsReq
 */
export const SearchRefundedBillPaymentsReq = new SearchRefundedBillPaymentsReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchRefundedBillPaymentsRes$Type extends MessageType<SearchRefundedBillPaymentsRes> {
    constructor() {
        super("rd.search.service.SearchRefundedBillPaymentsRes", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SearchRefundedBillPaymentsRes_Result },
            { no: 2, name: "cursor", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<SearchRefundedBillPaymentsRes>): SearchRefundedBillPaymentsRes {
        const message = { results: [], cursor: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchRefundedBillPaymentsRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchRefundedBillPaymentsRes): SearchRefundedBillPaymentsRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated rd.search.service.SearchRefundedBillPaymentsRes.Result results */ 1:
                    message.results.push(SearchRefundedBillPaymentsRes_Result.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string cursor */ 2:
                    message.cursor = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SearchRefundedBillPaymentsRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated rd.search.service.SearchRefundedBillPaymentsRes.Result results = 1; */
        for (let i = 0; i < message.results.length; i++)
            SearchRefundedBillPaymentsRes_Result.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string cursor = 2; */
        if (message.cursor !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.cursor);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.SearchRefundedBillPaymentsRes
 */
export const SearchRefundedBillPaymentsRes = new SearchRefundedBillPaymentsRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchRefundedBillPaymentsRes_Result$Type extends MessageType<SearchRefundedBillPaymentsRes_Result> {
    constructor() {
        super("rd.search.service.SearchRefundedBillPaymentsRes.Result", [
            { no: 1, name: "payment_ledger_entry_id", kind: "message", T: () => UUID },
            { no: 2, name: "bill_payment", kind: "message", T: () => BillPayment },
            { no: 3, name: "payment_ledger_entry", kind: "message", T: () => LedgerEntry },
            { no: 4, name: "sales_by_id", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => Sale } },
            { no: 5, name: "allocations_by_sale_id", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => Money } },
            { no: 6, name: "totals_by_sale_id", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => Money } },
            { no: 7, name: "purchase_orders_by_sale_id", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => PurchaseOrder } },
            { no: 8, name: "job_sites_by_sale_id", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => JobSite } },
            { no: 9, name: "locations_by_sale_id", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => Location } },
            { no: 10, name: "customer", kind: "message", T: () => Customer },
            { no: 11, name: "payment_card", kind: "message", T: () => Card }
        ]);
    }
    create(value?: PartialMessage<SearchRefundedBillPaymentsRes_Result>): SearchRefundedBillPaymentsRes_Result {
        const message = { salesById: {}, allocationsBySaleId: {}, totalsBySaleId: {}, purchaseOrdersBySaleId: {}, jobSitesBySaleId: {}, locationsBySaleId: {} };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchRefundedBillPaymentsRes_Result>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchRefundedBillPaymentsRes_Result): SearchRefundedBillPaymentsRes_Result {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID payment_ledger_entry_id */ 1:
                    message.paymentLedgerEntryId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.paymentLedgerEntryId);
                    break;
                case /* rd.txn.BillPayment bill_payment */ 2:
                    message.billPayment = BillPayment.internalBinaryRead(reader, reader.uint32(), options, message.billPayment);
                    break;
                case /* rd.txn.LedgerEntry payment_ledger_entry */ 3:
                    message.paymentLedgerEntry = LedgerEntry.internalBinaryRead(reader, reader.uint32(), options, message.paymentLedgerEntry);
                    break;
                case /* map<string, rd.txn.Sale> sales_by_id */ 4:
                    this.binaryReadMap4(message.salesById, reader, options);
                    break;
                case /* map<string, rd.proto.money.Money> allocations_by_sale_id */ 5:
                    this.binaryReadMap5(message.allocationsBySaleId, reader, options);
                    break;
                case /* map<string, rd.proto.money.Money> totals_by_sale_id */ 6:
                    this.binaryReadMap6(message.totalsBySaleId, reader, options);
                    break;
                case /* map<string, rd.customer.PurchaseOrder> purchase_orders_by_sale_id */ 7:
                    this.binaryReadMap7(message.purchaseOrdersBySaleId, reader, options);
                    break;
                case /* map<string, rd.customer.JobSite> job_sites_by_sale_id */ 8:
                    this.binaryReadMap8(message.jobSitesBySaleId, reader, options);
                    break;
                case /* map<string, rd.company.Location> locations_by_sale_id */ 9:
                    this.binaryReadMap9(message.locationsBySaleId, reader, options);
                    break;
                case /* rd.customer.Customer customer */ 10:
                    message.customer = Customer.internalBinaryRead(reader, reader.uint32(), options, message.customer);
                    break;
                case /* rd.payments.Card payment_card */ 11:
                    message.paymentCard = Card.internalBinaryRead(reader, reader.uint32(), options, message.paymentCard);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap4(map: SearchRefundedBillPaymentsRes_Result["salesById"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof SearchRefundedBillPaymentsRes_Result["salesById"] | undefined, val: SearchRefundedBillPaymentsRes_Result["salesById"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = Sale.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.search.service.SearchRefundedBillPaymentsRes.Result.sales_by_id");
            }
        }
        map[key ?? ""] = val ?? Sale.create();
    }
    private binaryReadMap5(map: SearchRefundedBillPaymentsRes_Result["allocationsBySaleId"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof SearchRefundedBillPaymentsRes_Result["allocationsBySaleId"] | undefined, val: SearchRefundedBillPaymentsRes_Result["allocationsBySaleId"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = Money.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.search.service.SearchRefundedBillPaymentsRes.Result.allocations_by_sale_id");
            }
        }
        map[key ?? ""] = val ?? Money.create();
    }
    private binaryReadMap6(map: SearchRefundedBillPaymentsRes_Result["totalsBySaleId"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof SearchRefundedBillPaymentsRes_Result["totalsBySaleId"] | undefined, val: SearchRefundedBillPaymentsRes_Result["totalsBySaleId"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = Money.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.search.service.SearchRefundedBillPaymentsRes.Result.totals_by_sale_id");
            }
        }
        map[key ?? ""] = val ?? Money.create();
    }
    private binaryReadMap7(map: SearchRefundedBillPaymentsRes_Result["purchaseOrdersBySaleId"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof SearchRefundedBillPaymentsRes_Result["purchaseOrdersBySaleId"] | undefined, val: SearchRefundedBillPaymentsRes_Result["purchaseOrdersBySaleId"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = PurchaseOrder.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.search.service.SearchRefundedBillPaymentsRes.Result.purchase_orders_by_sale_id");
            }
        }
        map[key ?? ""] = val ?? PurchaseOrder.create();
    }
    private binaryReadMap8(map: SearchRefundedBillPaymentsRes_Result["jobSitesBySaleId"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof SearchRefundedBillPaymentsRes_Result["jobSitesBySaleId"] | undefined, val: SearchRefundedBillPaymentsRes_Result["jobSitesBySaleId"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = JobSite.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.search.service.SearchRefundedBillPaymentsRes.Result.job_sites_by_sale_id");
            }
        }
        map[key ?? ""] = val ?? JobSite.create();
    }
    private binaryReadMap9(map: SearchRefundedBillPaymentsRes_Result["locationsBySaleId"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof SearchRefundedBillPaymentsRes_Result["locationsBySaleId"] | undefined, val: SearchRefundedBillPaymentsRes_Result["locationsBySaleId"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = Location.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.search.service.SearchRefundedBillPaymentsRes.Result.locations_by_sale_id");
            }
        }
        map[key ?? ""] = val ?? Location.create();
    }
    internalBinaryWrite(message: SearchRefundedBillPaymentsRes_Result, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID payment_ledger_entry_id = 1; */
        if (message.paymentLedgerEntryId)
            UUID.internalBinaryWrite(message.paymentLedgerEntryId, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.txn.BillPayment bill_payment = 2; */
        if (message.billPayment)
            BillPayment.internalBinaryWrite(message.billPayment, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* rd.txn.LedgerEntry payment_ledger_entry = 3; */
        if (message.paymentLedgerEntry)
            LedgerEntry.internalBinaryWrite(message.paymentLedgerEntry, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* map<string, rd.txn.Sale> sales_by_id = 4; */
        for (let k of Object.keys(message.salesById)) {
            writer.tag(4, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            Sale.internalBinaryWrite(message.salesById[k], writer, options);
            writer.join().join();
        }
        /* map<string, rd.proto.money.Money> allocations_by_sale_id = 5; */
        for (let k of Object.keys(message.allocationsBySaleId)) {
            writer.tag(5, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            Money.internalBinaryWrite(message.allocationsBySaleId[k], writer, options);
            writer.join().join();
        }
        /* map<string, rd.proto.money.Money> totals_by_sale_id = 6; */
        for (let k of Object.keys(message.totalsBySaleId)) {
            writer.tag(6, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            Money.internalBinaryWrite(message.totalsBySaleId[k], writer, options);
            writer.join().join();
        }
        /* map<string, rd.customer.PurchaseOrder> purchase_orders_by_sale_id = 7; */
        for (let k of Object.keys(message.purchaseOrdersBySaleId)) {
            writer.tag(7, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            PurchaseOrder.internalBinaryWrite(message.purchaseOrdersBySaleId[k], writer, options);
            writer.join().join();
        }
        /* map<string, rd.customer.JobSite> job_sites_by_sale_id = 8; */
        for (let k of Object.keys(message.jobSitesBySaleId)) {
            writer.tag(8, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            JobSite.internalBinaryWrite(message.jobSitesBySaleId[k], writer, options);
            writer.join().join();
        }
        /* map<string, rd.company.Location> locations_by_sale_id = 9; */
        for (let k of Object.keys(message.locationsBySaleId)) {
            writer.tag(9, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            Location.internalBinaryWrite(message.locationsBySaleId[k], writer, options);
            writer.join().join();
        }
        /* rd.customer.Customer customer = 10; */
        if (message.customer)
            Customer.internalBinaryWrite(message.customer, writer.tag(10, WireType.LengthDelimited).fork(), options).join();
        /* rd.payments.Card payment_card = 11; */
        if (message.paymentCard)
            Card.internalBinaryWrite(message.paymentCard, writer.tag(11, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.SearchRefundedBillPaymentsRes.Result
 */
export const SearchRefundedBillPaymentsRes_Result = new SearchRefundedBillPaymentsRes_Result$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchCustomersV2Req$Type extends MessageType<SearchCustomersV2Req> {
    constructor() {
        super("rd.search.service.SearchCustomersV2Req", [
            { no: 1, name: "query", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "financing_term_id_strings", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "pricing_tier_id_strings", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "is_on_credit_hold_filter", kind: "enum", T: () => ["rd.search.service.BoolFilter", BoolFilter, "BOOL_FILTER_"] },
            { no: 6, name: "rep_staff_ids", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UUID },
            { no: 7, name: "is_active_filter", kind: "enum", T: () => ["rd.search.service.BoolFilter", BoolFilter, "BOOL_FILTER_"] },
            { no: 9, name: "sort_order", kind: "enum", T: () => ["rd.search.service.SortOrder", SortOrder, "SORT_ORDER_"] },
            { no: 10, name: "cursor", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "offset", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 12, name: "limit", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 13, name: "order_by", kind: "enum", T: () => ["rd.search.service.OrderCustomersBy", OrderCustomersBy, "ORDER_CUSTOMERS_BY_"] },
            { no: 14, name: "finance_charge_settings_ids", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UUID }
        ]);
    }
    create(value?: PartialMessage<SearchCustomersV2Req>): SearchCustomersV2Req {
        const message = { query: "", financingTermIdStrings: [], pricingTierIdStrings: [], isOnCreditHoldFilter: 0, repStaffIds: [], isActiveFilter: 0, sortOrder: 0, cursor: "", offset: 0, limit: 0, orderBy: 0, financeChargeSettingsIds: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchCustomersV2Req>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchCustomersV2Req): SearchCustomersV2Req {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string query */ 1:
                    message.query = reader.string();
                    break;
                case /* repeated string financing_term_id_strings */ 3:
                    message.financingTermIdStrings.push(reader.string());
                    break;
                case /* repeated string pricing_tier_id_strings */ 4:
                    message.pricingTierIdStrings.push(reader.string());
                    break;
                case /* rd.search.service.BoolFilter is_on_credit_hold_filter */ 5:
                    message.isOnCreditHoldFilter = reader.int32();
                    break;
                case /* repeated rd.proto.uuid.UUID rep_staff_ids */ 6:
                    message.repStaffIds.push(UUID.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* rd.search.service.BoolFilter is_active_filter */ 7:
                    message.isActiveFilter = reader.int32();
                    break;
                case /* rd.search.service.SortOrder sort_order */ 9:
                    message.sortOrder = reader.int32();
                    break;
                case /* string cursor */ 10:
                    message.cursor = reader.string();
                    break;
                case /* int32 offset */ 11:
                    message.offset = reader.int32();
                    break;
                case /* int32 limit */ 12:
                    message.limit = reader.int32();
                    break;
                case /* rd.search.service.OrderCustomersBy order_by */ 13:
                    message.orderBy = reader.int32();
                    break;
                case /* repeated rd.proto.uuid.UUID finance_charge_settings_ids */ 14:
                    message.financeChargeSettingsIds.push(UUID.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SearchCustomersV2Req, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string query = 1; */
        if (message.query !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.query);
        /* repeated string financing_term_id_strings = 3; */
        for (let i = 0; i < message.financingTermIdStrings.length; i++)
            writer.tag(3, WireType.LengthDelimited).string(message.financingTermIdStrings[i]);
        /* repeated string pricing_tier_id_strings = 4; */
        for (let i = 0; i < message.pricingTierIdStrings.length; i++)
            writer.tag(4, WireType.LengthDelimited).string(message.pricingTierIdStrings[i]);
        /* rd.search.service.BoolFilter is_on_credit_hold_filter = 5; */
        if (message.isOnCreditHoldFilter !== 0)
            writer.tag(5, WireType.Varint).int32(message.isOnCreditHoldFilter);
        /* repeated rd.proto.uuid.UUID rep_staff_ids = 6; */
        for (let i = 0; i < message.repStaffIds.length; i++)
            UUID.internalBinaryWrite(message.repStaffIds[i], writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* rd.search.service.BoolFilter is_active_filter = 7; */
        if (message.isActiveFilter !== 0)
            writer.tag(7, WireType.Varint).int32(message.isActiveFilter);
        /* rd.search.service.SortOrder sort_order = 9; */
        if (message.sortOrder !== 0)
            writer.tag(9, WireType.Varint).int32(message.sortOrder);
        /* string cursor = 10; */
        if (message.cursor !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.cursor);
        /* int32 offset = 11; */
        if (message.offset !== 0)
            writer.tag(11, WireType.Varint).int32(message.offset);
        /* int32 limit = 12; */
        if (message.limit !== 0)
            writer.tag(12, WireType.Varint).int32(message.limit);
        /* rd.search.service.OrderCustomersBy order_by = 13; */
        if (message.orderBy !== 0)
            writer.tag(13, WireType.Varint).int32(message.orderBy);
        /* repeated rd.proto.uuid.UUID finance_charge_settings_ids = 14; */
        for (let i = 0; i < message.financeChargeSettingsIds.length; i++)
            UUID.internalBinaryWrite(message.financeChargeSettingsIds[i], writer.tag(14, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.SearchCustomersV2Req
 */
export const SearchCustomersV2Req = new SearchCustomersV2Req$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchCustomersV2Res$Type extends MessageType<SearchCustomersV2Res> {
    constructor() {
        super("rd.search.service.SearchCustomersV2Res", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SearchCustomersV2Res_Result },
            { no: 2, name: "cursor", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<SearchCustomersV2Res>): SearchCustomersV2Res {
        const message = { results: [], cursor: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchCustomersV2Res>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchCustomersV2Res): SearchCustomersV2Res {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated rd.search.service.SearchCustomersV2Res.Result results */ 1:
                    message.results.push(SearchCustomersV2Res_Result.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string cursor */ 2:
                    message.cursor = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SearchCustomersV2Res, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated rd.search.service.SearchCustomersV2Res.Result results = 1; */
        for (let i = 0; i < message.results.length; i++)
            SearchCustomersV2Res_Result.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string cursor = 2; */
        if (message.cursor !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.cursor);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.SearchCustomersV2Res
 */
export const SearchCustomersV2Res = new SearchCustomersV2Res$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchCustomersV2Res_Result$Type extends MessageType<SearchCustomersV2Res_Result> {
    constructor() {
        super("rd.search.service.SearchCustomersV2Res.Result", [
            { no: 1, name: "customer", kind: "message", T: () => Customer },
            { no: 2, name: "contacts", kind: "message", T: () => Contacts },
            { no: 4, name: "financing_term", kind: "message", T: () => FinancingTerms_Term },
            { no: 5, name: "pricing_tier", kind: "message", T: () => PricingTiers_Tier },
            { no: 6, name: "balance", kind: "message", T: () => CustomerBalance },
            { no: 7, name: "total_balance", kind: "message", T: () => Money },
            { no: 8, name: "financing_term_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "last_interaction", kind: "message", T: () => Interaction },
            { no: 11, name: "rep_staff", kind: "message", T: () => Staff },
            { no: 13, name: "analytics", kind: "message", T: () => CustomerSalesAnalytics },
            { no: 14, name: "finance_charge_setting", kind: "message", T: () => FinanceChargeSetting }
        ]);
    }
    create(value?: PartialMessage<SearchCustomersV2Res_Result>): SearchCustomersV2Res_Result {
        const message = { financingTermName: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchCustomersV2Res_Result>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchCustomersV2Res_Result): SearchCustomersV2Res_Result {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.customer.Customer customer */ 1:
                    message.customer = Customer.internalBinaryRead(reader, reader.uint32(), options, message.customer);
                    break;
                case /* rd.customer.Contacts contacts */ 2:
                    message.contacts = Contacts.internalBinaryRead(reader, reader.uint32(), options, message.contacts);
                    break;
                case /* rd.company.FinancingTerms.Term financing_term */ 4:
                    message.financingTerm = FinancingTerms_Term.internalBinaryRead(reader, reader.uint32(), options, message.financingTerm);
                    break;
                case /* rd.company.PricingTiers.Tier pricing_tier */ 5:
                    message.pricingTier = PricingTiers_Tier.internalBinaryRead(reader, reader.uint32(), options, message.pricingTier);
                    break;
                case /* rd.txn.CustomerBalance balance */ 6:
                    message.balance = CustomerBalance.internalBinaryRead(reader, reader.uint32(), options, message.balance);
                    break;
                case /* rd.proto.money.Money total_balance */ 7:
                    message.totalBalance = Money.internalBinaryRead(reader, reader.uint32(), options, message.totalBalance);
                    break;
                case /* string financing_term_name */ 8:
                    message.financingTermName = reader.string();
                    break;
                case /* rd.customer.Interaction last_interaction */ 10:
                    message.lastInteraction = Interaction.internalBinaryRead(reader, reader.uint32(), options, message.lastInteraction);
                    break;
                case /* rd.company.Staff rep_staff */ 11:
                    message.repStaff = Staff.internalBinaryRead(reader, reader.uint32(), options, message.repStaff);
                    break;
                case /* rd.txn.CustomerSalesAnalytics analytics */ 13:
                    message.analytics = CustomerSalesAnalytics.internalBinaryRead(reader, reader.uint32(), options, message.analytics);
                    break;
                case /* rd.company.FinanceChargeSetting finance_charge_setting */ 14:
                    message.financeChargeSetting = FinanceChargeSetting.internalBinaryRead(reader, reader.uint32(), options, message.financeChargeSetting);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SearchCustomersV2Res_Result, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.customer.Customer customer = 1; */
        if (message.customer)
            Customer.internalBinaryWrite(message.customer, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.customer.Contacts contacts = 2; */
        if (message.contacts)
            Contacts.internalBinaryWrite(message.contacts, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* rd.company.FinancingTerms.Term financing_term = 4; */
        if (message.financingTerm)
            FinancingTerms_Term.internalBinaryWrite(message.financingTerm, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* rd.company.PricingTiers.Tier pricing_tier = 5; */
        if (message.pricingTier)
            PricingTiers_Tier.internalBinaryWrite(message.pricingTier, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* rd.txn.CustomerBalance balance = 6; */
        if (message.balance)
            CustomerBalance.internalBinaryWrite(message.balance, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money total_balance = 7; */
        if (message.totalBalance)
            Money.internalBinaryWrite(message.totalBalance, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* string financing_term_name = 8; */
        if (message.financingTermName !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.financingTermName);
        /* rd.customer.Interaction last_interaction = 10; */
        if (message.lastInteraction)
            Interaction.internalBinaryWrite(message.lastInteraction, writer.tag(10, WireType.LengthDelimited).fork(), options).join();
        /* rd.company.Staff rep_staff = 11; */
        if (message.repStaff)
            Staff.internalBinaryWrite(message.repStaff, writer.tag(11, WireType.LengthDelimited).fork(), options).join();
        /* rd.txn.CustomerSalesAnalytics analytics = 13; */
        if (message.analytics)
            CustomerSalesAnalytics.internalBinaryWrite(message.analytics, writer.tag(13, WireType.LengthDelimited).fork(), options).join();
        /* rd.company.FinanceChargeSetting finance_charge_setting = 14; */
        if (message.financeChargeSetting)
            FinanceChargeSetting.internalBinaryWrite(message.financeChargeSetting, writer.tag(14, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.SearchCustomersV2Res.Result
 */
export const SearchCustomersV2Res_Result = new SearchCustomersV2Res_Result$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BulkExportCustomersReq$Type extends MessageType<BulkExportCustomersReq> {
    constructor() {
        super("rd.search.service.BulkExportCustomersReq", [
            { no: 1, name: "query", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "financing_term_id_strings", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "pricing_tier_id_strings", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "is_on_credit_hold_filter", kind: "enum", T: () => ["rd.search.service.BoolFilter", BoolFilter, "BOOL_FILTER_"] },
            { no: 6, name: "rep_staff_ids", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UUID },
            { no: 7, name: "is_active_filter", kind: "enum", T: () => ["rd.search.service.BoolFilter", BoolFilter, "BOOL_FILTER_"] },
            { no: 8, name: "order_by", kind: "enum", T: () => ["rd.search.service.OrderCustomersBy", OrderCustomersBy, "ORDER_CUSTOMERS_BY_"] },
            { no: 9, name: "sort_order", kind: "enum", T: () => ["rd.search.service.SortOrder", SortOrder, "SORT_ORDER_"] },
            { no: 10, name: "is_template", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 11, name: "finance_charge_setting_ids", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UUID }
        ]);
    }
    create(value?: PartialMessage<BulkExportCustomersReq>): BulkExportCustomersReq {
        const message = { query: "", financingTermIdStrings: [], pricingTierIdStrings: [], isOnCreditHoldFilter: 0, repStaffIds: [], isActiveFilter: 0, orderBy: 0, sortOrder: 0, isTemplate: false, financeChargeSettingIds: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<BulkExportCustomersReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BulkExportCustomersReq): BulkExportCustomersReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string query */ 1:
                    message.query = reader.string();
                    break;
                case /* repeated string financing_term_id_strings */ 3:
                    message.financingTermIdStrings.push(reader.string());
                    break;
                case /* repeated string pricing_tier_id_strings */ 4:
                    message.pricingTierIdStrings.push(reader.string());
                    break;
                case /* rd.search.service.BoolFilter is_on_credit_hold_filter */ 5:
                    message.isOnCreditHoldFilter = reader.int32();
                    break;
                case /* repeated rd.proto.uuid.UUID rep_staff_ids */ 6:
                    message.repStaffIds.push(UUID.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* rd.search.service.BoolFilter is_active_filter */ 7:
                    message.isActiveFilter = reader.int32();
                    break;
                case /* rd.search.service.OrderCustomersBy order_by */ 8:
                    message.orderBy = reader.int32();
                    break;
                case /* rd.search.service.SortOrder sort_order */ 9:
                    message.sortOrder = reader.int32();
                    break;
                case /* bool is_template */ 10:
                    message.isTemplate = reader.bool();
                    break;
                case /* repeated rd.proto.uuid.UUID finance_charge_setting_ids */ 11:
                    message.financeChargeSettingIds.push(UUID.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BulkExportCustomersReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string query = 1; */
        if (message.query !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.query);
        /* repeated string financing_term_id_strings = 3; */
        for (let i = 0; i < message.financingTermIdStrings.length; i++)
            writer.tag(3, WireType.LengthDelimited).string(message.financingTermIdStrings[i]);
        /* repeated string pricing_tier_id_strings = 4; */
        for (let i = 0; i < message.pricingTierIdStrings.length; i++)
            writer.tag(4, WireType.LengthDelimited).string(message.pricingTierIdStrings[i]);
        /* rd.search.service.BoolFilter is_on_credit_hold_filter = 5; */
        if (message.isOnCreditHoldFilter !== 0)
            writer.tag(5, WireType.Varint).int32(message.isOnCreditHoldFilter);
        /* repeated rd.proto.uuid.UUID rep_staff_ids = 6; */
        for (let i = 0; i < message.repStaffIds.length; i++)
            UUID.internalBinaryWrite(message.repStaffIds[i], writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* rd.search.service.BoolFilter is_active_filter = 7; */
        if (message.isActiveFilter !== 0)
            writer.tag(7, WireType.Varint).int32(message.isActiveFilter);
        /* rd.search.service.OrderCustomersBy order_by = 8; */
        if (message.orderBy !== 0)
            writer.tag(8, WireType.Varint).int32(message.orderBy);
        /* rd.search.service.SortOrder sort_order = 9; */
        if (message.sortOrder !== 0)
            writer.tag(9, WireType.Varint).int32(message.sortOrder);
        /* bool is_template = 10; */
        if (message.isTemplate !== false)
            writer.tag(10, WireType.Varint).bool(message.isTemplate);
        /* repeated rd.proto.uuid.UUID finance_charge_setting_ids = 11; */
        for (let i = 0; i < message.financeChargeSettingIds.length; i++)
            UUID.internalBinaryWrite(message.financeChargeSettingIds[i], writer.tag(11, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.BulkExportCustomersReq
 */
export const BulkExportCustomersReq = new BulkExportCustomersReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BulkExportCustomersRes$Type extends MessageType<BulkExportCustomersRes> {
    constructor() {
        super("rd.search.service.BulkExportCustomersRes", [
            { no: 1, name: "excel_bytes", kind: "scalar", T: 12 /*ScalarType.BYTES*/ }
        ]);
    }
    create(value?: PartialMessage<BulkExportCustomersRes>): BulkExportCustomersRes {
        const message = { excelBytes: new Uint8Array(0) };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<BulkExportCustomersRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BulkExportCustomersRes): BulkExportCustomersRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bytes excel_bytes */ 1:
                    message.excelBytes = reader.bytes();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BulkExportCustomersRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bytes excel_bytes = 1; */
        if (message.excelBytes.length)
            writer.tag(1, WireType.LengthDelimited).bytes(message.excelBytes);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.BulkExportCustomersRes
 */
export const BulkExportCustomersRes = new BulkExportCustomersRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchCustomPricesReq$Type extends MessageType<SearchCustomPricesReq> {
    constructor() {
        super("rd.search.service.SearchCustomPricesReq", [
            { no: 1, name: "query", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<SearchCustomPricesReq>): SearchCustomPricesReq {
        const message = { query: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchCustomPricesReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchCustomPricesReq): SearchCustomPricesReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string query */ 1:
                    message.query = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SearchCustomPricesReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string query = 1; */
        if (message.query !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.query);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.SearchCustomPricesReq
 */
export const SearchCustomPricesReq = new SearchCustomPricesReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchCustomPricesRes$Type extends MessageType<SearchCustomPricesRes> {
    constructor() {
        super("rd.search.service.SearchCustomPricesRes", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SearchCustomPricesRes_Result }
        ]);
    }
    create(value?: PartialMessage<SearchCustomPricesRes>): SearchCustomPricesRes {
        const message = { results: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchCustomPricesRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchCustomPricesRes): SearchCustomPricesRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated rd.search.service.SearchCustomPricesRes.Result results */ 1:
                    message.results.push(SearchCustomPricesRes_Result.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SearchCustomPricesRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated rd.search.service.SearchCustomPricesRes.Result results = 1; */
        for (let i = 0; i < message.results.length; i++)
            SearchCustomPricesRes_Result.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.SearchCustomPricesRes
 */
export const SearchCustomPricesRes = new SearchCustomPricesRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchCustomPricesRes_Result$Type extends MessageType<SearchCustomPricesRes_Result> {
    constructor() {
        super("rd.search.service.SearchCustomPricesRes.Result", [
            { no: 1, name: "customer", kind: "message", T: () => Customer },
            { no: 2, name: "custom_prices", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => CustomPrice },
            { no: 3, name: "products_by_id", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => Product } }
        ]);
    }
    create(value?: PartialMessage<SearchCustomPricesRes_Result>): SearchCustomPricesRes_Result {
        const message = { customPrices: [], productsById: {} };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchCustomPricesRes_Result>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchCustomPricesRes_Result): SearchCustomPricesRes_Result {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.customer.Customer customer */ 1:
                    message.customer = Customer.internalBinaryRead(reader, reader.uint32(), options, message.customer);
                    break;
                case /* repeated rd.product.CustomPrice custom_prices */ 2:
                    message.customPrices.push(CustomPrice.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* map<string, rd.product.Product> products_by_id */ 3:
                    this.binaryReadMap3(message.productsById, reader, options);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap3(map: SearchCustomPricesRes_Result["productsById"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof SearchCustomPricesRes_Result["productsById"] | undefined, val: SearchCustomPricesRes_Result["productsById"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = Product.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.search.service.SearchCustomPricesRes.Result.products_by_id");
            }
        }
        map[key ?? ""] = val ?? Product.create();
    }
    internalBinaryWrite(message: SearchCustomPricesRes_Result, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.customer.Customer customer = 1; */
        if (message.customer)
            Customer.internalBinaryWrite(message.customer, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* repeated rd.product.CustomPrice custom_prices = 2; */
        for (let i = 0; i < message.customPrices.length; i++)
            CustomPrice.internalBinaryWrite(message.customPrices[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* map<string, rd.product.Product> products_by_id = 3; */
        for (let k of Object.keys(message.productsById)) {
            writer.tag(3, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            Product.internalBinaryWrite(message.productsById[k], writer, options);
            writer.join().join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.SearchCustomPricesRes.Result
 */
export const SearchCustomPricesRes_Result = new SearchCustomPricesRes_Result$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchOrdersReq$Type extends MessageType<SearchOrdersReq> {
    constructor() {
        super("rd.search.service.SearchOrdersReq", [
            { no: 1, name: "query", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "created_at_range", kind: "message", T: () => TimestampRange },
            { no: 3, name: "ordered_at_range", kind: "message", T: () => TimestampRange },
            { no: 4, name: "received_at_range", kind: "message", T: () => TimestampRange },
            { no: 5, name: "voided_at_range", kind: "message", T: () => TimestampRange },
            { no: 6, name: "location_ids", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UUID },
            { no: 7, name: "statuses", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["rd.txn.Order.Status", Order_Status, "STATUS_"] },
            { no: 8, name: "cursor", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "offset", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 10, name: "limit", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<SearchOrdersReq>): SearchOrdersReq {
        const message = { query: "", locationIds: [], statuses: [], cursor: "", offset: 0, limit: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchOrdersReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchOrdersReq): SearchOrdersReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string query */ 1:
                    message.query = reader.string();
                    break;
                case /* rd.proto.timestamprange.TimestampRange created_at_range */ 2:
                    message.createdAtRange = TimestampRange.internalBinaryRead(reader, reader.uint32(), options, message.createdAtRange);
                    break;
                case /* rd.proto.timestamprange.TimestampRange ordered_at_range */ 3:
                    message.orderedAtRange = TimestampRange.internalBinaryRead(reader, reader.uint32(), options, message.orderedAtRange);
                    break;
                case /* rd.proto.timestamprange.TimestampRange received_at_range */ 4:
                    message.receivedAtRange = TimestampRange.internalBinaryRead(reader, reader.uint32(), options, message.receivedAtRange);
                    break;
                case /* rd.proto.timestamprange.TimestampRange voided_at_range */ 5:
                    message.voidedAtRange = TimestampRange.internalBinaryRead(reader, reader.uint32(), options, message.voidedAtRange);
                    break;
                case /* repeated rd.proto.uuid.UUID location_ids */ 6:
                    message.locationIds.push(UUID.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated rd.txn.Order.Status statuses */ 7:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.statuses.push(reader.int32());
                    else
                        message.statuses.push(reader.int32());
                    break;
                case /* string cursor */ 8:
                    message.cursor = reader.string();
                    break;
                case /* int32 offset */ 9:
                    message.offset = reader.int32();
                    break;
                case /* int32 limit */ 10:
                    message.limit = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SearchOrdersReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string query = 1; */
        if (message.query !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.query);
        /* rd.proto.timestamprange.TimestampRange created_at_range = 2; */
        if (message.createdAtRange)
            TimestampRange.internalBinaryWrite(message.createdAtRange, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.timestamprange.TimestampRange ordered_at_range = 3; */
        if (message.orderedAtRange)
            TimestampRange.internalBinaryWrite(message.orderedAtRange, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.timestamprange.TimestampRange received_at_range = 4; */
        if (message.receivedAtRange)
            TimestampRange.internalBinaryWrite(message.receivedAtRange, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.timestamprange.TimestampRange voided_at_range = 5; */
        if (message.voidedAtRange)
            TimestampRange.internalBinaryWrite(message.voidedAtRange, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* repeated rd.proto.uuid.UUID location_ids = 6; */
        for (let i = 0; i < message.locationIds.length; i++)
            UUID.internalBinaryWrite(message.locationIds[i], writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* repeated rd.txn.Order.Status statuses = 7; */
        if (message.statuses.length) {
            writer.tag(7, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.statuses.length; i++)
                writer.int32(message.statuses[i]);
            writer.join();
        }
        /* string cursor = 8; */
        if (message.cursor !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.cursor);
        /* int32 offset = 9; */
        if (message.offset !== 0)
            writer.tag(9, WireType.Varint).int32(message.offset);
        /* int32 limit = 10; */
        if (message.limit !== 0)
            writer.tag(10, WireType.Varint).int32(message.limit);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.SearchOrdersReq
 */
export const SearchOrdersReq = new SearchOrdersReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchOrdersRes$Type extends MessageType<SearchOrdersRes> {
    constructor() {
        super("rd.search.service.SearchOrdersRes", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SearchOrdersRes_Result },
            { no: 2, name: "cursor", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<SearchOrdersRes>): SearchOrdersRes {
        const message = { results: [], cursor: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchOrdersRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchOrdersRes): SearchOrdersRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated rd.search.service.SearchOrdersRes.Result results */ 1:
                    message.results.push(SearchOrdersRes_Result.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string cursor */ 2:
                    message.cursor = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SearchOrdersRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated rd.search.service.SearchOrdersRes.Result results = 1; */
        for (let i = 0; i < message.results.length; i++)
            SearchOrdersRes_Result.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string cursor = 2; */
        if (message.cursor !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.cursor);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.SearchOrdersRes
 */
export const SearchOrdersRes = new SearchOrdersRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchOrdersRes_Result$Type extends MessageType<SearchOrdersRes_Result> {
    constructor() {
        super("rd.search.service.SearchOrdersRes.Result", [
            { no: 1, name: "order", kind: "message", T: () => Order },
            { no: 2, name: "vendor", kind: "message", T: () => Vendor },
            { no: 3, name: "location", kind: "message", T: () => Location },
            { no: 4, name: "products_by_id", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => Product } },
            { no: 5, name: "matched_products_by_id", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => Product } },
            { no: 6, name: "total", kind: "message", T: () => Money },
            { no: 7, name: "order_shipments", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => OrderShipment }
        ]);
    }
    create(value?: PartialMessage<SearchOrdersRes_Result>): SearchOrdersRes_Result {
        const message = { productsById: {}, matchedProductsById: {}, orderShipments: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchOrdersRes_Result>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchOrdersRes_Result): SearchOrdersRes_Result {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.txn.Order order */ 1:
                    message.order = Order.internalBinaryRead(reader, reader.uint32(), options, message.order);
                    break;
                case /* rd.vendors.Vendor vendor */ 2:
                    message.vendor = Vendor.internalBinaryRead(reader, reader.uint32(), options, message.vendor);
                    break;
                case /* rd.company.Location location */ 3:
                    message.location = Location.internalBinaryRead(reader, reader.uint32(), options, message.location);
                    break;
                case /* map<string, rd.product.Product> products_by_id */ 4:
                    this.binaryReadMap4(message.productsById, reader, options);
                    break;
                case /* map<string, rd.product.Product> matched_products_by_id */ 5:
                    this.binaryReadMap5(message.matchedProductsById, reader, options);
                    break;
                case /* rd.proto.money.Money total */ 6:
                    message.total = Money.internalBinaryRead(reader, reader.uint32(), options, message.total);
                    break;
                case /* repeated rd.txn.OrderShipment order_shipments */ 7:
                    message.orderShipments.push(OrderShipment.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap4(map: SearchOrdersRes_Result["productsById"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof SearchOrdersRes_Result["productsById"] | undefined, val: SearchOrdersRes_Result["productsById"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = Product.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.search.service.SearchOrdersRes.Result.products_by_id");
            }
        }
        map[key ?? ""] = val ?? Product.create();
    }
    private binaryReadMap5(map: SearchOrdersRes_Result["matchedProductsById"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof SearchOrdersRes_Result["matchedProductsById"] | undefined, val: SearchOrdersRes_Result["matchedProductsById"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = Product.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.search.service.SearchOrdersRes.Result.matched_products_by_id");
            }
        }
        map[key ?? ""] = val ?? Product.create();
    }
    internalBinaryWrite(message: SearchOrdersRes_Result, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.txn.Order order = 1; */
        if (message.order)
            Order.internalBinaryWrite(message.order, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.vendors.Vendor vendor = 2; */
        if (message.vendor)
            Vendor.internalBinaryWrite(message.vendor, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* rd.company.Location location = 3; */
        if (message.location)
            Location.internalBinaryWrite(message.location, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* map<string, rd.product.Product> products_by_id = 4; */
        for (let k of Object.keys(message.productsById)) {
            writer.tag(4, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            Product.internalBinaryWrite(message.productsById[k], writer, options);
            writer.join().join();
        }
        /* map<string, rd.product.Product> matched_products_by_id = 5; */
        for (let k of Object.keys(message.matchedProductsById)) {
            writer.tag(5, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            Product.internalBinaryWrite(message.matchedProductsById[k], writer, options);
            writer.join().join();
        }
        /* rd.proto.money.Money total = 6; */
        if (message.total)
            Money.internalBinaryWrite(message.total, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* repeated rd.txn.OrderShipment order_shipments = 7; */
        for (let i = 0; i < message.orderShipments.length; i++)
            OrderShipment.internalBinaryWrite(message.orderShipments[i], writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.SearchOrdersRes.Result
 */
export const SearchOrdersRes_Result = new SearchOrdersRes_Result$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchOrderShipmentsReq$Type extends MessageType<SearchOrderShipmentsReq> {
    constructor() {
        super("rd.search.service.SearchOrderShipmentsReq", [
            { no: 1, name: "query", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "location_ids", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UUID },
            { no: 4, name: "cursor", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "offset", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "limit", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 10, name: "isPaid", kind: "enum", T: () => ["rd.search.service.BoolFilter", BoolFilter, "BOOL_FILTER_"] },
            { no: 11, name: "timestamp_range", kind: "message", T: () => TimestampRange }
        ]);
    }
    create(value?: PartialMessage<SearchOrderShipmentsReq>): SearchOrderShipmentsReq {
        const message = { query: "", locationIds: [], cursor: "", offset: 0, limit: 0, isPaid: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchOrderShipmentsReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchOrderShipmentsReq): SearchOrderShipmentsReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string query */ 1:
                    message.query = reader.string();
                    break;
                case /* repeated rd.proto.uuid.UUID location_ids */ 3:
                    message.locationIds.push(UUID.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string cursor */ 4:
                    message.cursor = reader.string();
                    break;
                case /* int32 offset */ 5:
                    message.offset = reader.int32();
                    break;
                case /* int32 limit */ 6:
                    message.limit = reader.int32();
                    break;
                case /* rd.search.service.BoolFilter isPaid */ 10:
                    message.isPaid = reader.int32();
                    break;
                case /* rd.proto.timestamprange.TimestampRange timestamp_range */ 11:
                    message.timestampRange = TimestampRange.internalBinaryRead(reader, reader.uint32(), options, message.timestampRange);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SearchOrderShipmentsReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string query = 1; */
        if (message.query !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.query);
        /* repeated rd.proto.uuid.UUID location_ids = 3; */
        for (let i = 0; i < message.locationIds.length; i++)
            UUID.internalBinaryWrite(message.locationIds[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* string cursor = 4; */
        if (message.cursor !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.cursor);
        /* int32 offset = 5; */
        if (message.offset !== 0)
            writer.tag(5, WireType.Varint).int32(message.offset);
        /* int32 limit = 6; */
        if (message.limit !== 0)
            writer.tag(6, WireType.Varint).int32(message.limit);
        /* rd.search.service.BoolFilter isPaid = 10; */
        if (message.isPaid !== 0)
            writer.tag(10, WireType.Varint).int32(message.isPaid);
        /* rd.proto.timestamprange.TimestampRange timestamp_range = 11; */
        if (message.timestampRange)
            TimestampRange.internalBinaryWrite(message.timestampRange, writer.tag(11, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.SearchOrderShipmentsReq
 */
export const SearchOrderShipmentsReq = new SearchOrderShipmentsReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchOrderShipmentsRes$Type extends MessageType<SearchOrderShipmentsRes> {
    constructor() {
        super("rd.search.service.SearchOrderShipmentsRes", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SearchOrderShipmentsRes_Result },
            { no: 2, name: "cursor", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<SearchOrderShipmentsRes>): SearchOrderShipmentsRes {
        const message = { results: [], cursor: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchOrderShipmentsRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchOrderShipmentsRes): SearchOrderShipmentsRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated rd.search.service.SearchOrderShipmentsRes.Result results */ 1:
                    message.results.push(SearchOrderShipmentsRes_Result.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string cursor */ 2:
                    message.cursor = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SearchOrderShipmentsRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated rd.search.service.SearchOrderShipmentsRes.Result results = 1; */
        for (let i = 0; i < message.results.length; i++)
            SearchOrderShipmentsRes_Result.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string cursor = 2; */
        if (message.cursor !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.cursor);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.SearchOrderShipmentsRes
 */
export const SearchOrderShipmentsRes = new SearchOrderShipmentsRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchOrderShipmentsRes_Result$Type extends MessageType<SearchOrderShipmentsRes_Result> {
    constructor() {
        super("rd.search.service.SearchOrderShipmentsRes.Result", [
            { no: 1, name: "order_shipment", kind: "message", T: () => OrderShipment },
            { no: 2, name: "vendor", kind: "message", T: () => Vendor },
            { no: 3, name: "location", kind: "message", T: () => Location },
            { no: 4, name: "products_by_id", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => Product } },
            { no: 5, name: "matched_products_by_id", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => Product } },
            { no: 6, name: "total", kind: "message", T: () => Money }
        ]);
    }
    create(value?: PartialMessage<SearchOrderShipmentsRes_Result>): SearchOrderShipmentsRes_Result {
        const message = { productsById: {}, matchedProductsById: {} };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchOrderShipmentsRes_Result>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchOrderShipmentsRes_Result): SearchOrderShipmentsRes_Result {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.txn.OrderShipment order_shipment */ 1:
                    message.orderShipment = OrderShipment.internalBinaryRead(reader, reader.uint32(), options, message.orderShipment);
                    break;
                case /* rd.vendors.Vendor vendor */ 2:
                    message.vendor = Vendor.internalBinaryRead(reader, reader.uint32(), options, message.vendor);
                    break;
                case /* rd.company.Location location */ 3:
                    message.location = Location.internalBinaryRead(reader, reader.uint32(), options, message.location);
                    break;
                case /* map<string, rd.product.Product> products_by_id */ 4:
                    this.binaryReadMap4(message.productsById, reader, options);
                    break;
                case /* map<string, rd.product.Product> matched_products_by_id */ 5:
                    this.binaryReadMap5(message.matchedProductsById, reader, options);
                    break;
                case /* rd.proto.money.Money total */ 6:
                    message.total = Money.internalBinaryRead(reader, reader.uint32(), options, message.total);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap4(map: SearchOrderShipmentsRes_Result["productsById"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof SearchOrderShipmentsRes_Result["productsById"] | undefined, val: SearchOrderShipmentsRes_Result["productsById"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = Product.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.search.service.SearchOrderShipmentsRes.Result.products_by_id");
            }
        }
        map[key ?? ""] = val ?? Product.create();
    }
    private binaryReadMap5(map: SearchOrderShipmentsRes_Result["matchedProductsById"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof SearchOrderShipmentsRes_Result["matchedProductsById"] | undefined, val: SearchOrderShipmentsRes_Result["matchedProductsById"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = Product.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.search.service.SearchOrderShipmentsRes.Result.matched_products_by_id");
            }
        }
        map[key ?? ""] = val ?? Product.create();
    }
    internalBinaryWrite(message: SearchOrderShipmentsRes_Result, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.txn.OrderShipment order_shipment = 1; */
        if (message.orderShipment)
            OrderShipment.internalBinaryWrite(message.orderShipment, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.vendors.Vendor vendor = 2; */
        if (message.vendor)
            Vendor.internalBinaryWrite(message.vendor, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* rd.company.Location location = 3; */
        if (message.location)
            Location.internalBinaryWrite(message.location, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* map<string, rd.product.Product> products_by_id = 4; */
        for (let k of Object.keys(message.productsById)) {
            writer.tag(4, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            Product.internalBinaryWrite(message.productsById[k], writer, options);
            writer.join().join();
        }
        /* map<string, rd.product.Product> matched_products_by_id = 5; */
        for (let k of Object.keys(message.matchedProductsById)) {
            writer.tag(5, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            Product.internalBinaryWrite(message.matchedProductsById[k], writer, options);
            writer.join().join();
        }
        /* rd.proto.money.Money total = 6; */
        if (message.total)
            Money.internalBinaryWrite(message.total, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.SearchOrderShipmentsRes.Result
 */
export const SearchOrderShipmentsRes_Result = new SearchOrderShipmentsRes_Result$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchProductsV2Req$Type extends MessageType<SearchProductsV2Req> {
    constructor() {
        super("rd.search.service.SearchProductsV2Req", [
            { no: 1, name: "query", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "inv_sort_location_id", kind: "message", T: () => UUID },
            { no: 3, name: "current_location_id", kind: "message", T: () => UUID, options: { "rd.validator.require": true } },
            { no: 5, name: "vendor_ids", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UUID },
            { no: 6, name: "additional_fee_id_strings", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "is_active_filter", kind: "enum", T: () => ["rd.search.service.BoolFilter", BoolFilter, "BOOL_FILTER_"] },
            { no: 9, name: "is_tintable_filter", kind: "enum", T: () => ["rd.search.service.BoolFilter", BoolFilter, "BOOL_FILTER_"] },
            { no: 10, name: "cursor", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "offset", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 12, name: "limit", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 13, name: "order_by", kind: "enum", T: () => ["rd.search.service.SearchProductsV2Req.OrderBy", SearchProductsV2Req_OrderBy, "ORDER_BY_"] },
            { no: 14, name: "sort_order", kind: "enum", T: () => ["rd.search.service.SortOrder", SortOrder, "SORT_ORDER_"] },
            { no: 15, name: "tax_statuses", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["rd.product.Product.TaxStatus", Product_TaxStatus, "TAX_STATUS_"] },
            { no: 16, name: "department_ids", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UUID },
            { no: 17, name: "class_ids", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UUID },
            { no: 18, name: "fineline_ids", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UUID }
        ]);
    }
    create(value?: PartialMessage<SearchProductsV2Req>): SearchProductsV2Req {
        const message = { query: "", vendorIds: [], additionalFeeIdStrings: [], isActiveFilter: 0, isTintableFilter: 0, cursor: "", offset: 0, limit: 0, orderBy: 0, sortOrder: 0, taxStatuses: [], departmentIds: [], classIds: [], finelineIds: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchProductsV2Req>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchProductsV2Req): SearchProductsV2Req {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string query */ 1:
                    message.query = reader.string();
                    break;
                case /* rd.proto.uuid.UUID inv_sort_location_id */ 2:
                    message.invSortLocationId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.invSortLocationId);
                    break;
                case /* rd.proto.uuid.UUID current_location_id */ 3:
                    message.currentLocationId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.currentLocationId);
                    break;
                case /* repeated rd.proto.uuid.UUID vendor_ids */ 5:
                    message.vendorIds.push(UUID.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated string additional_fee_id_strings */ 6:
                    message.additionalFeeIdStrings.push(reader.string());
                    break;
                case /* rd.search.service.BoolFilter is_active_filter */ 7:
                    message.isActiveFilter = reader.int32();
                    break;
                case /* rd.search.service.BoolFilter is_tintable_filter */ 9:
                    message.isTintableFilter = reader.int32();
                    break;
                case /* string cursor */ 10:
                    message.cursor = reader.string();
                    break;
                case /* int32 offset */ 11:
                    message.offset = reader.int32();
                    break;
                case /* int32 limit */ 12:
                    message.limit = reader.int32();
                    break;
                case /* rd.search.service.SearchProductsV2Req.OrderBy order_by */ 13:
                    message.orderBy = reader.int32();
                    break;
                case /* rd.search.service.SortOrder sort_order */ 14:
                    message.sortOrder = reader.int32();
                    break;
                case /* repeated rd.product.Product.TaxStatus tax_statuses */ 15:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.taxStatuses.push(reader.int32());
                    else
                        message.taxStatuses.push(reader.int32());
                    break;
                case /* repeated rd.proto.uuid.UUID department_ids */ 16:
                    message.departmentIds.push(UUID.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated rd.proto.uuid.UUID class_ids */ 17:
                    message.classIds.push(UUID.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated rd.proto.uuid.UUID fineline_ids */ 18:
                    message.finelineIds.push(UUID.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SearchProductsV2Req, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string query = 1; */
        if (message.query !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.query);
        /* rd.proto.uuid.UUID inv_sort_location_id = 2; */
        if (message.invSortLocationId)
            UUID.internalBinaryWrite(message.invSortLocationId, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID current_location_id = 3; */
        if (message.currentLocationId)
            UUID.internalBinaryWrite(message.currentLocationId, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* repeated rd.proto.uuid.UUID vendor_ids = 5; */
        for (let i = 0; i < message.vendorIds.length; i++)
            UUID.internalBinaryWrite(message.vendorIds[i], writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* repeated string additional_fee_id_strings = 6; */
        for (let i = 0; i < message.additionalFeeIdStrings.length; i++)
            writer.tag(6, WireType.LengthDelimited).string(message.additionalFeeIdStrings[i]);
        /* rd.search.service.BoolFilter is_active_filter = 7; */
        if (message.isActiveFilter !== 0)
            writer.tag(7, WireType.Varint).int32(message.isActiveFilter);
        /* rd.search.service.BoolFilter is_tintable_filter = 9; */
        if (message.isTintableFilter !== 0)
            writer.tag(9, WireType.Varint).int32(message.isTintableFilter);
        /* string cursor = 10; */
        if (message.cursor !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.cursor);
        /* int32 offset = 11; */
        if (message.offset !== 0)
            writer.tag(11, WireType.Varint).int32(message.offset);
        /* int32 limit = 12; */
        if (message.limit !== 0)
            writer.tag(12, WireType.Varint).int32(message.limit);
        /* rd.search.service.SearchProductsV2Req.OrderBy order_by = 13; */
        if (message.orderBy !== 0)
            writer.tag(13, WireType.Varint).int32(message.orderBy);
        /* rd.search.service.SortOrder sort_order = 14; */
        if (message.sortOrder !== 0)
            writer.tag(14, WireType.Varint).int32(message.sortOrder);
        /* repeated rd.product.Product.TaxStatus tax_statuses = 15; */
        if (message.taxStatuses.length) {
            writer.tag(15, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.taxStatuses.length; i++)
                writer.int32(message.taxStatuses[i]);
            writer.join();
        }
        /* repeated rd.proto.uuid.UUID department_ids = 16; */
        for (let i = 0; i < message.departmentIds.length; i++)
            UUID.internalBinaryWrite(message.departmentIds[i], writer.tag(16, WireType.LengthDelimited).fork(), options).join();
        /* repeated rd.proto.uuid.UUID class_ids = 17; */
        for (let i = 0; i < message.classIds.length; i++)
            UUID.internalBinaryWrite(message.classIds[i], writer.tag(17, WireType.LengthDelimited).fork(), options).join();
        /* repeated rd.proto.uuid.UUID fineline_ids = 18; */
        for (let i = 0; i < message.finelineIds.length; i++)
            UUID.internalBinaryWrite(message.finelineIds[i], writer.tag(18, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.SearchProductsV2Req
 */
export const SearchProductsV2Req = new SearchProductsV2Req$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchProductsV2Res$Type extends MessageType<SearchProductsV2Res> {
    constructor() {
        super("rd.search.service.SearchProductsV2Res", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SearchProductsV2Res_Result },
            { no: 2, name: "locations_by_id", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => Location } },
            { no: 3, name: "pricing_tiers", kind: "message", T: () => PricingTiers },
            { no: 4, name: "cursor", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<SearchProductsV2Res>): SearchProductsV2Res {
        const message = { results: [], locationsById: {}, cursor: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchProductsV2Res>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchProductsV2Res): SearchProductsV2Res {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated rd.search.service.SearchProductsV2Res.Result results */ 1:
                    message.results.push(SearchProductsV2Res_Result.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* map<string, rd.company.Location> locations_by_id */ 2:
                    this.binaryReadMap2(message.locationsById, reader, options);
                    break;
                case /* rd.company.PricingTiers pricing_tiers */ 3:
                    message.pricingTiers = PricingTiers.internalBinaryRead(reader, reader.uint32(), options, message.pricingTiers);
                    break;
                case /* string cursor */ 4:
                    message.cursor = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap2(map: SearchProductsV2Res["locationsById"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof SearchProductsV2Res["locationsById"] | undefined, val: SearchProductsV2Res["locationsById"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = Location.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.search.service.SearchProductsV2Res.locations_by_id");
            }
        }
        map[key ?? ""] = val ?? Location.create();
    }
    internalBinaryWrite(message: SearchProductsV2Res, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated rd.search.service.SearchProductsV2Res.Result results = 1; */
        for (let i = 0; i < message.results.length; i++)
            SearchProductsV2Res_Result.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* map<string, rd.company.Location> locations_by_id = 2; */
        for (let k of Object.keys(message.locationsById)) {
            writer.tag(2, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            Location.internalBinaryWrite(message.locationsById[k], writer, options);
            writer.join().join();
        }
        /* rd.company.PricingTiers pricing_tiers = 3; */
        if (message.pricingTiers)
            PricingTiers.internalBinaryWrite(message.pricingTiers, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* string cursor = 4; */
        if (message.cursor !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.cursor);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.SearchProductsV2Res
 */
export const SearchProductsV2Res = new SearchProductsV2Res$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchProductsV2Res_Result$Type extends MessageType<SearchProductsV2Res_Result> {
    constructor() {
        super("rd.search.service.SearchProductsV2Res.Result", [
            { no: 1, name: "product", kind: "message", T: () => Product },
            { no: 2, name: "matched_price_product", kind: "message", T: () => Product },
            { no: 3, name: "matched_cost_product", kind: "message", T: () => Product },
            { no: 4, name: "inventories", kind: "message", T: () => Inventories },
            { no: 5, name: "primary_cost_vendor", kind: "message", T: () => Vendor },
            { no: 7, name: "additional_fee", kind: "message", T: () => AdditionalFees_Fee },
            { no: 9, name: "delta", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 10, name: "analytics", kind: "message", T: () => ProductSalesAnalytics },
            { no: 11, name: "catalog_product", kind: "message", T: () => Product$ },
            { no: 12, name: "department", kind: "message", T: () => ProductCategory },
            { no: 13, name: "class", kind: "message", T: () => ProductCategory },
            { no: 14, name: "fineline", kind: "message", T: () => ProductCategory },
            { no: 15, name: "tier_prices", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Tier }
        ]);
    }
    create(value?: PartialMessage<SearchProductsV2Res_Result>): SearchProductsV2Res_Result {
        const message = { delta: 0, tierPrices: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchProductsV2Res_Result>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchProductsV2Res_Result): SearchProductsV2Res_Result {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.product.Product product */ 1:
                    message.product = Product.internalBinaryRead(reader, reader.uint32(), options, message.product);
                    break;
                case /* rd.product.Product matched_price_product */ 2:
                    message.matchedPriceProduct = Product.internalBinaryRead(reader, reader.uint32(), options, message.matchedPriceProduct);
                    break;
                case /* rd.product.Product matched_cost_product */ 3:
                    message.matchedCostProduct = Product.internalBinaryRead(reader, reader.uint32(), options, message.matchedCostProduct);
                    break;
                case /* rd.product.Inventories inventories */ 4:
                    message.inventories = Inventories.internalBinaryRead(reader, reader.uint32(), options, message.inventories);
                    break;
                case /* rd.vendors.Vendor primary_cost_vendor */ 5:
                    message.primaryCostVendor = Vendor.internalBinaryRead(reader, reader.uint32(), options, message.primaryCostVendor);
                    break;
                case /* rd.company.AdditionalFees.Fee additional_fee */ 7:
                    message.additionalFee = AdditionalFees_Fee.internalBinaryRead(reader, reader.uint32(), options, message.additionalFee);
                    break;
                case /* int32 delta */ 9:
                    message.delta = reader.int32();
                    break;
                case /* rd.txn.ProductSalesAnalytics analytics */ 10:
                    message.analytics = ProductSalesAnalytics.internalBinaryRead(reader, reader.uint32(), options, message.analytics);
                    break;
                case /* rd.catalog.Product catalog_product */ 11:
                    message.catalogProduct = Product$.internalBinaryRead(reader, reader.uint32(), options, message.catalogProduct);
                    break;
                case /* rd.company.ProductCategory department */ 12:
                    message.department = ProductCategory.internalBinaryRead(reader, reader.uint32(), options, message.department);
                    break;
                case /* rd.company.ProductCategory class */ 13:
                    message.class = ProductCategory.internalBinaryRead(reader, reader.uint32(), options, message.class);
                    break;
                case /* rd.company.ProductCategory fineline */ 14:
                    message.fineline = ProductCategory.internalBinaryRead(reader, reader.uint32(), options, message.fineline);
                    break;
                case /* repeated rd.price.Tier tier_prices */ 15:
                    message.tierPrices.push(Tier.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SearchProductsV2Res_Result, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.product.Product product = 1; */
        if (message.product)
            Product.internalBinaryWrite(message.product, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.product.Product matched_price_product = 2; */
        if (message.matchedPriceProduct)
            Product.internalBinaryWrite(message.matchedPriceProduct, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* rd.product.Product matched_cost_product = 3; */
        if (message.matchedCostProduct)
            Product.internalBinaryWrite(message.matchedCostProduct, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* rd.product.Inventories inventories = 4; */
        if (message.inventories)
            Inventories.internalBinaryWrite(message.inventories, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* rd.vendors.Vendor primary_cost_vendor = 5; */
        if (message.primaryCostVendor)
            Vendor.internalBinaryWrite(message.primaryCostVendor, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* rd.company.AdditionalFees.Fee additional_fee = 7; */
        if (message.additionalFee)
            AdditionalFees_Fee.internalBinaryWrite(message.additionalFee, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* int32 delta = 9; */
        if (message.delta !== 0)
            writer.tag(9, WireType.Varint).int32(message.delta);
        /* rd.txn.ProductSalesAnalytics analytics = 10; */
        if (message.analytics)
            ProductSalesAnalytics.internalBinaryWrite(message.analytics, writer.tag(10, WireType.LengthDelimited).fork(), options).join();
        /* rd.catalog.Product catalog_product = 11; */
        if (message.catalogProduct)
            Product$.internalBinaryWrite(message.catalogProduct, writer.tag(11, WireType.LengthDelimited).fork(), options).join();
        /* rd.company.ProductCategory department = 12; */
        if (message.department)
            ProductCategory.internalBinaryWrite(message.department, writer.tag(12, WireType.LengthDelimited).fork(), options).join();
        /* rd.company.ProductCategory class = 13; */
        if (message.class)
            ProductCategory.internalBinaryWrite(message.class, writer.tag(13, WireType.LengthDelimited).fork(), options).join();
        /* rd.company.ProductCategory fineline = 14; */
        if (message.fineline)
            ProductCategory.internalBinaryWrite(message.fineline, writer.tag(14, WireType.LengthDelimited).fork(), options).join();
        /* repeated rd.price.Tier tier_prices = 15; */
        for (let i = 0; i < message.tierPrices.length; i++)
            Tier.internalBinaryWrite(message.tierPrices[i], writer.tag(15, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.SearchProductsV2Res.Result
 */
export const SearchProductsV2Res_Result = new SearchProductsV2Res_Result$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BulkExportProductsReq$Type extends MessageType<BulkExportProductsReq> {
    constructor() {
        super("rd.search.service.BulkExportProductsReq", [
            { no: 1, name: "query", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "inv_sort_location_id", kind: "message", T: () => UUID },
            { no: 3, name: "current_location_id", kind: "message", T: () => UUID, options: { "rd.validator.require": true } },
            { no: 5, name: "vendor_ids", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UUID },
            { no: 6, name: "additional_fee_id_strings", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "is_active_filter", kind: "enum", T: () => ["rd.search.service.BoolFilter", BoolFilter, "BOOL_FILTER_"] },
            { no: 8, name: "is_tintable_filter", kind: "enum", T: () => ["rd.search.service.BoolFilter", BoolFilter, "BOOL_FILTER_"] },
            { no: 9, name: "order_by", kind: "enum", T: () => ["rd.search.service.SearchProductOrderBy", SearchProductOrderBy] },
            { no: 10, name: "sort_order", kind: "enum", T: () => ["rd.search.service.SortOrder", SortOrder, "SORT_ORDER_"] },
            { no: 11, name: "tax_statuses", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["rd.product.Product.TaxStatus", Product_TaxStatus, "TAX_STATUS_"] },
            { no: 16, name: "is_template", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 17, name: "department_ids", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UUID },
            { no: 18, name: "class_ids", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UUID },
            { no: 19, name: "fineline_ids", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UUID }
        ]);
    }
    create(value?: PartialMessage<BulkExportProductsReq>): BulkExportProductsReq {
        const message = { query: "", vendorIds: [], additionalFeeIdStrings: [], isActiveFilter: 0, isTintableFilter: 0, orderBy: 0, sortOrder: 0, taxStatuses: [], isTemplate: false, departmentIds: [], classIds: [], finelineIds: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<BulkExportProductsReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BulkExportProductsReq): BulkExportProductsReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string query */ 1:
                    message.query = reader.string();
                    break;
                case /* rd.proto.uuid.UUID inv_sort_location_id */ 2:
                    message.invSortLocationId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.invSortLocationId);
                    break;
                case /* rd.proto.uuid.UUID current_location_id */ 3:
                    message.currentLocationId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.currentLocationId);
                    break;
                case /* repeated rd.proto.uuid.UUID vendor_ids */ 5:
                    message.vendorIds.push(UUID.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated string additional_fee_id_strings */ 6:
                    message.additionalFeeIdStrings.push(reader.string());
                    break;
                case /* rd.search.service.BoolFilter is_active_filter */ 7:
                    message.isActiveFilter = reader.int32();
                    break;
                case /* rd.search.service.BoolFilter is_tintable_filter */ 8:
                    message.isTintableFilter = reader.int32();
                    break;
                case /* rd.search.service.SearchProductOrderBy order_by */ 9:
                    message.orderBy = reader.int32();
                    break;
                case /* rd.search.service.SortOrder sort_order */ 10:
                    message.sortOrder = reader.int32();
                    break;
                case /* repeated rd.product.Product.TaxStatus tax_statuses */ 11:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.taxStatuses.push(reader.int32());
                    else
                        message.taxStatuses.push(reader.int32());
                    break;
                case /* bool is_template */ 16:
                    message.isTemplate = reader.bool();
                    break;
                case /* repeated rd.proto.uuid.UUID department_ids */ 17:
                    message.departmentIds.push(UUID.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated rd.proto.uuid.UUID class_ids */ 18:
                    message.classIds.push(UUID.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated rd.proto.uuid.UUID fineline_ids */ 19:
                    message.finelineIds.push(UUID.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BulkExportProductsReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string query = 1; */
        if (message.query !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.query);
        /* rd.proto.uuid.UUID inv_sort_location_id = 2; */
        if (message.invSortLocationId)
            UUID.internalBinaryWrite(message.invSortLocationId, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID current_location_id = 3; */
        if (message.currentLocationId)
            UUID.internalBinaryWrite(message.currentLocationId, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* repeated rd.proto.uuid.UUID vendor_ids = 5; */
        for (let i = 0; i < message.vendorIds.length; i++)
            UUID.internalBinaryWrite(message.vendorIds[i], writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* repeated string additional_fee_id_strings = 6; */
        for (let i = 0; i < message.additionalFeeIdStrings.length; i++)
            writer.tag(6, WireType.LengthDelimited).string(message.additionalFeeIdStrings[i]);
        /* rd.search.service.BoolFilter is_active_filter = 7; */
        if (message.isActiveFilter !== 0)
            writer.tag(7, WireType.Varint).int32(message.isActiveFilter);
        /* rd.search.service.BoolFilter is_tintable_filter = 8; */
        if (message.isTintableFilter !== 0)
            writer.tag(8, WireType.Varint).int32(message.isTintableFilter);
        /* rd.search.service.SearchProductOrderBy order_by = 9; */
        if (message.orderBy !== 0)
            writer.tag(9, WireType.Varint).int32(message.orderBy);
        /* rd.search.service.SortOrder sort_order = 10; */
        if (message.sortOrder !== 0)
            writer.tag(10, WireType.Varint).int32(message.sortOrder);
        /* repeated rd.product.Product.TaxStatus tax_statuses = 11; */
        if (message.taxStatuses.length) {
            writer.tag(11, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.taxStatuses.length; i++)
                writer.int32(message.taxStatuses[i]);
            writer.join();
        }
        /* bool is_template = 16; */
        if (message.isTemplate !== false)
            writer.tag(16, WireType.Varint).bool(message.isTemplate);
        /* repeated rd.proto.uuid.UUID department_ids = 17; */
        for (let i = 0; i < message.departmentIds.length; i++)
            UUID.internalBinaryWrite(message.departmentIds[i], writer.tag(17, WireType.LengthDelimited).fork(), options).join();
        /* repeated rd.proto.uuid.UUID class_ids = 18; */
        for (let i = 0; i < message.classIds.length; i++)
            UUID.internalBinaryWrite(message.classIds[i], writer.tag(18, WireType.LengthDelimited).fork(), options).join();
        /* repeated rd.proto.uuid.UUID fineline_ids = 19; */
        for (let i = 0; i < message.finelineIds.length; i++)
            UUID.internalBinaryWrite(message.finelineIds[i], writer.tag(19, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.BulkExportProductsReq
 */
export const BulkExportProductsReq = new BulkExportProductsReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BulkExportProductsRes$Type extends MessageType<BulkExportProductsRes> {
    constructor() {
        super("rd.search.service.BulkExportProductsRes", [
            { no: 1, name: "excel_bytes", kind: "scalar", T: 12 /*ScalarType.BYTES*/ }
        ]);
    }
    create(value?: PartialMessage<BulkExportProductsRes>): BulkExportProductsRes {
        const message = { excelBytes: new Uint8Array(0) };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<BulkExportProductsRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BulkExportProductsRes): BulkExportProductsRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bytes excel_bytes */ 1:
                    message.excelBytes = reader.bytes();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BulkExportProductsRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bytes excel_bytes = 1; */
        if (message.excelBytes.length)
            writer.tag(1, WireType.LengthDelimited).bytes(message.excelBytes);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.BulkExportProductsRes
 */
export const BulkExportProductsRes = new BulkExportProductsRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchSalesV2Req$Type extends MessageType<SearchSalesV2Req> {
    constructor() {
        super("rd.search.service.SearchSalesV2Req", [
            { no: 1, name: "query", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "created_at_range", kind: "message", T: () => TimestampRange },
            { no: 3, name: "quoted_at_range", kind: "message", T: () => TimestampRange },
            { no: 4, name: "will_called_at_range", kind: "message", T: () => TimestampRange },
            { no: 5, name: "sold_at_range", kind: "message", T: () => TimestampRange },
            { no: 6, name: "voided_at_range", kind: "message", T: () => TimestampRange },
            { no: 7, name: "created_by_ids", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UUID },
            { no: 8, name: "quoted_by_ids", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UUID },
            { no: 9, name: "will_called_by_ids", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UUID },
            { no: 10, name: "sold_by_ids", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UUID },
            { no: 11, name: "voided_by_ids", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UUID },
            { no: 12, name: "customer_ids", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UUID },
            { no: 13, name: "location_ids", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UUID },
            { no: 14, name: "statuses", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["rd.txn.Sale.Status", Sale_Status, "STATUS_"] },
            { no: 15, name: "cursor", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 16, name: "offset", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 17, name: "limit", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 18, name: "purchase_order_ids", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UUID },
            { no: 19, name: "tint_color_ids", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UUID },
            { no: 20, name: "contains_non_taxable_products_filter", kind: "enum", T: () => ["rd.search.service.BoolFilter", BoolFilter, "BOOL_FILTER_"] },
            { no: 21, name: "purchase_method", kind: "enum", T: () => ["rd.txn.PurchaseMethod.Method", PurchaseMethod_Method, "METHOD_"] }
        ]);
    }
    create(value?: PartialMessage<SearchSalesV2Req>): SearchSalesV2Req {
        const message = { query: "", createdByIds: [], quotedByIds: [], willCalledByIds: [], soldByIds: [], voidedByIds: [], customerIds: [], locationIds: [], statuses: [], cursor: "", offset: 0, limit: 0, purchaseOrderIds: [], tintColorIds: [], containsNonTaxableProductsFilter: 0, purchaseMethod: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchSalesV2Req>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchSalesV2Req): SearchSalesV2Req {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string query */ 1:
                    message.query = reader.string();
                    break;
                case /* rd.proto.timestamprange.TimestampRange created_at_range */ 2:
                    message.createdAtRange = TimestampRange.internalBinaryRead(reader, reader.uint32(), options, message.createdAtRange);
                    break;
                case /* rd.proto.timestamprange.TimestampRange quoted_at_range */ 3:
                    message.quotedAtRange = TimestampRange.internalBinaryRead(reader, reader.uint32(), options, message.quotedAtRange);
                    break;
                case /* rd.proto.timestamprange.TimestampRange will_called_at_range */ 4:
                    message.willCalledAtRange = TimestampRange.internalBinaryRead(reader, reader.uint32(), options, message.willCalledAtRange);
                    break;
                case /* rd.proto.timestamprange.TimestampRange sold_at_range */ 5:
                    message.soldAtRange = TimestampRange.internalBinaryRead(reader, reader.uint32(), options, message.soldAtRange);
                    break;
                case /* rd.proto.timestamprange.TimestampRange voided_at_range */ 6:
                    message.voidedAtRange = TimestampRange.internalBinaryRead(reader, reader.uint32(), options, message.voidedAtRange);
                    break;
                case /* repeated rd.proto.uuid.UUID created_by_ids */ 7:
                    message.createdByIds.push(UUID.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated rd.proto.uuid.UUID quoted_by_ids */ 8:
                    message.quotedByIds.push(UUID.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated rd.proto.uuid.UUID will_called_by_ids */ 9:
                    message.willCalledByIds.push(UUID.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated rd.proto.uuid.UUID sold_by_ids */ 10:
                    message.soldByIds.push(UUID.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated rd.proto.uuid.UUID voided_by_ids */ 11:
                    message.voidedByIds.push(UUID.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated rd.proto.uuid.UUID customer_ids */ 12:
                    message.customerIds.push(UUID.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated rd.proto.uuid.UUID location_ids */ 13:
                    message.locationIds.push(UUID.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated rd.txn.Sale.Status statuses */ 14:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.statuses.push(reader.int32());
                    else
                        message.statuses.push(reader.int32());
                    break;
                case /* string cursor */ 15:
                    message.cursor = reader.string();
                    break;
                case /* int32 offset */ 16:
                    message.offset = reader.int32();
                    break;
                case /* int32 limit */ 17:
                    message.limit = reader.int32();
                    break;
                case /* repeated rd.proto.uuid.UUID purchase_order_ids */ 18:
                    message.purchaseOrderIds.push(UUID.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated rd.proto.uuid.UUID tint_color_ids */ 19:
                    message.tintColorIds.push(UUID.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* rd.search.service.BoolFilter contains_non_taxable_products_filter */ 20:
                    message.containsNonTaxableProductsFilter = reader.int32();
                    break;
                case /* rd.txn.PurchaseMethod.Method purchase_method */ 21:
                    message.purchaseMethod = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SearchSalesV2Req, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string query = 1; */
        if (message.query !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.query);
        /* rd.proto.timestamprange.TimestampRange created_at_range = 2; */
        if (message.createdAtRange)
            TimestampRange.internalBinaryWrite(message.createdAtRange, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.timestamprange.TimestampRange quoted_at_range = 3; */
        if (message.quotedAtRange)
            TimestampRange.internalBinaryWrite(message.quotedAtRange, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.timestamprange.TimestampRange will_called_at_range = 4; */
        if (message.willCalledAtRange)
            TimestampRange.internalBinaryWrite(message.willCalledAtRange, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.timestamprange.TimestampRange sold_at_range = 5; */
        if (message.soldAtRange)
            TimestampRange.internalBinaryWrite(message.soldAtRange, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.timestamprange.TimestampRange voided_at_range = 6; */
        if (message.voidedAtRange)
            TimestampRange.internalBinaryWrite(message.voidedAtRange, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* repeated rd.proto.uuid.UUID created_by_ids = 7; */
        for (let i = 0; i < message.createdByIds.length; i++)
            UUID.internalBinaryWrite(message.createdByIds[i], writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* repeated rd.proto.uuid.UUID quoted_by_ids = 8; */
        for (let i = 0; i < message.quotedByIds.length; i++)
            UUID.internalBinaryWrite(message.quotedByIds[i], writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* repeated rd.proto.uuid.UUID will_called_by_ids = 9; */
        for (let i = 0; i < message.willCalledByIds.length; i++)
            UUID.internalBinaryWrite(message.willCalledByIds[i], writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        /* repeated rd.proto.uuid.UUID sold_by_ids = 10; */
        for (let i = 0; i < message.soldByIds.length; i++)
            UUID.internalBinaryWrite(message.soldByIds[i], writer.tag(10, WireType.LengthDelimited).fork(), options).join();
        /* repeated rd.proto.uuid.UUID voided_by_ids = 11; */
        for (let i = 0; i < message.voidedByIds.length; i++)
            UUID.internalBinaryWrite(message.voidedByIds[i], writer.tag(11, WireType.LengthDelimited).fork(), options).join();
        /* repeated rd.proto.uuid.UUID customer_ids = 12; */
        for (let i = 0; i < message.customerIds.length; i++)
            UUID.internalBinaryWrite(message.customerIds[i], writer.tag(12, WireType.LengthDelimited).fork(), options).join();
        /* repeated rd.proto.uuid.UUID location_ids = 13; */
        for (let i = 0; i < message.locationIds.length; i++)
            UUID.internalBinaryWrite(message.locationIds[i], writer.tag(13, WireType.LengthDelimited).fork(), options).join();
        /* repeated rd.txn.Sale.Status statuses = 14; */
        if (message.statuses.length) {
            writer.tag(14, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.statuses.length; i++)
                writer.int32(message.statuses[i]);
            writer.join();
        }
        /* string cursor = 15; */
        if (message.cursor !== "")
            writer.tag(15, WireType.LengthDelimited).string(message.cursor);
        /* int32 offset = 16; */
        if (message.offset !== 0)
            writer.tag(16, WireType.Varint).int32(message.offset);
        /* int32 limit = 17; */
        if (message.limit !== 0)
            writer.tag(17, WireType.Varint).int32(message.limit);
        /* repeated rd.proto.uuid.UUID purchase_order_ids = 18; */
        for (let i = 0; i < message.purchaseOrderIds.length; i++)
            UUID.internalBinaryWrite(message.purchaseOrderIds[i], writer.tag(18, WireType.LengthDelimited).fork(), options).join();
        /* repeated rd.proto.uuid.UUID tint_color_ids = 19; */
        for (let i = 0; i < message.tintColorIds.length; i++)
            UUID.internalBinaryWrite(message.tintColorIds[i], writer.tag(19, WireType.LengthDelimited).fork(), options).join();
        /* rd.search.service.BoolFilter contains_non_taxable_products_filter = 20; */
        if (message.containsNonTaxableProductsFilter !== 0)
            writer.tag(20, WireType.Varint).int32(message.containsNonTaxableProductsFilter);
        /* rd.txn.PurchaseMethod.Method purchase_method = 21; */
        if (message.purchaseMethod !== 0)
            writer.tag(21, WireType.Varint).int32(message.purchaseMethod);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.SearchSalesV2Req
 */
export const SearchSalesV2Req = new SearchSalesV2Req$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchSalesV2Res$Type extends MessageType<SearchSalesV2Res> {
    constructor() {
        super("rd.search.service.SearchSalesV2Res", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SearchSalesV2Res_Result },
            { no: 2, name: "cursor", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<SearchSalesV2Res>): SearchSalesV2Res {
        const message = { results: [], cursor: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchSalesV2Res>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchSalesV2Res): SearchSalesV2Res {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated rd.search.service.SearchSalesV2Res.Result results */ 1:
                    message.results.push(SearchSalesV2Res_Result.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string cursor */ 2:
                    message.cursor = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SearchSalesV2Res, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated rd.search.service.SearchSalesV2Res.Result results = 1; */
        for (let i = 0; i < message.results.length; i++)
            SearchSalesV2Res_Result.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string cursor = 2; */
        if (message.cursor !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.cursor);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.SearchSalesV2Res
 */
export const SearchSalesV2Res = new SearchSalesV2Res$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchSalesV2Res_Result$Type extends MessageType<SearchSalesV2Res_Result> {
    constructor() {
        super("rd.search.service.SearchSalesV2Res.Result", [
            { no: 1, name: "sale", kind: "message", T: () => Sale },
            { no: 2, name: "location", kind: "message", T: () => Location },
            { no: 3, name: "customer", kind: "message", T: () => Customer },
            { no: 4, name: "purchase_order", kind: "message", T: () => PurchaseOrder },
            { no: 5, name: "job_site", kind: "message", T: () => JobSite },
            { no: 6, name: "products_by_id", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => Product } },
            { no: 7, name: "matched_products_by_id", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => Product } },
            { no: 8, name: "tint_colors_by_id", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => TintColor } },
            { no: 9, name: "total", kind: "message", T: () => Money },
            { no: 10, name: "customer_contact", kind: "message", T: () => Contacts_Contact },
            { no: 11, name: "purchase_methods", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => PurchaseMethod },
            { no: 12, name: "sale_products", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SaleProduct }
        ]);
    }
    create(value?: PartialMessage<SearchSalesV2Res_Result>): SearchSalesV2Res_Result {
        const message = { productsById: {}, matchedProductsById: {}, tintColorsById: {}, purchaseMethods: [], saleProducts: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchSalesV2Res_Result>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchSalesV2Res_Result): SearchSalesV2Res_Result {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.txn.Sale sale */ 1:
                    message.sale = Sale.internalBinaryRead(reader, reader.uint32(), options, message.sale);
                    break;
                case /* rd.company.Location location */ 2:
                    message.location = Location.internalBinaryRead(reader, reader.uint32(), options, message.location);
                    break;
                case /* rd.customer.Customer customer */ 3:
                    message.customer = Customer.internalBinaryRead(reader, reader.uint32(), options, message.customer);
                    break;
                case /* rd.customer.PurchaseOrder purchase_order */ 4:
                    message.purchaseOrder = PurchaseOrder.internalBinaryRead(reader, reader.uint32(), options, message.purchaseOrder);
                    break;
                case /* rd.customer.JobSite job_site */ 5:
                    message.jobSite = JobSite.internalBinaryRead(reader, reader.uint32(), options, message.jobSite);
                    break;
                case /* map<string, rd.product.Product> products_by_id */ 6:
                    this.binaryReadMap6(message.productsById, reader, options);
                    break;
                case /* map<string, rd.product.Product> matched_products_by_id */ 7:
                    this.binaryReadMap7(message.matchedProductsById, reader, options);
                    break;
                case /* map<string, rd.tintcolor.TintColor> tint_colors_by_id */ 8:
                    this.binaryReadMap8(message.tintColorsById, reader, options);
                    break;
                case /* rd.proto.money.Money total */ 9:
                    message.total = Money.internalBinaryRead(reader, reader.uint32(), options, message.total);
                    break;
                case /* rd.customer.Contacts.Contact customer_contact */ 10:
                    message.customerContact = Contacts_Contact.internalBinaryRead(reader, reader.uint32(), options, message.customerContact);
                    break;
                case /* repeated rd.txn.PurchaseMethod purchase_methods */ 11:
                    message.purchaseMethods.push(PurchaseMethod.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated rd.txn.SaleProduct sale_products */ 12:
                    message.saleProducts.push(SaleProduct.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap6(map: SearchSalesV2Res_Result["productsById"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof SearchSalesV2Res_Result["productsById"] | undefined, val: SearchSalesV2Res_Result["productsById"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = Product.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.search.service.SearchSalesV2Res.Result.products_by_id");
            }
        }
        map[key ?? ""] = val ?? Product.create();
    }
    private binaryReadMap7(map: SearchSalesV2Res_Result["matchedProductsById"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof SearchSalesV2Res_Result["matchedProductsById"] | undefined, val: SearchSalesV2Res_Result["matchedProductsById"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = Product.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.search.service.SearchSalesV2Res.Result.matched_products_by_id");
            }
        }
        map[key ?? ""] = val ?? Product.create();
    }
    private binaryReadMap8(map: SearchSalesV2Res_Result["tintColorsById"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof SearchSalesV2Res_Result["tintColorsById"] | undefined, val: SearchSalesV2Res_Result["tintColorsById"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = TintColor.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.search.service.SearchSalesV2Res.Result.tint_colors_by_id");
            }
        }
        map[key ?? ""] = val ?? TintColor.create();
    }
    internalBinaryWrite(message: SearchSalesV2Res_Result, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.txn.Sale sale = 1; */
        if (message.sale)
            Sale.internalBinaryWrite(message.sale, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.company.Location location = 2; */
        if (message.location)
            Location.internalBinaryWrite(message.location, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* rd.customer.Customer customer = 3; */
        if (message.customer)
            Customer.internalBinaryWrite(message.customer, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* rd.customer.PurchaseOrder purchase_order = 4; */
        if (message.purchaseOrder)
            PurchaseOrder.internalBinaryWrite(message.purchaseOrder, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* rd.customer.JobSite job_site = 5; */
        if (message.jobSite)
            JobSite.internalBinaryWrite(message.jobSite, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* map<string, rd.product.Product> products_by_id = 6; */
        for (let k of Object.keys(message.productsById)) {
            writer.tag(6, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            Product.internalBinaryWrite(message.productsById[k], writer, options);
            writer.join().join();
        }
        /* map<string, rd.product.Product> matched_products_by_id = 7; */
        for (let k of Object.keys(message.matchedProductsById)) {
            writer.tag(7, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            Product.internalBinaryWrite(message.matchedProductsById[k], writer, options);
            writer.join().join();
        }
        /* map<string, rd.tintcolor.TintColor> tint_colors_by_id = 8; */
        for (let k of Object.keys(message.tintColorsById)) {
            writer.tag(8, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            TintColor.internalBinaryWrite(message.tintColorsById[k], writer, options);
            writer.join().join();
        }
        /* rd.proto.money.Money total = 9; */
        if (message.total)
            Money.internalBinaryWrite(message.total, writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        /* rd.customer.Contacts.Contact customer_contact = 10; */
        if (message.customerContact)
            Contacts_Contact.internalBinaryWrite(message.customerContact, writer.tag(10, WireType.LengthDelimited).fork(), options).join();
        /* repeated rd.txn.PurchaseMethod purchase_methods = 11; */
        for (let i = 0; i < message.purchaseMethods.length; i++)
            PurchaseMethod.internalBinaryWrite(message.purchaseMethods[i], writer.tag(11, WireType.LengthDelimited).fork(), options).join();
        /* repeated rd.txn.SaleProduct sale_products = 12; */
        for (let i = 0; i < message.saleProducts.length; i++)
            SaleProduct.internalBinaryWrite(message.saleProducts[i], writer.tag(12, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.SearchSalesV2Res.Result
 */
export const SearchSalesV2Res_Result = new SearchSalesV2Res_Result$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchReturnsReq$Type extends MessageType<SearchReturnsReq> {
    constructor() {
        super("rd.search.service.SearchReturnsReq", [
            { no: 1, name: "query", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "created_at_range", kind: "message", T: () => TimestampRange },
            { no: 3, name: "created_by_ids", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UUID },
            { no: 4, name: "customer_ids", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UUID },
            { no: 5, name: "location_ids", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UUID },
            { no: 15, name: "cursor", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 16, name: "offset", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 17, name: "limit", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<SearchReturnsReq>): SearchReturnsReq {
        const message = { query: "", createdByIds: [], customerIds: [], locationIds: [], cursor: "", offset: 0, limit: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchReturnsReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchReturnsReq): SearchReturnsReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string query */ 1:
                    message.query = reader.string();
                    break;
                case /* rd.proto.timestamprange.TimestampRange created_at_range */ 2:
                    message.createdAtRange = TimestampRange.internalBinaryRead(reader, reader.uint32(), options, message.createdAtRange);
                    break;
                case /* repeated rd.proto.uuid.UUID created_by_ids */ 3:
                    message.createdByIds.push(UUID.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated rd.proto.uuid.UUID customer_ids */ 4:
                    message.customerIds.push(UUID.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated rd.proto.uuid.UUID location_ids */ 5:
                    message.locationIds.push(UUID.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string cursor */ 15:
                    message.cursor = reader.string();
                    break;
                case /* int32 offset */ 16:
                    message.offset = reader.int32();
                    break;
                case /* int32 limit */ 17:
                    message.limit = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SearchReturnsReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string query = 1; */
        if (message.query !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.query);
        /* rd.proto.timestamprange.TimestampRange created_at_range = 2; */
        if (message.createdAtRange)
            TimestampRange.internalBinaryWrite(message.createdAtRange, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* repeated rd.proto.uuid.UUID created_by_ids = 3; */
        for (let i = 0; i < message.createdByIds.length; i++)
            UUID.internalBinaryWrite(message.createdByIds[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* repeated rd.proto.uuid.UUID customer_ids = 4; */
        for (let i = 0; i < message.customerIds.length; i++)
            UUID.internalBinaryWrite(message.customerIds[i], writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* repeated rd.proto.uuid.UUID location_ids = 5; */
        for (let i = 0; i < message.locationIds.length; i++)
            UUID.internalBinaryWrite(message.locationIds[i], writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* string cursor = 15; */
        if (message.cursor !== "")
            writer.tag(15, WireType.LengthDelimited).string(message.cursor);
        /* int32 offset = 16; */
        if (message.offset !== 0)
            writer.tag(16, WireType.Varint).int32(message.offset);
        /* int32 limit = 17; */
        if (message.limit !== 0)
            writer.tag(17, WireType.Varint).int32(message.limit);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.SearchReturnsReq
 */
export const SearchReturnsReq = new SearchReturnsReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchReturnsRes$Type extends MessageType<SearchReturnsRes> {
    constructor() {
        super("rd.search.service.SearchReturnsRes", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SearchReturnsRes_Result },
            { no: 2, name: "cursor", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<SearchReturnsRes>): SearchReturnsRes {
        const message = { results: [], cursor: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchReturnsRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchReturnsRes): SearchReturnsRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated rd.search.service.SearchReturnsRes.Result results */ 1:
                    message.results.push(SearchReturnsRes_Result.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string cursor */ 2:
                    message.cursor = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SearchReturnsRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated rd.search.service.SearchReturnsRes.Result results = 1; */
        for (let i = 0; i < message.results.length; i++)
            SearchReturnsRes_Result.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string cursor = 2; */
        if (message.cursor !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.cursor);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.SearchReturnsRes
 */
export const SearchReturnsRes = new SearchReturnsRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchReturnsRes_Result$Type extends MessageType<SearchReturnsRes_Result> {
    constructor() {
        super("rd.search.service.SearchReturnsRes.Result", [
            { no: 1, name: "return_ledger_entry", kind: "message", T: () => LedgerEntry },
            { no: 2, name: "sale", kind: "message", T: () => Sale },
            { no: 3, name: "location", kind: "message", T: () => Location },
            { no: 4, name: "customer", kind: "message", T: () => Customer },
            { no: 5, name: "purchase_order", kind: "message", T: () => PurchaseOrder },
            { no: 6, name: "job_site", kind: "message", T: () => JobSite },
            { no: 7, name: "products_by_id", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => Product } },
            { no: 8, name: "matched_products_by_id", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => Product } },
            { no: 9, name: "tint_colors_by_id", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => TintColor } },
            { no: 10, name: "customer_contact", kind: "message", T: () => Contacts_Contact },
            { no: 13, name: "sale_return", kind: "message", T: () => Sale_Return },
            { no: 14, name: "return_method", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<SearchReturnsRes_Result>): SearchReturnsRes_Result {
        const message = { productsById: {}, matchedProductsById: {}, tintColorsById: {}, returnMethod: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchReturnsRes_Result>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchReturnsRes_Result): SearchReturnsRes_Result {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.txn.LedgerEntry return_ledger_entry */ 1:
                    message.returnLedgerEntry = LedgerEntry.internalBinaryRead(reader, reader.uint32(), options, message.returnLedgerEntry);
                    break;
                case /* rd.txn.Sale sale */ 2:
                    message.sale = Sale.internalBinaryRead(reader, reader.uint32(), options, message.sale);
                    break;
                case /* rd.company.Location location */ 3:
                    message.location = Location.internalBinaryRead(reader, reader.uint32(), options, message.location);
                    break;
                case /* rd.customer.Customer customer */ 4:
                    message.customer = Customer.internalBinaryRead(reader, reader.uint32(), options, message.customer);
                    break;
                case /* rd.customer.PurchaseOrder purchase_order */ 5:
                    message.purchaseOrder = PurchaseOrder.internalBinaryRead(reader, reader.uint32(), options, message.purchaseOrder);
                    break;
                case /* rd.customer.JobSite job_site */ 6:
                    message.jobSite = JobSite.internalBinaryRead(reader, reader.uint32(), options, message.jobSite);
                    break;
                case /* map<string, rd.product.Product> products_by_id */ 7:
                    this.binaryReadMap7(message.productsById, reader, options);
                    break;
                case /* map<string, rd.product.Product> matched_products_by_id */ 8:
                    this.binaryReadMap8(message.matchedProductsById, reader, options);
                    break;
                case /* map<string, rd.tintcolor.TintColor> tint_colors_by_id */ 9:
                    this.binaryReadMap9(message.tintColorsById, reader, options);
                    break;
                case /* rd.customer.Contacts.Contact customer_contact */ 10:
                    message.customerContact = Contacts_Contact.internalBinaryRead(reader, reader.uint32(), options, message.customerContact);
                    break;
                case /* rd.txn.Sale.Return sale_return */ 13:
                    message.saleReturn = Sale_Return.internalBinaryRead(reader, reader.uint32(), options, message.saleReturn);
                    break;
                case /* string return_method */ 14:
                    message.returnMethod = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap7(map: SearchReturnsRes_Result["productsById"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof SearchReturnsRes_Result["productsById"] | undefined, val: SearchReturnsRes_Result["productsById"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = Product.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.search.service.SearchReturnsRes.Result.products_by_id");
            }
        }
        map[key ?? ""] = val ?? Product.create();
    }
    private binaryReadMap8(map: SearchReturnsRes_Result["matchedProductsById"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof SearchReturnsRes_Result["matchedProductsById"] | undefined, val: SearchReturnsRes_Result["matchedProductsById"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = Product.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.search.service.SearchReturnsRes.Result.matched_products_by_id");
            }
        }
        map[key ?? ""] = val ?? Product.create();
    }
    private binaryReadMap9(map: SearchReturnsRes_Result["tintColorsById"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof SearchReturnsRes_Result["tintColorsById"] | undefined, val: SearchReturnsRes_Result["tintColorsById"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = TintColor.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.search.service.SearchReturnsRes.Result.tint_colors_by_id");
            }
        }
        map[key ?? ""] = val ?? TintColor.create();
    }
    internalBinaryWrite(message: SearchReturnsRes_Result, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.txn.LedgerEntry return_ledger_entry = 1; */
        if (message.returnLedgerEntry)
            LedgerEntry.internalBinaryWrite(message.returnLedgerEntry, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.txn.Sale sale = 2; */
        if (message.sale)
            Sale.internalBinaryWrite(message.sale, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* rd.company.Location location = 3; */
        if (message.location)
            Location.internalBinaryWrite(message.location, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* rd.customer.Customer customer = 4; */
        if (message.customer)
            Customer.internalBinaryWrite(message.customer, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* rd.customer.PurchaseOrder purchase_order = 5; */
        if (message.purchaseOrder)
            PurchaseOrder.internalBinaryWrite(message.purchaseOrder, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* rd.customer.JobSite job_site = 6; */
        if (message.jobSite)
            JobSite.internalBinaryWrite(message.jobSite, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* map<string, rd.product.Product> products_by_id = 7; */
        for (let k of Object.keys(message.productsById)) {
            writer.tag(7, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            Product.internalBinaryWrite(message.productsById[k], writer, options);
            writer.join().join();
        }
        /* map<string, rd.product.Product> matched_products_by_id = 8; */
        for (let k of Object.keys(message.matchedProductsById)) {
            writer.tag(8, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            Product.internalBinaryWrite(message.matchedProductsById[k], writer, options);
            writer.join().join();
        }
        /* map<string, rd.tintcolor.TintColor> tint_colors_by_id = 9; */
        for (let k of Object.keys(message.tintColorsById)) {
            writer.tag(9, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            TintColor.internalBinaryWrite(message.tintColorsById[k], writer, options);
            writer.join().join();
        }
        /* rd.customer.Contacts.Contact customer_contact = 10; */
        if (message.customerContact)
            Contacts_Contact.internalBinaryWrite(message.customerContact, writer.tag(10, WireType.LengthDelimited).fork(), options).join();
        /* rd.txn.Sale.Return sale_return = 13; */
        if (message.saleReturn)
            Sale_Return.internalBinaryWrite(message.saleReturn, writer.tag(13, WireType.LengthDelimited).fork(), options).join();
        /* string return_method = 14; */
        if (message.returnMethod !== "")
            writer.tag(14, WireType.LengthDelimited).string(message.returnMethod);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.SearchReturnsRes.Result
 */
export const SearchReturnsRes_Result = new SearchReturnsRes_Result$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchOutstandingSalesReq$Type extends MessageType<SearchOutstandingSalesReq> {
    constructor() {
        super("rd.search.service.SearchOutstandingSalesReq", [
            { no: 1, name: "query", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "customer_id", kind: "message", T: () => UUID }
        ]);
    }
    create(value?: PartialMessage<SearchOutstandingSalesReq>): SearchOutstandingSalesReq {
        const message = { query: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchOutstandingSalesReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchOutstandingSalesReq): SearchOutstandingSalesReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string query */ 1:
                    message.query = reader.string();
                    break;
                case /* rd.proto.uuid.UUID customer_id */ 2:
                    message.customerId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.customerId);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SearchOutstandingSalesReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string query = 1; */
        if (message.query !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.query);
        /* rd.proto.uuid.UUID customer_id = 2; */
        if (message.customerId)
            UUID.internalBinaryWrite(message.customerId, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.SearchOutstandingSalesReq
 */
export const SearchOutstandingSalesReq = new SearchOutstandingSalesReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchOutstandingSalesRes$Type extends MessageType<SearchOutstandingSalesRes> {
    constructor() {
        super("rd.search.service.SearchOutstandingSalesRes", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SearchOutstandingSalesRes_Result }
        ]);
    }
    create(value?: PartialMessage<SearchOutstandingSalesRes>): SearchOutstandingSalesRes {
        const message = { results: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchOutstandingSalesRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchOutstandingSalesRes): SearchOutstandingSalesRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated rd.search.service.SearchOutstandingSalesRes.Result results */ 1:
                    message.results.push(SearchOutstandingSalesRes_Result.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SearchOutstandingSalesRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated rd.search.service.SearchOutstandingSalesRes.Result results = 1; */
        for (let i = 0; i < message.results.length; i++)
            SearchOutstandingSalesRes_Result.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.SearchOutstandingSalesRes
 */
export const SearchOutstandingSalesRes = new SearchOutstandingSalesRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchOutstandingSalesRes_Result$Type extends MessageType<SearchOutstandingSalesRes_Result> {
    constructor() {
        super("rd.search.service.SearchOutstandingSalesRes.Result", [
            { no: 1, name: "sale", kind: "message", T: () => Sale },
            { no: 2, name: "sale_balance", kind: "message", T: () => Money },
            { no: 3, name: "location", kind: "message", T: () => Location },
            { no: 4, name: "customer", kind: "message", T: () => Customer },
            { no: 5, name: "purchase_order", kind: "message", T: () => PurchaseOrder },
            { no: 6, name: "job_site", kind: "message", T: () => JobSite },
            { no: 7, name: "customer_contact", kind: "message", T: () => Contacts_Contact }
        ]);
    }
    create(value?: PartialMessage<SearchOutstandingSalesRes_Result>): SearchOutstandingSalesRes_Result {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchOutstandingSalesRes_Result>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchOutstandingSalesRes_Result): SearchOutstandingSalesRes_Result {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.txn.Sale sale */ 1:
                    message.sale = Sale.internalBinaryRead(reader, reader.uint32(), options, message.sale);
                    break;
                case /* rd.proto.money.Money sale_balance */ 2:
                    message.saleBalance = Money.internalBinaryRead(reader, reader.uint32(), options, message.saleBalance);
                    break;
                case /* rd.company.Location location */ 3:
                    message.location = Location.internalBinaryRead(reader, reader.uint32(), options, message.location);
                    break;
                case /* rd.customer.Customer customer */ 4:
                    message.customer = Customer.internalBinaryRead(reader, reader.uint32(), options, message.customer);
                    break;
                case /* rd.customer.PurchaseOrder purchase_order */ 5:
                    message.purchaseOrder = PurchaseOrder.internalBinaryRead(reader, reader.uint32(), options, message.purchaseOrder);
                    break;
                case /* rd.customer.JobSite job_site */ 6:
                    message.jobSite = JobSite.internalBinaryRead(reader, reader.uint32(), options, message.jobSite);
                    break;
                case /* rd.customer.Contacts.Contact customer_contact */ 7:
                    message.customerContact = Contacts_Contact.internalBinaryRead(reader, reader.uint32(), options, message.customerContact);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SearchOutstandingSalesRes_Result, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.txn.Sale sale = 1; */
        if (message.sale)
            Sale.internalBinaryWrite(message.sale, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money sale_balance = 2; */
        if (message.saleBalance)
            Money.internalBinaryWrite(message.saleBalance, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* rd.company.Location location = 3; */
        if (message.location)
            Location.internalBinaryWrite(message.location, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* rd.customer.Customer customer = 4; */
        if (message.customer)
            Customer.internalBinaryWrite(message.customer, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* rd.customer.PurchaseOrder purchase_order = 5; */
        if (message.purchaseOrder)
            PurchaseOrder.internalBinaryWrite(message.purchaseOrder, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* rd.customer.JobSite job_site = 6; */
        if (message.jobSite)
            JobSite.internalBinaryWrite(message.jobSite, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* rd.customer.Contacts.Contact customer_contact = 7; */
        if (message.customerContact)
            Contacts_Contact.internalBinaryWrite(message.customerContact, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.SearchOutstandingSalesRes.Result
 */
export const SearchOutstandingSalesRes_Result = new SearchOutstandingSalesRes_Result$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchTintColorsReq$Type extends MessageType<SearchTintColorsReq> {
    constructor() {
        super("rd.search.service.SearchTintColorsReq", [
            { no: 1, name: "query", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<SearchTintColorsReq>): SearchTintColorsReq {
        const message = { query: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchTintColorsReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchTintColorsReq): SearchTintColorsReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string query */ 1:
                    message.query = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SearchTintColorsReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string query = 1; */
        if (message.query !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.query);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.SearchTintColorsReq
 */
export const SearchTintColorsReq = new SearchTintColorsReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchTintColorsRes$Type extends MessageType<SearchTintColorsRes> {
    constructor() {
        super("rd.search.service.SearchTintColorsRes", [
            { no: 2, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SearchTintColorsRes_Result }
        ]);
    }
    create(value?: PartialMessage<SearchTintColorsRes>): SearchTintColorsRes {
        const message = { results: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchTintColorsRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchTintColorsRes): SearchTintColorsRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated rd.search.service.SearchTintColorsRes.Result results */ 2:
                    message.results.push(SearchTintColorsRes_Result.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SearchTintColorsRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated rd.search.service.SearchTintColorsRes.Result results = 2; */
        for (let i = 0; i < message.results.length; i++)
            SearchTintColorsRes_Result.internalBinaryWrite(message.results[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.SearchTintColorsRes
 */
export const SearchTintColorsRes = new SearchTintColorsRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchTintColorsRes_Result$Type extends MessageType<SearchTintColorsRes_Result> {
    constructor() {
        super("rd.search.service.SearchTintColorsRes.Result", [
            { no: 1, name: "tint_color", kind: "message", T: () => TintColor }
        ]);
    }
    create(value?: PartialMessage<SearchTintColorsRes_Result>): SearchTintColorsRes_Result {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchTintColorsRes_Result>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchTintColorsRes_Result): SearchTintColorsRes_Result {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.tintcolor.TintColor tint_color */ 1:
                    message.tintColor = TintColor.internalBinaryRead(reader, reader.uint32(), options, message.tintColor);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SearchTintColorsRes_Result, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.tintcolor.TintColor tint_color = 1; */
        if (message.tintColor)
            TintColor.internalBinaryWrite(message.tintColor, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.SearchTintColorsRes.Result
 */
export const SearchTintColorsRes_Result = new SearchTintColorsRes_Result$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchTransfersReq$Type extends MessageType<SearchTransfersReq> {
    constructor() {
        super("rd.search.service.SearchTransfersReq", [
            { no: 1, name: "query", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "created_at_range", kind: "message", T: () => TimestampRange },
            { no: 3, name: "sent_at_range", kind: "message", T: () => TimestampRange },
            { no: 4, name: "received_at_range", kind: "message", T: () => TimestampRange },
            { no: 5, name: "voided_at_range", kind: "message", T: () => TimestampRange },
            { no: 6, name: "from_location_ids", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UUID },
            { no: 7, name: "to_location_ids", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UUID },
            { no: 8, name: "statuses", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["rd.txn.Transfer.Status", Transfer_Status, "STATUS_"] },
            { no: 9, name: "cursor", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "offset", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 11, name: "limit", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<SearchTransfersReq>): SearchTransfersReq {
        const message = { query: "", fromLocationIds: [], toLocationIds: [], statuses: [], cursor: "", offset: 0, limit: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchTransfersReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchTransfersReq): SearchTransfersReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string query */ 1:
                    message.query = reader.string();
                    break;
                case /* rd.proto.timestamprange.TimestampRange created_at_range */ 2:
                    message.createdAtRange = TimestampRange.internalBinaryRead(reader, reader.uint32(), options, message.createdAtRange);
                    break;
                case /* rd.proto.timestamprange.TimestampRange sent_at_range */ 3:
                    message.sentAtRange = TimestampRange.internalBinaryRead(reader, reader.uint32(), options, message.sentAtRange);
                    break;
                case /* rd.proto.timestamprange.TimestampRange received_at_range */ 4:
                    message.receivedAtRange = TimestampRange.internalBinaryRead(reader, reader.uint32(), options, message.receivedAtRange);
                    break;
                case /* rd.proto.timestamprange.TimestampRange voided_at_range */ 5:
                    message.voidedAtRange = TimestampRange.internalBinaryRead(reader, reader.uint32(), options, message.voidedAtRange);
                    break;
                case /* repeated rd.proto.uuid.UUID from_location_ids */ 6:
                    message.fromLocationIds.push(UUID.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated rd.proto.uuid.UUID to_location_ids */ 7:
                    message.toLocationIds.push(UUID.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated rd.txn.Transfer.Status statuses */ 8:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.statuses.push(reader.int32());
                    else
                        message.statuses.push(reader.int32());
                    break;
                case /* string cursor */ 9:
                    message.cursor = reader.string();
                    break;
                case /* int32 offset */ 10:
                    message.offset = reader.int32();
                    break;
                case /* int32 limit */ 11:
                    message.limit = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SearchTransfersReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string query = 1; */
        if (message.query !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.query);
        /* rd.proto.timestamprange.TimestampRange created_at_range = 2; */
        if (message.createdAtRange)
            TimestampRange.internalBinaryWrite(message.createdAtRange, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.timestamprange.TimestampRange sent_at_range = 3; */
        if (message.sentAtRange)
            TimestampRange.internalBinaryWrite(message.sentAtRange, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.timestamprange.TimestampRange received_at_range = 4; */
        if (message.receivedAtRange)
            TimestampRange.internalBinaryWrite(message.receivedAtRange, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.timestamprange.TimestampRange voided_at_range = 5; */
        if (message.voidedAtRange)
            TimestampRange.internalBinaryWrite(message.voidedAtRange, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* repeated rd.proto.uuid.UUID from_location_ids = 6; */
        for (let i = 0; i < message.fromLocationIds.length; i++)
            UUID.internalBinaryWrite(message.fromLocationIds[i], writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* repeated rd.proto.uuid.UUID to_location_ids = 7; */
        for (let i = 0; i < message.toLocationIds.length; i++)
            UUID.internalBinaryWrite(message.toLocationIds[i], writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* repeated rd.txn.Transfer.Status statuses = 8; */
        if (message.statuses.length) {
            writer.tag(8, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.statuses.length; i++)
                writer.int32(message.statuses[i]);
            writer.join();
        }
        /* string cursor = 9; */
        if (message.cursor !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.cursor);
        /* int32 offset = 10; */
        if (message.offset !== 0)
            writer.tag(10, WireType.Varint).int32(message.offset);
        /* int32 limit = 11; */
        if (message.limit !== 0)
            writer.tag(11, WireType.Varint).int32(message.limit);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.SearchTransfersReq
 */
export const SearchTransfersReq = new SearchTransfersReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchTransfersRes$Type extends MessageType<SearchTransfersRes> {
    constructor() {
        super("rd.search.service.SearchTransfersRes", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SearchTransfersRes_Result },
            { no: 2, name: "cursor", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<SearchTransfersRes>): SearchTransfersRes {
        const message = { results: [], cursor: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchTransfersRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchTransfersRes): SearchTransfersRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated rd.search.service.SearchTransfersRes.Result results */ 1:
                    message.results.push(SearchTransfersRes_Result.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string cursor */ 2:
                    message.cursor = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SearchTransfersRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated rd.search.service.SearchTransfersRes.Result results = 1; */
        for (let i = 0; i < message.results.length; i++)
            SearchTransfersRes_Result.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string cursor = 2; */
        if (message.cursor !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.cursor);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.SearchTransfersRes
 */
export const SearchTransfersRes = new SearchTransfersRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchTransfersRes_Result$Type extends MessageType<SearchTransfersRes_Result> {
    constructor() {
        super("rd.search.service.SearchTransfersRes.Result", [
            { no: 1, name: "transfer", kind: "message", T: () => Transfer },
            { no: 2, name: "from_location", kind: "message", T: () => Location },
            { no: 3, name: "to_location", kind: "message", T: () => Location },
            { no: 4, name: "products_by_id", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => Product } },
            { no: 5, name: "matched_products_by_id", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => Product } },
            { no: 6, name: "total", kind: "message", T: () => Money }
        ]);
    }
    create(value?: PartialMessage<SearchTransfersRes_Result>): SearchTransfersRes_Result {
        const message = { productsById: {}, matchedProductsById: {} };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchTransfersRes_Result>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchTransfersRes_Result): SearchTransfersRes_Result {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.txn.Transfer transfer */ 1:
                    message.transfer = Transfer.internalBinaryRead(reader, reader.uint32(), options, message.transfer);
                    break;
                case /* rd.company.Location from_location */ 2:
                    message.fromLocation = Location.internalBinaryRead(reader, reader.uint32(), options, message.fromLocation);
                    break;
                case /* rd.company.Location to_location */ 3:
                    message.toLocation = Location.internalBinaryRead(reader, reader.uint32(), options, message.toLocation);
                    break;
                case /* map<string, rd.product.Product> products_by_id */ 4:
                    this.binaryReadMap4(message.productsById, reader, options);
                    break;
                case /* map<string, rd.product.Product> matched_products_by_id */ 5:
                    this.binaryReadMap5(message.matchedProductsById, reader, options);
                    break;
                case /* rd.proto.money.Money total */ 6:
                    message.total = Money.internalBinaryRead(reader, reader.uint32(), options, message.total);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap4(map: SearchTransfersRes_Result["productsById"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof SearchTransfersRes_Result["productsById"] | undefined, val: SearchTransfersRes_Result["productsById"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = Product.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.search.service.SearchTransfersRes.Result.products_by_id");
            }
        }
        map[key ?? ""] = val ?? Product.create();
    }
    private binaryReadMap5(map: SearchTransfersRes_Result["matchedProductsById"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof SearchTransfersRes_Result["matchedProductsById"] | undefined, val: SearchTransfersRes_Result["matchedProductsById"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = Product.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.search.service.SearchTransfersRes.Result.matched_products_by_id");
            }
        }
        map[key ?? ""] = val ?? Product.create();
    }
    internalBinaryWrite(message: SearchTransfersRes_Result, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.txn.Transfer transfer = 1; */
        if (message.transfer)
            Transfer.internalBinaryWrite(message.transfer, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.company.Location from_location = 2; */
        if (message.fromLocation)
            Location.internalBinaryWrite(message.fromLocation, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* rd.company.Location to_location = 3; */
        if (message.toLocation)
            Location.internalBinaryWrite(message.toLocation, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* map<string, rd.product.Product> products_by_id = 4; */
        for (let k of Object.keys(message.productsById)) {
            writer.tag(4, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            Product.internalBinaryWrite(message.productsById[k], writer, options);
            writer.join().join();
        }
        /* map<string, rd.product.Product> matched_products_by_id = 5; */
        for (let k of Object.keys(message.matchedProductsById)) {
            writer.tag(5, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            Product.internalBinaryWrite(message.matchedProductsById[k], writer, options);
            writer.join().join();
        }
        /* rd.proto.money.Money total = 6; */
        if (message.total)
            Money.internalBinaryWrite(message.total, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.SearchTransfersRes.Result
 */
export const SearchTransfersRes_Result = new SearchTransfersRes_Result$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchVendorsReq$Type extends MessageType<SearchVendorsReq> {
    constructor() {
        super("rd.search.service.SearchVendorsReq", [
            { no: 1, name: "query", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "is_active_filter", kind: "enum", T: () => ["rd.search.service.BoolFilter", BoolFilter, "BOOL_FILTER_"] },
            { no: 3, name: "cursor", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "offset", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "limit", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<SearchVendorsReq>): SearchVendorsReq {
        const message = { query: "", isActiveFilter: 0, cursor: "", offset: 0, limit: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchVendorsReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchVendorsReq): SearchVendorsReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string query */ 1:
                    message.query = reader.string();
                    break;
                case /* rd.search.service.BoolFilter is_active_filter */ 2:
                    message.isActiveFilter = reader.int32();
                    break;
                case /* string cursor */ 3:
                    message.cursor = reader.string();
                    break;
                case /* int32 offset */ 4:
                    message.offset = reader.int32();
                    break;
                case /* int32 limit */ 5:
                    message.limit = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SearchVendorsReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string query = 1; */
        if (message.query !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.query);
        /* rd.search.service.BoolFilter is_active_filter = 2; */
        if (message.isActiveFilter !== 0)
            writer.tag(2, WireType.Varint).int32(message.isActiveFilter);
        /* string cursor = 3; */
        if (message.cursor !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.cursor);
        /* int32 offset = 4; */
        if (message.offset !== 0)
            writer.tag(4, WireType.Varint).int32(message.offset);
        /* int32 limit = 5; */
        if (message.limit !== 0)
            writer.tag(5, WireType.Varint).int32(message.limit);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.SearchVendorsReq
 */
export const SearchVendorsReq = new SearchVendorsReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchVendorsRes$Type extends MessageType<SearchVendorsRes> {
    constructor() {
        super("rd.search.service.SearchVendorsRes", [
            { no: 2, name: "cursor", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SearchVendorsRes_Result }
        ]);
    }
    create(value?: PartialMessage<SearchVendorsRes>): SearchVendorsRes {
        const message = { cursor: "", results: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchVendorsRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchVendorsRes): SearchVendorsRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string cursor */ 2:
                    message.cursor = reader.string();
                    break;
                case /* repeated rd.search.service.SearchVendorsRes.Result results */ 3:
                    message.results.push(SearchVendorsRes_Result.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SearchVendorsRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string cursor = 2; */
        if (message.cursor !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.cursor);
        /* repeated rd.search.service.SearchVendorsRes.Result results = 3; */
        for (let i = 0; i < message.results.length; i++)
            SearchVendorsRes_Result.internalBinaryWrite(message.results[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.SearchVendorsRes
 */
export const SearchVendorsRes = new SearchVendorsRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchVendorsRes_Result$Type extends MessageType<SearchVendorsRes_Result> {
    constructor() {
        super("rd.search.service.SearchVendorsRes.Result", [
            { no: 1, name: "vendor", kind: "message", T: () => Vendor }
        ]);
    }
    create(value?: PartialMessage<SearchVendorsRes_Result>): SearchVendorsRes_Result {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchVendorsRes_Result>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchVendorsRes_Result): SearchVendorsRes_Result {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.vendors.Vendor vendor */ 1:
                    message.vendor = Vendor.internalBinaryRead(reader, reader.uint32(), options, message.vendor);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SearchVendorsRes_Result, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.vendors.Vendor vendor = 1; */
        if (message.vendor)
            Vendor.internalBinaryWrite(message.vendor, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.SearchVendorsRes.Result
 */
export const SearchVendorsRes_Result = new SearchVendorsRes_Result$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BulkExportVendorsReq$Type extends MessageType<BulkExportVendorsReq> {
    constructor() {
        super("rd.search.service.BulkExportVendorsReq", [
            { no: 1, name: "query", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "is_active_filter", kind: "enum", T: () => ["rd.search.service.BoolFilter", BoolFilter, "BOOL_FILTER_"] },
            { no: 3, name: "is_template", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<BulkExportVendorsReq>): BulkExportVendorsReq {
        const message = { query: "", isActiveFilter: 0, isTemplate: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<BulkExportVendorsReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BulkExportVendorsReq): BulkExportVendorsReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string query */ 1:
                    message.query = reader.string();
                    break;
                case /* rd.search.service.BoolFilter is_active_filter */ 2:
                    message.isActiveFilter = reader.int32();
                    break;
                case /* bool is_template */ 3:
                    message.isTemplate = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BulkExportVendorsReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string query = 1; */
        if (message.query !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.query);
        /* rd.search.service.BoolFilter is_active_filter = 2; */
        if (message.isActiveFilter !== 0)
            writer.tag(2, WireType.Varint).int32(message.isActiveFilter);
        /* bool is_template = 3; */
        if (message.isTemplate !== false)
            writer.tag(3, WireType.Varint).bool(message.isTemplate);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.BulkExportVendorsReq
 */
export const BulkExportVendorsReq = new BulkExportVendorsReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BulkExportVendorsRes$Type extends MessageType<BulkExportVendorsRes> {
    constructor() {
        super("rd.search.service.BulkExportVendorsRes", [
            { no: 1, name: "excel_bytes", kind: "scalar", T: 12 /*ScalarType.BYTES*/ }
        ]);
    }
    create(value?: PartialMessage<BulkExportVendorsRes>): BulkExportVendorsRes {
        const message = { excelBytes: new Uint8Array(0) };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<BulkExportVendorsRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BulkExportVendorsRes): BulkExportVendorsRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bytes excel_bytes */ 1:
                    message.excelBytes = reader.bytes();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BulkExportVendorsRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bytes excel_bytes = 1; */
        if (message.excelBytes.length)
            writer.tag(1, WireType.LengthDelimited).bytes(message.excelBytes);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.BulkExportVendorsRes
 */
export const BulkExportVendorsRes = new BulkExportVendorsRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateRecordReq$Type extends MessageType<UpdateRecordReq> {
    constructor() {
        super("rd.search.service.UpdateRecordReq", [
            { no: 1, name: "record", kind: "message", T: () => Any },
            { no: 2, name: "company_id", kind: "message", T: () => UUID },
            { no: 3, name: "delete", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 4, name: "pubsub_token", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "pubsub_origin", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "update", kind: "message", T: () => Update }
        ]);
    }
    create(value?: PartialMessage<UpdateRecordReq>): UpdateRecordReq {
        const message = { delete: false, pubsubToken: "", pubsubOrigin: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateRecordReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateRecordReq): UpdateRecordReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* google.protobuf.Any record */ 1:
                    message.record = Any.internalBinaryRead(reader, reader.uint32(), options, message.record);
                    break;
                case /* rd.proto.uuid.UUID company_id */ 2:
                    message.companyId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.companyId);
                    break;
                case /* bool delete */ 3:
                    message.delete = reader.bool();
                    break;
                case /* string pubsub_token */ 4:
                    message.pubsubToken = reader.string();
                    break;
                case /* string pubsub_origin */ 5:
                    message.pubsubOrigin = reader.string();
                    break;
                case /* rd.search.cache.Update update */ 6:
                    message.update = Update.internalBinaryRead(reader, reader.uint32(), options, message.update);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateRecordReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* google.protobuf.Any record = 1; */
        if (message.record)
            Any.internalBinaryWrite(message.record, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID company_id = 2; */
        if (message.companyId)
            UUID.internalBinaryWrite(message.companyId, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* bool delete = 3; */
        if (message.delete !== false)
            writer.tag(3, WireType.Varint).bool(message.delete);
        /* string pubsub_token = 4; */
        if (message.pubsubToken !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.pubsubToken);
        /* string pubsub_origin = 5; */
        if (message.pubsubOrigin !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.pubsubOrigin);
        /* rd.search.cache.Update update = 6; */
        if (message.update)
            Update.internalBinaryWrite(message.update, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.UpdateRecordReq
 */
export const UpdateRecordReq = new UpdateRecordReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateRecordRes$Type extends MessageType<UpdateRecordRes> {
    constructor() {
        super("rd.search.service.UpdateRecordRes", []);
    }
    create(value?: PartialMessage<UpdateRecordRes>): UpdateRecordRes {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateRecordRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateRecordRes): UpdateRecordRes {
        return target ?? this.create();
    }
    internalBinaryWrite(message: UpdateRecordRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.UpdateRecordRes
 */
export const UpdateRecordRes = new UpdateRecordRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ReloadReq$Type extends MessageType<ReloadReq> {
    constructor() {
        super("rd.search.service.ReloadReq", [
            { no: 1, name: "company_id", kind: "message", T: () => UUID },
            { no: 2, name: "full_reload", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 3, name: "table_names", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ReloadReq>): ReloadReq {
        const message = { fullReload: false, tableNames: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ReloadReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ReloadReq): ReloadReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID company_id */ 1:
                    message.companyId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.companyId);
                    break;
                case /* bool full_reload */ 2:
                    message.fullReload = reader.bool();
                    break;
                case /* repeated string table_names */ 3:
                    message.tableNames.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ReloadReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID company_id = 1; */
        if (message.companyId)
            UUID.internalBinaryWrite(message.companyId, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* bool full_reload = 2; */
        if (message.fullReload !== false)
            writer.tag(2, WireType.Varint).bool(message.fullReload);
        /* repeated string table_names = 3; */
        for (let i = 0; i < message.tableNames.length; i++)
            writer.tag(3, WireType.LengthDelimited).string(message.tableNames[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.ReloadReq
 */
export const ReloadReq = new ReloadReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ReloadRes$Type extends MessageType<ReloadRes> {
    constructor() {
        super("rd.search.service.ReloadRes", []);
    }
    create(value?: PartialMessage<ReloadRes>): ReloadRes {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ReloadRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ReloadRes): ReloadRes {
        return target ?? this.create();
    }
    internalBinaryWrite(message: ReloadRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.ReloadRes
 */
export const ReloadRes = new ReloadRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchCashTransactionsReq$Type extends MessageType<SearchCashTransactionsReq> {
    constructor() {
        super("rd.search.service.SearchCashTransactionsReq", [
            { no: 1, name: "query", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "location_ids", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UUID },
            { no: 3, name: "created_at_range", kind: "message", T: () => TimestampRange },
            { no: 4, name: "types", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["rd.search.service.SearchCashTransactionsReq.Type", SearchCashTransactionsReq_Type, "TYPE_"] },
            { no: 5, name: "created_by_ids", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UUID },
            { no: 6, name: "cursor", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "offset", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 8, name: "limit", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<SearchCashTransactionsReq>): SearchCashTransactionsReq {
        const message = { query: "", locationIds: [], types: [], createdByIds: [], cursor: "", offset: 0, limit: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchCashTransactionsReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchCashTransactionsReq): SearchCashTransactionsReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string query */ 1:
                    message.query = reader.string();
                    break;
                case /* repeated rd.proto.uuid.UUID location_ids */ 2:
                    message.locationIds.push(UUID.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* rd.proto.timestamprange.TimestampRange created_at_range */ 3:
                    message.createdAtRange = TimestampRange.internalBinaryRead(reader, reader.uint32(), options, message.createdAtRange);
                    break;
                case /* repeated rd.search.service.SearchCashTransactionsReq.Type types */ 4:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.types.push(reader.int32());
                    else
                        message.types.push(reader.int32());
                    break;
                case /* repeated rd.proto.uuid.UUID created_by_ids */ 5:
                    message.createdByIds.push(UUID.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string cursor */ 6:
                    message.cursor = reader.string();
                    break;
                case /* int32 offset */ 7:
                    message.offset = reader.int32();
                    break;
                case /* int32 limit */ 8:
                    message.limit = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SearchCashTransactionsReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string query = 1; */
        if (message.query !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.query);
        /* repeated rd.proto.uuid.UUID location_ids = 2; */
        for (let i = 0; i < message.locationIds.length; i++)
            UUID.internalBinaryWrite(message.locationIds[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.timestamprange.TimestampRange created_at_range = 3; */
        if (message.createdAtRange)
            TimestampRange.internalBinaryWrite(message.createdAtRange, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* repeated rd.search.service.SearchCashTransactionsReq.Type types = 4; */
        if (message.types.length) {
            writer.tag(4, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.types.length; i++)
                writer.int32(message.types[i]);
            writer.join();
        }
        /* repeated rd.proto.uuid.UUID created_by_ids = 5; */
        for (let i = 0; i < message.createdByIds.length; i++)
            UUID.internalBinaryWrite(message.createdByIds[i], writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* string cursor = 6; */
        if (message.cursor !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.cursor);
        /* int32 offset = 7; */
        if (message.offset !== 0)
            writer.tag(7, WireType.Varint).int32(message.offset);
        /* int32 limit = 8; */
        if (message.limit !== 0)
            writer.tag(8, WireType.Varint).int32(message.limit);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.SearchCashTransactionsReq
 */
export const SearchCashTransactionsReq = new SearchCashTransactionsReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchCashTransactionsRes$Type extends MessageType<SearchCashTransactionsRes> {
    constructor() {
        super("rd.search.service.SearchCashTransactionsRes", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SearchCashTransactionsRes_Result },
            { no: 2, name: "cursor", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<SearchCashTransactionsRes>): SearchCashTransactionsRes {
        const message = { results: [], cursor: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchCashTransactionsRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchCashTransactionsRes): SearchCashTransactionsRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated rd.search.service.SearchCashTransactionsRes.Result results */ 1:
                    message.results.push(SearchCashTransactionsRes_Result.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string cursor */ 2:
                    message.cursor = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SearchCashTransactionsRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated rd.search.service.SearchCashTransactionsRes.Result results = 1; */
        for (let i = 0; i < message.results.length; i++)
            SearchCashTransactionsRes_Result.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string cursor = 2; */
        if (message.cursor !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.cursor);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.SearchCashTransactionsRes
 */
export const SearchCashTransactionsRes = new SearchCashTransactionsRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchCashTransactionsRes_Result$Type extends MessageType<SearchCashTransactionsRes_Result> {
    constructor() {
        super("rd.search.service.SearchCashTransactionsRes.Result", [
            { no: 1, name: "ledger_entry", kind: "message", T: () => LedgerEntry },
            { no: 2, name: "sale", kind: "message", T: () => Sale },
            { no: 3, name: "bill_payment", kind: "message", T: () => BillPayment },
            { no: 4, name: "petty_cash", kind: "message", T: () => PettyCash },
            { no: 5, name: "close_out", kind: "message", T: () => CloseOut },
            { no: 6, name: "sale_return", kind: "message", T: () => Sale_Return }
        ]);
    }
    create(value?: PartialMessage<SearchCashTransactionsRes_Result>): SearchCashTransactionsRes_Result {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchCashTransactionsRes_Result>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchCashTransactionsRes_Result): SearchCashTransactionsRes_Result {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.txn.LedgerEntry ledger_entry */ 1:
                    message.ledgerEntry = LedgerEntry.internalBinaryRead(reader, reader.uint32(), options, message.ledgerEntry);
                    break;
                case /* rd.txn.Sale sale */ 2:
                    message.sale = Sale.internalBinaryRead(reader, reader.uint32(), options, message.sale);
                    break;
                case /* rd.txn.BillPayment bill_payment */ 3:
                    message.billPayment = BillPayment.internalBinaryRead(reader, reader.uint32(), options, message.billPayment);
                    break;
                case /* rd.txn.PettyCash petty_cash */ 4:
                    message.pettyCash = PettyCash.internalBinaryRead(reader, reader.uint32(), options, message.pettyCash);
                    break;
                case /* rd.txn.CloseOut close_out */ 5:
                    message.closeOut = CloseOut.internalBinaryRead(reader, reader.uint32(), options, message.closeOut);
                    break;
                case /* rd.txn.Sale.Return sale_return */ 6:
                    message.saleReturn = Sale_Return.internalBinaryRead(reader, reader.uint32(), options, message.saleReturn);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SearchCashTransactionsRes_Result, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.txn.LedgerEntry ledger_entry = 1; */
        if (message.ledgerEntry)
            LedgerEntry.internalBinaryWrite(message.ledgerEntry, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.txn.Sale sale = 2; */
        if (message.sale)
            Sale.internalBinaryWrite(message.sale, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* rd.txn.BillPayment bill_payment = 3; */
        if (message.billPayment)
            BillPayment.internalBinaryWrite(message.billPayment, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* rd.txn.PettyCash petty_cash = 4; */
        if (message.pettyCash)
            PettyCash.internalBinaryWrite(message.pettyCash, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* rd.txn.CloseOut close_out = 5; */
        if (message.closeOut)
            CloseOut.internalBinaryWrite(message.closeOut, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* rd.txn.Sale.Return sale_return = 6; */
        if (message.saleReturn)
            Sale_Return.internalBinaryWrite(message.saleReturn, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.SearchCashTransactionsRes.Result
 */
export const SearchCashTransactionsRes_Result = new SearchCashTransactionsRes_Result$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchFinancialTransactionsReq$Type extends MessageType<SearchFinancialTransactionsReq> {
    constructor() {
        super("rd.search.service.SearchFinancialTransactionsReq", [
            { no: 1, name: "query", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "vendor_ids", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UUID },
            { no: 3, name: "types", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["rd.payments.FinancialTransaction.Type", FinancialTransaction_Type, "TYPE_"] },
            { no: 4, name: "statuses", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["rd.payments.FinancialTransaction.Status", FinancialTransaction_Status, "STATUS_"] },
            { no: 5, name: "range", kind: "message", T: () => TimestampRange },
            { no: 6, name: "cursor", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "offset", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 8, name: "limit", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 9, name: "chart_of_account_ids", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UUID }
        ]);
    }
    create(value?: PartialMessage<SearchFinancialTransactionsReq>): SearchFinancialTransactionsReq {
        const message = { query: "", vendorIds: [], types: [], statuses: [], cursor: "", offset: 0, limit: 0, chartOfAccountIds: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchFinancialTransactionsReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchFinancialTransactionsReq): SearchFinancialTransactionsReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string query */ 1:
                    message.query = reader.string();
                    break;
                case /* repeated rd.proto.uuid.UUID vendor_ids */ 2:
                    message.vendorIds.push(UUID.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated rd.payments.FinancialTransaction.Type types */ 3:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.types.push(reader.int32());
                    else
                        message.types.push(reader.int32());
                    break;
                case /* repeated rd.payments.FinancialTransaction.Status statuses */ 4:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.statuses.push(reader.int32());
                    else
                        message.statuses.push(reader.int32());
                    break;
                case /* rd.proto.timestamprange.TimestampRange range */ 5:
                    message.range = TimestampRange.internalBinaryRead(reader, reader.uint32(), options, message.range);
                    break;
                case /* string cursor */ 6:
                    message.cursor = reader.string();
                    break;
                case /* int32 offset */ 7:
                    message.offset = reader.int32();
                    break;
                case /* int32 limit */ 8:
                    message.limit = reader.int32();
                    break;
                case /* repeated rd.proto.uuid.UUID chart_of_account_ids */ 9:
                    message.chartOfAccountIds.push(UUID.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SearchFinancialTransactionsReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string query = 1; */
        if (message.query !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.query);
        /* repeated rd.proto.uuid.UUID vendor_ids = 2; */
        for (let i = 0; i < message.vendorIds.length; i++)
            UUID.internalBinaryWrite(message.vendorIds[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* repeated rd.payments.FinancialTransaction.Type types = 3; */
        if (message.types.length) {
            writer.tag(3, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.types.length; i++)
                writer.int32(message.types[i]);
            writer.join();
        }
        /* repeated rd.payments.FinancialTransaction.Status statuses = 4; */
        if (message.statuses.length) {
            writer.tag(4, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.statuses.length; i++)
                writer.int32(message.statuses[i]);
            writer.join();
        }
        /* rd.proto.timestamprange.TimestampRange range = 5; */
        if (message.range)
            TimestampRange.internalBinaryWrite(message.range, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* string cursor = 6; */
        if (message.cursor !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.cursor);
        /* int32 offset = 7; */
        if (message.offset !== 0)
            writer.tag(7, WireType.Varint).int32(message.offset);
        /* int32 limit = 8; */
        if (message.limit !== 0)
            writer.tag(8, WireType.Varint).int32(message.limit);
        /* repeated rd.proto.uuid.UUID chart_of_account_ids = 9; */
        for (let i = 0; i < message.chartOfAccountIds.length; i++)
            UUID.internalBinaryWrite(message.chartOfAccountIds[i], writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.SearchFinancialTransactionsReq
 */
export const SearchFinancialTransactionsReq = new SearchFinancialTransactionsReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchFinancialTransactionsRes$Type extends MessageType<SearchFinancialTransactionsRes> {
    constructor() {
        super("rd.search.service.SearchFinancialTransactionsRes", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SearchFinancialTransactionsRes_Result },
            { no: 2, name: "cursor", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<SearchFinancialTransactionsRes>): SearchFinancialTransactionsRes {
        const message = { results: [], cursor: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchFinancialTransactionsRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchFinancialTransactionsRes): SearchFinancialTransactionsRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated rd.search.service.SearchFinancialTransactionsRes.Result results */ 1:
                    message.results.push(SearchFinancialTransactionsRes_Result.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string cursor */ 2:
                    message.cursor = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SearchFinancialTransactionsRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated rd.search.service.SearchFinancialTransactionsRes.Result results = 1; */
        for (let i = 0; i < message.results.length; i++)
            SearchFinancialTransactionsRes_Result.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string cursor = 2; */
        if (message.cursor !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.cursor);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.SearchFinancialTransactionsRes
 */
export const SearchFinancialTransactionsRes = new SearchFinancialTransactionsRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchFinancialTransactionsRes_Result$Type extends MessageType<SearchFinancialTransactionsRes_Result> {
    constructor() {
        super("rd.search.service.SearchFinancialTransactionsRes.Result", [
            { no: 1, name: "financial_transaction", kind: "message", T: () => FinancialTransaction },
            { no: 2, name: "vendor", kind: "message", T: () => Vendor },
            { no: 5, name: "accounts_by_id", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => Account } },
            { no: 6, name: "order_shipment_data_by_id", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => SearchFinancialTransactionsRes_Result_OrderShipmentData } },
            { no: 7, name: "location", kind: "message", T: () => Location }
        ]);
    }
    create(value?: PartialMessage<SearchFinancialTransactionsRes_Result>): SearchFinancialTransactionsRes_Result {
        const message = { accountsById: {}, orderShipmentDataById: {} };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchFinancialTransactionsRes_Result>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchFinancialTransactionsRes_Result): SearchFinancialTransactionsRes_Result {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.payments.FinancialTransaction financial_transaction */ 1:
                    message.financialTransaction = FinancialTransaction.internalBinaryRead(reader, reader.uint32(), options, message.financialTransaction);
                    break;
                case /* rd.vendors.Vendor vendor */ 2:
                    message.vendor = Vendor.internalBinaryRead(reader, reader.uint32(), options, message.vendor);
                    break;
                case /* map<string, rd.coa.Account> accounts_by_id */ 5:
                    this.binaryReadMap5(message.accountsById, reader, options);
                    break;
                case /* map<string, rd.search.service.SearchFinancialTransactionsRes.Result.OrderShipmentData> order_shipment_data_by_id */ 6:
                    this.binaryReadMap6(message.orderShipmentDataById, reader, options);
                    break;
                case /* rd.company.Location location */ 7:
                    message.location = Location.internalBinaryRead(reader, reader.uint32(), options, message.location);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap5(map: SearchFinancialTransactionsRes_Result["accountsById"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof SearchFinancialTransactionsRes_Result["accountsById"] | undefined, val: SearchFinancialTransactionsRes_Result["accountsById"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = Account.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.search.service.SearchFinancialTransactionsRes.Result.accounts_by_id");
            }
        }
        map[key ?? ""] = val ?? Account.create();
    }
    private binaryReadMap6(map: SearchFinancialTransactionsRes_Result["orderShipmentDataById"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof SearchFinancialTransactionsRes_Result["orderShipmentDataById"] | undefined, val: SearchFinancialTransactionsRes_Result["orderShipmentDataById"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = SearchFinancialTransactionsRes_Result_OrderShipmentData.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.search.service.SearchFinancialTransactionsRes.Result.order_shipment_data_by_id");
            }
        }
        map[key ?? ""] = val ?? SearchFinancialTransactionsRes_Result_OrderShipmentData.create();
    }
    internalBinaryWrite(message: SearchFinancialTransactionsRes_Result, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.payments.FinancialTransaction financial_transaction = 1; */
        if (message.financialTransaction)
            FinancialTransaction.internalBinaryWrite(message.financialTransaction, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.vendors.Vendor vendor = 2; */
        if (message.vendor)
            Vendor.internalBinaryWrite(message.vendor, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* map<string, rd.coa.Account> accounts_by_id = 5; */
        for (let k of Object.keys(message.accountsById)) {
            writer.tag(5, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            Account.internalBinaryWrite(message.accountsById[k], writer, options);
            writer.join().join();
        }
        /* map<string, rd.search.service.SearchFinancialTransactionsRes.Result.OrderShipmentData> order_shipment_data_by_id = 6; */
        for (let k of Object.keys(message.orderShipmentDataById)) {
            writer.tag(6, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            SearchFinancialTransactionsRes_Result_OrderShipmentData.internalBinaryWrite(message.orderShipmentDataById[k], writer, options);
            writer.join().join();
        }
        /* rd.company.Location location = 7; */
        if (message.location)
            Location.internalBinaryWrite(message.location, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.SearchFinancialTransactionsRes.Result
 */
export const SearchFinancialTransactionsRes_Result = new SearchFinancialTransactionsRes_Result$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchFinancialTransactionsRes_Result_OrderShipmentData$Type extends MessageType<SearchFinancialTransactionsRes_Result_OrderShipmentData> {
    constructor() {
        super("rd.search.service.SearchFinancialTransactionsRes.Result.OrderShipmentData", [
            { no: 1, name: "order_shipment", kind: "message", T: () => OrderShipment },
            { no: 2, name: "vendor", kind: "message", T: () => Vendor },
            { no: 3, name: "total", kind: "message", T: () => Money }
        ]);
    }
    create(value?: PartialMessage<SearchFinancialTransactionsRes_Result_OrderShipmentData>): SearchFinancialTransactionsRes_Result_OrderShipmentData {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchFinancialTransactionsRes_Result_OrderShipmentData>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchFinancialTransactionsRes_Result_OrderShipmentData): SearchFinancialTransactionsRes_Result_OrderShipmentData {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.txn.OrderShipment order_shipment */ 1:
                    message.orderShipment = OrderShipment.internalBinaryRead(reader, reader.uint32(), options, message.orderShipment);
                    break;
                case /* rd.vendors.Vendor vendor */ 2:
                    message.vendor = Vendor.internalBinaryRead(reader, reader.uint32(), options, message.vendor);
                    break;
                case /* rd.proto.money.Money total */ 3:
                    message.total = Money.internalBinaryRead(reader, reader.uint32(), options, message.total);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SearchFinancialTransactionsRes_Result_OrderShipmentData, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.txn.OrderShipment order_shipment = 1; */
        if (message.orderShipment)
            OrderShipment.internalBinaryWrite(message.orderShipment, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.vendors.Vendor vendor = 2; */
        if (message.vendor)
            Vendor.internalBinaryWrite(message.vendor, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money total = 3; */
        if (message.total)
            Money.internalBinaryWrite(message.total, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.SearchFinancialTransactionsRes.Result.OrderShipmentData
 */
export const SearchFinancialTransactionsRes_Result_OrderShipmentData = new SearchFinancialTransactionsRes_Result_OrderShipmentData$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchInventoryCountProductsReq$Type extends MessageType<SearchInventoryCountProductsReq> {
    constructor() {
        super("rd.search.service.SearchInventoryCountProductsReq", [
            { no: 1, name: "query", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "inventory_location_id", kind: "message", T: () => UUID, options: { "rd.validator.require": true } },
            { no: 3, name: "inventory_count_id", kind: "message", T: () => UUID, options: { "rd.validator.require": true } },
            { no: 4, name: "product_status", kind: "enum", T: () => ["rd.product.InventoryCountProduct.Status", InventoryCountProduct_Status], options: { "rd.validator.require": true } },
            { no: 5, name: "cursor", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "offset", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 7, name: "limit", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 13, name: "order_by", kind: "enum", T: () => ["rd.search.service.SearchInventoryCountProductsReq.OrderBy", SearchInventoryCountProductsReq_OrderBy, "ORDER_BY_"] },
            { no: 14, name: "sort_order", kind: "enum", T: () => ["rd.search.service.SortOrder", SortOrder, "SORT_ORDER_"] }
        ]);
    }
    create(value?: PartialMessage<SearchInventoryCountProductsReq>): SearchInventoryCountProductsReq {
        const message = { query: "", productStatus: 0, cursor: "", offset: 0, limit: 0, orderBy: 0, sortOrder: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchInventoryCountProductsReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchInventoryCountProductsReq): SearchInventoryCountProductsReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string query */ 1:
                    message.query = reader.string();
                    break;
                case /* rd.proto.uuid.UUID inventory_location_id */ 2:
                    message.inventoryLocationId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.inventoryLocationId);
                    break;
                case /* rd.proto.uuid.UUID inventory_count_id */ 3:
                    message.inventoryCountId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.inventoryCountId);
                    break;
                case /* rd.product.InventoryCountProduct.Status product_status */ 4:
                    message.productStatus = reader.int32();
                    break;
                case /* string cursor */ 5:
                    message.cursor = reader.string();
                    break;
                case /* int32 offset */ 6:
                    message.offset = reader.int32();
                    break;
                case /* int32 limit */ 7:
                    message.limit = reader.int32();
                    break;
                case /* rd.search.service.SearchInventoryCountProductsReq.OrderBy order_by */ 13:
                    message.orderBy = reader.int32();
                    break;
                case /* rd.search.service.SortOrder sort_order */ 14:
                    message.sortOrder = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SearchInventoryCountProductsReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string query = 1; */
        if (message.query !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.query);
        /* rd.proto.uuid.UUID inventory_location_id = 2; */
        if (message.inventoryLocationId)
            UUID.internalBinaryWrite(message.inventoryLocationId, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID inventory_count_id = 3; */
        if (message.inventoryCountId)
            UUID.internalBinaryWrite(message.inventoryCountId, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* rd.product.InventoryCountProduct.Status product_status = 4; */
        if (message.productStatus !== 0)
            writer.tag(4, WireType.Varint).int32(message.productStatus);
        /* string cursor = 5; */
        if (message.cursor !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.cursor);
        /* int32 offset = 6; */
        if (message.offset !== 0)
            writer.tag(6, WireType.Varint).int32(message.offset);
        /* int32 limit = 7; */
        if (message.limit !== 0)
            writer.tag(7, WireType.Varint).int32(message.limit);
        /* rd.search.service.SearchInventoryCountProductsReq.OrderBy order_by = 13; */
        if (message.orderBy !== 0)
            writer.tag(13, WireType.Varint).int32(message.orderBy);
        /* rd.search.service.SortOrder sort_order = 14; */
        if (message.sortOrder !== 0)
            writer.tag(14, WireType.Varint).int32(message.sortOrder);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.SearchInventoryCountProductsReq
 */
export const SearchInventoryCountProductsReq = new SearchInventoryCountProductsReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchInventoryCountProductsRes$Type extends MessageType<SearchInventoryCountProductsRes> {
    constructor() {
        super("rd.search.service.SearchInventoryCountProductsRes", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SearchInventoryCountProductsRes_Result },
            { no: 2, name: "cursor", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<SearchInventoryCountProductsRes>): SearchInventoryCountProductsRes {
        const message = { results: [], cursor: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchInventoryCountProductsRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchInventoryCountProductsRes): SearchInventoryCountProductsRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated rd.search.service.SearchInventoryCountProductsRes.Result results */ 1:
                    message.results.push(SearchInventoryCountProductsRes_Result.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string cursor */ 2:
                    message.cursor = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SearchInventoryCountProductsRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated rd.search.service.SearchInventoryCountProductsRes.Result results = 1; */
        for (let i = 0; i < message.results.length; i++)
            SearchInventoryCountProductsRes_Result.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string cursor = 2; */
        if (message.cursor !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.cursor);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.SearchInventoryCountProductsRes
 */
export const SearchInventoryCountProductsRes = new SearchInventoryCountProductsRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchInventoryCountProductsRes_Result$Type extends MessageType<SearchInventoryCountProductsRes_Result> {
    constructor() {
        super("rd.search.service.SearchInventoryCountProductsRes.Result", [
            { no: 1, name: "product", kind: "message", T: () => Product },
            { no: 2, name: "on_hand", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "counted", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "variance", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "dollar_variance", kind: "message", T: () => Money }
        ]);
    }
    create(value?: PartialMessage<SearchInventoryCountProductsRes_Result>): SearchInventoryCountProductsRes_Result {
        const message = { onHand: 0, counted: 0, variance: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchInventoryCountProductsRes_Result>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchInventoryCountProductsRes_Result): SearchInventoryCountProductsRes_Result {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.product.Product product */ 1:
                    message.product = Product.internalBinaryRead(reader, reader.uint32(), options, message.product);
                    break;
                case /* int32 on_hand */ 2:
                    message.onHand = reader.int32();
                    break;
                case /* int32 counted */ 3:
                    message.counted = reader.int32();
                    break;
                case /* int32 variance */ 4:
                    message.variance = reader.int32();
                    break;
                case /* rd.proto.money.Money dollar_variance */ 5:
                    message.dollarVariance = Money.internalBinaryRead(reader, reader.uint32(), options, message.dollarVariance);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SearchInventoryCountProductsRes_Result, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.product.Product product = 1; */
        if (message.product)
            Product.internalBinaryWrite(message.product, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 on_hand = 2; */
        if (message.onHand !== 0)
            writer.tag(2, WireType.Varint).int32(message.onHand);
        /* int32 counted = 3; */
        if (message.counted !== 0)
            writer.tag(3, WireType.Varint).int32(message.counted);
        /* int32 variance = 4; */
        if (message.variance !== 0)
            writer.tag(4, WireType.Varint).int32(message.variance);
        /* rd.proto.money.Money dollar_variance = 5; */
        if (message.dollarVariance)
            Money.internalBinaryWrite(message.dollarVariance, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.SearchInventoryCountProductsRes.Result
 */
export const SearchInventoryCountProductsRes_Result = new SearchInventoryCountProductsRes_Result$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchExcludedInventoryCountProductsReq$Type extends MessageType<SearchExcludedInventoryCountProductsReq> {
    constructor() {
        super("rd.search.service.SearchExcludedInventoryCountProductsReq", [
            { no: 1, name: "query", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "location_id", kind: "message", T: () => UUID, options: { "rd.validator.require": true } },
            { no: 3, name: "inventory_count_id", kind: "message", T: () => UUID, options: { "rd.validator.require": true } },
            { no: 4, name: "cursor", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "offset", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "limit", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<SearchExcludedInventoryCountProductsReq>): SearchExcludedInventoryCountProductsReq {
        const message = { query: "", cursor: "", offset: 0, limit: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchExcludedInventoryCountProductsReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchExcludedInventoryCountProductsReq): SearchExcludedInventoryCountProductsReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string query */ 1:
                    message.query = reader.string();
                    break;
                case /* rd.proto.uuid.UUID location_id */ 2:
                    message.locationId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.locationId);
                    break;
                case /* rd.proto.uuid.UUID inventory_count_id */ 3:
                    message.inventoryCountId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.inventoryCountId);
                    break;
                case /* string cursor */ 4:
                    message.cursor = reader.string();
                    break;
                case /* int32 offset */ 5:
                    message.offset = reader.int32();
                    break;
                case /* int32 limit */ 6:
                    message.limit = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SearchExcludedInventoryCountProductsReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string query = 1; */
        if (message.query !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.query);
        /* rd.proto.uuid.UUID location_id = 2; */
        if (message.locationId)
            UUID.internalBinaryWrite(message.locationId, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID inventory_count_id = 3; */
        if (message.inventoryCountId)
            UUID.internalBinaryWrite(message.inventoryCountId, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* string cursor = 4; */
        if (message.cursor !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.cursor);
        /* int32 offset = 5; */
        if (message.offset !== 0)
            writer.tag(5, WireType.Varint).int32(message.offset);
        /* int32 limit = 6; */
        if (message.limit !== 0)
            writer.tag(6, WireType.Varint).int32(message.limit);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.SearchExcludedInventoryCountProductsReq
 */
export const SearchExcludedInventoryCountProductsReq = new SearchExcludedInventoryCountProductsReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchExcludedInventoryCountProductsRes$Type extends MessageType<SearchExcludedInventoryCountProductsRes> {
    constructor() {
        super("rd.search.service.SearchExcludedInventoryCountProductsRes", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SearchExcludedInventoryCountProductsRes_Result },
            { no: 2, name: "cursor", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<SearchExcludedInventoryCountProductsRes>): SearchExcludedInventoryCountProductsRes {
        const message = { results: [], cursor: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchExcludedInventoryCountProductsRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchExcludedInventoryCountProductsRes): SearchExcludedInventoryCountProductsRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated rd.search.service.SearchExcludedInventoryCountProductsRes.Result results */ 1:
                    message.results.push(SearchExcludedInventoryCountProductsRes_Result.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string cursor */ 2:
                    message.cursor = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SearchExcludedInventoryCountProductsRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated rd.search.service.SearchExcludedInventoryCountProductsRes.Result results = 1; */
        for (let i = 0; i < message.results.length; i++)
            SearchExcludedInventoryCountProductsRes_Result.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string cursor = 2; */
        if (message.cursor !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.cursor);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.SearchExcludedInventoryCountProductsRes
 */
export const SearchExcludedInventoryCountProductsRes = new SearchExcludedInventoryCountProductsRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchExcludedInventoryCountProductsRes_Result$Type extends MessageType<SearchExcludedInventoryCountProductsRes_Result> {
    constructor() {
        super("rd.search.service.SearchExcludedInventoryCountProductsRes.Result", [
            { no: 1, name: "product", kind: "message", T: () => Product }
        ]);
    }
    create(value?: PartialMessage<SearchExcludedInventoryCountProductsRes_Result>): SearchExcludedInventoryCountProductsRes_Result {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchExcludedInventoryCountProductsRes_Result>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchExcludedInventoryCountProductsRes_Result): SearchExcludedInventoryCountProductsRes_Result {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.product.Product product */ 1:
                    message.product = Product.internalBinaryRead(reader, reader.uint32(), options, message.product);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SearchExcludedInventoryCountProductsRes_Result, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.product.Product product = 1; */
        if (message.product)
            Product.internalBinaryWrite(message.product, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.SearchExcludedInventoryCountProductsRes.Result
 */
export const SearchExcludedInventoryCountProductsRes_Result = new SearchExcludedInventoryCountProductsRes_Result$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchExternalTransactionsReq$Type extends MessageType<SearchExternalTransactionsReq> {
    constructor() {
        super("rd.search.service.SearchExternalTransactionsReq", [
            { no: 1, name: "query", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "transacted_at_range", kind: "message", T: () => TimestampRange },
            { no: 3, name: "location_ids", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UUID },
            { no: 4, name: "customer_ids", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UUID },
            { no: 5, name: "vendor_ids", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UUID },
            { no: 6, name: "chart_of_account_ids", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UUID },
            { no: 7, name: "types", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["rd.accounting.ExternalTransaction.Type", ExternalTransaction_Type, "TYPE_"] },
            { no: 8, name: "payment_methods", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["rd.accounting.ExternalTransaction.PaymentMethod", ExternalTransaction_PaymentMethod, "PAYMENT_METHOD_"] },
            { no: 9, name: "cursor", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "offset", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 11, name: "limit", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<SearchExternalTransactionsReq>): SearchExternalTransactionsReq {
        const message = { query: "", locationIds: [], customerIds: [], vendorIds: [], chartOfAccountIds: [], types: [], paymentMethods: [], cursor: "", offset: 0, limit: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchExternalTransactionsReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchExternalTransactionsReq): SearchExternalTransactionsReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string query */ 1:
                    message.query = reader.string();
                    break;
                case /* rd.proto.timestamprange.TimestampRange transacted_at_range */ 2:
                    message.transactedAtRange = TimestampRange.internalBinaryRead(reader, reader.uint32(), options, message.transactedAtRange);
                    break;
                case /* repeated rd.proto.uuid.UUID location_ids */ 3:
                    message.locationIds.push(UUID.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated rd.proto.uuid.UUID customer_ids */ 4:
                    message.customerIds.push(UUID.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated rd.proto.uuid.UUID vendor_ids */ 5:
                    message.vendorIds.push(UUID.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated rd.proto.uuid.UUID chart_of_account_ids */ 6:
                    message.chartOfAccountIds.push(UUID.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated rd.accounting.ExternalTransaction.Type types */ 7:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.types.push(reader.int32());
                    else
                        message.types.push(reader.int32());
                    break;
                case /* repeated rd.accounting.ExternalTransaction.PaymentMethod payment_methods */ 8:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.paymentMethods.push(reader.int32());
                    else
                        message.paymentMethods.push(reader.int32());
                    break;
                case /* string cursor */ 9:
                    message.cursor = reader.string();
                    break;
                case /* int32 offset */ 10:
                    message.offset = reader.int32();
                    break;
                case /* int32 limit */ 11:
                    message.limit = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SearchExternalTransactionsReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string query = 1; */
        if (message.query !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.query);
        /* rd.proto.timestamprange.TimestampRange transacted_at_range = 2; */
        if (message.transactedAtRange)
            TimestampRange.internalBinaryWrite(message.transactedAtRange, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* repeated rd.proto.uuid.UUID location_ids = 3; */
        for (let i = 0; i < message.locationIds.length; i++)
            UUID.internalBinaryWrite(message.locationIds[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* repeated rd.proto.uuid.UUID customer_ids = 4; */
        for (let i = 0; i < message.customerIds.length; i++)
            UUID.internalBinaryWrite(message.customerIds[i], writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* repeated rd.proto.uuid.UUID vendor_ids = 5; */
        for (let i = 0; i < message.vendorIds.length; i++)
            UUID.internalBinaryWrite(message.vendorIds[i], writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* repeated rd.proto.uuid.UUID chart_of_account_ids = 6; */
        for (let i = 0; i < message.chartOfAccountIds.length; i++)
            UUID.internalBinaryWrite(message.chartOfAccountIds[i], writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* repeated rd.accounting.ExternalTransaction.Type types = 7; */
        if (message.types.length) {
            writer.tag(7, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.types.length; i++)
                writer.int32(message.types[i]);
            writer.join();
        }
        /* repeated rd.accounting.ExternalTransaction.PaymentMethod payment_methods = 8; */
        if (message.paymentMethods.length) {
            writer.tag(8, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.paymentMethods.length; i++)
                writer.int32(message.paymentMethods[i]);
            writer.join();
        }
        /* string cursor = 9; */
        if (message.cursor !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.cursor);
        /* int32 offset = 10; */
        if (message.offset !== 0)
            writer.tag(10, WireType.Varint).int32(message.offset);
        /* int32 limit = 11; */
        if (message.limit !== 0)
            writer.tag(11, WireType.Varint).int32(message.limit);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.SearchExternalTransactionsReq
 */
export const SearchExternalTransactionsReq = new SearchExternalTransactionsReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchExternalTransactionsRes$Type extends MessageType<SearchExternalTransactionsRes> {
    constructor() {
        super("rd.search.service.SearchExternalTransactionsRes", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SearchExternalTransactionsRes_Result },
            { no: 2, name: "cursor", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<SearchExternalTransactionsRes>): SearchExternalTransactionsRes {
        const message = { results: [], cursor: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchExternalTransactionsRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchExternalTransactionsRes): SearchExternalTransactionsRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated rd.search.service.SearchExternalTransactionsRes.Result results */ 1:
                    message.results.push(SearchExternalTransactionsRes_Result.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string cursor */ 2:
                    message.cursor = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SearchExternalTransactionsRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated rd.search.service.SearchExternalTransactionsRes.Result results = 1; */
        for (let i = 0; i < message.results.length; i++)
            SearchExternalTransactionsRes_Result.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string cursor = 2; */
        if (message.cursor !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.cursor);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.SearchExternalTransactionsRes
 */
export const SearchExternalTransactionsRes = new SearchExternalTransactionsRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchExternalTransactionsRes_Result$Type extends MessageType<SearchExternalTransactionsRes_Result> {
    constructor() {
        super("rd.search.service.SearchExternalTransactionsRes.Result", [
            { no: 1, name: "external_transaction", kind: "message", T: () => ExternalTransaction },
            { no: 2, name: "location", kind: "message", T: () => Location },
            { no: 3, name: "customer", kind: "message", T: () => Customer },
            { no: 4, name: "vendor", kind: "message", T: () => Vendor },
            { no: 7, name: "accounts_by_id", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => Account } },
            { no: 8, name: "order_shipment_data_by_id", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => SearchExternalTransactionsRes_Result_OrderShipmentData } }
        ]);
    }
    create(value?: PartialMessage<SearchExternalTransactionsRes_Result>): SearchExternalTransactionsRes_Result {
        const message = { accountsById: {}, orderShipmentDataById: {} };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchExternalTransactionsRes_Result>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchExternalTransactionsRes_Result): SearchExternalTransactionsRes_Result {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.accounting.ExternalTransaction external_transaction */ 1:
                    message.externalTransaction = ExternalTransaction.internalBinaryRead(reader, reader.uint32(), options, message.externalTransaction);
                    break;
                case /* rd.company.Location location */ 2:
                    message.location = Location.internalBinaryRead(reader, reader.uint32(), options, message.location);
                    break;
                case /* rd.customer.Customer customer */ 3:
                    message.customer = Customer.internalBinaryRead(reader, reader.uint32(), options, message.customer);
                    break;
                case /* rd.vendors.Vendor vendor */ 4:
                    message.vendor = Vendor.internalBinaryRead(reader, reader.uint32(), options, message.vendor);
                    break;
                case /* map<string, rd.coa.Account> accounts_by_id */ 7:
                    this.binaryReadMap7(message.accountsById, reader, options);
                    break;
                case /* map<string, rd.search.service.SearchExternalTransactionsRes.Result.OrderShipmentData> order_shipment_data_by_id */ 8:
                    this.binaryReadMap8(message.orderShipmentDataById, reader, options);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap7(map: SearchExternalTransactionsRes_Result["accountsById"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof SearchExternalTransactionsRes_Result["accountsById"] | undefined, val: SearchExternalTransactionsRes_Result["accountsById"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = Account.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.search.service.SearchExternalTransactionsRes.Result.accounts_by_id");
            }
        }
        map[key ?? ""] = val ?? Account.create();
    }
    private binaryReadMap8(map: SearchExternalTransactionsRes_Result["orderShipmentDataById"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof SearchExternalTransactionsRes_Result["orderShipmentDataById"] | undefined, val: SearchExternalTransactionsRes_Result["orderShipmentDataById"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = SearchExternalTransactionsRes_Result_OrderShipmentData.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.search.service.SearchExternalTransactionsRes.Result.order_shipment_data_by_id");
            }
        }
        map[key ?? ""] = val ?? SearchExternalTransactionsRes_Result_OrderShipmentData.create();
    }
    internalBinaryWrite(message: SearchExternalTransactionsRes_Result, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.accounting.ExternalTransaction external_transaction = 1; */
        if (message.externalTransaction)
            ExternalTransaction.internalBinaryWrite(message.externalTransaction, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.company.Location location = 2; */
        if (message.location)
            Location.internalBinaryWrite(message.location, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* rd.customer.Customer customer = 3; */
        if (message.customer)
            Customer.internalBinaryWrite(message.customer, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* rd.vendors.Vendor vendor = 4; */
        if (message.vendor)
            Vendor.internalBinaryWrite(message.vendor, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* map<string, rd.coa.Account> accounts_by_id = 7; */
        for (let k of Object.keys(message.accountsById)) {
            writer.tag(7, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            Account.internalBinaryWrite(message.accountsById[k], writer, options);
            writer.join().join();
        }
        /* map<string, rd.search.service.SearchExternalTransactionsRes.Result.OrderShipmentData> order_shipment_data_by_id = 8; */
        for (let k of Object.keys(message.orderShipmentDataById)) {
            writer.tag(8, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            SearchExternalTransactionsRes_Result_OrderShipmentData.internalBinaryWrite(message.orderShipmentDataById[k], writer, options);
            writer.join().join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.SearchExternalTransactionsRes.Result
 */
export const SearchExternalTransactionsRes_Result = new SearchExternalTransactionsRes_Result$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchExternalTransactionsRes_Result_OrderShipmentData$Type extends MessageType<SearchExternalTransactionsRes_Result_OrderShipmentData> {
    constructor() {
        super("rd.search.service.SearchExternalTransactionsRes.Result.OrderShipmentData", [
            { no: 1, name: "order_shipment", kind: "message", T: () => OrderShipment },
            { no: 2, name: "vendor", kind: "message", T: () => Vendor },
            { no: 3, name: "total", kind: "message", T: () => Money }
        ]);
    }
    create(value?: PartialMessage<SearchExternalTransactionsRes_Result_OrderShipmentData>): SearchExternalTransactionsRes_Result_OrderShipmentData {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchExternalTransactionsRes_Result_OrderShipmentData>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchExternalTransactionsRes_Result_OrderShipmentData): SearchExternalTransactionsRes_Result_OrderShipmentData {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.txn.OrderShipment order_shipment */ 1:
                    message.orderShipment = OrderShipment.internalBinaryRead(reader, reader.uint32(), options, message.orderShipment);
                    break;
                case /* rd.vendors.Vendor vendor */ 2:
                    message.vendor = Vendor.internalBinaryRead(reader, reader.uint32(), options, message.vendor);
                    break;
                case /* rd.proto.money.Money total */ 3:
                    message.total = Money.internalBinaryRead(reader, reader.uint32(), options, message.total);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SearchExternalTransactionsRes_Result_OrderShipmentData, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.txn.OrderShipment order_shipment = 1; */
        if (message.orderShipment)
            OrderShipment.internalBinaryWrite(message.orderShipment, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.vendors.Vendor vendor = 2; */
        if (message.vendor)
            Vendor.internalBinaryWrite(message.vendor, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money total = 3; */
        if (message.total)
            Money.internalBinaryWrite(message.total, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.SearchExternalTransactionsRes.Result.OrderShipmentData
 */
export const SearchExternalTransactionsRes_Result_OrderShipmentData = new SearchExternalTransactionsRes_Result_OrderShipmentData$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchOrderShipmentsForReconciliationReq$Type extends MessageType<SearchOrderShipmentsForReconciliationReq> {
    constructor() {
        super("rd.search.service.SearchOrderShipmentsForReconciliationReq", [
            { no: 1, name: "cursor", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "offset", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "limit", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "query", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "transaction_id", kind: "message", T: () => UUID },
            { no: 6, name: "stripe_transaction_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<SearchOrderShipmentsForReconciliationReq>): SearchOrderShipmentsForReconciliationReq {
        const message = { cursor: "", offset: 0, limit: 0, query: "", stripeTransactionId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchOrderShipmentsForReconciliationReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchOrderShipmentsForReconciliationReq): SearchOrderShipmentsForReconciliationReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string cursor */ 1:
                    message.cursor = reader.string();
                    break;
                case /* int32 offset */ 2:
                    message.offset = reader.int32();
                    break;
                case /* int32 limit */ 3:
                    message.limit = reader.int32();
                    break;
                case /* string query */ 4:
                    message.query = reader.string();
                    break;
                case /* rd.proto.uuid.UUID transaction_id */ 5:
                    message.transactionId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.transactionId);
                    break;
                case /* string stripe_transaction_id */ 6:
                    message.stripeTransactionId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SearchOrderShipmentsForReconciliationReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string cursor = 1; */
        if (message.cursor !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.cursor);
        /* int32 offset = 2; */
        if (message.offset !== 0)
            writer.tag(2, WireType.Varint).int32(message.offset);
        /* int32 limit = 3; */
        if (message.limit !== 0)
            writer.tag(3, WireType.Varint).int32(message.limit);
        /* string query = 4; */
        if (message.query !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.query);
        /* rd.proto.uuid.UUID transaction_id = 5; */
        if (message.transactionId)
            UUID.internalBinaryWrite(message.transactionId, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* string stripe_transaction_id = 6; */
        if (message.stripeTransactionId !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.stripeTransactionId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.SearchOrderShipmentsForReconciliationReq
 */
export const SearchOrderShipmentsForReconciliationReq = new SearchOrderShipmentsForReconciliationReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchOrderShipmentsForReconciliationRes$Type extends MessageType<SearchOrderShipmentsForReconciliationRes> {
    constructor() {
        super("rd.search.service.SearchOrderShipmentsForReconciliationRes", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SearchOrderShipmentsForReconciliationRes_Result },
            { no: 2, name: "cursor", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<SearchOrderShipmentsForReconciliationRes>): SearchOrderShipmentsForReconciliationRes {
        const message = { results: [], cursor: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchOrderShipmentsForReconciliationRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchOrderShipmentsForReconciliationRes): SearchOrderShipmentsForReconciliationRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated rd.search.service.SearchOrderShipmentsForReconciliationRes.Result results */ 1:
                    message.results.push(SearchOrderShipmentsForReconciliationRes_Result.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string cursor */ 2:
                    message.cursor = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SearchOrderShipmentsForReconciliationRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated rd.search.service.SearchOrderShipmentsForReconciliationRes.Result results = 1; */
        for (let i = 0; i < message.results.length; i++)
            SearchOrderShipmentsForReconciliationRes_Result.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string cursor = 2; */
        if (message.cursor !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.cursor);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.SearchOrderShipmentsForReconciliationRes
 */
export const SearchOrderShipmentsForReconciliationRes = new SearchOrderShipmentsForReconciliationRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchOrderShipmentsForReconciliationRes_Result$Type extends MessageType<SearchOrderShipmentsForReconciliationRes_Result> {
    constructor() {
        super("rd.search.service.SearchOrderShipmentsForReconciliationRes.Result", [
            { no: 1, name: "order_shipment", kind: "message", T: () => OrderShipment },
            { no: 2, name: "vendor", kind: "message", T: () => Vendor },
            { no: 3, name: "total", kind: "message", T: () => Money }
        ]);
    }
    create(value?: PartialMessage<SearchOrderShipmentsForReconciliationRes_Result>): SearchOrderShipmentsForReconciliationRes_Result {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchOrderShipmentsForReconciliationRes_Result>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchOrderShipmentsForReconciliationRes_Result): SearchOrderShipmentsForReconciliationRes_Result {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.txn.OrderShipment order_shipment */ 1:
                    message.orderShipment = OrderShipment.internalBinaryRead(reader, reader.uint32(), options, message.orderShipment);
                    break;
                case /* rd.vendors.Vendor vendor */ 2:
                    message.vendor = Vendor.internalBinaryRead(reader, reader.uint32(), options, message.vendor);
                    break;
                case /* rd.proto.money.Money total */ 3:
                    message.total = Money.internalBinaryRead(reader, reader.uint32(), options, message.total);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SearchOrderShipmentsForReconciliationRes_Result, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.txn.OrderShipment order_shipment = 1; */
        if (message.orderShipment)
            OrderShipment.internalBinaryWrite(message.orderShipment, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.vendors.Vendor vendor = 2; */
        if (message.vendor)
            Vendor.internalBinaryWrite(message.vendor, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money total = 3; */
        if (message.total)
            Money.internalBinaryWrite(message.total, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.SearchOrderShipmentsForReconciliationRes.Result
 */
export const SearchOrderShipmentsForReconciliationRes_Result = new SearchOrderShipmentsForReconciliationRes_Result$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchInventoryChangesReq$Type extends MessageType<SearchInventoryChangesReq> {
    constructor() {
        super("rd.search.service.SearchInventoryChangesReq", [
            { no: 1, name: "query", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "product_id", kind: "message", T: () => UUID },
            { no: 3, name: "current_location_id", kind: "message", T: () => UUID },
            { no: 4, name: "staff_ids", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UUID },
            { no: 5, name: "types", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["rd.search.service.SearchInventoryChangesReq.Type", SearchInventoryChangesReq_Type, "TYPE_"] },
            { no: 6, name: "range", kind: "message", T: () => TimestampRange },
            { no: 7, name: "cursor", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "offset", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 9, name: "limit", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<SearchInventoryChangesReq>): SearchInventoryChangesReq {
        const message = { query: "", staffIds: [], types: [], cursor: "", offset: 0, limit: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchInventoryChangesReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchInventoryChangesReq): SearchInventoryChangesReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string query */ 1:
                    message.query = reader.string();
                    break;
                case /* rd.proto.uuid.UUID product_id */ 2:
                    message.productId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.productId);
                    break;
                case /* rd.proto.uuid.UUID current_location_id */ 3:
                    message.currentLocationId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.currentLocationId);
                    break;
                case /* repeated rd.proto.uuid.UUID staff_ids */ 4:
                    message.staffIds.push(UUID.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated rd.search.service.SearchInventoryChangesReq.Type types */ 5:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.types.push(reader.int32());
                    else
                        message.types.push(reader.int32());
                    break;
                case /* rd.proto.timestamprange.TimestampRange range */ 6:
                    message.range = TimestampRange.internalBinaryRead(reader, reader.uint32(), options, message.range);
                    break;
                case /* string cursor */ 7:
                    message.cursor = reader.string();
                    break;
                case /* int32 offset */ 8:
                    message.offset = reader.int32();
                    break;
                case /* int32 limit */ 9:
                    message.limit = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SearchInventoryChangesReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string query = 1; */
        if (message.query !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.query);
        /* rd.proto.uuid.UUID product_id = 2; */
        if (message.productId)
            UUID.internalBinaryWrite(message.productId, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID current_location_id = 3; */
        if (message.currentLocationId)
            UUID.internalBinaryWrite(message.currentLocationId, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* repeated rd.proto.uuid.UUID staff_ids = 4; */
        for (let i = 0; i < message.staffIds.length; i++)
            UUID.internalBinaryWrite(message.staffIds[i], writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* repeated rd.search.service.SearchInventoryChangesReq.Type types = 5; */
        if (message.types.length) {
            writer.tag(5, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.types.length; i++)
                writer.int32(message.types[i]);
            writer.join();
        }
        /* rd.proto.timestamprange.TimestampRange range = 6; */
        if (message.range)
            TimestampRange.internalBinaryWrite(message.range, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* string cursor = 7; */
        if (message.cursor !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.cursor);
        /* int32 offset = 8; */
        if (message.offset !== 0)
            writer.tag(8, WireType.Varint).int32(message.offset);
        /* int32 limit = 9; */
        if (message.limit !== 0)
            writer.tag(9, WireType.Varint).int32(message.limit);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.SearchInventoryChangesReq
 */
export const SearchInventoryChangesReq = new SearchInventoryChangesReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchInventoryChangesRes$Type extends MessageType<SearchInventoryChangesRes> {
    constructor() {
        super("rd.search.service.SearchInventoryChangesRes", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SearchInventoryChangesRes_Result },
            { no: 2, name: "cursor", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<SearchInventoryChangesRes>): SearchInventoryChangesRes {
        const message = { results: [], cursor: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchInventoryChangesRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchInventoryChangesRes): SearchInventoryChangesRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated rd.search.service.SearchInventoryChangesRes.Result results */ 1:
                    message.results.push(SearchInventoryChangesRes_Result.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string cursor */ 2:
                    message.cursor = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SearchInventoryChangesRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated rd.search.service.SearchInventoryChangesRes.Result results = 1; */
        for (let i = 0; i < message.results.length; i++)
            SearchInventoryChangesRes_Result.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string cursor = 2; */
        if (message.cursor !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.cursor);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.SearchInventoryChangesRes
 */
export const SearchInventoryChangesRes = new SearchInventoryChangesRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchInventoryChangesRes_Result$Type extends MessageType<SearchInventoryChangesRes_Result> {
    constructor() {
        super("rd.search.service.SearchInventoryChangesRes.Result", [
            { no: 1, name: "inventory_change", kind: "message", T: () => InventoryChange }
        ]);
    }
    create(value?: PartialMessage<SearchInventoryChangesRes_Result>): SearchInventoryChangesRes_Result {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchInventoryChangesRes_Result>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchInventoryChangesRes_Result): SearchInventoryChangesRes_Result {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.product.InventoryChange inventory_change */ 1:
                    message.inventoryChange = InventoryChange.internalBinaryRead(reader, reader.uint32(), options, message.inventoryChange);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SearchInventoryChangesRes_Result, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.product.InventoryChange inventory_change = 1; */
        if (message.inventoryChange)
            InventoryChange.internalBinaryWrite(message.inventoryChange, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.SearchInventoryChangesRes.Result
 */
export const SearchInventoryChangesRes_Result = new SearchInventoryChangesRes_Result$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchCustomPricesV2Req$Type extends MessageType<SearchCustomPricesV2Req> {
    constructor() {
        super("rd.search.service.SearchCustomPricesV2Req", [
            { no: 1, name: "query", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "cursor", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "offset", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "limit", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<SearchCustomPricesV2Req>): SearchCustomPricesV2Req {
        const message = { query: "", cursor: "", offset: 0, limit: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchCustomPricesV2Req>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchCustomPricesV2Req): SearchCustomPricesV2Req {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string query */ 1:
                    message.query = reader.string();
                    break;
                case /* string cursor */ 2:
                    message.cursor = reader.string();
                    break;
                case /* int32 offset */ 3:
                    message.offset = reader.int32();
                    break;
                case /* int32 limit */ 4:
                    message.limit = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SearchCustomPricesV2Req, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string query = 1; */
        if (message.query !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.query);
        /* string cursor = 2; */
        if (message.cursor !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.cursor);
        /* int32 offset = 3; */
        if (message.offset !== 0)
            writer.tag(3, WireType.Varint).int32(message.offset);
        /* int32 limit = 4; */
        if (message.limit !== 0)
            writer.tag(4, WireType.Varint).int32(message.limit);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.SearchCustomPricesV2Req
 */
export const SearchCustomPricesV2Req = new SearchCustomPricesV2Req$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchCustomPricesV2Res$Type extends MessageType<SearchCustomPricesV2Res> {
    constructor() {
        super("rd.search.service.SearchCustomPricesV2Res", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SearchCustomPricesV2Res_Result },
            { no: 2, name: "cursor", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<SearchCustomPricesV2Res>): SearchCustomPricesV2Res {
        const message = { results: [], cursor: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchCustomPricesV2Res>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchCustomPricesV2Res): SearchCustomPricesV2Res {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated rd.search.service.SearchCustomPricesV2Res.Result results */ 1:
                    message.results.push(SearchCustomPricesV2Res_Result.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string cursor */ 2:
                    message.cursor = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SearchCustomPricesV2Res, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated rd.search.service.SearchCustomPricesV2Res.Result results = 1; */
        for (let i = 0; i < message.results.length; i++)
            SearchCustomPricesV2Res_Result.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string cursor = 2; */
        if (message.cursor !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.cursor);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.SearchCustomPricesV2Res
 */
export const SearchCustomPricesV2Res = new SearchCustomPricesV2Res$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchCustomPricesV2Res_Result$Type extends MessageType<SearchCustomPricesV2Res_Result> {
    constructor() {
        super("rd.search.service.SearchCustomPricesV2Res.Result", [
            { no: 1, name: "custom_price", kind: "message", T: () => CustomPrice },
            { no: 2, name: "customer", kind: "message", T: () => Customer },
            { no: 3, name: "product", kind: "message", T: () => Product }
        ]);
    }
    create(value?: PartialMessage<SearchCustomPricesV2Res_Result>): SearchCustomPricesV2Res_Result {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchCustomPricesV2Res_Result>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchCustomPricesV2Res_Result): SearchCustomPricesV2Res_Result {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.product.CustomPrice custom_price */ 1:
                    message.customPrice = CustomPrice.internalBinaryRead(reader, reader.uint32(), options, message.customPrice);
                    break;
                case /* rd.customer.Customer customer */ 2:
                    message.customer = Customer.internalBinaryRead(reader, reader.uint32(), options, message.customer);
                    break;
                case /* rd.product.Product product */ 3:
                    message.product = Product.internalBinaryRead(reader, reader.uint32(), options, message.product);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SearchCustomPricesV2Res_Result, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.product.CustomPrice custom_price = 1; */
        if (message.customPrice)
            CustomPrice.internalBinaryWrite(message.customPrice, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.customer.Customer customer = 2; */
        if (message.customer)
            Customer.internalBinaryWrite(message.customer, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* rd.product.Product product = 3; */
        if (message.product)
            Product.internalBinaryWrite(message.product, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.SearchCustomPricesV2Res.Result
 */
export const SearchCustomPricesV2Res_Result = new SearchCustomPricesV2Res_Result$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CxaSearchSalesReq$Type extends MessageType<CxaSearchSalesReq> {
    constructor() {
        super("rd.search.service.CxaSearchSalesReq", [
            { no: 1, name: "query", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "customer_id", kind: "message", T: () => UUID },
            { no: 3, name: "location_ids", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UUID },
            { no: 4, name: "statuses", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["rd.txn.Sale.Status", Sale_Status, "STATUS_"] },
            { no: 5, name: "cursor", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "offset", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 7, name: "limit", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<CxaSearchSalesReq>): CxaSearchSalesReq {
        const message = { query: "", locationIds: [], statuses: [], cursor: "", offset: 0, limit: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CxaSearchSalesReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CxaSearchSalesReq): CxaSearchSalesReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string query */ 1:
                    message.query = reader.string();
                    break;
                case /* rd.proto.uuid.UUID customer_id */ 2:
                    message.customerId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.customerId);
                    break;
                case /* repeated rd.proto.uuid.UUID location_ids */ 3:
                    message.locationIds.push(UUID.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated rd.txn.Sale.Status statuses */ 4:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.statuses.push(reader.int32());
                    else
                        message.statuses.push(reader.int32());
                    break;
                case /* string cursor */ 5:
                    message.cursor = reader.string();
                    break;
                case /* int32 offset */ 6:
                    message.offset = reader.int32();
                    break;
                case /* int32 limit */ 7:
                    message.limit = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CxaSearchSalesReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string query = 1; */
        if (message.query !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.query);
        /* rd.proto.uuid.UUID customer_id = 2; */
        if (message.customerId)
            UUID.internalBinaryWrite(message.customerId, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* repeated rd.proto.uuid.UUID location_ids = 3; */
        for (let i = 0; i < message.locationIds.length; i++)
            UUID.internalBinaryWrite(message.locationIds[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* repeated rd.txn.Sale.Status statuses = 4; */
        if (message.statuses.length) {
            writer.tag(4, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.statuses.length; i++)
                writer.int32(message.statuses[i]);
            writer.join();
        }
        /* string cursor = 5; */
        if (message.cursor !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.cursor);
        /* int32 offset = 6; */
        if (message.offset !== 0)
            writer.tag(6, WireType.Varint).int32(message.offset);
        /* int32 limit = 7; */
        if (message.limit !== 0)
            writer.tag(7, WireType.Varint).int32(message.limit);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.CxaSearchSalesReq
 */
export const CxaSearchSalesReq = new CxaSearchSalesReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CxaSearchSalesRes$Type extends MessageType<CxaSearchSalesRes> {
    constructor() {
        super("rd.search.service.CxaSearchSalesRes", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => CxaSearchSalesRes_Result },
            { no: 2, name: "cursor", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<CxaSearchSalesRes>): CxaSearchSalesRes {
        const message = { results: [], cursor: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CxaSearchSalesRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CxaSearchSalesRes): CxaSearchSalesRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated rd.search.service.CxaSearchSalesRes.Result results */ 1:
                    message.results.push(CxaSearchSalesRes_Result.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string cursor */ 2:
                    message.cursor = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CxaSearchSalesRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated rd.search.service.CxaSearchSalesRes.Result results = 1; */
        for (let i = 0; i < message.results.length; i++)
            CxaSearchSalesRes_Result.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string cursor = 2; */
        if (message.cursor !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.cursor);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.CxaSearchSalesRes
 */
export const CxaSearchSalesRes = new CxaSearchSalesRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CxaSearchSalesRes_Result$Type extends MessageType<CxaSearchSalesRes_Result> {
    constructor() {
        super("rd.search.service.CxaSearchSalesRes.Result", [
            { no: 1, name: "sale", kind: "message", T: () => Sale },
            { no: 2, name: "location", kind: "message", T: () => Location },
            { no: 3, name: "purchase_order", kind: "message", T: () => PurchaseOrder },
            { no: 4, name: "job_site", kind: "message", T: () => JobSite },
            { no: 5, name: "products_by_id", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => Product } },
            { no: 6, name: "matched_products_by_id", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => Product } },
            { no: 7, name: "tint_colors_by_id", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => TintColor } },
            { no: 8, name: "total", kind: "message", T: () => Money },
            { no: 9, name: "purchase_methods", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => PurchaseMethod },
            { no: 10, name: "customer_contact_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "customer_identifier", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<CxaSearchSalesRes_Result>): CxaSearchSalesRes_Result {
        const message = { productsById: {}, matchedProductsById: {}, tintColorsById: {}, purchaseMethods: [], customerContactName: "", customerIdentifier: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CxaSearchSalesRes_Result>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CxaSearchSalesRes_Result): CxaSearchSalesRes_Result {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.txn.Sale sale */ 1:
                    message.sale = Sale.internalBinaryRead(reader, reader.uint32(), options, message.sale);
                    break;
                case /* rd.company.Location location */ 2:
                    message.location = Location.internalBinaryRead(reader, reader.uint32(), options, message.location);
                    break;
                case /* rd.customer.PurchaseOrder purchase_order */ 3:
                    message.purchaseOrder = PurchaseOrder.internalBinaryRead(reader, reader.uint32(), options, message.purchaseOrder);
                    break;
                case /* rd.customer.JobSite job_site */ 4:
                    message.jobSite = JobSite.internalBinaryRead(reader, reader.uint32(), options, message.jobSite);
                    break;
                case /* map<string, rd.product.Product> products_by_id */ 5:
                    this.binaryReadMap5(message.productsById, reader, options);
                    break;
                case /* map<string, rd.product.Product> matched_products_by_id */ 6:
                    this.binaryReadMap6(message.matchedProductsById, reader, options);
                    break;
                case /* map<string, rd.tintcolor.TintColor> tint_colors_by_id */ 7:
                    this.binaryReadMap7(message.tintColorsById, reader, options);
                    break;
                case /* rd.proto.money.Money total */ 8:
                    message.total = Money.internalBinaryRead(reader, reader.uint32(), options, message.total);
                    break;
                case /* repeated rd.txn.PurchaseMethod purchase_methods */ 9:
                    message.purchaseMethods.push(PurchaseMethod.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string customer_contact_name */ 10:
                    message.customerContactName = reader.string();
                    break;
                case /* string customer_identifier */ 11:
                    message.customerIdentifier = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap5(map: CxaSearchSalesRes_Result["productsById"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof CxaSearchSalesRes_Result["productsById"] | undefined, val: CxaSearchSalesRes_Result["productsById"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = Product.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.search.service.CxaSearchSalesRes.Result.products_by_id");
            }
        }
        map[key ?? ""] = val ?? Product.create();
    }
    private binaryReadMap6(map: CxaSearchSalesRes_Result["matchedProductsById"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof CxaSearchSalesRes_Result["matchedProductsById"] | undefined, val: CxaSearchSalesRes_Result["matchedProductsById"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = Product.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.search.service.CxaSearchSalesRes.Result.matched_products_by_id");
            }
        }
        map[key ?? ""] = val ?? Product.create();
    }
    private binaryReadMap7(map: CxaSearchSalesRes_Result["tintColorsById"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof CxaSearchSalesRes_Result["tintColorsById"] | undefined, val: CxaSearchSalesRes_Result["tintColorsById"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = TintColor.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.search.service.CxaSearchSalesRes.Result.tint_colors_by_id");
            }
        }
        map[key ?? ""] = val ?? TintColor.create();
    }
    internalBinaryWrite(message: CxaSearchSalesRes_Result, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.txn.Sale sale = 1; */
        if (message.sale)
            Sale.internalBinaryWrite(message.sale, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.company.Location location = 2; */
        if (message.location)
            Location.internalBinaryWrite(message.location, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* rd.customer.PurchaseOrder purchase_order = 3; */
        if (message.purchaseOrder)
            PurchaseOrder.internalBinaryWrite(message.purchaseOrder, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* rd.customer.JobSite job_site = 4; */
        if (message.jobSite)
            JobSite.internalBinaryWrite(message.jobSite, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* map<string, rd.product.Product> products_by_id = 5; */
        for (let k of Object.keys(message.productsById)) {
            writer.tag(5, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            Product.internalBinaryWrite(message.productsById[k], writer, options);
            writer.join().join();
        }
        /* map<string, rd.product.Product> matched_products_by_id = 6; */
        for (let k of Object.keys(message.matchedProductsById)) {
            writer.tag(6, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            Product.internalBinaryWrite(message.matchedProductsById[k], writer, options);
            writer.join().join();
        }
        /* map<string, rd.tintcolor.TintColor> tint_colors_by_id = 7; */
        for (let k of Object.keys(message.tintColorsById)) {
            writer.tag(7, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            TintColor.internalBinaryWrite(message.tintColorsById[k], writer, options);
            writer.join().join();
        }
        /* rd.proto.money.Money total = 8; */
        if (message.total)
            Money.internalBinaryWrite(message.total, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* repeated rd.txn.PurchaseMethod purchase_methods = 9; */
        for (let i = 0; i < message.purchaseMethods.length; i++)
            PurchaseMethod.internalBinaryWrite(message.purchaseMethods[i], writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        /* string customer_contact_name = 10; */
        if (message.customerContactName !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.customerContactName);
        /* string customer_identifier = 11; */
        if (message.customerIdentifier !== "")
            writer.tag(11, WireType.LengthDelimited).string(message.customerIdentifier);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.CxaSearchSalesRes.Result
 */
export const CxaSearchSalesRes_Result = new CxaSearchSalesRes_Result$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CxaSearchOutstandingSalesReq$Type extends MessageType<CxaSearchOutstandingSalesReq> {
    constructor() {
        super("rd.search.service.CxaSearchOutstandingSalesReq", [
            { no: 1, name: "query", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<CxaSearchOutstandingSalesReq>): CxaSearchOutstandingSalesReq {
        const message = { query: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CxaSearchOutstandingSalesReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CxaSearchOutstandingSalesReq): CxaSearchOutstandingSalesReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string query */ 1:
                    message.query = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CxaSearchOutstandingSalesReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string query = 1; */
        if (message.query !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.query);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.CxaSearchOutstandingSalesReq
 */
export const CxaSearchOutstandingSalesReq = new CxaSearchOutstandingSalesReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CxaSearchOutstandingSalesRes$Type extends MessageType<CxaSearchOutstandingSalesRes> {
    constructor() {
        super("rd.search.service.CxaSearchOutstandingSalesRes", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => CxaSearchOutstandingSalesRes_Result },
            { no: 2, name: "customer_balance", kind: "message", T: () => CustomerBalance }
        ]);
    }
    create(value?: PartialMessage<CxaSearchOutstandingSalesRes>): CxaSearchOutstandingSalesRes {
        const message = { results: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CxaSearchOutstandingSalesRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CxaSearchOutstandingSalesRes): CxaSearchOutstandingSalesRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated rd.search.service.CxaSearchOutstandingSalesRes.Result results */ 1:
                    message.results.push(CxaSearchOutstandingSalesRes_Result.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* rd.txn.CustomerBalance customer_balance */ 2:
                    message.customerBalance = CustomerBalance.internalBinaryRead(reader, reader.uint32(), options, message.customerBalance);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CxaSearchOutstandingSalesRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated rd.search.service.CxaSearchOutstandingSalesRes.Result results = 1; */
        for (let i = 0; i < message.results.length; i++)
            CxaSearchOutstandingSalesRes_Result.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.txn.CustomerBalance customer_balance = 2; */
        if (message.customerBalance)
            CustomerBalance.internalBinaryWrite(message.customerBalance, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.CxaSearchOutstandingSalesRes
 */
export const CxaSearchOutstandingSalesRes = new CxaSearchOutstandingSalesRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CxaSearchOutstandingSalesRes_Result$Type extends MessageType<CxaSearchOutstandingSalesRes_Result> {
    constructor() {
        super("rd.search.service.CxaSearchOutstandingSalesRes.Result", [
            { no: 1, name: "sale", kind: "message", T: () => Sale },
            { no: 2, name: "sale_balance", kind: "message", T: () => Money },
            { no: 3, name: "location", kind: "message", T: () => Location },
            { no: 4, name: "purchase_order", kind: "message", T: () => PurchaseOrder },
            { no: 5, name: "job_site", kind: "message", T: () => JobSite },
            { no: 6, name: "customer_contact_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<CxaSearchOutstandingSalesRes_Result>): CxaSearchOutstandingSalesRes_Result {
        const message = { customerContactName: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CxaSearchOutstandingSalesRes_Result>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CxaSearchOutstandingSalesRes_Result): CxaSearchOutstandingSalesRes_Result {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.txn.Sale sale */ 1:
                    message.sale = Sale.internalBinaryRead(reader, reader.uint32(), options, message.sale);
                    break;
                case /* rd.proto.money.Money sale_balance */ 2:
                    message.saleBalance = Money.internalBinaryRead(reader, reader.uint32(), options, message.saleBalance);
                    break;
                case /* rd.company.Location location */ 3:
                    message.location = Location.internalBinaryRead(reader, reader.uint32(), options, message.location);
                    break;
                case /* rd.customer.PurchaseOrder purchase_order */ 4:
                    message.purchaseOrder = PurchaseOrder.internalBinaryRead(reader, reader.uint32(), options, message.purchaseOrder);
                    break;
                case /* rd.customer.JobSite job_site */ 5:
                    message.jobSite = JobSite.internalBinaryRead(reader, reader.uint32(), options, message.jobSite);
                    break;
                case /* string customer_contact_name */ 6:
                    message.customerContactName = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CxaSearchOutstandingSalesRes_Result, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.txn.Sale sale = 1; */
        if (message.sale)
            Sale.internalBinaryWrite(message.sale, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money sale_balance = 2; */
        if (message.saleBalance)
            Money.internalBinaryWrite(message.saleBalance, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* rd.company.Location location = 3; */
        if (message.location)
            Location.internalBinaryWrite(message.location, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* rd.customer.PurchaseOrder purchase_order = 4; */
        if (message.purchaseOrder)
            PurchaseOrder.internalBinaryWrite(message.purchaseOrder, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* rd.customer.JobSite job_site = 5; */
        if (message.jobSite)
            JobSite.internalBinaryWrite(message.jobSite, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* string customer_contact_name = 6; */
        if (message.customerContactName !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.customerContactName);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.CxaSearchOutstandingSalesRes.Result
 */
export const CxaSearchOutstandingSalesRes_Result = new CxaSearchOutstandingSalesRes_Result$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CxaSearchReturnsReq$Type extends MessageType<CxaSearchReturnsReq> {
    constructor() {
        super("rd.search.service.CxaSearchReturnsReq", [
            { no: 1, name: "query", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "cursor", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "offset", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "limit", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<CxaSearchReturnsReq>): CxaSearchReturnsReq {
        const message = { query: "", cursor: "", offset: 0, limit: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CxaSearchReturnsReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CxaSearchReturnsReq): CxaSearchReturnsReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string query */ 1:
                    message.query = reader.string();
                    break;
                case /* string cursor */ 2:
                    message.cursor = reader.string();
                    break;
                case /* int32 offset */ 3:
                    message.offset = reader.int32();
                    break;
                case /* int32 limit */ 4:
                    message.limit = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CxaSearchReturnsReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string query = 1; */
        if (message.query !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.query);
        /* string cursor = 2; */
        if (message.cursor !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.cursor);
        /* int32 offset = 3; */
        if (message.offset !== 0)
            writer.tag(3, WireType.Varint).int32(message.offset);
        /* int32 limit = 4; */
        if (message.limit !== 0)
            writer.tag(4, WireType.Varint).int32(message.limit);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.CxaSearchReturnsReq
 */
export const CxaSearchReturnsReq = new CxaSearchReturnsReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CxaSearchReturnsRes$Type extends MessageType<CxaSearchReturnsRes> {
    constructor() {
        super("rd.search.service.CxaSearchReturnsRes", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => CxaSearchReturnsRes_Result },
            { no: 2, name: "cursor", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<CxaSearchReturnsRes>): CxaSearchReturnsRes {
        const message = { results: [], cursor: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CxaSearchReturnsRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CxaSearchReturnsRes): CxaSearchReturnsRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated rd.search.service.CxaSearchReturnsRes.Result results */ 1:
                    message.results.push(CxaSearchReturnsRes_Result.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string cursor */ 2:
                    message.cursor = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CxaSearchReturnsRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated rd.search.service.CxaSearchReturnsRes.Result results = 1; */
        for (let i = 0; i < message.results.length; i++)
            CxaSearchReturnsRes_Result.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string cursor = 2; */
        if (message.cursor !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.cursor);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.CxaSearchReturnsRes
 */
export const CxaSearchReturnsRes = new CxaSearchReturnsRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CxaSearchReturnsRes_Result$Type extends MessageType<CxaSearchReturnsRes_Result> {
    constructor() {
        super("rd.search.service.CxaSearchReturnsRes.Result", [
            { no: 1, name: "sale", kind: "message", T: () => Sale },
            { no: 2, name: "sale_return", kind: "message", T: () => Sale_Return },
            { no: 3, name: "return_ledger_entry", kind: "message", T: () => LedgerEntry },
            { no: 4, name: "customer", kind: "message", T: () => Customer },
            { no: 5, name: "location", kind: "message", T: () => Location },
            { no: 6, name: "job_site", kind: "message", T: () => JobSite },
            { no: 7, name: "purchase_order", kind: "message", T: () => PurchaseOrder },
            { no: 8, name: "customer_contact", kind: "message", T: () => Contacts_Contact },
            { no: 9, name: "return_method", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<CxaSearchReturnsRes_Result>): CxaSearchReturnsRes_Result {
        const message = { returnMethod: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CxaSearchReturnsRes_Result>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CxaSearchReturnsRes_Result): CxaSearchReturnsRes_Result {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.txn.Sale sale */ 1:
                    message.sale = Sale.internalBinaryRead(reader, reader.uint32(), options, message.sale);
                    break;
                case /* rd.txn.Sale.Return sale_return */ 2:
                    message.saleReturn = Sale_Return.internalBinaryRead(reader, reader.uint32(), options, message.saleReturn);
                    break;
                case /* rd.txn.LedgerEntry return_ledger_entry */ 3:
                    message.returnLedgerEntry = LedgerEntry.internalBinaryRead(reader, reader.uint32(), options, message.returnLedgerEntry);
                    break;
                case /* rd.customer.Customer customer */ 4:
                    message.customer = Customer.internalBinaryRead(reader, reader.uint32(), options, message.customer);
                    break;
                case /* rd.company.Location location */ 5:
                    message.location = Location.internalBinaryRead(reader, reader.uint32(), options, message.location);
                    break;
                case /* rd.customer.JobSite job_site */ 6:
                    message.jobSite = JobSite.internalBinaryRead(reader, reader.uint32(), options, message.jobSite);
                    break;
                case /* rd.customer.PurchaseOrder purchase_order */ 7:
                    message.purchaseOrder = PurchaseOrder.internalBinaryRead(reader, reader.uint32(), options, message.purchaseOrder);
                    break;
                case /* rd.customer.Contacts.Contact customer_contact */ 8:
                    message.customerContact = Contacts_Contact.internalBinaryRead(reader, reader.uint32(), options, message.customerContact);
                    break;
                case /* string return_method */ 9:
                    message.returnMethod = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CxaSearchReturnsRes_Result, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.txn.Sale sale = 1; */
        if (message.sale)
            Sale.internalBinaryWrite(message.sale, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.txn.Sale.Return sale_return = 2; */
        if (message.saleReturn)
            Sale_Return.internalBinaryWrite(message.saleReturn, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* rd.txn.LedgerEntry return_ledger_entry = 3; */
        if (message.returnLedgerEntry)
            LedgerEntry.internalBinaryWrite(message.returnLedgerEntry, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* rd.customer.Customer customer = 4; */
        if (message.customer)
            Customer.internalBinaryWrite(message.customer, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* rd.company.Location location = 5; */
        if (message.location)
            Location.internalBinaryWrite(message.location, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* rd.customer.JobSite job_site = 6; */
        if (message.jobSite)
            JobSite.internalBinaryWrite(message.jobSite, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* rd.customer.PurchaseOrder purchase_order = 7; */
        if (message.purchaseOrder)
            PurchaseOrder.internalBinaryWrite(message.purchaseOrder, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* rd.customer.Contacts.Contact customer_contact = 8; */
        if (message.customerContact)
            Contacts_Contact.internalBinaryWrite(message.customerContact, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* string return_method = 9; */
        if (message.returnMethod !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.returnMethod);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.search.service.CxaSearchReturnsRes.Result
 */
export const CxaSearchReturnsRes_Result = new CxaSearchReturnsRes_Result$Type();
/**
 * @generated ServiceType for protobuf service rd.search.service.SearchService
 */
export const SearchService = new ServiceType("rd.search.service.SearchService", [
    { name: "SearchBillPayments", options: {}, I: SearchBillPaymentsReq, O: SearchBillPaymentsRes },
    { name: "SearchRefundedBillPayments", options: {}, I: SearchRefundedBillPaymentsReq, O: SearchRefundedBillPaymentsRes },
    { name: "SearchCustomersV2", options: {}, I: SearchCustomersV2Req, O: SearchCustomersV2Res },
    { name: "SearchCustomPrices", options: {}, I: SearchCustomPricesReq, O: SearchCustomPricesRes },
    { name: "SearchExternalTransactions", options: {}, I: SearchExternalTransactionsReq, O: SearchExternalTransactionsRes },
    { name: "SearchInventoryCountProducts", options: {}, I: SearchInventoryCountProductsReq, O: SearchInventoryCountProductsRes },
    { name: "SearchExcludedInventoryCountProducts", options: {}, I: SearchExcludedInventoryCountProductsReq, O: SearchExcludedInventoryCountProductsRes },
    { name: "SearchOrders", options: {}, I: SearchOrdersReq, O: SearchOrdersRes },
    { name: "SearchOrderShipments", options: {}, I: SearchOrderShipmentsReq, O: SearchOrderShipmentsRes },
    { name: "SearchProductsV2", options: {}, I: SearchProductsV2Req, O: SearchProductsV2Res },
    { name: "SearchSalesV2", options: {}, I: SearchSalesV2Req, O: SearchSalesV2Res },
    { name: "SearchReturns", options: {}, I: SearchReturnsReq, O: SearchReturnsRes },
    { name: "SearchOutstandingSales", options: {}, I: SearchOutstandingSalesReq, O: SearchOutstandingSalesRes },
    { name: "SearchTintColors", options: {}, I: SearchTintColorsReq, O: SearchTintColorsRes },
    { name: "SearchTransfers", options: {}, I: SearchTransfersReq, O: SearchTransfersRes },
    { name: "SearchVendors", options: {}, I: SearchVendorsReq, O: SearchVendorsRes },
    { name: "SearchCashTransactions", options: {}, I: SearchCashTransactionsReq, O: SearchCashTransactionsRes },
    { name: "SearchFinancialTransactions", options: {}, I: SearchFinancialTransactionsReq, O: SearchFinancialTransactionsRes },
    { name: "UpdateRecord", options: {}, I: UpdateRecordReq, O: UpdateRecordRes },
    { name: "Reload", options: {}, I: ReloadReq, O: ReloadRes },
    { name: "SearchOrderShipmentsForReconciliation", options: {}, I: SearchOrderShipmentsForReconciliationReq, O: SearchOrderShipmentsForReconciliationRes },
    { name: "SearchInventoryChanges", options: {}, I: SearchInventoryChangesReq, O: SearchInventoryChangesRes },
    { name: "SearchCustomPricesV2", options: {}, I: SearchCustomPricesV2Req, O: SearchCustomPricesV2Res }
]);
/**
 * @generated ServiceType for protobuf service rd.search.service.BulkService
 */
export const BulkService = new ServiceType("rd.search.service.BulkService", [
    { name: "BulkExportCustomers", options: {}, I: BulkExportCustomersReq, O: BulkExportCustomersRes },
    { name: "BulkExportProducts", options: {}, I: BulkExportProductsReq, O: BulkExportProductsRes },
    { name: "BulkExportVendors", options: {}, I: BulkExportVendorsReq, O: BulkExportVendorsRes }
]);
/**
 * @generated ServiceType for protobuf service rd.search.service.SearchalyticsService
 */
export const SearchalyticsService = new ServiceType("rd.search.service.SearchalyticsService", [
    { name: "GetSalesByDay", options: {}, I: GetSalesByDayReq, O: GetSalesByDayRes },
    { name: "GetSalesByDayPDF", options: {}, I: GetSalesByDayPDFReq, O: GetSalesByDayPDFRes },
    { name: "GetSalesByDayCSV", options: {}, I: GetSalesByDayCSVReq, O: GetSalesByDayCSVRes }
]);
/**
 * @generated ServiceType for protobuf service rd.search.service.CxaSearchService
 */
export const CxaSearchService = new ServiceType("rd.search.service.CxaSearchService", [
    { name: "CxaSearchSales", options: {}, I: CxaSearchSalesReq, O: CxaSearchSalesRes },
    { name: "CxaSearchOutstandingSales", options: {}, I: CxaSearchOutstandingSalesReq, O: CxaSearchOutstandingSalesRes },
    { name: "CxaSearchReturns", options: {}, I: CxaSearchReturnsReq, O: CxaSearchReturnsRes }
]);
