// @ts-strict-ignore
import {
  BulkExportCustomersReq,
  BulkExportCustomersRes,
  BulkExportProductsReq,
  BulkExportProductsRes,
  BulkExportVendorsReq,
  BulkExportVendorsRes,
  SearchBillPaymentsRes_Result as SearchBillPaymentResult,
  SearchBillPaymentsReq,
  SearchBillPaymentsRes,
  SearchCashTransactionsRes_Result as SearchCashTransactionResult,
  SearchCashTransactionsReq,
  SearchCashTransactionsRes,
  SearchCustomPricesReq,
  SearchCustomPricesRes,
  SearchCustomPricesRes_Result as SearchCustomPricesResult,
  SearchCustomPricesV2Req,
  SearchCustomPricesV2Res,
  SearchCustomPricesV2Res_Result as SearchCustomPricesV2Result,
  SearchCustomersV2Req,
  SearchCustomersV2Res,
  SearchCustomersV2Res_Result as SearchCustomersV2Result,
  SearchExcludedInventoryCountProductsReq,
  SearchExcludedInventoryCountProductsRes,
  SearchExcludedInventoryCountProductsRes_Result as SearchExcludedInventoryCountProductsResult,
  SearchExternalTransactionsReq,
  SearchExternalTransactionsRes,
  SearchExternalTransactionsRes_Result as SearchExternalTransactionsResult,
  SearchFinancialTransactionsReq,
  SearchFinancialTransactionsRes,
  SearchFinancialTransactionsRes_Result as SearchFinancialTransactionsResult,
  SearchInventoryChangesReq,
  SearchInventoryChangesRes,
  SearchInventoryChangesRes_Result as SearchInventoryChangesResult,
  SearchInventoryCountProductsReq,
  SearchInventoryCountProductsRes,
  SearchOrderShipmentsForReconciliationReq,
  SearchOrderShipmentsForReconciliationRes,
  SearchOrderShipmentsForReconciliationRes_Result as SearchOrderShipmentsForReconciliationResult,
  SearchOrderShipmentsReq,
  SearchOrderShipmentsRes,
  SearchOrderShipmentsRes_Result as SearchOrderShipmentsResult,
  SearchOrdersReq,
  SearchOrdersRes,
  SearchOrdersRes_Result as SearchOrdersResult,
  SearchOutstandingSalesReq,
  SearchOutstandingSalesRes,
  SearchOutstandingSalesRes_Result as SearchOutstandingSalesResult,
  SearchProductsV2Req,
  SearchProductsV2Res,
  SearchProductsV2Res_Result as SearchProductsV2Result,
  SearchRefundedBillPaymentsRes_Result as SearchRefundedBillPaymentResult,
  SearchRefundedBillPaymentsReq,
  SearchRefundedBillPaymentsRes,
  SearchReturnsReq,
  SearchReturnsRes,
  SearchReturnsRes_Result as SearchReturnsResult,
  SearchSalesV2Req,
  SearchSalesV2Res,
  SearchSalesV2Res_Result as SearchSalesV2Result,
  SearchTintColorsReq,
  SearchTintColorsRes,
  SearchTintColorsRes_Result as SearchTintColorsResult,
  SearchTransfersReq,
  SearchTransfersRes,
  SearchTransfersRes_Result as SearchTransfersResult,
  SearchVendorsReq,
  SearchVendorsRes,
  SearchVendorsRes_Result as SearchVendorsResult,
} from "gen/search/service/service_pb"
import {
  BulkServiceClient,
  SearchServiceClient,
  SearchalyticsServiceClient,
} from "gen/search/service/service_pb.client"
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useDeferredValue,
  useMemo,
  useState,
} from "react"
import {
  GetSalesByDayReq,
  GetSalesByDayRes,
} from "gen/api/analytics/service_pb"
import {
  InfiniteData,
  QueryKey,
  UseInfiniteQueryResult,
  UseQueryResult,
  hashKey,
  keepPreviousData,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query"
import { RpcOptions, UnaryCall } from "@protobuf-ts/runtime-rpc"
import {
  SerializableReq,
  restoreSerializableReq,
} from "features/pos/analytics/Reports/configuration/helpers"
import {
  selectExternalTransactionKey,
  selectFinancialTransactionKey,
} from "./searchSlice"

import { ApiError } from "./apiSlice"
import { SearchInventoryCountProductsRes_Result as SearchInventoryCountProductsResult } from "gen/search/service/service_pb"
import { TwirpFetchTransport } from "@protobuf-ts/twirp-transport"
import { stringify } from "lib/stringify"
import { useAppSelector } from "app/hooks"

interface PageParam {
  cursor: string
  offset: number
}
export type UseSearchQueryHookArg<Request extends { query: string }> =
  Partial<Request>

type UseSearchQueryHookBaseResult<T> = {
  query: string
  setQuery: Dispatch<SetStateAction<string>>
  results: T[]
}

type UsePaginatedSearchQueryHookBaseResult<T> =
  UseSearchQueryHookBaseResult<T> & {
    loadNext: () => void
  }

type UsePaginatedSearchQueryHookResult<Response, Result> =
  UseInfiniteQueryResult<InfiniteData<Response, PageParam>, Error> &
    UsePaginatedSearchQueryHookBaseResult<Result>

type UseUnpaginatedSearchQueryHookResult<Response, Result> = UseQueryResult<
  Response,
  Error
> &
  UseSearchQueryHookBaseResult<Result>

function useServiceClient<
  T extends
    | BulkServiceClient
    | SearchServiceClient
    | SearchalyticsServiceClient,
>(ClientConstructor: new (transport: TwirpFetchTransport) => T): T {
  const subdomain = window.location.hostname.split(".")[0]
  const baseUrl =
    `${process.env.NEXT_PUBLIC_RUNDOO_SEARCH_API_BASE_URL}/twirp`.replace(
      "https://",
      `https://${subdomain}.`
    )
  return new ClientConstructor(
    new TwirpFetchTransport({
      baseUrl,
      fetchInit: { credentials: "include" },
      sendJson: true,
      jsonOptions: { ignoreUnknownFields: true },
    })
  )
}

function useBulkClient(): BulkServiceClient {
  return useServiceClient(BulkServiceClient)
}

function useSearchClient(): SearchServiceClient {
  return useServiceClient(SearchServiceClient)
}

function useSearchalyticsClient(): SearchalyticsServiceClient {
  return useServiceClient(SearchalyticsServiceClient)
}

function useBulkMutation<Req extends object, Res extends object>(
  key: keyof BulkServiceClient
) {
  const client = useBulkClient()

  const fn = (
    client[key] as (input: Req, options?: RpcOptions) => UnaryCall<Req, Res>
  ).bind(client)

  return useMutation<Res, ApiError, Req>({
    mutationFn: async (req: Req): Promise<Res> => {
      const res = await fn(req)
      return res.response
    },
  })
}

export function useBulkExportCustomers() {
  return useBulkMutation<BulkExportCustomersReq, BulkExportCustomersRes>(
    "bulkExportCustomers"
  )
}

export function useBulkExportProducts() {
  return useBulkMutation<BulkExportProductsReq, BulkExportProductsRes>(
    "bulkExportProducts"
  )
}

export function useBulkExportVendors() {
  return useBulkMutation<BulkExportVendorsReq, BulkExportVendorsRes>(
    "bulkExportVendors"
  )
}

function usePaginatedSearch<
  SearchReq extends { query: string },
  SearchResult,
  SearchRes extends {
    results: SearchResult[]
    cursor: string
  },
>(
  key: keyof SearchServiceClient,
  queryKey: string,
  SearchReqInstanceType: { create: () => SearchReq },
  arg?: UseSearchQueryHookArg<Omit<SearchReq, "offset" | "cursor">>,
  skip?: boolean,
  reloadKey?: string
): UsePaginatedSearchQueryHookResult<SearchRes, SearchResult> {
  const client = useSearchClient()

  const fn = (
    client[key] as (
      input: SearchReq,
      options?: RpcOptions
    ) => UnaryCall<SearchReq, SearchRes>
  ).bind(client)

  const defaultArgs = useMemo(
    () => SearchReqInstanceType.create(),
    [SearchReqInstanceType]
  )

  const [query, setQuery] = useState<string>(arg.query ?? "")
  const deferredQuery = useDeferredValue(query)

  const res = useInfiniteQuery<
    SearchRes,
    Error,
    InfiniteData<SearchRes, PageParam>,
    QueryKey,
    PageParam
  >({
    queryKey: ["search", queryKey, defaultArgs, arg, deferredQuery, reloadKey],
    queryKeyHashFn: (queryKey) => {
      const serializable = queryKey.map((key) => stringify(key))
      return hashKey(serializable)
    },
    initialPageParam: {
      cursor: "",
      offset: 0,
    },
    queryFn: async ({ pageParam }) => {
      const req: SearchReq = {
        ...defaultArgs,
        ...arg,
        query: deferredQuery,
        cursor: pageParam.cursor,
        offset: pageParam.offset,
      }
      const { response } = await fn(req)
      return response
    },
    getNextPageParam: (lastPage, allPages) =>
      lastPage.cursor
        ? {
            cursor: lastPage.cursor,
            offset: allPages.flatMap((x) => x.results).length,
          }
        : undefined,
    placeholderData: keepPreviousData,
    enabled: !skip,
  })

  const { hasNextPage, fetchNextPage } = res
  const loadNext = useCallback(() => {
    if (hasNextPage) {
      fetchNextPage()
    }
  }, [hasNextPage, fetchNextPage])

  const results = res.data?.pages.flatMap((page) => page.results) ?? []

  return {
    ...res,
    query,
    setQuery,
    results,
    loadNext,
  }
}

function useUnpaginatedSearch<
  SearchReq extends { query: string },
  SearchResult,
  SearchRes extends {
    results: SearchResult[]
  },
>(
  key: keyof SearchServiceClient,
  queryKey: string,
  SearchReqInstanceType: { create: () => SearchReq },
  arg?: UseSearchQueryHookArg<Omit<SearchReq, "offset" | "cursor">>,
  skip?: boolean
): UseUnpaginatedSearchQueryHookResult<SearchRes, SearchResult> {
  const client = useSearchClient()

  const fn = (
    client[key] as (
      input: SearchReq,
      options?: RpcOptions
    ) => UnaryCall<SearchReq, SearchRes>
  ).bind(client)

  const defaultArgs = useMemo(
    () => SearchReqInstanceType.create(),
    [SearchReqInstanceType]
  )
  const [query, setQuery] = useState<string>("")
  const deferredQuery = useDeferredValue(query)

  const res = useQuery({
    queryKey: ["search", queryKey, defaultArgs, arg, deferredQuery],
    queryFn: async () => {
      const req: SearchReq = {
        ...defaultArgs,
        ...arg,
        query: deferredQuery,
      }
      const { response } = await fn(req)
      return response
    },
    placeholderData: keepPreviousData,
    enabled: !skip,
  })

  const results = res.data?.results ?? []

  return {
    ...res,
    query,
    setQuery,
    results,
  }
}

export function useSearchBills(
  arg?: UseSearchQueryHookArg<SearchBillPaymentsReq>,
  skip?: boolean
): UsePaginatedSearchQueryHookResult<
  SearchBillPaymentsRes,
  SearchBillPaymentResult
> {
  return usePaginatedSearch(
    "searchBillPayments",
    "bill-payments",
    SearchBillPaymentsReq,
    arg,
    skip
  )
}

export function useSearchRefundedBills(
  arg?: UseSearchQueryHookArg<SearchRefundedBillPaymentsReq>,
  skip?: boolean
): UsePaginatedSearchQueryHookResult<
  SearchRefundedBillPaymentsRes,
  SearchRefundedBillPaymentResult
> {
  return usePaginatedSearch(
    "searchRefundedBillPayments",
    "refunded-bill-payments",
    SearchRefundedBillPaymentsReq,
    arg,
    skip
  )
}

export function useSearchCustomersV2(
  arg?: UseSearchQueryHookArg<SearchCustomersV2Req>,
  skip?: boolean
): UsePaginatedSearchQueryHookResult<
  SearchCustomersV2Res,
  SearchCustomersV2Result
> {
  return usePaginatedSearch(
    "searchCustomersV2",
    "customers",
    SearchCustomersV2Req,
    arg,
    skip
  )
}

export function useSearchInventoryCountProducts(
  arg?: UseSearchQueryHookArg<
    Omit<SearchInventoryCountProductsReq, "offset" | "cursor">
  >,
  skip?: boolean
): UsePaginatedSearchQueryHookResult<
  SearchInventoryCountProductsRes,
  SearchInventoryCountProductsResult
> {
  return usePaginatedSearch(
    "searchInventoryCountProducts",
    "inventory-count-products",
    SearchInventoryCountProductsReq,
    arg,
    skip
  )
}

export function useInvalidateSearchInventoryCountProducts(): () => Promise<void> {
  const queryClient = useQueryClient()
  return useCallback(
    () =>
      queryClient.invalidateQueries({
        queryKey: ["search", "inventory-count-products"],
      }),
    [queryClient]
  )
}

export function useSearchExcludedInventoryCountProducts(
  arg?: UseSearchQueryHookArg<SearchExcludedInventoryCountProductsReq>,
  skip?: boolean
): UsePaginatedSearchQueryHookResult<
  SearchExcludedInventoryCountProductsRes,
  SearchExcludedInventoryCountProductsResult
> {
  return usePaginatedSearch(
    "searchExcludedInventoryCountProducts",
    "excluded-inventory-count-products",
    SearchExcludedInventoryCountProductsReq,
    arg,
    skip
  )
}

export function useSearchOrders(
  arg?: UseSearchQueryHookArg<SearchOrdersReq>,
  skip?: boolean
): UsePaginatedSearchQueryHookResult<SearchOrdersRes, SearchOrdersResult> {
  return usePaginatedSearch(
    "searchOrders",
    "orders",
    SearchOrdersReq,
    arg,
    skip
  )
}

export function useSearchOrderShipments(
  arg?: UseSearchQueryHookArg<SearchOrderShipmentsReq>,
  skip?: boolean
): UsePaginatedSearchQueryHookResult<
  SearchOrderShipmentsRes,
  SearchOrderShipmentsResult
> {
  return usePaginatedSearch(
    "searchOrderShipments",
    "order-shipments",
    SearchOrderShipmentsReq,
    arg,
    skip
  )
}

export function useSearchProductsV2(
  arg?: UseSearchQueryHookArg<SearchProductsV2Req>,
  skip?: boolean
): UsePaginatedSearchQueryHookResult<
  SearchProductsV2Res,
  SearchProductsV2Result
> {
  return usePaginatedSearch(
    "searchProductsV2",
    "products",
    SearchProductsV2Req,
    arg,
    skip
  )
}

export function useSearchSalesV2(
  arg?: UseSearchQueryHookArg<SearchSalesV2Req>,
  skip?: boolean
): UsePaginatedSearchQueryHookResult<SearchSalesV2Res, SearchSalesV2Result> {
  return usePaginatedSearch(
    "searchSalesV2",
    "sales",
    SearchSalesV2Req,
    arg,
    skip
  )
}

export function useSearchReturns(
  arg?: UseSearchQueryHookArg<SearchReturnsReq>,
  skip?: boolean
): UsePaginatedSearchQueryHookResult<SearchReturnsRes, SearchReturnsResult> {
  return usePaginatedSearch(
    "searchReturns",
    "returns",
    SearchReturnsReq,
    arg,
    skip
  )
}

export function useSearchOutstandingSales(
  arg?: UseSearchQueryHookArg<SearchOutstandingSalesReq>,
  skip?: boolean
): UseUnpaginatedSearchQueryHookResult<
  SearchOutstandingSalesRes,
  SearchOutstandingSalesResult
> {
  return useUnpaginatedSearch(
    "searchOutstandingSales",
    "outstanding-sales",
    SearchOutstandingSalesReq,
    arg,
    skip
  )
}

export function useSearchTransfers(
  arg?: UseSearchQueryHookArg<SearchTransfersReq>,
  skip?: boolean
): UsePaginatedSearchQueryHookResult<
  SearchTransfersRes,
  SearchTransfersResult
> {
  return usePaginatedSearch(
    "searchTransfers",
    "transfers",
    SearchTransfersReq,
    arg,
    skip
  )
}

export function useSearchVendors(
  arg?: UseSearchQueryHookArg<SearchVendorsReq>,
  skip?: boolean
): UsePaginatedSearchQueryHookResult<SearchVendorsRes, SearchVendorsResult> {
  return usePaginatedSearch(
    "searchVendors",
    "vendors",
    SearchVendorsReq,
    arg,
    skip
  )
}

export function useSearchTintColors(): UseUnpaginatedSearchQueryHookResult<
  SearchTintColorsRes,
  SearchTintColorsResult
> {
  return useUnpaginatedSearch(
    "searchTintColors",
    "tint-colors",
    SearchTintColorsReq
  )
}

export function useSearchCustomPrices(): UseUnpaginatedSearchQueryHookResult<
  SearchCustomPricesRes,
  SearchCustomPricesResult
> {
  return useUnpaginatedSearch(
    "searchCustomPrices",
    "custom-prices",
    SearchCustomPricesReq
  )
}

export function useSearchCashTransactions(
  arg?: UseSearchQueryHookArg<SearchCashTransactionsReq>,
  skip?: boolean
): UsePaginatedSearchQueryHookResult<
  SearchCashTransactionsRes,
  SearchCashTransactionResult
> {
  return usePaginatedSearch(
    "searchCashTransactions",
    "cash-transactions",
    SearchCashTransactionsReq,
    arg,
    skip
  )
}

export function useSearchFinancialTransactions(
  arg?: UseSearchQueryHookArg<SearchFinancialTransactionsReq>,
  skip?: boolean
): UsePaginatedSearchQueryHookResult<
  SearchFinancialTransactionsRes,
  SearchFinancialTransactionsResult
> {
  const reloadKey = useAppSelector(selectFinancialTransactionKey)

  return usePaginatedSearch(
    "searchFinancialTransactions",
    "financial-transactions",
    SearchFinancialTransactionsReq,
    arg,
    skip,
    reloadKey
  )
}

export function useSearchExternalTransactions(
  arg?: UseSearchQueryHookArg<SearchExternalTransactionsReq>,
  skip?: boolean
): UsePaginatedSearchQueryHookResult<
  SearchExternalTransactionsRes,
  SearchExternalTransactionsResult
> {
  const reloadKey = useAppSelector(selectExternalTransactionKey)

  return usePaginatedSearch(
    "searchExternalTransactions",
    "external-transactions",
    SearchExternalTransactionsReq,
    arg,
    skip,
    reloadKey
  )
}

export function useSearchOrderShipmentsForReconciliation(
  arg?: UseSearchQueryHookArg<SearchOrderShipmentsForReconciliationReq>,
  skip?: boolean
): UsePaginatedSearchQueryHookResult<
  SearchOrderShipmentsForReconciliationRes,
  SearchOrderShipmentsForReconciliationResult
> {
  return usePaginatedSearch(
    "searchOrderShipmentsForReconciliation",
    "order-shipments-for-reconciliation",
    SearchOrderShipmentsForReconciliationReq,
    arg,
    skip
  )
}

export function useSearchInventoryChanges(
  arg?: UseSearchQueryHookArg<SearchInventoryChangesReq>,
  skip?: boolean
): UsePaginatedSearchQueryHookResult<
  SearchInventoryChangesRes,
  SearchInventoryChangesResult
> {
  return usePaginatedSearch(
    "searchInventoryChanges",
    "inventory-changes",
    SearchInventoryChangesReq,
    arg,
    skip
  )
}

export function useSearchCustomPricesV2(
  arg?: UseSearchQueryHookArg<SearchCustomPricesV2Req>,
  skip?: boolean
): UsePaginatedSearchQueryHookResult<
  SearchCustomPricesV2Res,
  SearchCustomPricesV2Result
> {
  return usePaginatedSearch(
    "searchCustomPricesV2",
    "custom-prices",
    SearchCustomPricesV2Req,
    arg,
    skip
  )
}

function useSearchalytics<Req extends object, Res extends object>(
  key: keyof SearchalyticsServiceClient,
  queryKey: string,
  SearchReqInstanceType: { create: () => Req },
  arg?: Partial<Req>,
  skip?: boolean
): UseQueryResult<Res, Error> {
  const client = useSearchalyticsClient()

  const fn = (
    client[key] as (input: Req, options?: RpcOptions) => UnaryCall<Req, Res>
  ).bind(client)
  const defaultArgs = useMemo(
    () => SearchReqInstanceType.create(),
    [SearchReqInstanceType]
  )

  const res = useQuery({
    queryKey: ["searchalytics", queryKey, arg],
    queryFn: async () => {
      const req: Req = {
        ...defaultArgs,
        ...arg,
      }
      const { response } = await fn(
        restoreSerializableReq(req as SerializableReq<Req>)
      )
      return response
    },
    placeholderData: keepPreviousData,
    enabled: !skip,
  })

  return res
}

export function useSearchalyticsGetSalesByDay(
  arg?: Partial<GetSalesByDayReq>,
  skip?: boolean
): UseQueryResult<GetSalesByDayRes, Error> {
  return useSearchalytics(
    "getSalesByDay",
    "sales-by-day",
    GetSalesByDayReq,
    arg,
    skip
  )
}
