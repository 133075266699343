/* eslint-disable */
// @generated by protobuf-ts 2.9.0 with parameter generate_dependencies,add_pb_suffix,ts_nocheck,eslint_disable
// @generated from protobuf file "api/sale/service.proto" (package "rd.api.sale", syntax proto3)
// tslint:disable
// @ts-nocheck
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { SaleService } from "./service_pb";
import type { CalculateReturnTotalsRes } from "./service_pb";
import type { CalculateReturnTotalsReq } from "./service_pb";
import type { UpdateSaleProductReturnedCostRes } from "./service_pb";
import type { UpdateSaleProductReturnedCostReq } from "./service_pb";
import type { GetScannedReturnRes } from "./service_pb";
import type { GetScannedReturnReq } from "./service_pb";
import type { UpdateSaleProductReturnedRes } from "./service_pb";
import type { UpdateSaleProductReturnedReq } from "./service_pb";
import type { EmailReturnPDFRes } from "./service_pb";
import type { EmailReturnPDFReq } from "./service_pb";
import type { UpdateReturnDetailsRes } from "./service_pb";
import type { UpdateReturnDetailsReq } from "./service_pb";
import type { GetReturnPDFRes } from "./service_pb";
import type { GetReturnPDFReq } from "./service_pb";
import type { ReturnSaleRes } from "./service_pb";
import type { ReturnSaleReq } from "./service_pb";
import type { GetReturnRes } from "./service_pb";
import type { GetReturnReq } from "./service_pb";
import type { DuplicateSaleRes } from "./service_pb";
import type { DuplicateSaleReq } from "./service_pb";
import type { UpdateSaleProductCostRes } from "./service_pb";
import type { UpdateSaleProductCostReq } from "./service_pb";
import type { DownloadItemizedSalesCSVRes } from "./service_pb";
import type { DownloadItemizedSalesCSVReq } from "./service_pb";
import type { CanEditPriceDuringSaleRes } from "./service_pb";
import type { CanEditPriceDuringSaleReq } from "./service_pb";
import type { SetCardReaderDisplayRes } from "./service_pb";
import type { SetCardReaderDisplayReq } from "./service_pb";
import type { AuthorizeCardPaymentRes } from "./service_pb";
import type { AuthorizeCardPaymentReq } from "./service_pb";
import type { GetSalePDFRes } from "./service_pb";
import type { GetSalePDFReq } from "./service_pb";
import type { EmailSalePDFRes } from "./service_pb";
import type { EmailSalePDFReq } from "./service_pb";
import type { UpdateSoldSaleDetailsRes } from "./service_pb";
import type { UpdateSoldSaleDetailsReq } from "./service_pb";
import type { UpdateSoldSaleProductRes } from "./service_pb";
import type { UpdateSoldSaleProductReq } from "./service_pb";
import type { GetCartPricesRes } from "./service_pb";
import type { GetCartPricesReq } from "./service_pb";
import type { SyncCartPricesRes } from "./service_pb";
import type { SyncCartPricesReq } from "./service_pb";
import type { UpsertSaleV3Res } from "./service_pb";
import type { UpsertSaleV3Req } from "./service_pb";
import type { UpsertSaleV2Res } from "./service_pb";
import type { UpsertSaleV2Req } from "./service_pb";
import type { GetScannedSaleRes } from "./service_pb";
import type { GetScannedSaleReq } from "./service_pb";
import type { GetSaleRes } from "./service_pb";
import type { GetSaleReq } from "./service_pb";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { GetSaleCountsByStatusRes } from "./service_pb";
import type { GetSaleCountsByStatusReq } from "./service_pb";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service rd.api.sale.SaleService
 */
export interface ISaleServiceClient {
    /**
     * @generated from protobuf rpc: GetSaleCountsByStatus(rd.api.sale.GetSaleCountsByStatusReq) returns (rd.api.sale.GetSaleCountsByStatusRes);
     */
    getSaleCountsByStatus(input: GetSaleCountsByStatusReq, options?: RpcOptions): UnaryCall<GetSaleCountsByStatusReq, GetSaleCountsByStatusRes>;
    /**
     * @generated from protobuf rpc: GetSale(rd.api.sale.GetSaleReq) returns (rd.api.sale.GetSaleRes);
     */
    getSale(input: GetSaleReq, options?: RpcOptions): UnaryCall<GetSaleReq, GetSaleRes>;
    /**
     * @generated from protobuf rpc: GetScannedSale(rd.api.sale.GetScannedSaleReq) returns (rd.api.sale.GetScannedSaleRes);
     */
    getScannedSale(input: GetScannedSaleReq, options?: RpcOptions): UnaryCall<GetScannedSaleReq, GetScannedSaleRes>;
    /**
     * @generated from protobuf rpc: UpsertSaleV2(rd.api.sale.UpsertSaleV2Req) returns (rd.api.sale.UpsertSaleV2Res);
     */
    upsertSaleV2(input: UpsertSaleV2Req, options?: RpcOptions): UnaryCall<UpsertSaleV2Req, UpsertSaleV2Res>;
    /**
     * @generated from protobuf rpc: UpsertSaleV3(rd.api.sale.UpsertSaleV3Req) returns (rd.api.sale.UpsertSaleV3Res);
     */
    upsertSaleV3(input: UpsertSaleV3Req, options?: RpcOptions): UnaryCall<UpsertSaleV3Req, UpsertSaleV3Res>;
    /**
     * @generated from protobuf rpc: SyncCartPrices(rd.api.sale.SyncCartPricesReq) returns (rd.api.sale.SyncCartPricesRes);
     */
    syncCartPrices(input: SyncCartPricesReq, options?: RpcOptions): UnaryCall<SyncCartPricesReq, SyncCartPricesRes>;
    /**
     * @generated from protobuf rpc: GetCartPrices(rd.api.sale.GetCartPricesReq) returns (rd.api.sale.GetCartPricesRes);
     */
    getCartPrices(input: GetCartPricesReq, options?: RpcOptions): UnaryCall<GetCartPricesReq, GetCartPricesRes>;
    /**
     * @generated from protobuf rpc: UpdateSoldSaleProduct(rd.api.sale.UpdateSoldSaleProductReq) returns (rd.api.sale.UpdateSoldSaleProductRes);
     */
    updateSoldSaleProduct(input: UpdateSoldSaleProductReq, options?: RpcOptions): UnaryCall<UpdateSoldSaleProductReq, UpdateSoldSaleProductRes>;
    /**
     * @generated from protobuf rpc: UpdateSoldSaleDetails(rd.api.sale.UpdateSoldSaleDetailsReq) returns (rd.api.sale.UpdateSoldSaleDetailsRes);
     */
    updateSoldSaleDetails(input: UpdateSoldSaleDetailsReq, options?: RpcOptions): UnaryCall<UpdateSoldSaleDetailsReq, UpdateSoldSaleDetailsRes>;
    /**
     * @generated from protobuf rpc: EmailSalePDF(rd.api.sale.EmailSalePDFReq) returns (rd.api.sale.EmailSalePDFRes);
     */
    emailSalePDF(input: EmailSalePDFReq, options?: RpcOptions): UnaryCall<EmailSalePDFReq, EmailSalePDFRes>;
    /**
     * @generated from protobuf rpc: GetSalePDF(rd.api.sale.GetSalePDFReq) returns (rd.api.sale.GetSalePDFRes);
     */
    getSalePDF(input: GetSalePDFReq, options?: RpcOptions): UnaryCall<GetSalePDFReq, GetSalePDFRes>;
    /**
     * @generated from protobuf rpc: AuthorizeCardPayment(rd.api.sale.AuthorizeCardPaymentReq) returns (rd.api.sale.AuthorizeCardPaymentRes);
     */
    authorizeCardPayment(input: AuthorizeCardPaymentReq, options?: RpcOptions): UnaryCall<AuthorizeCardPaymentReq, AuthorizeCardPaymentRes>;
    /**
     * @generated from protobuf rpc: SetCardReaderDisplay(rd.api.sale.SetCardReaderDisplayReq) returns (rd.api.sale.SetCardReaderDisplayRes);
     */
    setCardReaderDisplay(input: SetCardReaderDisplayReq, options?: RpcOptions): UnaryCall<SetCardReaderDisplayReq, SetCardReaderDisplayRes>;
    /**
     * @generated from protobuf rpc: CanEditPriceDuringSale(rd.api.sale.CanEditPriceDuringSaleReq) returns (rd.api.sale.CanEditPriceDuringSaleRes);
     */
    canEditPriceDuringSale(input: CanEditPriceDuringSaleReq, options?: RpcOptions): UnaryCall<CanEditPriceDuringSaleReq, CanEditPriceDuringSaleRes>;
    /**
     * @generated from protobuf rpc: DownloadItemizedSalesCSV(rd.api.sale.DownloadItemizedSalesCSVReq) returns (rd.api.sale.DownloadItemizedSalesCSVRes);
     */
    downloadItemizedSalesCSV(input: DownloadItemizedSalesCSVReq, options?: RpcOptions): UnaryCall<DownloadItemizedSalesCSVReq, DownloadItemizedSalesCSVRes>;
    /**
     * @generated from protobuf rpc: UpdateSaleProductCost(rd.api.sale.UpdateSaleProductCostReq) returns (rd.api.sale.UpdateSaleProductCostRes);
     */
    updateSaleProductCost(input: UpdateSaleProductCostReq, options?: RpcOptions): UnaryCall<UpdateSaleProductCostReq, UpdateSaleProductCostRes>;
    /**
     * @generated from protobuf rpc: DuplicateSale(rd.api.sale.DuplicateSaleReq) returns (rd.api.sale.DuplicateSaleRes);
     */
    duplicateSale(input: DuplicateSaleReq, options?: RpcOptions): UnaryCall<DuplicateSaleReq, DuplicateSaleRes>;
    /**
     * Returns
     *
     * @generated from protobuf rpc: GetReturn(rd.api.sale.GetReturnReq) returns (rd.api.sale.GetReturnRes);
     */
    getReturn(input: GetReturnReq, options?: RpcOptions): UnaryCall<GetReturnReq, GetReturnRes>;
    /**
     * @generated from protobuf rpc: ReturnSale(rd.api.sale.ReturnSaleReq) returns (rd.api.sale.ReturnSaleRes);
     */
    returnSale(input: ReturnSaleReq, options?: RpcOptions): UnaryCall<ReturnSaleReq, ReturnSaleRes>;
    /**
     * @generated from protobuf rpc: GetReturnPDF(rd.api.sale.GetReturnPDFReq) returns (rd.api.sale.GetReturnPDFRes);
     */
    getReturnPDF(input: GetReturnPDFReq, options?: RpcOptions): UnaryCall<GetReturnPDFReq, GetReturnPDFRes>;
    /**
     * @generated from protobuf rpc: UpdateReturnDetails(rd.api.sale.UpdateReturnDetailsReq) returns (rd.api.sale.UpdateReturnDetailsRes);
     */
    updateReturnDetails(input: UpdateReturnDetailsReq, options?: RpcOptions): UnaryCall<UpdateReturnDetailsReq, UpdateReturnDetailsRes>;
    /**
     * @generated from protobuf rpc: EmailReturnPDF(rd.api.sale.EmailReturnPDFReq) returns (rd.api.sale.EmailReturnPDFRes);
     */
    emailReturnPDF(input: EmailReturnPDFReq, options?: RpcOptions): UnaryCall<EmailReturnPDFReq, EmailReturnPDFRes>;
    /**
     * @generated from protobuf rpc: UpdateSaleProductReturned(rd.api.sale.UpdateSaleProductReturnedReq) returns (rd.api.sale.UpdateSaleProductReturnedRes);
     */
    updateSaleProductReturned(input: UpdateSaleProductReturnedReq, options?: RpcOptions): UnaryCall<UpdateSaleProductReturnedReq, UpdateSaleProductReturnedRes>;
    /**
     * @generated from protobuf rpc: GetScannedReturn(rd.api.sale.GetScannedReturnReq) returns (rd.api.sale.GetScannedReturnRes);
     */
    getScannedReturn(input: GetScannedReturnReq, options?: RpcOptions): UnaryCall<GetScannedReturnReq, GetScannedReturnRes>;
    /**
     * @generated from protobuf rpc: UpdateSaleProductReturnedCost(rd.api.sale.UpdateSaleProductReturnedCostReq) returns (rd.api.sale.UpdateSaleProductReturnedCostRes);
     */
    updateSaleProductReturnedCost(input: UpdateSaleProductReturnedCostReq, options?: RpcOptions): UnaryCall<UpdateSaleProductReturnedCostReq, UpdateSaleProductReturnedCostRes>;
    /**
     * @generated from protobuf rpc: CalculateReturnTotals(rd.api.sale.CalculateReturnTotalsReq) returns (rd.api.sale.CalculateReturnTotalsRes);
     */
    calculateReturnTotals(input: CalculateReturnTotalsReq, options?: RpcOptions): UnaryCall<CalculateReturnTotalsReq, CalculateReturnTotalsRes>;
}
/**
 * @generated from protobuf service rd.api.sale.SaleService
 */
export class SaleServiceClient implements ISaleServiceClient, ServiceInfo {
    typeName = SaleService.typeName;
    methods = SaleService.methods;
    options = SaleService.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * @generated from protobuf rpc: GetSaleCountsByStatus(rd.api.sale.GetSaleCountsByStatusReq) returns (rd.api.sale.GetSaleCountsByStatusRes);
     */
    getSaleCountsByStatus(input: GetSaleCountsByStatusReq, options?: RpcOptions): UnaryCall<GetSaleCountsByStatusReq, GetSaleCountsByStatusRes> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetSaleCountsByStatusReq, GetSaleCountsByStatusRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetSale(rd.api.sale.GetSaleReq) returns (rd.api.sale.GetSaleRes);
     */
    getSale(input: GetSaleReq, options?: RpcOptions): UnaryCall<GetSaleReq, GetSaleRes> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetSaleReq, GetSaleRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetScannedSale(rd.api.sale.GetScannedSaleReq) returns (rd.api.sale.GetScannedSaleRes);
     */
    getScannedSale(input: GetScannedSaleReq, options?: RpcOptions): UnaryCall<GetScannedSaleReq, GetScannedSaleRes> {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetScannedSaleReq, GetScannedSaleRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpsertSaleV2(rd.api.sale.UpsertSaleV2Req) returns (rd.api.sale.UpsertSaleV2Res);
     */
    upsertSaleV2(input: UpsertSaleV2Req, options?: RpcOptions): UnaryCall<UpsertSaleV2Req, UpsertSaleV2Res> {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept<UpsertSaleV2Req, UpsertSaleV2Res>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpsertSaleV3(rd.api.sale.UpsertSaleV3Req) returns (rd.api.sale.UpsertSaleV3Res);
     */
    upsertSaleV3(input: UpsertSaleV3Req, options?: RpcOptions): UnaryCall<UpsertSaleV3Req, UpsertSaleV3Res> {
        const method = this.methods[4], opt = this._transport.mergeOptions(options);
        return stackIntercept<UpsertSaleV3Req, UpsertSaleV3Res>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: SyncCartPrices(rd.api.sale.SyncCartPricesReq) returns (rd.api.sale.SyncCartPricesRes);
     */
    syncCartPrices(input: SyncCartPricesReq, options?: RpcOptions): UnaryCall<SyncCartPricesReq, SyncCartPricesRes> {
        const method = this.methods[5], opt = this._transport.mergeOptions(options);
        return stackIntercept<SyncCartPricesReq, SyncCartPricesRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetCartPrices(rd.api.sale.GetCartPricesReq) returns (rd.api.sale.GetCartPricesRes);
     */
    getCartPrices(input: GetCartPricesReq, options?: RpcOptions): UnaryCall<GetCartPricesReq, GetCartPricesRes> {
        const method = this.methods[6], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetCartPricesReq, GetCartPricesRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateSoldSaleProduct(rd.api.sale.UpdateSoldSaleProductReq) returns (rd.api.sale.UpdateSoldSaleProductRes);
     */
    updateSoldSaleProduct(input: UpdateSoldSaleProductReq, options?: RpcOptions): UnaryCall<UpdateSoldSaleProductReq, UpdateSoldSaleProductRes> {
        const method = this.methods[7], opt = this._transport.mergeOptions(options);
        return stackIntercept<UpdateSoldSaleProductReq, UpdateSoldSaleProductRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateSoldSaleDetails(rd.api.sale.UpdateSoldSaleDetailsReq) returns (rd.api.sale.UpdateSoldSaleDetailsRes);
     */
    updateSoldSaleDetails(input: UpdateSoldSaleDetailsReq, options?: RpcOptions): UnaryCall<UpdateSoldSaleDetailsReq, UpdateSoldSaleDetailsRes> {
        const method = this.methods[8], opt = this._transport.mergeOptions(options);
        return stackIntercept<UpdateSoldSaleDetailsReq, UpdateSoldSaleDetailsRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: EmailSalePDF(rd.api.sale.EmailSalePDFReq) returns (rd.api.sale.EmailSalePDFRes);
     */
    emailSalePDF(input: EmailSalePDFReq, options?: RpcOptions): UnaryCall<EmailSalePDFReq, EmailSalePDFRes> {
        const method = this.methods[9], opt = this._transport.mergeOptions(options);
        return stackIntercept<EmailSalePDFReq, EmailSalePDFRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetSalePDF(rd.api.sale.GetSalePDFReq) returns (rd.api.sale.GetSalePDFRes);
     */
    getSalePDF(input: GetSalePDFReq, options?: RpcOptions): UnaryCall<GetSalePDFReq, GetSalePDFRes> {
        const method = this.methods[10], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetSalePDFReq, GetSalePDFRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: AuthorizeCardPayment(rd.api.sale.AuthorizeCardPaymentReq) returns (rd.api.sale.AuthorizeCardPaymentRes);
     */
    authorizeCardPayment(input: AuthorizeCardPaymentReq, options?: RpcOptions): UnaryCall<AuthorizeCardPaymentReq, AuthorizeCardPaymentRes> {
        const method = this.methods[11], opt = this._transport.mergeOptions(options);
        return stackIntercept<AuthorizeCardPaymentReq, AuthorizeCardPaymentRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: SetCardReaderDisplay(rd.api.sale.SetCardReaderDisplayReq) returns (rd.api.sale.SetCardReaderDisplayRes);
     */
    setCardReaderDisplay(input: SetCardReaderDisplayReq, options?: RpcOptions): UnaryCall<SetCardReaderDisplayReq, SetCardReaderDisplayRes> {
        const method = this.methods[12], opt = this._transport.mergeOptions(options);
        return stackIntercept<SetCardReaderDisplayReq, SetCardReaderDisplayRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CanEditPriceDuringSale(rd.api.sale.CanEditPriceDuringSaleReq) returns (rd.api.sale.CanEditPriceDuringSaleRes);
     */
    canEditPriceDuringSale(input: CanEditPriceDuringSaleReq, options?: RpcOptions): UnaryCall<CanEditPriceDuringSaleReq, CanEditPriceDuringSaleRes> {
        const method = this.methods[13], opt = this._transport.mergeOptions(options);
        return stackIntercept<CanEditPriceDuringSaleReq, CanEditPriceDuringSaleRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DownloadItemizedSalesCSV(rd.api.sale.DownloadItemizedSalesCSVReq) returns (rd.api.sale.DownloadItemizedSalesCSVRes);
     */
    downloadItemizedSalesCSV(input: DownloadItemizedSalesCSVReq, options?: RpcOptions): UnaryCall<DownloadItemizedSalesCSVReq, DownloadItemizedSalesCSVRes> {
        const method = this.methods[14], opt = this._transport.mergeOptions(options);
        return stackIntercept<DownloadItemizedSalesCSVReq, DownloadItemizedSalesCSVRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateSaleProductCost(rd.api.sale.UpdateSaleProductCostReq) returns (rd.api.sale.UpdateSaleProductCostRes);
     */
    updateSaleProductCost(input: UpdateSaleProductCostReq, options?: RpcOptions): UnaryCall<UpdateSaleProductCostReq, UpdateSaleProductCostRes> {
        const method = this.methods[15], opt = this._transport.mergeOptions(options);
        return stackIntercept<UpdateSaleProductCostReq, UpdateSaleProductCostRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DuplicateSale(rd.api.sale.DuplicateSaleReq) returns (rd.api.sale.DuplicateSaleRes);
     */
    duplicateSale(input: DuplicateSaleReq, options?: RpcOptions): UnaryCall<DuplicateSaleReq, DuplicateSaleRes> {
        const method = this.methods[16], opt = this._transport.mergeOptions(options);
        return stackIntercept<DuplicateSaleReq, DuplicateSaleRes>("unary", this._transport, method, opt, input);
    }
    /**
     * Returns
     *
     * @generated from protobuf rpc: GetReturn(rd.api.sale.GetReturnReq) returns (rd.api.sale.GetReturnRes);
     */
    getReturn(input: GetReturnReq, options?: RpcOptions): UnaryCall<GetReturnReq, GetReturnRes> {
        const method = this.methods[17], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetReturnReq, GetReturnRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: ReturnSale(rd.api.sale.ReturnSaleReq) returns (rd.api.sale.ReturnSaleRes);
     */
    returnSale(input: ReturnSaleReq, options?: RpcOptions): UnaryCall<ReturnSaleReq, ReturnSaleRes> {
        const method = this.methods[18], opt = this._transport.mergeOptions(options);
        return stackIntercept<ReturnSaleReq, ReturnSaleRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetReturnPDF(rd.api.sale.GetReturnPDFReq) returns (rd.api.sale.GetReturnPDFRes);
     */
    getReturnPDF(input: GetReturnPDFReq, options?: RpcOptions): UnaryCall<GetReturnPDFReq, GetReturnPDFRes> {
        const method = this.methods[19], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetReturnPDFReq, GetReturnPDFRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateReturnDetails(rd.api.sale.UpdateReturnDetailsReq) returns (rd.api.sale.UpdateReturnDetailsRes);
     */
    updateReturnDetails(input: UpdateReturnDetailsReq, options?: RpcOptions): UnaryCall<UpdateReturnDetailsReq, UpdateReturnDetailsRes> {
        const method = this.methods[20], opt = this._transport.mergeOptions(options);
        return stackIntercept<UpdateReturnDetailsReq, UpdateReturnDetailsRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: EmailReturnPDF(rd.api.sale.EmailReturnPDFReq) returns (rd.api.sale.EmailReturnPDFRes);
     */
    emailReturnPDF(input: EmailReturnPDFReq, options?: RpcOptions): UnaryCall<EmailReturnPDFReq, EmailReturnPDFRes> {
        const method = this.methods[21], opt = this._transport.mergeOptions(options);
        return stackIntercept<EmailReturnPDFReq, EmailReturnPDFRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateSaleProductReturned(rd.api.sale.UpdateSaleProductReturnedReq) returns (rd.api.sale.UpdateSaleProductReturnedRes);
     */
    updateSaleProductReturned(input: UpdateSaleProductReturnedReq, options?: RpcOptions): UnaryCall<UpdateSaleProductReturnedReq, UpdateSaleProductReturnedRes> {
        const method = this.methods[22], opt = this._transport.mergeOptions(options);
        return stackIntercept<UpdateSaleProductReturnedReq, UpdateSaleProductReturnedRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetScannedReturn(rd.api.sale.GetScannedReturnReq) returns (rd.api.sale.GetScannedReturnRes);
     */
    getScannedReturn(input: GetScannedReturnReq, options?: RpcOptions): UnaryCall<GetScannedReturnReq, GetScannedReturnRes> {
        const method = this.methods[23], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetScannedReturnReq, GetScannedReturnRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateSaleProductReturnedCost(rd.api.sale.UpdateSaleProductReturnedCostReq) returns (rd.api.sale.UpdateSaleProductReturnedCostRes);
     */
    updateSaleProductReturnedCost(input: UpdateSaleProductReturnedCostReq, options?: RpcOptions): UnaryCall<UpdateSaleProductReturnedCostReq, UpdateSaleProductReturnedCostRes> {
        const method = this.methods[24], opt = this._transport.mergeOptions(options);
        return stackIntercept<UpdateSaleProductReturnedCostReq, UpdateSaleProductReturnedCostRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CalculateReturnTotals(rd.api.sale.CalculateReturnTotalsReq) returns (rd.api.sale.CalculateReturnTotalsRes);
     */
    calculateReturnTotals(input: CalculateReturnTotalsReq, options?: RpcOptions): UnaryCall<CalculateReturnTotalsReq, CalculateReturnTotalsRes> {
        const method = this.methods[25], opt = this._transport.mergeOptions(options);
        return stackIntercept<CalculateReturnTotalsReq, CalculateReturnTotalsRes>("unary", this._transport, method, opt, input);
    }
}
