/* eslint-disable */
// @generated by protobuf-ts 2.9.0 with parameter generate_dependencies,add_pb_suffix,ts_nocheck,eslint_disable
// @generated from protobuf file "api/analytics/service.proto" (package "rd.api.analytics", syntax proto3)
// tslint:disable
// @ts-nocheck
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { WriteOffsByDaySortBy } from "../../analyticsv2/models_pb";
import { SalesByClerkSortBy } from "../../analytics/models_pb";
import { SalesByCustomerSortBy } from "../../analyticsv2/models_pb";
import { SortOrder as SortOrder$ } from "../../analyticsv2/models_pb";
import { SalesByDaySortBy } from "../../analyticsv2/models_pb";
import { SalesDetail } from "../../analytics/models_pb";
import { SalesDetailSortBy } from "../../analytics/models_pb";
import { NewAccountsOpened } from "../../analytics/models_pb";
import { NewAccountsOpenedSortBy } from "../../analytics/models_pb";
import { Report } from "../../analytics/models_pb";
import { PriceEditsSortBy } from "../../analytics/models_pb";
import { TaxEntries } from "../../analytics/models_pb";
import { CustomerAging } from "../../analytics/models_pb";
import { SortOrder } from "../../analytics/models_pb";
import { CustomerAgingSortBy } from "../../analytics/models_pb";
import { InventoryValuation } from "../../analytics/models_pb";
import { Filters } from "../../analyticsv2/models_pb";
import { Decimal } from "../../proto/decimal/models_pb";
import { Money } from "../../proto/money/models_pb";
import { ProfitAndLoss } from "../../analytics/models_pb";
import { TimestampRange } from "../../proto/timestamprange/models_pb";
import { UUID } from "../../proto/uuid/models_pb";
// Closing Summary 

/**
 * @generated from protobuf message rd.api.analytics.GetProfitAndLossPDFReq
 */
export interface GetProfitAndLossPDFReq {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID location_id = 2;
     */
    locationId?: UUID;
    /**
     * @generated from protobuf field: rd.proto.timestamprange.TimestampRange timestamp_range = 3;
     */
    timestampRange?: TimestampRange;
    /**
     * @generated from protobuf field: string client_time_zone = 4;
     */
    clientTimeZone: string;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID customer_id = 5;
     */
    customerId?: UUID;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID rep_staff_id = 6;
     */
    repStaffId?: UUID;
    /**
     * @generated from protobuf field: bool hide_product_categories = 7;
     */
    hideProductCategories: boolean;
}
/**
 * @generated from protobuf message rd.api.analytics.GetProfitAndLossPDFRes
 */
export interface GetProfitAndLossPDFRes {
    /**
     * @generated from protobuf field: bytes bytes = 1;
     */
    bytes: Uint8Array;
}
/**
 * @generated from protobuf message rd.api.analytics.GetProfitAndLossReq
 */
export interface GetProfitAndLossReq {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID location_id = 2;
     */
    locationId?: UUID;
    /**
     * @generated from protobuf field: rd.proto.timestamprange.TimestampRange timestamp_range = 3;
     */
    timestampRange?: TimestampRange;
    /**
     * @generated from protobuf field: string client_time_zone = 4;
     */
    clientTimeZone: string;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID customer_id = 5;
     */
    customerId?: UUID;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID rep_staff_id = 6;
     */
    repStaffId?: UUID;
}
/**
 * @generated from protobuf message rd.api.analytics.GetProfitAndLossRes
 */
export interface GetProfitAndLossRes {
    /**
     * @generated from protobuf field: rd.analytics.ProfitAndLoss profit_and_loss = 1;
     */
    profitAndLoss?: ProfitAndLoss;
}
/**
 * @generated from protobuf message rd.api.analytics.GetGrossProfitAndRevenueByLocationReq
 */
export interface GetGrossProfitAndRevenueByLocationReq {
    /**
     * @generated from protobuf field: rd.proto.timestamprange.TimestampRange timestamp_range = 1;
     */
    timestampRange?: TimestampRange;
    /**
     * @generated from protobuf field: string client_time_zone = 2;
     */
    clientTimeZone: string;
}
/**
 * @generated from protobuf message rd.api.analytics.GetGrossProfitAndRevenueByLocationRes
 */
export interface GetGrossProfitAndRevenueByLocationRes {
    /**
     * @generated from protobuf field: map<string, rd.proto.money.Money> revenue_by_location = 1;
     */
    revenueByLocation: {
        [key: string]: Money;
    };
    /**
     * @generated from protobuf field: map<string, rd.proto.money.Money> gross_profit_by_location = 2;
     */
    grossProfitByLocation: {
        [key: string]: Money;
    };
    /**
     * @generated from protobuf field: map<string, int32> purchase_count_by_location = 3;
     */
    purchaseCountByLocation: {
        [key: string]: number;
    };
    /**
     * @generated from protobuf field: map<string, rd.proto.decimal.Decimal> gross_margin_percent_by_location = 4;
     */
    grossMarginPercentByLocation: {
        [key: string]: Decimal;
    };
}
// Inventory Valuation 

/**
 * @generated from protobuf message rd.api.analytics.GetInventoryValuationReq
 */
export interface GetInventoryValuationReq {
    /**
     * @generated from protobuf field: rd.analyticsv2.Filters filters = 5;
     */
    filters?: Filters;
}
/**
 * @generated from protobuf message rd.api.analytics.GetInventoryValuationRes
 */
export interface GetInventoryValuationRes {
    /**
     * @generated from protobuf field: rd.analytics.InventoryValuation inventory_valuation = 1;
     */
    inventoryValuation?: InventoryValuation;
}
/**
 * @generated from protobuf message rd.api.analytics.GetInventoryValuationPDFReq
 */
export interface GetInventoryValuationPDFReq {
    /**
     * @generated from protobuf field: rd.analyticsv2.Filters filters = 5;
     */
    filters?: Filters;
}
/**
 * @generated from protobuf message rd.api.analytics.GetInventoryValuationPDFRes
 */
export interface GetInventoryValuationPDFRes {
    /**
     * @generated from protobuf field: bytes bytes = 1;
     */
    bytes: Uint8Array;
}
/**
 * @generated from protobuf message rd.api.analytics.GetInventoryValuationCSVReq
 */
export interface GetInventoryValuationCSVReq {
    /**
     * @generated from protobuf field: rd.analyticsv2.Filters filters = 4;
     */
    filters?: Filters;
}
/**
 * @generated from protobuf message rd.api.analytics.GetInventoryValuationCSVRes
 */
export interface GetInventoryValuationCSVRes {
    /**
     * @generated from protobuf field: bytes bytes = 1;
     */
    bytes: Uint8Array;
}
// Customer Aging 

/**
 * @generated from protobuf message rd.api.analytics.GetCustomerAgingReq
 */
export interface GetCustomerAgingReq {
    /**
     * @generated from protobuf field: rd.analytics.CustomerAgingSortBy sort_by = 3;
     */
    sortBy: CustomerAgingSortBy;
    /**
     * @generated from protobuf field: rd.analytics.SortOrder sort_order = 4;
     */
    sortOrder: SortOrder;
    /**
     * @generated from protobuf field: rd.analyticsv2.Filters filters = 5;
     */
    filters?: Filters;
}
/**
 * @generated from protobuf message rd.api.analytics.GetCustomerAgingRes
 */
export interface GetCustomerAgingRes {
    /**
     * @generated from protobuf field: rd.analytics.CustomerAging customer_aging = 1;
     */
    customerAging?: CustomerAging;
}
/**
 * @generated from protobuf message rd.api.analytics.GetCustomerAgingPDFReq
 */
export interface GetCustomerAgingPDFReq {
    /**
     * @generated from protobuf field: rd.analytics.CustomerAgingSortBy sort_by = 3;
     */
    sortBy: CustomerAgingSortBy;
    /**
     * @generated from protobuf field: rd.analytics.SortOrder sort_order = 4;
     */
    sortOrder: SortOrder;
    /**
     * @generated from protobuf field: rd.analyticsv2.Filters filters = 6;
     */
    filters?: Filters;
}
/**
 * @generated from protobuf message rd.api.analytics.GetCustomerAgingPDFRes
 */
export interface GetCustomerAgingPDFRes {
    /**
     * @generated from protobuf field: bytes bytes = 1;
     */
    bytes: Uint8Array;
}
/**
 * @generated from protobuf message rd.api.analytics.GetCustomerAgingCSVReq
 */
export interface GetCustomerAgingCSVReq {
    /**
     * @generated from protobuf field: rd.analytics.CustomerAgingSortBy sort_by = 2;
     */
    sortBy: CustomerAgingSortBy;
    /**
     * @generated from protobuf field: rd.analytics.SortOrder sort_order = 3;
     */
    sortOrder: SortOrder;
    /**
     * @generated from protobuf field: rd.analyticsv2.Filters filters = 5;
     */
    filters?: Filters;
}
/**
 * @generated from protobuf message rd.api.analytics.GetCustomerAgingCSVRes
 */
export interface GetCustomerAgingCSVRes {
    /**
     * @generated from protobuf field: bytes bytes = 1;
     */
    bytes: Uint8Array;
}
// Journal Entries 

/**
 * @generated from protobuf message rd.api.analytics.GenerateJournalEntriesReq
 */
export interface GenerateJournalEntriesReq {
    /**
     * @generated from protobuf field: rd.analyticsv2.Filters filters = 4;
     */
    filters?: Filters;
}
/**
 * @generated from protobuf message rd.api.analytics.GenerateJournalEntriesRes
 */
export interface GenerateJournalEntriesRes {
}
/**
 * @generated from protobuf message rd.api.analytics.GenerateJournalEntriesPDFReq
 */
export interface GenerateJournalEntriesPDFReq {
}
/**
 * @generated from protobuf message rd.api.analytics.GenerateJournalEntriesPDFRes
 */
export interface GenerateJournalEntriesPDFRes {
}
/**
 * Tax Entries
 *
 * @generated from protobuf message rd.api.analytics.GetTaxEntriesReq
 */
export interface GetTaxEntriesReq {
    /**
     * @generated from protobuf field: rd.analyticsv2.Filters filters = 5;
     */
    filters?: Filters;
}
/**
 * @generated from protobuf message rd.api.analytics.GetTaxEntriesRes
 */
export interface GetTaxEntriesRes {
    /**
     * @generated from protobuf field: rd.analytics.TaxEntries tax_entries = 1;
     */
    taxEntries?: TaxEntries;
}
/**
 * @generated from protobuf message rd.api.analytics.GetTaxEntriesPDFReq
 */
export interface GetTaxEntriesPDFReq {
    /**
     * @generated from protobuf field: rd.analyticsv2.Filters filters = 5;
     */
    filters?: Filters;
}
/**
 * @generated from protobuf message rd.api.analytics.GetTaxEntriesPDFRes
 */
export interface GetTaxEntriesPDFRes {
    /**
     * @generated from protobuf field: bytes bytes = 1;
     */
    bytes: Uint8Array;
}
/**
 * @generated from protobuf message rd.api.analytics.GetTaxEntriesCSVReq
 */
export interface GetTaxEntriesCSVReq {
    /**
     * @generated from protobuf field: rd.analyticsv2.Filters filters = 4;
     */
    filters?: Filters;
}
/**
 * @generated from protobuf message rd.api.analytics.GetTaxEntriesCSVRes
 */
export interface GetTaxEntriesCSVRes {
    /**
     * @generated from protobuf field: bytes bytes = 1;
     */
    bytes: Uint8Array;
}
/**
 * Price Edits
 *
 * @generated from protobuf message rd.api.analytics.GetPriceEditsReq
 */
export interface GetPriceEditsReq {
    /**
     * @generated from protobuf field: rd.analytics.PriceEditsSortBy sort_by = 5;
     */
    sortBy: PriceEditsSortBy;
    /**
     * @generated from protobuf field: rd.analytics.SortOrder sort_order = 6;
     */
    sortOrder: SortOrder;
    /**
     * @generated from protobuf field: rd.analyticsv2.Filters filters = 7;
     */
    filters?: Filters;
}
/**
 * @generated from protobuf message rd.api.analytics.GetPriceEditsRes
 */
export interface GetPriceEditsRes {
    /**
     * @generated from protobuf field: rd.analytics.Report report = 2;
     */
    report?: Report;
}
/**
 * @generated from protobuf message rd.api.analytics.GetPriceEditsPDFReq
 */
export interface GetPriceEditsPDFReq {
    /**
     * @generated from protobuf field: rd.analytics.PriceEditsSortBy sort_by = 5;
     */
    sortBy: PriceEditsSortBy;
    /**
     * @generated from protobuf field: rd.analytics.SortOrder sort_order = 6;
     */
    sortOrder: SortOrder;
    /**
     * @generated from protobuf field: rd.analyticsv2.Filters filters = 7;
     */
    filters?: Filters;
}
/**
 * @generated from protobuf message rd.api.analytics.GetPriceEditsPDFRes
 */
export interface GetPriceEditsPDFRes {
    /**
     * @generated from protobuf field: bytes bytes = 1;
     */
    bytes: Uint8Array;
}
/**
 * @generated from protobuf message rd.api.analytics.GetPriceEditsCSVReq
 */
export interface GetPriceEditsCSVReq {
    /**
     * @generated from protobuf field: rd.analytics.PriceEditsSortBy sort_by = 5;
     */
    sortBy: PriceEditsSortBy;
    /**
     * @generated from protobuf field: rd.analytics.SortOrder sort_order = 6;
     */
    sortOrder: SortOrder;
    /**
     * @generated from protobuf field: rd.analyticsv2.Filters filters = 7;
     */
    filters?: Filters;
}
/**
 * @generated from protobuf message rd.api.analytics.GetPriceEditsCSVRes
 */
export interface GetPriceEditsCSVRes {
    /**
     * @generated from protobuf field: bytes bytes = 1;
     */
    bytes: Uint8Array;
}
/**
 * New Accounts Opened
 *
 * @generated from protobuf message rd.api.analytics.GetNewAccountsOpenedReq
 */
export interface GetNewAccountsOpenedReq {
    /**
     * @generated from protobuf field: rd.analytics.NewAccountsOpenedSortBy sort_by = 4;
     */
    sortBy: NewAccountsOpenedSortBy;
    /**
     * @generated from protobuf field: rd.analytics.SortOrder sort_order = 5;
     */
    sortOrder: SortOrder;
    /**
     * @generated from protobuf field: rd.analyticsv2.Filters filters = 6;
     */
    filters?: Filters;
}
/**
 * @generated from protobuf message rd.api.analytics.GetNewAccountsOpenedRes
 */
export interface GetNewAccountsOpenedRes {
    /**
     * @generated from protobuf field: rd.analytics.NewAccountsOpened new_accounts_opened = 1;
     */
    newAccountsOpened?: NewAccountsOpened;
}
/**
 * @generated from protobuf message rd.api.analytics.GetNewAccountsOpenedPDFReq
 */
export interface GetNewAccountsOpenedPDFReq {
    /**
     * @generated from protobuf field: rd.analytics.NewAccountsOpenedSortBy sort_by = 4;
     */
    sortBy: NewAccountsOpenedSortBy;
    /**
     * @generated from protobuf field: rd.analytics.SortOrder sort_order = 5;
     */
    sortOrder: SortOrder;
    /**
     * @generated from protobuf field: rd.analyticsv2.Filters filters = 6;
     */
    filters?: Filters;
}
/**
 * @generated from protobuf message rd.api.analytics.GetNewAccountsOpenedPDFRes
 */
export interface GetNewAccountsOpenedPDFRes {
    /**
     * @generated from protobuf field: bytes bytes = 1;
     */
    bytes: Uint8Array;
}
/**
 * @generated from protobuf message rd.api.analytics.GetNewAccountsOpenedCSVReq
 */
export interface GetNewAccountsOpenedCSVReq {
    /**
     * @generated from protobuf field: rd.analytics.NewAccountsOpenedSortBy sort_by = 4;
     */
    sortBy: NewAccountsOpenedSortBy;
    /**
     * @generated from protobuf field: rd.analytics.SortOrder sort_order = 5;
     */
    sortOrder: SortOrder;
    /**
     * @generated from protobuf field: rd.analyticsv2.Filters filters = 6;
     */
    filters?: Filters;
}
/**
 * @generated from protobuf message rd.api.analytics.GetNewAccountsOpenedCSVRes
 */
export interface GetNewAccountsOpenedCSVRes {
    /**
     * @generated from protobuf field: bytes bytes = 1;
     */
    bytes: Uint8Array;
}
/**
 * Sales Detail
 *
 * @generated from protobuf message rd.api.analytics.GetSalesDetailReq
 */
export interface GetSalesDetailReq {
    /**
     * @generated from protobuf field: rd.analytics.SalesDetailSortBy sort_by = 5;
     */
    sortBy: SalesDetailSortBy;
    /**
     * @generated from protobuf field: rd.analytics.SortOrder sort_order = 6;
     */
    sortOrder: SortOrder;
    /**
     * @generated from protobuf field: rd.analyticsv2.Filters filters = 13;
     */
    filters?: Filters;
}
/**
 * @generated from protobuf message rd.api.analytics.GetSalesDetailRes
 */
export interface GetSalesDetailRes {
    /**
     * @generated from protobuf field: rd.analytics.SalesDetail sales_detail = 1;
     */
    salesDetail?: SalesDetail;
}
/**
 * @generated from protobuf message rd.api.analytics.GetSalesDetailCSVReq
 */
export interface GetSalesDetailCSVReq {
    /**
     * @generated from protobuf field: rd.analytics.SalesDetailSortBy sort_by = 5;
     */
    sortBy: SalesDetailSortBy;
    /**
     * @generated from protobuf field: rd.analytics.SortOrder sort_order = 6;
     */
    sortOrder: SortOrder;
    /**
     * @generated from protobuf field: rd.analyticsv2.Filters filters = 13;
     */
    filters?: Filters;
}
/**
 * @generated from protobuf message rd.api.analytics.GetSalesDetailCSVRes
 */
export interface GetSalesDetailCSVRes {
    /**
     * @generated from protobuf field: bytes bytes = 1;
     */
    bytes: Uint8Array;
}
/**
 * Sales by Day
 *
 * @generated from protobuf message rd.api.analytics.GetSalesByDayReq
 */
export interface GetSalesByDayReq {
    /**
     * @generated from protobuf field: rd.analyticsv2.SalesByDaySortBy sort_by = 5;
     */
    sortBy: SalesByDaySortBy;
    /**
     * @generated from protobuf field: rd.analyticsv2.SortOrder sort_order = 6;
     */
    sortOrder: SortOrder$;
    /**
     * @generated from protobuf field: rd.analyticsv2.Filters filters = 13;
     */
    filters?: Filters;
}
/**
 * @generated from protobuf message rd.api.analytics.GetSalesByDayRes
 */
export interface GetSalesByDayRes {
    /**
     * @generated from protobuf field: rd.analytics.Report report = 1;
     */
    report?: Report;
}
/**
 * @generated from protobuf message rd.api.analytics.GetSalesByDayPDFReq
 */
export interface GetSalesByDayPDFReq {
    /**
     * @generated from protobuf field: rd.analyticsv2.SalesByDaySortBy sort_by = 5;
     */
    sortBy: SalesByDaySortBy;
    /**
     * @generated from protobuf field: rd.analyticsv2.SortOrder sort_order = 6;
     */
    sortOrder: SortOrder$;
    /**
     * @generated from protobuf field: rd.analyticsv2.Filters filters = 13;
     */
    filters?: Filters;
}
/**
 * @generated from protobuf message rd.api.analytics.GetSalesByDayPDFRes
 */
export interface GetSalesByDayPDFRes {
    /**
     * @generated from protobuf field: bytes bytes = 1;
     */
    bytes: Uint8Array;
}
/**
 * @generated from protobuf message rd.api.analytics.GetSalesByDayCSVReq
 */
export interface GetSalesByDayCSVReq {
    /**
     * @generated from protobuf field: rd.analyticsv2.SalesByDaySortBy sort_by = 5;
     */
    sortBy: SalesByDaySortBy;
    /**
     * @generated from protobuf field: rd.analyticsv2.SortOrder sort_order = 6;
     */
    sortOrder: SortOrder$;
    /**
     * @generated from protobuf field: rd.analyticsv2.Filters filters = 13;
     */
    filters?: Filters;
}
/**
 * @generated from protobuf message rd.api.analytics.GetSalesByDayCSVRes
 */
export interface GetSalesByDayCSVRes {
    /**
     * @generated from protobuf field: bytes bytes = 1;
     */
    bytes: Uint8Array;
}
/**
 * Sales by Customer
 *
 * @generated from protobuf message rd.api.analytics.GetSalesByCustomerReq
 */
export interface GetSalesByCustomerReq {
    /**
     * @generated from protobuf field: rd.analyticsv2.SalesByCustomerSortBy sort_by = 1;
     */
    sortBy: SalesByCustomerSortBy;
    /**
     * @generated from protobuf field: rd.analyticsv2.SortOrder sort_order = 2;
     */
    sortOrder: SortOrder$;
    /**
     * @generated from protobuf field: rd.analyticsv2.Filters filters = 3;
     */
    filters?: Filters;
}
/**
 * @generated from protobuf message rd.api.analytics.GetSalesByCustomerRes
 */
export interface GetSalesByCustomerRes {
    /**
     * @generated from protobuf field: rd.analytics.Report report = 1;
     */
    report?: Report;
}
/**
 * @generated from protobuf message rd.api.analytics.GetSalesByCustomerPDFReq
 */
export interface GetSalesByCustomerPDFReq {
    /**
     * @generated from protobuf field: rd.analyticsv2.SalesByCustomerSortBy sort_by = 1;
     */
    sortBy: SalesByCustomerSortBy;
    /**
     * @generated from protobuf field: rd.analyticsv2.SortOrder sort_order = 2;
     */
    sortOrder: SortOrder$;
    /**
     * @generated from protobuf field: rd.analyticsv2.Filters filters = 3;
     */
    filters?: Filters;
}
/**
 * @generated from protobuf message rd.api.analytics.GetSalesByCustomerPDFRes
 */
export interface GetSalesByCustomerPDFRes {
    /**
     * @generated from protobuf field: bytes bytes = 1;
     */
    bytes: Uint8Array;
}
/**
 * @generated from protobuf message rd.api.analytics.GetSalesByCustomerCSVReq
 */
export interface GetSalesByCustomerCSVReq {
    /**
     * @generated from protobuf field: rd.analyticsv2.SalesByCustomerSortBy sort_by = 1;
     */
    sortBy: SalesByCustomerSortBy;
    /**
     * @generated from protobuf field: rd.analyticsv2.SortOrder sort_order = 2;
     */
    sortOrder: SortOrder$;
    /**
     * @generated from protobuf field: rd.analyticsv2.Filters filters = 3;
     */
    filters?: Filters;
}
/**
 * @generated from protobuf message rd.api.analytics.GetSalesByCustomerCSVRes
 */
export interface GetSalesByCustomerCSVRes {
    /**
     * @generated from protobuf field: bytes bytes = 1;
     */
    bytes: Uint8Array;
}
/**
 * Sales by Clerk
 *
 * @generated from protobuf message rd.api.analytics.GetSalesByClerkReq
 */
export interface GetSalesByClerkReq {
    /**
     * @generated from protobuf field: rd.analytics.SalesByClerkSortBy sort_by = 5;
     */
    sortBy: SalesByClerkSortBy;
    /**
     * @generated from protobuf field: rd.analytics.SortOrder sort_order = 6;
     */
    sortOrder: SortOrder;
    /**
     * @generated from protobuf field: rd.analyticsv2.Filters filters = 7;
     */
    filters?: Filters;
}
/**
 * @generated from protobuf message rd.api.analytics.GetSalesByClerkRes
 */
export interface GetSalesByClerkRes {
    /**
     * @generated from protobuf field: rd.analytics.Report report = 1;
     */
    report?: Report;
}
/**
 * @generated from protobuf message rd.api.analytics.GetSalesByClerkPDFReq
 */
export interface GetSalesByClerkPDFReq {
    /**
     * @generated from protobuf field: rd.analytics.SalesByClerkSortBy sort_by = 5;
     */
    sortBy: SalesByClerkSortBy;
    /**
     * @generated from protobuf field: rd.analytics.SortOrder sort_order = 6;
     */
    sortOrder: SortOrder;
    /**
     * @generated from protobuf field: rd.analyticsv2.Filters filters = 7;
     */
    filters?: Filters;
}
/**
 * @generated from protobuf message rd.api.analytics.GetSalesByClerkPDFRes
 */
export interface GetSalesByClerkPDFRes {
    /**
     * @generated from protobuf field: bytes bytes = 1;
     */
    bytes: Uint8Array;
}
/**
 * @generated from protobuf message rd.api.analytics.GetSalesByClerkCSVReq
 */
export interface GetSalesByClerkCSVReq {
    /**
     * @generated from protobuf field: rd.analytics.SalesByClerkSortBy sort_by = 5;
     */
    sortBy: SalesByClerkSortBy;
    /**
     * @generated from protobuf field: rd.analytics.SortOrder sort_order = 6;
     */
    sortOrder: SortOrder;
    /**
     * @generated from protobuf field: rd.analyticsv2.Filters filters = 7;
     */
    filters?: Filters;
}
/**
 * @generated from protobuf message rd.api.analytics.GetSalesByClerkCSVRes
 */
export interface GetSalesByClerkCSVRes {
    /**
     * @generated from protobuf field: bytes bytes = 1;
     */
    bytes: Uint8Array;
}
/**
 * @generated from protobuf message rd.api.analytics.GetWriteOffsByDayReq
 */
export interface GetWriteOffsByDayReq {
    /**
     * @generated from protobuf field: rd.analyticsv2.WriteOffsByDaySortBy sort_by = 1;
     */
    sortBy: WriteOffsByDaySortBy;
    /**
     * @generated from protobuf field: rd.analyticsv2.SortOrder sort_order = 2;
     */
    sortOrder: SortOrder$;
    /**
     * @generated from protobuf field: rd.analyticsv2.Filters filters = 3;
     */
    filters?: Filters;
}
/**
 * @generated from protobuf message rd.api.analytics.GetWriteOffsByDayRes
 */
export interface GetWriteOffsByDayRes {
    /**
     * @generated from protobuf field: rd.analytics.Report report = 1;
     */
    report?: Report;
}
/**
 * @generated from protobuf message rd.api.analytics.GetWriteOffsByDayPDFReq
 */
export interface GetWriteOffsByDayPDFReq {
    /**
     * @generated from protobuf field: rd.analyticsv2.WriteOffsByDaySortBy sort_by = 1;
     */
    sortBy: WriteOffsByDaySortBy;
    /**
     * @generated from protobuf field: rd.analyticsv2.SortOrder sort_order = 2;
     */
    sortOrder: SortOrder$;
    /**
     * @generated from protobuf field: rd.analyticsv2.Filters filters = 3;
     */
    filters?: Filters;
}
/**
 * @generated from protobuf message rd.api.analytics.GetWriteOffsByDayPDFRes
 */
export interface GetWriteOffsByDayPDFRes {
    /**
     * @generated from protobuf field: bytes bytes = 1;
     */
    bytes: Uint8Array;
}
/**
 * @generated from protobuf message rd.api.analytics.GetWriteOffsByDayCSVReq
 */
export interface GetWriteOffsByDayCSVReq {
    /**
     * @generated from protobuf field: rd.analyticsv2.WriteOffsByDaySortBy sort_by = 1;
     */
    sortBy: WriteOffsByDaySortBy;
    /**
     * @generated from protobuf field: rd.analyticsv2.SortOrder sort_order = 2;
     */
    sortOrder: SortOrder$;
    /**
     * @generated from protobuf field: rd.analyticsv2.Filters filters = 3;
     */
    filters?: Filters;
}
/**
 * @generated from protobuf message rd.api.analytics.GetWriteOffsByDayCSVRes
 */
export interface GetWriteOffsByDayCSVRes {
    /**
     * @generated from protobuf field: bytes bytes = 1;
     */
    bytes: Uint8Array;
}
// @generated message type with reflection information, may provide speed optimized methods
class GetProfitAndLossPDFReq$Type extends MessageType<GetProfitAndLossPDFReq> {
    constructor() {
        super("rd.api.analytics.GetProfitAndLossPDFReq", [
            { no: 2, name: "location_id", kind: "message", T: () => UUID },
            { no: 3, name: "timestamp_range", kind: "message", T: () => TimestampRange, options: { "rd.validator.require": true } },
            { no: 4, name: "client_time_zone", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "rd.validator.require": true } },
            { no: 5, name: "customer_id", kind: "message", T: () => UUID },
            { no: 6, name: "rep_staff_id", kind: "message", T: () => UUID },
            { no: 7, name: "hide_product_categories", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<GetProfitAndLossPDFReq>): GetProfitAndLossPDFReq {
        const message = { clientTimeZone: "", hideProductCategories: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetProfitAndLossPDFReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetProfitAndLossPDFReq): GetProfitAndLossPDFReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID location_id */ 2:
                    message.locationId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.locationId);
                    break;
                case /* rd.proto.timestamprange.TimestampRange timestamp_range */ 3:
                    message.timestampRange = TimestampRange.internalBinaryRead(reader, reader.uint32(), options, message.timestampRange);
                    break;
                case /* string client_time_zone */ 4:
                    message.clientTimeZone = reader.string();
                    break;
                case /* rd.proto.uuid.UUID customer_id */ 5:
                    message.customerId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.customerId);
                    break;
                case /* rd.proto.uuid.UUID rep_staff_id */ 6:
                    message.repStaffId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.repStaffId);
                    break;
                case /* bool hide_product_categories */ 7:
                    message.hideProductCategories = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetProfitAndLossPDFReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID location_id = 2; */
        if (message.locationId)
            UUID.internalBinaryWrite(message.locationId, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.timestamprange.TimestampRange timestamp_range = 3; */
        if (message.timestampRange)
            TimestampRange.internalBinaryWrite(message.timestampRange, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* string client_time_zone = 4; */
        if (message.clientTimeZone !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.clientTimeZone);
        /* rd.proto.uuid.UUID customer_id = 5; */
        if (message.customerId)
            UUID.internalBinaryWrite(message.customerId, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID rep_staff_id = 6; */
        if (message.repStaffId)
            UUID.internalBinaryWrite(message.repStaffId, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* bool hide_product_categories = 7; */
        if (message.hideProductCategories !== false)
            writer.tag(7, WireType.Varint).bool(message.hideProductCategories);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.analytics.GetProfitAndLossPDFReq
 */
export const GetProfitAndLossPDFReq = new GetProfitAndLossPDFReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetProfitAndLossPDFRes$Type extends MessageType<GetProfitAndLossPDFRes> {
    constructor() {
        super("rd.api.analytics.GetProfitAndLossPDFRes", [
            { no: 1, name: "bytes", kind: "scalar", T: 12 /*ScalarType.BYTES*/ }
        ]);
    }
    create(value?: PartialMessage<GetProfitAndLossPDFRes>): GetProfitAndLossPDFRes {
        const message = { bytes: new Uint8Array(0) };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetProfitAndLossPDFRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetProfitAndLossPDFRes): GetProfitAndLossPDFRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bytes bytes */ 1:
                    message.bytes = reader.bytes();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetProfitAndLossPDFRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bytes bytes = 1; */
        if (message.bytes.length)
            writer.tag(1, WireType.LengthDelimited).bytes(message.bytes);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.analytics.GetProfitAndLossPDFRes
 */
export const GetProfitAndLossPDFRes = new GetProfitAndLossPDFRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetProfitAndLossReq$Type extends MessageType<GetProfitAndLossReq> {
    constructor() {
        super("rd.api.analytics.GetProfitAndLossReq", [
            { no: 2, name: "location_id", kind: "message", T: () => UUID },
            { no: 3, name: "timestamp_range", kind: "message", T: () => TimestampRange, options: { "rd.validator.require": true } },
            { no: 4, name: "client_time_zone", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "rd.validator.require": true } },
            { no: 5, name: "customer_id", kind: "message", T: () => UUID },
            { no: 6, name: "rep_staff_id", kind: "message", T: () => UUID }
        ]);
    }
    create(value?: PartialMessage<GetProfitAndLossReq>): GetProfitAndLossReq {
        const message = { clientTimeZone: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetProfitAndLossReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetProfitAndLossReq): GetProfitAndLossReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID location_id */ 2:
                    message.locationId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.locationId);
                    break;
                case /* rd.proto.timestamprange.TimestampRange timestamp_range */ 3:
                    message.timestampRange = TimestampRange.internalBinaryRead(reader, reader.uint32(), options, message.timestampRange);
                    break;
                case /* string client_time_zone */ 4:
                    message.clientTimeZone = reader.string();
                    break;
                case /* rd.proto.uuid.UUID customer_id */ 5:
                    message.customerId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.customerId);
                    break;
                case /* rd.proto.uuid.UUID rep_staff_id */ 6:
                    message.repStaffId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.repStaffId);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetProfitAndLossReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID location_id = 2; */
        if (message.locationId)
            UUID.internalBinaryWrite(message.locationId, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.timestamprange.TimestampRange timestamp_range = 3; */
        if (message.timestampRange)
            TimestampRange.internalBinaryWrite(message.timestampRange, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* string client_time_zone = 4; */
        if (message.clientTimeZone !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.clientTimeZone);
        /* rd.proto.uuid.UUID customer_id = 5; */
        if (message.customerId)
            UUID.internalBinaryWrite(message.customerId, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID rep_staff_id = 6; */
        if (message.repStaffId)
            UUID.internalBinaryWrite(message.repStaffId, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.analytics.GetProfitAndLossReq
 */
export const GetProfitAndLossReq = new GetProfitAndLossReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetProfitAndLossRes$Type extends MessageType<GetProfitAndLossRes> {
    constructor() {
        super("rd.api.analytics.GetProfitAndLossRes", [
            { no: 1, name: "profit_and_loss", kind: "message", T: () => ProfitAndLoss }
        ]);
    }
    create(value?: PartialMessage<GetProfitAndLossRes>): GetProfitAndLossRes {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetProfitAndLossRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetProfitAndLossRes): GetProfitAndLossRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.analytics.ProfitAndLoss profit_and_loss */ 1:
                    message.profitAndLoss = ProfitAndLoss.internalBinaryRead(reader, reader.uint32(), options, message.profitAndLoss);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetProfitAndLossRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.analytics.ProfitAndLoss profit_and_loss = 1; */
        if (message.profitAndLoss)
            ProfitAndLoss.internalBinaryWrite(message.profitAndLoss, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.analytics.GetProfitAndLossRes
 */
export const GetProfitAndLossRes = new GetProfitAndLossRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetGrossProfitAndRevenueByLocationReq$Type extends MessageType<GetGrossProfitAndRevenueByLocationReq> {
    constructor() {
        super("rd.api.analytics.GetGrossProfitAndRevenueByLocationReq", [
            { no: 1, name: "timestamp_range", kind: "message", T: () => TimestampRange, options: { "rd.validator.require": true } },
            { no: 2, name: "client_time_zone", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "rd.validator.require": true } }
        ]);
    }
    create(value?: PartialMessage<GetGrossProfitAndRevenueByLocationReq>): GetGrossProfitAndRevenueByLocationReq {
        const message = { clientTimeZone: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetGrossProfitAndRevenueByLocationReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetGrossProfitAndRevenueByLocationReq): GetGrossProfitAndRevenueByLocationReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.timestamprange.TimestampRange timestamp_range */ 1:
                    message.timestampRange = TimestampRange.internalBinaryRead(reader, reader.uint32(), options, message.timestampRange);
                    break;
                case /* string client_time_zone */ 2:
                    message.clientTimeZone = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetGrossProfitAndRevenueByLocationReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.timestamprange.TimestampRange timestamp_range = 1; */
        if (message.timestampRange)
            TimestampRange.internalBinaryWrite(message.timestampRange, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string client_time_zone = 2; */
        if (message.clientTimeZone !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.clientTimeZone);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.analytics.GetGrossProfitAndRevenueByLocationReq
 */
export const GetGrossProfitAndRevenueByLocationReq = new GetGrossProfitAndRevenueByLocationReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetGrossProfitAndRevenueByLocationRes$Type extends MessageType<GetGrossProfitAndRevenueByLocationRes> {
    constructor() {
        super("rd.api.analytics.GetGrossProfitAndRevenueByLocationRes", [
            { no: 1, name: "revenue_by_location", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => Money } },
            { no: 2, name: "gross_profit_by_location", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => Money } },
            { no: 3, name: "purchase_count_by_location", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "scalar", T: 5 /*ScalarType.INT32*/ } },
            { no: 4, name: "gross_margin_percent_by_location", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => Decimal } }
        ]);
    }
    create(value?: PartialMessage<GetGrossProfitAndRevenueByLocationRes>): GetGrossProfitAndRevenueByLocationRes {
        const message = { revenueByLocation: {}, grossProfitByLocation: {}, purchaseCountByLocation: {}, grossMarginPercentByLocation: {} };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetGrossProfitAndRevenueByLocationRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetGrossProfitAndRevenueByLocationRes): GetGrossProfitAndRevenueByLocationRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* map<string, rd.proto.money.Money> revenue_by_location */ 1:
                    this.binaryReadMap1(message.revenueByLocation, reader, options);
                    break;
                case /* map<string, rd.proto.money.Money> gross_profit_by_location */ 2:
                    this.binaryReadMap2(message.grossProfitByLocation, reader, options);
                    break;
                case /* map<string, int32> purchase_count_by_location */ 3:
                    this.binaryReadMap3(message.purchaseCountByLocation, reader, options);
                    break;
                case /* map<string, rd.proto.decimal.Decimal> gross_margin_percent_by_location */ 4:
                    this.binaryReadMap4(message.grossMarginPercentByLocation, reader, options);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap1(map: GetGrossProfitAndRevenueByLocationRes["revenueByLocation"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof GetGrossProfitAndRevenueByLocationRes["revenueByLocation"] | undefined, val: GetGrossProfitAndRevenueByLocationRes["revenueByLocation"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = Money.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.api.analytics.GetGrossProfitAndRevenueByLocationRes.revenue_by_location");
            }
        }
        map[key ?? ""] = val ?? Money.create();
    }
    private binaryReadMap2(map: GetGrossProfitAndRevenueByLocationRes["grossProfitByLocation"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof GetGrossProfitAndRevenueByLocationRes["grossProfitByLocation"] | undefined, val: GetGrossProfitAndRevenueByLocationRes["grossProfitByLocation"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = Money.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.api.analytics.GetGrossProfitAndRevenueByLocationRes.gross_profit_by_location");
            }
        }
        map[key ?? ""] = val ?? Money.create();
    }
    private binaryReadMap3(map: GetGrossProfitAndRevenueByLocationRes["purchaseCountByLocation"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof GetGrossProfitAndRevenueByLocationRes["purchaseCountByLocation"] | undefined, val: GetGrossProfitAndRevenueByLocationRes["purchaseCountByLocation"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = reader.int32();
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.api.analytics.GetGrossProfitAndRevenueByLocationRes.purchase_count_by_location");
            }
        }
        map[key ?? ""] = val ?? 0;
    }
    private binaryReadMap4(map: GetGrossProfitAndRevenueByLocationRes["grossMarginPercentByLocation"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof GetGrossProfitAndRevenueByLocationRes["grossMarginPercentByLocation"] | undefined, val: GetGrossProfitAndRevenueByLocationRes["grossMarginPercentByLocation"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = Decimal.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.api.analytics.GetGrossProfitAndRevenueByLocationRes.gross_margin_percent_by_location");
            }
        }
        map[key ?? ""] = val ?? Decimal.create();
    }
    internalBinaryWrite(message: GetGrossProfitAndRevenueByLocationRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* map<string, rd.proto.money.Money> revenue_by_location = 1; */
        for (let k of Object.keys(message.revenueByLocation)) {
            writer.tag(1, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            Money.internalBinaryWrite(message.revenueByLocation[k], writer, options);
            writer.join().join();
        }
        /* map<string, rd.proto.money.Money> gross_profit_by_location = 2; */
        for (let k of Object.keys(message.grossProfitByLocation)) {
            writer.tag(2, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            Money.internalBinaryWrite(message.grossProfitByLocation[k], writer, options);
            writer.join().join();
        }
        /* map<string, int32> purchase_count_by_location = 3; */
        for (let k of Object.keys(message.purchaseCountByLocation))
            writer.tag(3, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k).tag(2, WireType.Varint).int32(message.purchaseCountByLocation[k]).join();
        /* map<string, rd.proto.decimal.Decimal> gross_margin_percent_by_location = 4; */
        for (let k of Object.keys(message.grossMarginPercentByLocation)) {
            writer.tag(4, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            Decimal.internalBinaryWrite(message.grossMarginPercentByLocation[k], writer, options);
            writer.join().join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.analytics.GetGrossProfitAndRevenueByLocationRes
 */
export const GetGrossProfitAndRevenueByLocationRes = new GetGrossProfitAndRevenueByLocationRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetInventoryValuationReq$Type extends MessageType<GetInventoryValuationReq> {
    constructor() {
        super("rd.api.analytics.GetInventoryValuationReq", [
            { no: 5, name: "filters", kind: "message", T: () => Filters, options: { "rd.validator.require": true } }
        ]);
    }
    create(value?: PartialMessage<GetInventoryValuationReq>): GetInventoryValuationReq {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetInventoryValuationReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetInventoryValuationReq): GetInventoryValuationReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.analyticsv2.Filters filters */ 5:
                    message.filters = Filters.internalBinaryRead(reader, reader.uint32(), options, message.filters);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetInventoryValuationReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.analyticsv2.Filters filters = 5; */
        if (message.filters)
            Filters.internalBinaryWrite(message.filters, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.analytics.GetInventoryValuationReq
 */
export const GetInventoryValuationReq = new GetInventoryValuationReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetInventoryValuationRes$Type extends MessageType<GetInventoryValuationRes> {
    constructor() {
        super("rd.api.analytics.GetInventoryValuationRes", [
            { no: 1, name: "inventory_valuation", kind: "message", T: () => InventoryValuation }
        ]);
    }
    create(value?: PartialMessage<GetInventoryValuationRes>): GetInventoryValuationRes {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetInventoryValuationRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetInventoryValuationRes): GetInventoryValuationRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.analytics.InventoryValuation inventory_valuation */ 1:
                    message.inventoryValuation = InventoryValuation.internalBinaryRead(reader, reader.uint32(), options, message.inventoryValuation);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetInventoryValuationRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.analytics.InventoryValuation inventory_valuation = 1; */
        if (message.inventoryValuation)
            InventoryValuation.internalBinaryWrite(message.inventoryValuation, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.analytics.GetInventoryValuationRes
 */
export const GetInventoryValuationRes = new GetInventoryValuationRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetInventoryValuationPDFReq$Type extends MessageType<GetInventoryValuationPDFReq> {
    constructor() {
        super("rd.api.analytics.GetInventoryValuationPDFReq", [
            { no: 5, name: "filters", kind: "message", T: () => Filters, options: { "rd.validator.require": true } }
        ]);
    }
    create(value?: PartialMessage<GetInventoryValuationPDFReq>): GetInventoryValuationPDFReq {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetInventoryValuationPDFReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetInventoryValuationPDFReq): GetInventoryValuationPDFReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.analyticsv2.Filters filters */ 5:
                    message.filters = Filters.internalBinaryRead(reader, reader.uint32(), options, message.filters);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetInventoryValuationPDFReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.analyticsv2.Filters filters = 5; */
        if (message.filters)
            Filters.internalBinaryWrite(message.filters, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.analytics.GetInventoryValuationPDFReq
 */
export const GetInventoryValuationPDFReq = new GetInventoryValuationPDFReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetInventoryValuationPDFRes$Type extends MessageType<GetInventoryValuationPDFRes> {
    constructor() {
        super("rd.api.analytics.GetInventoryValuationPDFRes", [
            { no: 1, name: "bytes", kind: "scalar", T: 12 /*ScalarType.BYTES*/ }
        ]);
    }
    create(value?: PartialMessage<GetInventoryValuationPDFRes>): GetInventoryValuationPDFRes {
        const message = { bytes: new Uint8Array(0) };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetInventoryValuationPDFRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetInventoryValuationPDFRes): GetInventoryValuationPDFRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bytes bytes */ 1:
                    message.bytes = reader.bytes();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetInventoryValuationPDFRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bytes bytes = 1; */
        if (message.bytes.length)
            writer.tag(1, WireType.LengthDelimited).bytes(message.bytes);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.analytics.GetInventoryValuationPDFRes
 */
export const GetInventoryValuationPDFRes = new GetInventoryValuationPDFRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetInventoryValuationCSVReq$Type extends MessageType<GetInventoryValuationCSVReq> {
    constructor() {
        super("rd.api.analytics.GetInventoryValuationCSVReq", [
            { no: 4, name: "filters", kind: "message", T: () => Filters, options: { "rd.validator.require": true } }
        ]);
    }
    create(value?: PartialMessage<GetInventoryValuationCSVReq>): GetInventoryValuationCSVReq {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetInventoryValuationCSVReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetInventoryValuationCSVReq): GetInventoryValuationCSVReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.analyticsv2.Filters filters */ 4:
                    message.filters = Filters.internalBinaryRead(reader, reader.uint32(), options, message.filters);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetInventoryValuationCSVReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.analyticsv2.Filters filters = 4; */
        if (message.filters)
            Filters.internalBinaryWrite(message.filters, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.analytics.GetInventoryValuationCSVReq
 */
export const GetInventoryValuationCSVReq = new GetInventoryValuationCSVReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetInventoryValuationCSVRes$Type extends MessageType<GetInventoryValuationCSVRes> {
    constructor() {
        super("rd.api.analytics.GetInventoryValuationCSVRes", [
            { no: 1, name: "bytes", kind: "scalar", T: 12 /*ScalarType.BYTES*/ }
        ]);
    }
    create(value?: PartialMessage<GetInventoryValuationCSVRes>): GetInventoryValuationCSVRes {
        const message = { bytes: new Uint8Array(0) };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetInventoryValuationCSVRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetInventoryValuationCSVRes): GetInventoryValuationCSVRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bytes bytes */ 1:
                    message.bytes = reader.bytes();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetInventoryValuationCSVRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bytes bytes = 1; */
        if (message.bytes.length)
            writer.tag(1, WireType.LengthDelimited).bytes(message.bytes);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.analytics.GetInventoryValuationCSVRes
 */
export const GetInventoryValuationCSVRes = new GetInventoryValuationCSVRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetCustomerAgingReq$Type extends MessageType<GetCustomerAgingReq> {
    constructor() {
        super("rd.api.analytics.GetCustomerAgingReq", [
            { no: 3, name: "sort_by", kind: "enum", T: () => ["rd.analytics.CustomerAgingSortBy", CustomerAgingSortBy, "CUSTOMER_AGING_SORT_BY_"], options: { "rd.validator.require": true } },
            { no: 4, name: "sort_order", kind: "enum", T: () => ["rd.analytics.SortOrder", SortOrder, "SORT_ORDER_"], options: { "rd.validator.require": true } },
            { no: 5, name: "filters", kind: "message", T: () => Filters, options: { "rd.validator.require": true } }
        ]);
    }
    create(value?: PartialMessage<GetCustomerAgingReq>): GetCustomerAgingReq {
        const message = { sortBy: 0, sortOrder: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetCustomerAgingReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetCustomerAgingReq): GetCustomerAgingReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.analytics.CustomerAgingSortBy sort_by */ 3:
                    message.sortBy = reader.int32();
                    break;
                case /* rd.analytics.SortOrder sort_order */ 4:
                    message.sortOrder = reader.int32();
                    break;
                case /* rd.analyticsv2.Filters filters */ 5:
                    message.filters = Filters.internalBinaryRead(reader, reader.uint32(), options, message.filters);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetCustomerAgingReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.analytics.CustomerAgingSortBy sort_by = 3; */
        if (message.sortBy !== 0)
            writer.tag(3, WireType.Varint).int32(message.sortBy);
        /* rd.analytics.SortOrder sort_order = 4; */
        if (message.sortOrder !== 0)
            writer.tag(4, WireType.Varint).int32(message.sortOrder);
        /* rd.analyticsv2.Filters filters = 5; */
        if (message.filters)
            Filters.internalBinaryWrite(message.filters, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.analytics.GetCustomerAgingReq
 */
export const GetCustomerAgingReq = new GetCustomerAgingReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetCustomerAgingRes$Type extends MessageType<GetCustomerAgingRes> {
    constructor() {
        super("rd.api.analytics.GetCustomerAgingRes", [
            { no: 1, name: "customer_aging", kind: "message", T: () => CustomerAging }
        ]);
    }
    create(value?: PartialMessage<GetCustomerAgingRes>): GetCustomerAgingRes {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetCustomerAgingRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetCustomerAgingRes): GetCustomerAgingRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.analytics.CustomerAging customer_aging */ 1:
                    message.customerAging = CustomerAging.internalBinaryRead(reader, reader.uint32(), options, message.customerAging);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetCustomerAgingRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.analytics.CustomerAging customer_aging = 1; */
        if (message.customerAging)
            CustomerAging.internalBinaryWrite(message.customerAging, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.analytics.GetCustomerAgingRes
 */
export const GetCustomerAgingRes = new GetCustomerAgingRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetCustomerAgingPDFReq$Type extends MessageType<GetCustomerAgingPDFReq> {
    constructor() {
        super("rd.api.analytics.GetCustomerAgingPDFReq", [
            { no: 3, name: "sort_by", kind: "enum", T: () => ["rd.analytics.CustomerAgingSortBy", CustomerAgingSortBy, "CUSTOMER_AGING_SORT_BY_"], options: { "rd.validator.require": true } },
            { no: 4, name: "sort_order", kind: "enum", T: () => ["rd.analytics.SortOrder", SortOrder, "SORT_ORDER_"], options: { "rd.validator.require": true } },
            { no: 6, name: "filters", kind: "message", T: () => Filters, options: { "rd.validator.require": true } }
        ]);
    }
    create(value?: PartialMessage<GetCustomerAgingPDFReq>): GetCustomerAgingPDFReq {
        const message = { sortBy: 0, sortOrder: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetCustomerAgingPDFReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetCustomerAgingPDFReq): GetCustomerAgingPDFReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.analytics.CustomerAgingSortBy sort_by */ 3:
                    message.sortBy = reader.int32();
                    break;
                case /* rd.analytics.SortOrder sort_order */ 4:
                    message.sortOrder = reader.int32();
                    break;
                case /* rd.analyticsv2.Filters filters */ 6:
                    message.filters = Filters.internalBinaryRead(reader, reader.uint32(), options, message.filters);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetCustomerAgingPDFReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.analytics.CustomerAgingSortBy sort_by = 3; */
        if (message.sortBy !== 0)
            writer.tag(3, WireType.Varint).int32(message.sortBy);
        /* rd.analytics.SortOrder sort_order = 4; */
        if (message.sortOrder !== 0)
            writer.tag(4, WireType.Varint).int32(message.sortOrder);
        /* rd.analyticsv2.Filters filters = 6; */
        if (message.filters)
            Filters.internalBinaryWrite(message.filters, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.analytics.GetCustomerAgingPDFReq
 */
export const GetCustomerAgingPDFReq = new GetCustomerAgingPDFReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetCustomerAgingPDFRes$Type extends MessageType<GetCustomerAgingPDFRes> {
    constructor() {
        super("rd.api.analytics.GetCustomerAgingPDFRes", [
            { no: 1, name: "bytes", kind: "scalar", T: 12 /*ScalarType.BYTES*/ }
        ]);
    }
    create(value?: PartialMessage<GetCustomerAgingPDFRes>): GetCustomerAgingPDFRes {
        const message = { bytes: new Uint8Array(0) };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetCustomerAgingPDFRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetCustomerAgingPDFRes): GetCustomerAgingPDFRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bytes bytes */ 1:
                    message.bytes = reader.bytes();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetCustomerAgingPDFRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bytes bytes = 1; */
        if (message.bytes.length)
            writer.tag(1, WireType.LengthDelimited).bytes(message.bytes);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.analytics.GetCustomerAgingPDFRes
 */
export const GetCustomerAgingPDFRes = new GetCustomerAgingPDFRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetCustomerAgingCSVReq$Type extends MessageType<GetCustomerAgingCSVReq> {
    constructor() {
        super("rd.api.analytics.GetCustomerAgingCSVReq", [
            { no: 2, name: "sort_by", kind: "enum", T: () => ["rd.analytics.CustomerAgingSortBy", CustomerAgingSortBy, "CUSTOMER_AGING_SORT_BY_"], options: { "rd.validator.require": true } },
            { no: 3, name: "sort_order", kind: "enum", T: () => ["rd.analytics.SortOrder", SortOrder, "SORT_ORDER_"], options: { "rd.validator.require": true } },
            { no: 5, name: "filters", kind: "message", T: () => Filters, options: { "rd.validator.require": true } }
        ]);
    }
    create(value?: PartialMessage<GetCustomerAgingCSVReq>): GetCustomerAgingCSVReq {
        const message = { sortBy: 0, sortOrder: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetCustomerAgingCSVReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetCustomerAgingCSVReq): GetCustomerAgingCSVReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.analytics.CustomerAgingSortBy sort_by */ 2:
                    message.sortBy = reader.int32();
                    break;
                case /* rd.analytics.SortOrder sort_order */ 3:
                    message.sortOrder = reader.int32();
                    break;
                case /* rd.analyticsv2.Filters filters */ 5:
                    message.filters = Filters.internalBinaryRead(reader, reader.uint32(), options, message.filters);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetCustomerAgingCSVReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.analytics.CustomerAgingSortBy sort_by = 2; */
        if (message.sortBy !== 0)
            writer.tag(2, WireType.Varint).int32(message.sortBy);
        /* rd.analytics.SortOrder sort_order = 3; */
        if (message.sortOrder !== 0)
            writer.tag(3, WireType.Varint).int32(message.sortOrder);
        /* rd.analyticsv2.Filters filters = 5; */
        if (message.filters)
            Filters.internalBinaryWrite(message.filters, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.analytics.GetCustomerAgingCSVReq
 */
export const GetCustomerAgingCSVReq = new GetCustomerAgingCSVReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetCustomerAgingCSVRes$Type extends MessageType<GetCustomerAgingCSVRes> {
    constructor() {
        super("rd.api.analytics.GetCustomerAgingCSVRes", [
            { no: 1, name: "bytes", kind: "scalar", T: 12 /*ScalarType.BYTES*/ }
        ]);
    }
    create(value?: PartialMessage<GetCustomerAgingCSVRes>): GetCustomerAgingCSVRes {
        const message = { bytes: new Uint8Array(0) };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetCustomerAgingCSVRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetCustomerAgingCSVRes): GetCustomerAgingCSVRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bytes bytes */ 1:
                    message.bytes = reader.bytes();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetCustomerAgingCSVRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bytes bytes = 1; */
        if (message.bytes.length)
            writer.tag(1, WireType.LengthDelimited).bytes(message.bytes);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.analytics.GetCustomerAgingCSVRes
 */
export const GetCustomerAgingCSVRes = new GetCustomerAgingCSVRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GenerateJournalEntriesReq$Type extends MessageType<GenerateJournalEntriesReq> {
    constructor() {
        super("rd.api.analytics.GenerateJournalEntriesReq", [
            { no: 4, name: "filters", kind: "message", T: () => Filters, options: { "rd.validator.require": true } }
        ]);
    }
    create(value?: PartialMessage<GenerateJournalEntriesReq>): GenerateJournalEntriesReq {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GenerateJournalEntriesReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GenerateJournalEntriesReq): GenerateJournalEntriesReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.analyticsv2.Filters filters */ 4:
                    message.filters = Filters.internalBinaryRead(reader, reader.uint32(), options, message.filters);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GenerateJournalEntriesReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.analyticsv2.Filters filters = 4; */
        if (message.filters)
            Filters.internalBinaryWrite(message.filters, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.analytics.GenerateJournalEntriesReq
 */
export const GenerateJournalEntriesReq = new GenerateJournalEntriesReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GenerateJournalEntriesRes$Type extends MessageType<GenerateJournalEntriesRes> {
    constructor() {
        super("rd.api.analytics.GenerateJournalEntriesRes", []);
    }
    create(value?: PartialMessage<GenerateJournalEntriesRes>): GenerateJournalEntriesRes {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GenerateJournalEntriesRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GenerateJournalEntriesRes): GenerateJournalEntriesRes {
        return target ?? this.create();
    }
    internalBinaryWrite(message: GenerateJournalEntriesRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.analytics.GenerateJournalEntriesRes
 */
export const GenerateJournalEntriesRes = new GenerateJournalEntriesRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GenerateJournalEntriesPDFReq$Type extends MessageType<GenerateJournalEntriesPDFReq> {
    constructor() {
        super("rd.api.analytics.GenerateJournalEntriesPDFReq", []);
    }
    create(value?: PartialMessage<GenerateJournalEntriesPDFReq>): GenerateJournalEntriesPDFReq {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GenerateJournalEntriesPDFReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GenerateJournalEntriesPDFReq): GenerateJournalEntriesPDFReq {
        return target ?? this.create();
    }
    internalBinaryWrite(message: GenerateJournalEntriesPDFReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.analytics.GenerateJournalEntriesPDFReq
 */
export const GenerateJournalEntriesPDFReq = new GenerateJournalEntriesPDFReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GenerateJournalEntriesPDFRes$Type extends MessageType<GenerateJournalEntriesPDFRes> {
    constructor() {
        super("rd.api.analytics.GenerateJournalEntriesPDFRes", []);
    }
    create(value?: PartialMessage<GenerateJournalEntriesPDFRes>): GenerateJournalEntriesPDFRes {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GenerateJournalEntriesPDFRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GenerateJournalEntriesPDFRes): GenerateJournalEntriesPDFRes {
        return target ?? this.create();
    }
    internalBinaryWrite(message: GenerateJournalEntriesPDFRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.analytics.GenerateJournalEntriesPDFRes
 */
export const GenerateJournalEntriesPDFRes = new GenerateJournalEntriesPDFRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetTaxEntriesReq$Type extends MessageType<GetTaxEntriesReq> {
    constructor() {
        super("rd.api.analytics.GetTaxEntriesReq", [
            { no: 5, name: "filters", kind: "message", T: () => Filters, options: { "rd.validator.require": true } }
        ]);
    }
    create(value?: PartialMessage<GetTaxEntriesReq>): GetTaxEntriesReq {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetTaxEntriesReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetTaxEntriesReq): GetTaxEntriesReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.analyticsv2.Filters filters */ 5:
                    message.filters = Filters.internalBinaryRead(reader, reader.uint32(), options, message.filters);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetTaxEntriesReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.analyticsv2.Filters filters = 5; */
        if (message.filters)
            Filters.internalBinaryWrite(message.filters, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.analytics.GetTaxEntriesReq
 */
export const GetTaxEntriesReq = new GetTaxEntriesReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetTaxEntriesRes$Type extends MessageType<GetTaxEntriesRes> {
    constructor() {
        super("rd.api.analytics.GetTaxEntriesRes", [
            { no: 1, name: "tax_entries", kind: "message", T: () => TaxEntries }
        ]);
    }
    create(value?: PartialMessage<GetTaxEntriesRes>): GetTaxEntriesRes {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetTaxEntriesRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetTaxEntriesRes): GetTaxEntriesRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.analytics.TaxEntries tax_entries */ 1:
                    message.taxEntries = TaxEntries.internalBinaryRead(reader, reader.uint32(), options, message.taxEntries);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetTaxEntriesRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.analytics.TaxEntries tax_entries = 1; */
        if (message.taxEntries)
            TaxEntries.internalBinaryWrite(message.taxEntries, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.analytics.GetTaxEntriesRes
 */
export const GetTaxEntriesRes = new GetTaxEntriesRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetTaxEntriesPDFReq$Type extends MessageType<GetTaxEntriesPDFReq> {
    constructor() {
        super("rd.api.analytics.GetTaxEntriesPDFReq", [
            { no: 5, name: "filters", kind: "message", T: () => Filters, options: { "rd.validator.require": true } }
        ]);
    }
    create(value?: PartialMessage<GetTaxEntriesPDFReq>): GetTaxEntriesPDFReq {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetTaxEntriesPDFReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetTaxEntriesPDFReq): GetTaxEntriesPDFReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.analyticsv2.Filters filters */ 5:
                    message.filters = Filters.internalBinaryRead(reader, reader.uint32(), options, message.filters);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetTaxEntriesPDFReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.analyticsv2.Filters filters = 5; */
        if (message.filters)
            Filters.internalBinaryWrite(message.filters, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.analytics.GetTaxEntriesPDFReq
 */
export const GetTaxEntriesPDFReq = new GetTaxEntriesPDFReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetTaxEntriesPDFRes$Type extends MessageType<GetTaxEntriesPDFRes> {
    constructor() {
        super("rd.api.analytics.GetTaxEntriesPDFRes", [
            { no: 1, name: "bytes", kind: "scalar", T: 12 /*ScalarType.BYTES*/ }
        ]);
    }
    create(value?: PartialMessage<GetTaxEntriesPDFRes>): GetTaxEntriesPDFRes {
        const message = { bytes: new Uint8Array(0) };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetTaxEntriesPDFRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetTaxEntriesPDFRes): GetTaxEntriesPDFRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bytes bytes */ 1:
                    message.bytes = reader.bytes();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetTaxEntriesPDFRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bytes bytes = 1; */
        if (message.bytes.length)
            writer.tag(1, WireType.LengthDelimited).bytes(message.bytes);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.analytics.GetTaxEntriesPDFRes
 */
export const GetTaxEntriesPDFRes = new GetTaxEntriesPDFRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetTaxEntriesCSVReq$Type extends MessageType<GetTaxEntriesCSVReq> {
    constructor() {
        super("rd.api.analytics.GetTaxEntriesCSVReq", [
            { no: 4, name: "filters", kind: "message", T: () => Filters, options: { "rd.validator.require": true } }
        ]);
    }
    create(value?: PartialMessage<GetTaxEntriesCSVReq>): GetTaxEntriesCSVReq {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetTaxEntriesCSVReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetTaxEntriesCSVReq): GetTaxEntriesCSVReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.analyticsv2.Filters filters */ 4:
                    message.filters = Filters.internalBinaryRead(reader, reader.uint32(), options, message.filters);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetTaxEntriesCSVReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.analyticsv2.Filters filters = 4; */
        if (message.filters)
            Filters.internalBinaryWrite(message.filters, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.analytics.GetTaxEntriesCSVReq
 */
export const GetTaxEntriesCSVReq = new GetTaxEntriesCSVReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetTaxEntriesCSVRes$Type extends MessageType<GetTaxEntriesCSVRes> {
    constructor() {
        super("rd.api.analytics.GetTaxEntriesCSVRes", [
            { no: 1, name: "bytes", kind: "scalar", T: 12 /*ScalarType.BYTES*/ }
        ]);
    }
    create(value?: PartialMessage<GetTaxEntriesCSVRes>): GetTaxEntriesCSVRes {
        const message = { bytes: new Uint8Array(0) };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetTaxEntriesCSVRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetTaxEntriesCSVRes): GetTaxEntriesCSVRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bytes bytes */ 1:
                    message.bytes = reader.bytes();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetTaxEntriesCSVRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bytes bytes = 1; */
        if (message.bytes.length)
            writer.tag(1, WireType.LengthDelimited).bytes(message.bytes);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.analytics.GetTaxEntriesCSVRes
 */
export const GetTaxEntriesCSVRes = new GetTaxEntriesCSVRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetPriceEditsReq$Type extends MessageType<GetPriceEditsReq> {
    constructor() {
        super("rd.api.analytics.GetPriceEditsReq", [
            { no: 5, name: "sort_by", kind: "enum", T: () => ["rd.analytics.PriceEditsSortBy", PriceEditsSortBy, "PRICE_EDITS_SORT_BY_"] },
            { no: 6, name: "sort_order", kind: "enum", T: () => ["rd.analytics.SortOrder", SortOrder, "SORT_ORDER_"] },
            { no: 7, name: "filters", kind: "message", T: () => Filters, options: { "rd.validator.require": true } }
        ]);
    }
    create(value?: PartialMessage<GetPriceEditsReq>): GetPriceEditsReq {
        const message = { sortBy: 0, sortOrder: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetPriceEditsReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetPriceEditsReq): GetPriceEditsReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.analytics.PriceEditsSortBy sort_by */ 5:
                    message.sortBy = reader.int32();
                    break;
                case /* rd.analytics.SortOrder sort_order */ 6:
                    message.sortOrder = reader.int32();
                    break;
                case /* rd.analyticsv2.Filters filters */ 7:
                    message.filters = Filters.internalBinaryRead(reader, reader.uint32(), options, message.filters);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetPriceEditsReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.analytics.PriceEditsSortBy sort_by = 5; */
        if (message.sortBy !== 0)
            writer.tag(5, WireType.Varint).int32(message.sortBy);
        /* rd.analytics.SortOrder sort_order = 6; */
        if (message.sortOrder !== 0)
            writer.tag(6, WireType.Varint).int32(message.sortOrder);
        /* rd.analyticsv2.Filters filters = 7; */
        if (message.filters)
            Filters.internalBinaryWrite(message.filters, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.analytics.GetPriceEditsReq
 */
export const GetPriceEditsReq = new GetPriceEditsReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetPriceEditsRes$Type extends MessageType<GetPriceEditsRes> {
    constructor() {
        super("rd.api.analytics.GetPriceEditsRes", [
            { no: 2, name: "report", kind: "message", T: () => Report }
        ]);
    }
    create(value?: PartialMessage<GetPriceEditsRes>): GetPriceEditsRes {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetPriceEditsRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetPriceEditsRes): GetPriceEditsRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.analytics.Report report */ 2:
                    message.report = Report.internalBinaryRead(reader, reader.uint32(), options, message.report);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetPriceEditsRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.analytics.Report report = 2; */
        if (message.report)
            Report.internalBinaryWrite(message.report, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.analytics.GetPriceEditsRes
 */
export const GetPriceEditsRes = new GetPriceEditsRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetPriceEditsPDFReq$Type extends MessageType<GetPriceEditsPDFReq> {
    constructor() {
        super("rd.api.analytics.GetPriceEditsPDFReq", [
            { no: 5, name: "sort_by", kind: "enum", T: () => ["rd.analytics.PriceEditsSortBy", PriceEditsSortBy, "PRICE_EDITS_SORT_BY_"] },
            { no: 6, name: "sort_order", kind: "enum", T: () => ["rd.analytics.SortOrder", SortOrder, "SORT_ORDER_"] },
            { no: 7, name: "filters", kind: "message", T: () => Filters, options: { "rd.validator.require": true } }
        ]);
    }
    create(value?: PartialMessage<GetPriceEditsPDFReq>): GetPriceEditsPDFReq {
        const message = { sortBy: 0, sortOrder: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetPriceEditsPDFReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetPriceEditsPDFReq): GetPriceEditsPDFReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.analytics.PriceEditsSortBy sort_by */ 5:
                    message.sortBy = reader.int32();
                    break;
                case /* rd.analytics.SortOrder sort_order */ 6:
                    message.sortOrder = reader.int32();
                    break;
                case /* rd.analyticsv2.Filters filters */ 7:
                    message.filters = Filters.internalBinaryRead(reader, reader.uint32(), options, message.filters);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetPriceEditsPDFReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.analytics.PriceEditsSortBy sort_by = 5; */
        if (message.sortBy !== 0)
            writer.tag(5, WireType.Varint).int32(message.sortBy);
        /* rd.analytics.SortOrder sort_order = 6; */
        if (message.sortOrder !== 0)
            writer.tag(6, WireType.Varint).int32(message.sortOrder);
        /* rd.analyticsv2.Filters filters = 7; */
        if (message.filters)
            Filters.internalBinaryWrite(message.filters, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.analytics.GetPriceEditsPDFReq
 */
export const GetPriceEditsPDFReq = new GetPriceEditsPDFReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetPriceEditsPDFRes$Type extends MessageType<GetPriceEditsPDFRes> {
    constructor() {
        super("rd.api.analytics.GetPriceEditsPDFRes", [
            { no: 1, name: "bytes", kind: "scalar", T: 12 /*ScalarType.BYTES*/ }
        ]);
    }
    create(value?: PartialMessage<GetPriceEditsPDFRes>): GetPriceEditsPDFRes {
        const message = { bytes: new Uint8Array(0) };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetPriceEditsPDFRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetPriceEditsPDFRes): GetPriceEditsPDFRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bytes bytes */ 1:
                    message.bytes = reader.bytes();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetPriceEditsPDFRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bytes bytes = 1; */
        if (message.bytes.length)
            writer.tag(1, WireType.LengthDelimited).bytes(message.bytes);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.analytics.GetPriceEditsPDFRes
 */
export const GetPriceEditsPDFRes = new GetPriceEditsPDFRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetPriceEditsCSVReq$Type extends MessageType<GetPriceEditsCSVReq> {
    constructor() {
        super("rd.api.analytics.GetPriceEditsCSVReq", [
            { no: 5, name: "sort_by", kind: "enum", T: () => ["rd.analytics.PriceEditsSortBy", PriceEditsSortBy, "PRICE_EDITS_SORT_BY_"] },
            { no: 6, name: "sort_order", kind: "enum", T: () => ["rd.analytics.SortOrder", SortOrder, "SORT_ORDER_"] },
            { no: 7, name: "filters", kind: "message", T: () => Filters, options: { "rd.validator.require": true } }
        ]);
    }
    create(value?: PartialMessage<GetPriceEditsCSVReq>): GetPriceEditsCSVReq {
        const message = { sortBy: 0, sortOrder: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetPriceEditsCSVReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetPriceEditsCSVReq): GetPriceEditsCSVReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.analytics.PriceEditsSortBy sort_by */ 5:
                    message.sortBy = reader.int32();
                    break;
                case /* rd.analytics.SortOrder sort_order */ 6:
                    message.sortOrder = reader.int32();
                    break;
                case /* rd.analyticsv2.Filters filters */ 7:
                    message.filters = Filters.internalBinaryRead(reader, reader.uint32(), options, message.filters);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetPriceEditsCSVReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.analytics.PriceEditsSortBy sort_by = 5; */
        if (message.sortBy !== 0)
            writer.tag(5, WireType.Varint).int32(message.sortBy);
        /* rd.analytics.SortOrder sort_order = 6; */
        if (message.sortOrder !== 0)
            writer.tag(6, WireType.Varint).int32(message.sortOrder);
        /* rd.analyticsv2.Filters filters = 7; */
        if (message.filters)
            Filters.internalBinaryWrite(message.filters, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.analytics.GetPriceEditsCSVReq
 */
export const GetPriceEditsCSVReq = new GetPriceEditsCSVReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetPriceEditsCSVRes$Type extends MessageType<GetPriceEditsCSVRes> {
    constructor() {
        super("rd.api.analytics.GetPriceEditsCSVRes", [
            { no: 1, name: "bytes", kind: "scalar", T: 12 /*ScalarType.BYTES*/ }
        ]);
    }
    create(value?: PartialMessage<GetPriceEditsCSVRes>): GetPriceEditsCSVRes {
        const message = { bytes: new Uint8Array(0) };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetPriceEditsCSVRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetPriceEditsCSVRes): GetPriceEditsCSVRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bytes bytes */ 1:
                    message.bytes = reader.bytes();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetPriceEditsCSVRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bytes bytes = 1; */
        if (message.bytes.length)
            writer.tag(1, WireType.LengthDelimited).bytes(message.bytes);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.analytics.GetPriceEditsCSVRes
 */
export const GetPriceEditsCSVRes = new GetPriceEditsCSVRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetNewAccountsOpenedReq$Type extends MessageType<GetNewAccountsOpenedReq> {
    constructor() {
        super("rd.api.analytics.GetNewAccountsOpenedReq", [
            { no: 4, name: "sort_by", kind: "enum", T: () => ["rd.analytics.NewAccountsOpenedSortBy", NewAccountsOpenedSortBy, "NEW_ACCOUNTS_OPENED_SORT_BY_"] },
            { no: 5, name: "sort_order", kind: "enum", T: () => ["rd.analytics.SortOrder", SortOrder, "SORT_ORDER_"] },
            { no: 6, name: "filters", kind: "message", T: () => Filters, options: { "rd.validator.require": true } }
        ]);
    }
    create(value?: PartialMessage<GetNewAccountsOpenedReq>): GetNewAccountsOpenedReq {
        const message = { sortBy: 0, sortOrder: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetNewAccountsOpenedReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetNewAccountsOpenedReq): GetNewAccountsOpenedReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.analytics.NewAccountsOpenedSortBy sort_by */ 4:
                    message.sortBy = reader.int32();
                    break;
                case /* rd.analytics.SortOrder sort_order */ 5:
                    message.sortOrder = reader.int32();
                    break;
                case /* rd.analyticsv2.Filters filters */ 6:
                    message.filters = Filters.internalBinaryRead(reader, reader.uint32(), options, message.filters);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetNewAccountsOpenedReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.analytics.NewAccountsOpenedSortBy sort_by = 4; */
        if (message.sortBy !== 0)
            writer.tag(4, WireType.Varint).int32(message.sortBy);
        /* rd.analytics.SortOrder sort_order = 5; */
        if (message.sortOrder !== 0)
            writer.tag(5, WireType.Varint).int32(message.sortOrder);
        /* rd.analyticsv2.Filters filters = 6; */
        if (message.filters)
            Filters.internalBinaryWrite(message.filters, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.analytics.GetNewAccountsOpenedReq
 */
export const GetNewAccountsOpenedReq = new GetNewAccountsOpenedReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetNewAccountsOpenedRes$Type extends MessageType<GetNewAccountsOpenedRes> {
    constructor() {
        super("rd.api.analytics.GetNewAccountsOpenedRes", [
            { no: 1, name: "new_accounts_opened", kind: "message", T: () => NewAccountsOpened }
        ]);
    }
    create(value?: PartialMessage<GetNewAccountsOpenedRes>): GetNewAccountsOpenedRes {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetNewAccountsOpenedRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetNewAccountsOpenedRes): GetNewAccountsOpenedRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.analytics.NewAccountsOpened new_accounts_opened */ 1:
                    message.newAccountsOpened = NewAccountsOpened.internalBinaryRead(reader, reader.uint32(), options, message.newAccountsOpened);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetNewAccountsOpenedRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.analytics.NewAccountsOpened new_accounts_opened = 1; */
        if (message.newAccountsOpened)
            NewAccountsOpened.internalBinaryWrite(message.newAccountsOpened, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.analytics.GetNewAccountsOpenedRes
 */
export const GetNewAccountsOpenedRes = new GetNewAccountsOpenedRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetNewAccountsOpenedPDFReq$Type extends MessageType<GetNewAccountsOpenedPDFReq> {
    constructor() {
        super("rd.api.analytics.GetNewAccountsOpenedPDFReq", [
            { no: 4, name: "sort_by", kind: "enum", T: () => ["rd.analytics.NewAccountsOpenedSortBy", NewAccountsOpenedSortBy, "NEW_ACCOUNTS_OPENED_SORT_BY_"] },
            { no: 5, name: "sort_order", kind: "enum", T: () => ["rd.analytics.SortOrder", SortOrder, "SORT_ORDER_"] },
            { no: 6, name: "filters", kind: "message", T: () => Filters, options: { "rd.validator.require": true } }
        ]);
    }
    create(value?: PartialMessage<GetNewAccountsOpenedPDFReq>): GetNewAccountsOpenedPDFReq {
        const message = { sortBy: 0, sortOrder: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetNewAccountsOpenedPDFReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetNewAccountsOpenedPDFReq): GetNewAccountsOpenedPDFReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.analytics.NewAccountsOpenedSortBy sort_by */ 4:
                    message.sortBy = reader.int32();
                    break;
                case /* rd.analytics.SortOrder sort_order */ 5:
                    message.sortOrder = reader.int32();
                    break;
                case /* rd.analyticsv2.Filters filters */ 6:
                    message.filters = Filters.internalBinaryRead(reader, reader.uint32(), options, message.filters);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetNewAccountsOpenedPDFReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.analytics.NewAccountsOpenedSortBy sort_by = 4; */
        if (message.sortBy !== 0)
            writer.tag(4, WireType.Varint).int32(message.sortBy);
        /* rd.analytics.SortOrder sort_order = 5; */
        if (message.sortOrder !== 0)
            writer.tag(5, WireType.Varint).int32(message.sortOrder);
        /* rd.analyticsv2.Filters filters = 6; */
        if (message.filters)
            Filters.internalBinaryWrite(message.filters, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.analytics.GetNewAccountsOpenedPDFReq
 */
export const GetNewAccountsOpenedPDFReq = new GetNewAccountsOpenedPDFReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetNewAccountsOpenedPDFRes$Type extends MessageType<GetNewAccountsOpenedPDFRes> {
    constructor() {
        super("rd.api.analytics.GetNewAccountsOpenedPDFRes", [
            { no: 1, name: "bytes", kind: "scalar", T: 12 /*ScalarType.BYTES*/ }
        ]);
    }
    create(value?: PartialMessage<GetNewAccountsOpenedPDFRes>): GetNewAccountsOpenedPDFRes {
        const message = { bytes: new Uint8Array(0) };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetNewAccountsOpenedPDFRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetNewAccountsOpenedPDFRes): GetNewAccountsOpenedPDFRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bytes bytes */ 1:
                    message.bytes = reader.bytes();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetNewAccountsOpenedPDFRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bytes bytes = 1; */
        if (message.bytes.length)
            writer.tag(1, WireType.LengthDelimited).bytes(message.bytes);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.analytics.GetNewAccountsOpenedPDFRes
 */
export const GetNewAccountsOpenedPDFRes = new GetNewAccountsOpenedPDFRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetNewAccountsOpenedCSVReq$Type extends MessageType<GetNewAccountsOpenedCSVReq> {
    constructor() {
        super("rd.api.analytics.GetNewAccountsOpenedCSVReq", [
            { no: 4, name: "sort_by", kind: "enum", T: () => ["rd.analytics.NewAccountsOpenedSortBy", NewAccountsOpenedSortBy, "NEW_ACCOUNTS_OPENED_SORT_BY_"] },
            { no: 5, name: "sort_order", kind: "enum", T: () => ["rd.analytics.SortOrder", SortOrder, "SORT_ORDER_"] },
            { no: 6, name: "filters", kind: "message", T: () => Filters, options: { "rd.validator.require": true } }
        ]);
    }
    create(value?: PartialMessage<GetNewAccountsOpenedCSVReq>): GetNewAccountsOpenedCSVReq {
        const message = { sortBy: 0, sortOrder: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetNewAccountsOpenedCSVReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetNewAccountsOpenedCSVReq): GetNewAccountsOpenedCSVReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.analytics.NewAccountsOpenedSortBy sort_by */ 4:
                    message.sortBy = reader.int32();
                    break;
                case /* rd.analytics.SortOrder sort_order */ 5:
                    message.sortOrder = reader.int32();
                    break;
                case /* rd.analyticsv2.Filters filters */ 6:
                    message.filters = Filters.internalBinaryRead(reader, reader.uint32(), options, message.filters);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetNewAccountsOpenedCSVReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.analytics.NewAccountsOpenedSortBy sort_by = 4; */
        if (message.sortBy !== 0)
            writer.tag(4, WireType.Varint).int32(message.sortBy);
        /* rd.analytics.SortOrder sort_order = 5; */
        if (message.sortOrder !== 0)
            writer.tag(5, WireType.Varint).int32(message.sortOrder);
        /* rd.analyticsv2.Filters filters = 6; */
        if (message.filters)
            Filters.internalBinaryWrite(message.filters, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.analytics.GetNewAccountsOpenedCSVReq
 */
export const GetNewAccountsOpenedCSVReq = new GetNewAccountsOpenedCSVReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetNewAccountsOpenedCSVRes$Type extends MessageType<GetNewAccountsOpenedCSVRes> {
    constructor() {
        super("rd.api.analytics.GetNewAccountsOpenedCSVRes", [
            { no: 1, name: "bytes", kind: "scalar", T: 12 /*ScalarType.BYTES*/ }
        ]);
    }
    create(value?: PartialMessage<GetNewAccountsOpenedCSVRes>): GetNewAccountsOpenedCSVRes {
        const message = { bytes: new Uint8Array(0) };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetNewAccountsOpenedCSVRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetNewAccountsOpenedCSVRes): GetNewAccountsOpenedCSVRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bytes bytes */ 1:
                    message.bytes = reader.bytes();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetNewAccountsOpenedCSVRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bytes bytes = 1; */
        if (message.bytes.length)
            writer.tag(1, WireType.LengthDelimited).bytes(message.bytes);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.analytics.GetNewAccountsOpenedCSVRes
 */
export const GetNewAccountsOpenedCSVRes = new GetNewAccountsOpenedCSVRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSalesDetailReq$Type extends MessageType<GetSalesDetailReq> {
    constructor() {
        super("rd.api.analytics.GetSalesDetailReq", [
            { no: 5, name: "sort_by", kind: "enum", T: () => ["rd.analytics.SalesDetailSortBy", SalesDetailSortBy, "SALES_DETAIL_SORT_BY_"] },
            { no: 6, name: "sort_order", kind: "enum", T: () => ["rd.analytics.SortOrder", SortOrder, "SORT_ORDER_"] },
            { no: 13, name: "filters", kind: "message", T: () => Filters, options: { "rd.validator.require": true } }
        ]);
    }
    create(value?: PartialMessage<GetSalesDetailReq>): GetSalesDetailReq {
        const message = { sortBy: 0, sortOrder: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetSalesDetailReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetSalesDetailReq): GetSalesDetailReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.analytics.SalesDetailSortBy sort_by */ 5:
                    message.sortBy = reader.int32();
                    break;
                case /* rd.analytics.SortOrder sort_order */ 6:
                    message.sortOrder = reader.int32();
                    break;
                case /* rd.analyticsv2.Filters filters */ 13:
                    message.filters = Filters.internalBinaryRead(reader, reader.uint32(), options, message.filters);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetSalesDetailReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.analytics.SalesDetailSortBy sort_by = 5; */
        if (message.sortBy !== 0)
            writer.tag(5, WireType.Varint).int32(message.sortBy);
        /* rd.analytics.SortOrder sort_order = 6; */
        if (message.sortOrder !== 0)
            writer.tag(6, WireType.Varint).int32(message.sortOrder);
        /* rd.analyticsv2.Filters filters = 13; */
        if (message.filters)
            Filters.internalBinaryWrite(message.filters, writer.tag(13, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.analytics.GetSalesDetailReq
 */
export const GetSalesDetailReq = new GetSalesDetailReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSalesDetailRes$Type extends MessageType<GetSalesDetailRes> {
    constructor() {
        super("rd.api.analytics.GetSalesDetailRes", [
            { no: 1, name: "sales_detail", kind: "message", T: () => SalesDetail }
        ]);
    }
    create(value?: PartialMessage<GetSalesDetailRes>): GetSalesDetailRes {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetSalesDetailRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetSalesDetailRes): GetSalesDetailRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.analytics.SalesDetail sales_detail */ 1:
                    message.salesDetail = SalesDetail.internalBinaryRead(reader, reader.uint32(), options, message.salesDetail);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetSalesDetailRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.analytics.SalesDetail sales_detail = 1; */
        if (message.salesDetail)
            SalesDetail.internalBinaryWrite(message.salesDetail, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.analytics.GetSalesDetailRes
 */
export const GetSalesDetailRes = new GetSalesDetailRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSalesDetailCSVReq$Type extends MessageType<GetSalesDetailCSVReq> {
    constructor() {
        super("rd.api.analytics.GetSalesDetailCSVReq", [
            { no: 5, name: "sort_by", kind: "enum", T: () => ["rd.analytics.SalesDetailSortBy", SalesDetailSortBy, "SALES_DETAIL_SORT_BY_"] },
            { no: 6, name: "sort_order", kind: "enum", T: () => ["rd.analytics.SortOrder", SortOrder, "SORT_ORDER_"] },
            { no: 13, name: "filters", kind: "message", T: () => Filters, options: { "rd.validator.require": true } }
        ]);
    }
    create(value?: PartialMessage<GetSalesDetailCSVReq>): GetSalesDetailCSVReq {
        const message = { sortBy: 0, sortOrder: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetSalesDetailCSVReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetSalesDetailCSVReq): GetSalesDetailCSVReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.analytics.SalesDetailSortBy sort_by */ 5:
                    message.sortBy = reader.int32();
                    break;
                case /* rd.analytics.SortOrder sort_order */ 6:
                    message.sortOrder = reader.int32();
                    break;
                case /* rd.analyticsv2.Filters filters */ 13:
                    message.filters = Filters.internalBinaryRead(reader, reader.uint32(), options, message.filters);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetSalesDetailCSVReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.analytics.SalesDetailSortBy sort_by = 5; */
        if (message.sortBy !== 0)
            writer.tag(5, WireType.Varint).int32(message.sortBy);
        /* rd.analytics.SortOrder sort_order = 6; */
        if (message.sortOrder !== 0)
            writer.tag(6, WireType.Varint).int32(message.sortOrder);
        /* rd.analyticsv2.Filters filters = 13; */
        if (message.filters)
            Filters.internalBinaryWrite(message.filters, writer.tag(13, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.analytics.GetSalesDetailCSVReq
 */
export const GetSalesDetailCSVReq = new GetSalesDetailCSVReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSalesDetailCSVRes$Type extends MessageType<GetSalesDetailCSVRes> {
    constructor() {
        super("rd.api.analytics.GetSalesDetailCSVRes", [
            { no: 1, name: "bytes", kind: "scalar", T: 12 /*ScalarType.BYTES*/ }
        ]);
    }
    create(value?: PartialMessage<GetSalesDetailCSVRes>): GetSalesDetailCSVRes {
        const message = { bytes: new Uint8Array(0) };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetSalesDetailCSVRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetSalesDetailCSVRes): GetSalesDetailCSVRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bytes bytes */ 1:
                    message.bytes = reader.bytes();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetSalesDetailCSVRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bytes bytes = 1; */
        if (message.bytes.length)
            writer.tag(1, WireType.LengthDelimited).bytes(message.bytes);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.analytics.GetSalesDetailCSVRes
 */
export const GetSalesDetailCSVRes = new GetSalesDetailCSVRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSalesByDayReq$Type extends MessageType<GetSalesByDayReq> {
    constructor() {
        super("rd.api.analytics.GetSalesByDayReq", [
            { no: 5, name: "sort_by", kind: "enum", T: () => ["rd.analyticsv2.SalesByDaySortBy", SalesByDaySortBy, "SALES_BY_DAY_SORT_BY_"] },
            { no: 6, name: "sort_order", kind: "enum", T: () => ["rd.analyticsv2.SortOrder", SortOrder$, "SORT_ORDER_"] },
            { no: 13, name: "filters", kind: "message", T: () => Filters, options: { "rd.validator.require": true } }
        ]);
    }
    create(value?: PartialMessage<GetSalesByDayReq>): GetSalesByDayReq {
        const message = { sortBy: 0, sortOrder: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetSalesByDayReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetSalesByDayReq): GetSalesByDayReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.analyticsv2.SalesByDaySortBy sort_by */ 5:
                    message.sortBy = reader.int32();
                    break;
                case /* rd.analyticsv2.SortOrder sort_order */ 6:
                    message.sortOrder = reader.int32();
                    break;
                case /* rd.analyticsv2.Filters filters */ 13:
                    message.filters = Filters.internalBinaryRead(reader, reader.uint32(), options, message.filters);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetSalesByDayReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.analyticsv2.SalesByDaySortBy sort_by = 5; */
        if (message.sortBy !== 0)
            writer.tag(5, WireType.Varint).int32(message.sortBy);
        /* rd.analyticsv2.SortOrder sort_order = 6; */
        if (message.sortOrder !== 0)
            writer.tag(6, WireType.Varint).int32(message.sortOrder);
        /* rd.analyticsv2.Filters filters = 13; */
        if (message.filters)
            Filters.internalBinaryWrite(message.filters, writer.tag(13, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.analytics.GetSalesByDayReq
 */
export const GetSalesByDayReq = new GetSalesByDayReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSalesByDayRes$Type extends MessageType<GetSalesByDayRes> {
    constructor() {
        super("rd.api.analytics.GetSalesByDayRes", [
            { no: 1, name: "report", kind: "message", T: () => Report }
        ]);
    }
    create(value?: PartialMessage<GetSalesByDayRes>): GetSalesByDayRes {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetSalesByDayRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetSalesByDayRes): GetSalesByDayRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.analytics.Report report */ 1:
                    message.report = Report.internalBinaryRead(reader, reader.uint32(), options, message.report);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetSalesByDayRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.analytics.Report report = 1; */
        if (message.report)
            Report.internalBinaryWrite(message.report, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.analytics.GetSalesByDayRes
 */
export const GetSalesByDayRes = new GetSalesByDayRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSalesByDayPDFReq$Type extends MessageType<GetSalesByDayPDFReq> {
    constructor() {
        super("rd.api.analytics.GetSalesByDayPDFReq", [
            { no: 5, name: "sort_by", kind: "enum", T: () => ["rd.analyticsv2.SalesByDaySortBy", SalesByDaySortBy, "SALES_BY_DAY_SORT_BY_"] },
            { no: 6, name: "sort_order", kind: "enum", T: () => ["rd.analyticsv2.SortOrder", SortOrder$, "SORT_ORDER_"] },
            { no: 13, name: "filters", kind: "message", T: () => Filters, options: { "rd.validator.require": true } }
        ]);
    }
    create(value?: PartialMessage<GetSalesByDayPDFReq>): GetSalesByDayPDFReq {
        const message = { sortBy: 0, sortOrder: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetSalesByDayPDFReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetSalesByDayPDFReq): GetSalesByDayPDFReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.analyticsv2.SalesByDaySortBy sort_by */ 5:
                    message.sortBy = reader.int32();
                    break;
                case /* rd.analyticsv2.SortOrder sort_order */ 6:
                    message.sortOrder = reader.int32();
                    break;
                case /* rd.analyticsv2.Filters filters */ 13:
                    message.filters = Filters.internalBinaryRead(reader, reader.uint32(), options, message.filters);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetSalesByDayPDFReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.analyticsv2.SalesByDaySortBy sort_by = 5; */
        if (message.sortBy !== 0)
            writer.tag(5, WireType.Varint).int32(message.sortBy);
        /* rd.analyticsv2.SortOrder sort_order = 6; */
        if (message.sortOrder !== 0)
            writer.tag(6, WireType.Varint).int32(message.sortOrder);
        /* rd.analyticsv2.Filters filters = 13; */
        if (message.filters)
            Filters.internalBinaryWrite(message.filters, writer.tag(13, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.analytics.GetSalesByDayPDFReq
 */
export const GetSalesByDayPDFReq = new GetSalesByDayPDFReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSalesByDayPDFRes$Type extends MessageType<GetSalesByDayPDFRes> {
    constructor() {
        super("rd.api.analytics.GetSalesByDayPDFRes", [
            { no: 1, name: "bytes", kind: "scalar", T: 12 /*ScalarType.BYTES*/ }
        ]);
    }
    create(value?: PartialMessage<GetSalesByDayPDFRes>): GetSalesByDayPDFRes {
        const message = { bytes: new Uint8Array(0) };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetSalesByDayPDFRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetSalesByDayPDFRes): GetSalesByDayPDFRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bytes bytes */ 1:
                    message.bytes = reader.bytes();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetSalesByDayPDFRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bytes bytes = 1; */
        if (message.bytes.length)
            writer.tag(1, WireType.LengthDelimited).bytes(message.bytes);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.analytics.GetSalesByDayPDFRes
 */
export const GetSalesByDayPDFRes = new GetSalesByDayPDFRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSalesByDayCSVReq$Type extends MessageType<GetSalesByDayCSVReq> {
    constructor() {
        super("rd.api.analytics.GetSalesByDayCSVReq", [
            { no: 5, name: "sort_by", kind: "enum", T: () => ["rd.analyticsv2.SalesByDaySortBy", SalesByDaySortBy, "SALES_BY_DAY_SORT_BY_"] },
            { no: 6, name: "sort_order", kind: "enum", T: () => ["rd.analyticsv2.SortOrder", SortOrder$, "SORT_ORDER_"] },
            { no: 13, name: "filters", kind: "message", T: () => Filters, options: { "rd.validator.require": true } }
        ]);
    }
    create(value?: PartialMessage<GetSalesByDayCSVReq>): GetSalesByDayCSVReq {
        const message = { sortBy: 0, sortOrder: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetSalesByDayCSVReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetSalesByDayCSVReq): GetSalesByDayCSVReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.analyticsv2.SalesByDaySortBy sort_by */ 5:
                    message.sortBy = reader.int32();
                    break;
                case /* rd.analyticsv2.SortOrder sort_order */ 6:
                    message.sortOrder = reader.int32();
                    break;
                case /* rd.analyticsv2.Filters filters */ 13:
                    message.filters = Filters.internalBinaryRead(reader, reader.uint32(), options, message.filters);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetSalesByDayCSVReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.analyticsv2.SalesByDaySortBy sort_by = 5; */
        if (message.sortBy !== 0)
            writer.tag(5, WireType.Varint).int32(message.sortBy);
        /* rd.analyticsv2.SortOrder sort_order = 6; */
        if (message.sortOrder !== 0)
            writer.tag(6, WireType.Varint).int32(message.sortOrder);
        /* rd.analyticsv2.Filters filters = 13; */
        if (message.filters)
            Filters.internalBinaryWrite(message.filters, writer.tag(13, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.analytics.GetSalesByDayCSVReq
 */
export const GetSalesByDayCSVReq = new GetSalesByDayCSVReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSalesByDayCSVRes$Type extends MessageType<GetSalesByDayCSVRes> {
    constructor() {
        super("rd.api.analytics.GetSalesByDayCSVRes", [
            { no: 1, name: "bytes", kind: "scalar", T: 12 /*ScalarType.BYTES*/ }
        ]);
    }
    create(value?: PartialMessage<GetSalesByDayCSVRes>): GetSalesByDayCSVRes {
        const message = { bytes: new Uint8Array(0) };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetSalesByDayCSVRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetSalesByDayCSVRes): GetSalesByDayCSVRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bytes bytes */ 1:
                    message.bytes = reader.bytes();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetSalesByDayCSVRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bytes bytes = 1; */
        if (message.bytes.length)
            writer.tag(1, WireType.LengthDelimited).bytes(message.bytes);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.analytics.GetSalesByDayCSVRes
 */
export const GetSalesByDayCSVRes = new GetSalesByDayCSVRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSalesByCustomerReq$Type extends MessageType<GetSalesByCustomerReq> {
    constructor() {
        super("rd.api.analytics.GetSalesByCustomerReq", [
            { no: 1, name: "sort_by", kind: "enum", T: () => ["rd.analyticsv2.SalesByCustomerSortBy", SalesByCustomerSortBy, "SALES_BY_CUSTOMER_SORT_BY_"] },
            { no: 2, name: "sort_order", kind: "enum", T: () => ["rd.analyticsv2.SortOrder", SortOrder$, "SORT_ORDER_"] },
            { no: 3, name: "filters", kind: "message", T: () => Filters, options: { "rd.validator.require": true } }
        ]);
    }
    create(value?: PartialMessage<GetSalesByCustomerReq>): GetSalesByCustomerReq {
        const message = { sortBy: 0, sortOrder: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetSalesByCustomerReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetSalesByCustomerReq): GetSalesByCustomerReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.analyticsv2.SalesByCustomerSortBy sort_by */ 1:
                    message.sortBy = reader.int32();
                    break;
                case /* rd.analyticsv2.SortOrder sort_order */ 2:
                    message.sortOrder = reader.int32();
                    break;
                case /* rd.analyticsv2.Filters filters */ 3:
                    message.filters = Filters.internalBinaryRead(reader, reader.uint32(), options, message.filters);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetSalesByCustomerReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.analyticsv2.SalesByCustomerSortBy sort_by = 1; */
        if (message.sortBy !== 0)
            writer.tag(1, WireType.Varint).int32(message.sortBy);
        /* rd.analyticsv2.SortOrder sort_order = 2; */
        if (message.sortOrder !== 0)
            writer.tag(2, WireType.Varint).int32(message.sortOrder);
        /* rd.analyticsv2.Filters filters = 3; */
        if (message.filters)
            Filters.internalBinaryWrite(message.filters, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.analytics.GetSalesByCustomerReq
 */
export const GetSalesByCustomerReq = new GetSalesByCustomerReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSalesByCustomerRes$Type extends MessageType<GetSalesByCustomerRes> {
    constructor() {
        super("rd.api.analytics.GetSalesByCustomerRes", [
            { no: 1, name: "report", kind: "message", T: () => Report }
        ]);
    }
    create(value?: PartialMessage<GetSalesByCustomerRes>): GetSalesByCustomerRes {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetSalesByCustomerRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetSalesByCustomerRes): GetSalesByCustomerRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.analytics.Report report */ 1:
                    message.report = Report.internalBinaryRead(reader, reader.uint32(), options, message.report);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetSalesByCustomerRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.analytics.Report report = 1; */
        if (message.report)
            Report.internalBinaryWrite(message.report, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.analytics.GetSalesByCustomerRes
 */
export const GetSalesByCustomerRes = new GetSalesByCustomerRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSalesByCustomerPDFReq$Type extends MessageType<GetSalesByCustomerPDFReq> {
    constructor() {
        super("rd.api.analytics.GetSalesByCustomerPDFReq", [
            { no: 1, name: "sort_by", kind: "enum", T: () => ["rd.analyticsv2.SalesByCustomerSortBy", SalesByCustomerSortBy, "SALES_BY_CUSTOMER_SORT_BY_"] },
            { no: 2, name: "sort_order", kind: "enum", T: () => ["rd.analyticsv2.SortOrder", SortOrder$, "SORT_ORDER_"] },
            { no: 3, name: "filters", kind: "message", T: () => Filters, options: { "rd.validator.require": true } }
        ]);
    }
    create(value?: PartialMessage<GetSalesByCustomerPDFReq>): GetSalesByCustomerPDFReq {
        const message = { sortBy: 0, sortOrder: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetSalesByCustomerPDFReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetSalesByCustomerPDFReq): GetSalesByCustomerPDFReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.analyticsv2.SalesByCustomerSortBy sort_by */ 1:
                    message.sortBy = reader.int32();
                    break;
                case /* rd.analyticsv2.SortOrder sort_order */ 2:
                    message.sortOrder = reader.int32();
                    break;
                case /* rd.analyticsv2.Filters filters */ 3:
                    message.filters = Filters.internalBinaryRead(reader, reader.uint32(), options, message.filters);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetSalesByCustomerPDFReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.analyticsv2.SalesByCustomerSortBy sort_by = 1; */
        if (message.sortBy !== 0)
            writer.tag(1, WireType.Varint).int32(message.sortBy);
        /* rd.analyticsv2.SortOrder sort_order = 2; */
        if (message.sortOrder !== 0)
            writer.tag(2, WireType.Varint).int32(message.sortOrder);
        /* rd.analyticsv2.Filters filters = 3; */
        if (message.filters)
            Filters.internalBinaryWrite(message.filters, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.analytics.GetSalesByCustomerPDFReq
 */
export const GetSalesByCustomerPDFReq = new GetSalesByCustomerPDFReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSalesByCustomerPDFRes$Type extends MessageType<GetSalesByCustomerPDFRes> {
    constructor() {
        super("rd.api.analytics.GetSalesByCustomerPDFRes", [
            { no: 1, name: "bytes", kind: "scalar", T: 12 /*ScalarType.BYTES*/ }
        ]);
    }
    create(value?: PartialMessage<GetSalesByCustomerPDFRes>): GetSalesByCustomerPDFRes {
        const message = { bytes: new Uint8Array(0) };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetSalesByCustomerPDFRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetSalesByCustomerPDFRes): GetSalesByCustomerPDFRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bytes bytes */ 1:
                    message.bytes = reader.bytes();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetSalesByCustomerPDFRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bytes bytes = 1; */
        if (message.bytes.length)
            writer.tag(1, WireType.LengthDelimited).bytes(message.bytes);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.analytics.GetSalesByCustomerPDFRes
 */
export const GetSalesByCustomerPDFRes = new GetSalesByCustomerPDFRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSalesByCustomerCSVReq$Type extends MessageType<GetSalesByCustomerCSVReq> {
    constructor() {
        super("rd.api.analytics.GetSalesByCustomerCSVReq", [
            { no: 1, name: "sort_by", kind: "enum", T: () => ["rd.analyticsv2.SalesByCustomerSortBy", SalesByCustomerSortBy, "SALES_BY_CUSTOMER_SORT_BY_"] },
            { no: 2, name: "sort_order", kind: "enum", T: () => ["rd.analyticsv2.SortOrder", SortOrder$, "SORT_ORDER_"] },
            { no: 3, name: "filters", kind: "message", T: () => Filters, options: { "rd.validator.require": true } }
        ]);
    }
    create(value?: PartialMessage<GetSalesByCustomerCSVReq>): GetSalesByCustomerCSVReq {
        const message = { sortBy: 0, sortOrder: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetSalesByCustomerCSVReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetSalesByCustomerCSVReq): GetSalesByCustomerCSVReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.analyticsv2.SalesByCustomerSortBy sort_by */ 1:
                    message.sortBy = reader.int32();
                    break;
                case /* rd.analyticsv2.SortOrder sort_order */ 2:
                    message.sortOrder = reader.int32();
                    break;
                case /* rd.analyticsv2.Filters filters */ 3:
                    message.filters = Filters.internalBinaryRead(reader, reader.uint32(), options, message.filters);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetSalesByCustomerCSVReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.analyticsv2.SalesByCustomerSortBy sort_by = 1; */
        if (message.sortBy !== 0)
            writer.tag(1, WireType.Varint).int32(message.sortBy);
        /* rd.analyticsv2.SortOrder sort_order = 2; */
        if (message.sortOrder !== 0)
            writer.tag(2, WireType.Varint).int32(message.sortOrder);
        /* rd.analyticsv2.Filters filters = 3; */
        if (message.filters)
            Filters.internalBinaryWrite(message.filters, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.analytics.GetSalesByCustomerCSVReq
 */
export const GetSalesByCustomerCSVReq = new GetSalesByCustomerCSVReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSalesByCustomerCSVRes$Type extends MessageType<GetSalesByCustomerCSVRes> {
    constructor() {
        super("rd.api.analytics.GetSalesByCustomerCSVRes", [
            { no: 1, name: "bytes", kind: "scalar", T: 12 /*ScalarType.BYTES*/ }
        ]);
    }
    create(value?: PartialMessage<GetSalesByCustomerCSVRes>): GetSalesByCustomerCSVRes {
        const message = { bytes: new Uint8Array(0) };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetSalesByCustomerCSVRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetSalesByCustomerCSVRes): GetSalesByCustomerCSVRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bytes bytes */ 1:
                    message.bytes = reader.bytes();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetSalesByCustomerCSVRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bytes bytes = 1; */
        if (message.bytes.length)
            writer.tag(1, WireType.LengthDelimited).bytes(message.bytes);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.analytics.GetSalesByCustomerCSVRes
 */
export const GetSalesByCustomerCSVRes = new GetSalesByCustomerCSVRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSalesByClerkReq$Type extends MessageType<GetSalesByClerkReq> {
    constructor() {
        super("rd.api.analytics.GetSalesByClerkReq", [
            { no: 5, name: "sort_by", kind: "enum", T: () => ["rd.analytics.SalesByClerkSortBy", SalesByClerkSortBy, "SALES_BY_CLERK_SORT_BY_"] },
            { no: 6, name: "sort_order", kind: "enum", T: () => ["rd.analytics.SortOrder", SortOrder, "SORT_ORDER_"] },
            { no: 7, name: "filters", kind: "message", T: () => Filters, options: { "rd.validator.require": true } }
        ]);
    }
    create(value?: PartialMessage<GetSalesByClerkReq>): GetSalesByClerkReq {
        const message = { sortBy: 0, sortOrder: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetSalesByClerkReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetSalesByClerkReq): GetSalesByClerkReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.analytics.SalesByClerkSortBy sort_by */ 5:
                    message.sortBy = reader.int32();
                    break;
                case /* rd.analytics.SortOrder sort_order */ 6:
                    message.sortOrder = reader.int32();
                    break;
                case /* rd.analyticsv2.Filters filters */ 7:
                    message.filters = Filters.internalBinaryRead(reader, reader.uint32(), options, message.filters);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetSalesByClerkReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.analytics.SalesByClerkSortBy sort_by = 5; */
        if (message.sortBy !== 0)
            writer.tag(5, WireType.Varint).int32(message.sortBy);
        /* rd.analytics.SortOrder sort_order = 6; */
        if (message.sortOrder !== 0)
            writer.tag(6, WireType.Varint).int32(message.sortOrder);
        /* rd.analyticsv2.Filters filters = 7; */
        if (message.filters)
            Filters.internalBinaryWrite(message.filters, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.analytics.GetSalesByClerkReq
 */
export const GetSalesByClerkReq = new GetSalesByClerkReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSalesByClerkRes$Type extends MessageType<GetSalesByClerkRes> {
    constructor() {
        super("rd.api.analytics.GetSalesByClerkRes", [
            { no: 1, name: "report", kind: "message", T: () => Report }
        ]);
    }
    create(value?: PartialMessage<GetSalesByClerkRes>): GetSalesByClerkRes {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetSalesByClerkRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetSalesByClerkRes): GetSalesByClerkRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.analytics.Report report */ 1:
                    message.report = Report.internalBinaryRead(reader, reader.uint32(), options, message.report);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetSalesByClerkRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.analytics.Report report = 1; */
        if (message.report)
            Report.internalBinaryWrite(message.report, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.analytics.GetSalesByClerkRes
 */
export const GetSalesByClerkRes = new GetSalesByClerkRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSalesByClerkPDFReq$Type extends MessageType<GetSalesByClerkPDFReq> {
    constructor() {
        super("rd.api.analytics.GetSalesByClerkPDFReq", [
            { no: 5, name: "sort_by", kind: "enum", T: () => ["rd.analytics.SalesByClerkSortBy", SalesByClerkSortBy, "SALES_BY_CLERK_SORT_BY_"] },
            { no: 6, name: "sort_order", kind: "enum", T: () => ["rd.analytics.SortOrder", SortOrder, "SORT_ORDER_"] },
            { no: 7, name: "filters", kind: "message", T: () => Filters, options: { "rd.validator.require": true } }
        ]);
    }
    create(value?: PartialMessage<GetSalesByClerkPDFReq>): GetSalesByClerkPDFReq {
        const message = { sortBy: 0, sortOrder: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetSalesByClerkPDFReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetSalesByClerkPDFReq): GetSalesByClerkPDFReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.analytics.SalesByClerkSortBy sort_by */ 5:
                    message.sortBy = reader.int32();
                    break;
                case /* rd.analytics.SortOrder sort_order */ 6:
                    message.sortOrder = reader.int32();
                    break;
                case /* rd.analyticsv2.Filters filters */ 7:
                    message.filters = Filters.internalBinaryRead(reader, reader.uint32(), options, message.filters);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetSalesByClerkPDFReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.analytics.SalesByClerkSortBy sort_by = 5; */
        if (message.sortBy !== 0)
            writer.tag(5, WireType.Varint).int32(message.sortBy);
        /* rd.analytics.SortOrder sort_order = 6; */
        if (message.sortOrder !== 0)
            writer.tag(6, WireType.Varint).int32(message.sortOrder);
        /* rd.analyticsv2.Filters filters = 7; */
        if (message.filters)
            Filters.internalBinaryWrite(message.filters, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.analytics.GetSalesByClerkPDFReq
 */
export const GetSalesByClerkPDFReq = new GetSalesByClerkPDFReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSalesByClerkPDFRes$Type extends MessageType<GetSalesByClerkPDFRes> {
    constructor() {
        super("rd.api.analytics.GetSalesByClerkPDFRes", [
            { no: 1, name: "bytes", kind: "scalar", T: 12 /*ScalarType.BYTES*/ }
        ]);
    }
    create(value?: PartialMessage<GetSalesByClerkPDFRes>): GetSalesByClerkPDFRes {
        const message = { bytes: new Uint8Array(0) };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetSalesByClerkPDFRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetSalesByClerkPDFRes): GetSalesByClerkPDFRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bytes bytes */ 1:
                    message.bytes = reader.bytes();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetSalesByClerkPDFRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bytes bytes = 1; */
        if (message.bytes.length)
            writer.tag(1, WireType.LengthDelimited).bytes(message.bytes);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.analytics.GetSalesByClerkPDFRes
 */
export const GetSalesByClerkPDFRes = new GetSalesByClerkPDFRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSalesByClerkCSVReq$Type extends MessageType<GetSalesByClerkCSVReq> {
    constructor() {
        super("rd.api.analytics.GetSalesByClerkCSVReq", [
            { no: 5, name: "sort_by", kind: "enum", T: () => ["rd.analytics.SalesByClerkSortBy", SalesByClerkSortBy, "SALES_BY_CLERK_SORT_BY_"] },
            { no: 6, name: "sort_order", kind: "enum", T: () => ["rd.analytics.SortOrder", SortOrder, "SORT_ORDER_"] },
            { no: 7, name: "filters", kind: "message", T: () => Filters, options: { "rd.validator.require": true } }
        ]);
    }
    create(value?: PartialMessage<GetSalesByClerkCSVReq>): GetSalesByClerkCSVReq {
        const message = { sortBy: 0, sortOrder: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetSalesByClerkCSVReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetSalesByClerkCSVReq): GetSalesByClerkCSVReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.analytics.SalesByClerkSortBy sort_by */ 5:
                    message.sortBy = reader.int32();
                    break;
                case /* rd.analytics.SortOrder sort_order */ 6:
                    message.sortOrder = reader.int32();
                    break;
                case /* rd.analyticsv2.Filters filters */ 7:
                    message.filters = Filters.internalBinaryRead(reader, reader.uint32(), options, message.filters);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetSalesByClerkCSVReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.analytics.SalesByClerkSortBy sort_by = 5; */
        if (message.sortBy !== 0)
            writer.tag(5, WireType.Varint).int32(message.sortBy);
        /* rd.analytics.SortOrder sort_order = 6; */
        if (message.sortOrder !== 0)
            writer.tag(6, WireType.Varint).int32(message.sortOrder);
        /* rd.analyticsv2.Filters filters = 7; */
        if (message.filters)
            Filters.internalBinaryWrite(message.filters, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.analytics.GetSalesByClerkCSVReq
 */
export const GetSalesByClerkCSVReq = new GetSalesByClerkCSVReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSalesByClerkCSVRes$Type extends MessageType<GetSalesByClerkCSVRes> {
    constructor() {
        super("rd.api.analytics.GetSalesByClerkCSVRes", [
            { no: 1, name: "bytes", kind: "scalar", T: 12 /*ScalarType.BYTES*/ }
        ]);
    }
    create(value?: PartialMessage<GetSalesByClerkCSVRes>): GetSalesByClerkCSVRes {
        const message = { bytes: new Uint8Array(0) };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetSalesByClerkCSVRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetSalesByClerkCSVRes): GetSalesByClerkCSVRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bytes bytes */ 1:
                    message.bytes = reader.bytes();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetSalesByClerkCSVRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bytes bytes = 1; */
        if (message.bytes.length)
            writer.tag(1, WireType.LengthDelimited).bytes(message.bytes);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.analytics.GetSalesByClerkCSVRes
 */
export const GetSalesByClerkCSVRes = new GetSalesByClerkCSVRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetWriteOffsByDayReq$Type extends MessageType<GetWriteOffsByDayReq> {
    constructor() {
        super("rd.api.analytics.GetWriteOffsByDayReq", [
            { no: 1, name: "sort_by", kind: "enum", T: () => ["rd.analyticsv2.WriteOffsByDaySortBy", WriteOffsByDaySortBy, "WRITE_OFFS_BY_DAY_SORT_BY_"] },
            { no: 2, name: "sort_order", kind: "enum", T: () => ["rd.analyticsv2.SortOrder", SortOrder$, "SORT_ORDER_"] },
            { no: 3, name: "filters", kind: "message", T: () => Filters, options: { "rd.validator.require": true } }
        ]);
    }
    create(value?: PartialMessage<GetWriteOffsByDayReq>): GetWriteOffsByDayReq {
        const message = { sortBy: 0, sortOrder: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetWriteOffsByDayReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetWriteOffsByDayReq): GetWriteOffsByDayReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.analyticsv2.WriteOffsByDaySortBy sort_by */ 1:
                    message.sortBy = reader.int32();
                    break;
                case /* rd.analyticsv2.SortOrder sort_order */ 2:
                    message.sortOrder = reader.int32();
                    break;
                case /* rd.analyticsv2.Filters filters */ 3:
                    message.filters = Filters.internalBinaryRead(reader, reader.uint32(), options, message.filters);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetWriteOffsByDayReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.analyticsv2.WriteOffsByDaySortBy sort_by = 1; */
        if (message.sortBy !== 0)
            writer.tag(1, WireType.Varint).int32(message.sortBy);
        /* rd.analyticsv2.SortOrder sort_order = 2; */
        if (message.sortOrder !== 0)
            writer.tag(2, WireType.Varint).int32(message.sortOrder);
        /* rd.analyticsv2.Filters filters = 3; */
        if (message.filters)
            Filters.internalBinaryWrite(message.filters, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.analytics.GetWriteOffsByDayReq
 */
export const GetWriteOffsByDayReq = new GetWriteOffsByDayReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetWriteOffsByDayRes$Type extends MessageType<GetWriteOffsByDayRes> {
    constructor() {
        super("rd.api.analytics.GetWriteOffsByDayRes", [
            { no: 1, name: "report", kind: "message", T: () => Report }
        ]);
    }
    create(value?: PartialMessage<GetWriteOffsByDayRes>): GetWriteOffsByDayRes {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetWriteOffsByDayRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetWriteOffsByDayRes): GetWriteOffsByDayRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.analytics.Report report */ 1:
                    message.report = Report.internalBinaryRead(reader, reader.uint32(), options, message.report);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetWriteOffsByDayRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.analytics.Report report = 1; */
        if (message.report)
            Report.internalBinaryWrite(message.report, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.analytics.GetWriteOffsByDayRes
 */
export const GetWriteOffsByDayRes = new GetWriteOffsByDayRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetWriteOffsByDayPDFReq$Type extends MessageType<GetWriteOffsByDayPDFReq> {
    constructor() {
        super("rd.api.analytics.GetWriteOffsByDayPDFReq", [
            { no: 1, name: "sort_by", kind: "enum", T: () => ["rd.analyticsv2.WriteOffsByDaySortBy", WriteOffsByDaySortBy, "WRITE_OFFS_BY_DAY_SORT_BY_"] },
            { no: 2, name: "sort_order", kind: "enum", T: () => ["rd.analyticsv2.SortOrder", SortOrder$, "SORT_ORDER_"] },
            { no: 3, name: "filters", kind: "message", T: () => Filters, options: { "rd.validator.require": true } }
        ]);
    }
    create(value?: PartialMessage<GetWriteOffsByDayPDFReq>): GetWriteOffsByDayPDFReq {
        const message = { sortBy: 0, sortOrder: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetWriteOffsByDayPDFReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetWriteOffsByDayPDFReq): GetWriteOffsByDayPDFReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.analyticsv2.WriteOffsByDaySortBy sort_by */ 1:
                    message.sortBy = reader.int32();
                    break;
                case /* rd.analyticsv2.SortOrder sort_order */ 2:
                    message.sortOrder = reader.int32();
                    break;
                case /* rd.analyticsv2.Filters filters */ 3:
                    message.filters = Filters.internalBinaryRead(reader, reader.uint32(), options, message.filters);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetWriteOffsByDayPDFReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.analyticsv2.WriteOffsByDaySortBy sort_by = 1; */
        if (message.sortBy !== 0)
            writer.tag(1, WireType.Varint).int32(message.sortBy);
        /* rd.analyticsv2.SortOrder sort_order = 2; */
        if (message.sortOrder !== 0)
            writer.tag(2, WireType.Varint).int32(message.sortOrder);
        /* rd.analyticsv2.Filters filters = 3; */
        if (message.filters)
            Filters.internalBinaryWrite(message.filters, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.analytics.GetWriteOffsByDayPDFReq
 */
export const GetWriteOffsByDayPDFReq = new GetWriteOffsByDayPDFReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetWriteOffsByDayPDFRes$Type extends MessageType<GetWriteOffsByDayPDFRes> {
    constructor() {
        super("rd.api.analytics.GetWriteOffsByDayPDFRes", [
            { no: 1, name: "bytes", kind: "scalar", T: 12 /*ScalarType.BYTES*/ }
        ]);
    }
    create(value?: PartialMessage<GetWriteOffsByDayPDFRes>): GetWriteOffsByDayPDFRes {
        const message = { bytes: new Uint8Array(0) };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetWriteOffsByDayPDFRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetWriteOffsByDayPDFRes): GetWriteOffsByDayPDFRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bytes bytes */ 1:
                    message.bytes = reader.bytes();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetWriteOffsByDayPDFRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bytes bytes = 1; */
        if (message.bytes.length)
            writer.tag(1, WireType.LengthDelimited).bytes(message.bytes);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.analytics.GetWriteOffsByDayPDFRes
 */
export const GetWriteOffsByDayPDFRes = new GetWriteOffsByDayPDFRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetWriteOffsByDayCSVReq$Type extends MessageType<GetWriteOffsByDayCSVReq> {
    constructor() {
        super("rd.api.analytics.GetWriteOffsByDayCSVReq", [
            { no: 1, name: "sort_by", kind: "enum", T: () => ["rd.analyticsv2.WriteOffsByDaySortBy", WriteOffsByDaySortBy, "WRITE_OFFS_BY_DAY_SORT_BY_"] },
            { no: 2, name: "sort_order", kind: "enum", T: () => ["rd.analyticsv2.SortOrder", SortOrder$, "SORT_ORDER_"] },
            { no: 3, name: "filters", kind: "message", T: () => Filters, options: { "rd.validator.require": true } }
        ]);
    }
    create(value?: PartialMessage<GetWriteOffsByDayCSVReq>): GetWriteOffsByDayCSVReq {
        const message = { sortBy: 0, sortOrder: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetWriteOffsByDayCSVReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetWriteOffsByDayCSVReq): GetWriteOffsByDayCSVReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.analyticsv2.WriteOffsByDaySortBy sort_by */ 1:
                    message.sortBy = reader.int32();
                    break;
                case /* rd.analyticsv2.SortOrder sort_order */ 2:
                    message.sortOrder = reader.int32();
                    break;
                case /* rd.analyticsv2.Filters filters */ 3:
                    message.filters = Filters.internalBinaryRead(reader, reader.uint32(), options, message.filters);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetWriteOffsByDayCSVReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.analyticsv2.WriteOffsByDaySortBy sort_by = 1; */
        if (message.sortBy !== 0)
            writer.tag(1, WireType.Varint).int32(message.sortBy);
        /* rd.analyticsv2.SortOrder sort_order = 2; */
        if (message.sortOrder !== 0)
            writer.tag(2, WireType.Varint).int32(message.sortOrder);
        /* rd.analyticsv2.Filters filters = 3; */
        if (message.filters)
            Filters.internalBinaryWrite(message.filters, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.analytics.GetWriteOffsByDayCSVReq
 */
export const GetWriteOffsByDayCSVReq = new GetWriteOffsByDayCSVReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetWriteOffsByDayCSVRes$Type extends MessageType<GetWriteOffsByDayCSVRes> {
    constructor() {
        super("rd.api.analytics.GetWriteOffsByDayCSVRes", [
            { no: 1, name: "bytes", kind: "scalar", T: 12 /*ScalarType.BYTES*/ }
        ]);
    }
    create(value?: PartialMessage<GetWriteOffsByDayCSVRes>): GetWriteOffsByDayCSVRes {
        const message = { bytes: new Uint8Array(0) };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetWriteOffsByDayCSVRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetWriteOffsByDayCSVRes): GetWriteOffsByDayCSVRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bytes bytes */ 1:
                    message.bytes = reader.bytes();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetWriteOffsByDayCSVRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bytes bytes = 1; */
        if (message.bytes.length)
            writer.tag(1, WireType.LengthDelimited).bytes(message.bytes);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.analytics.GetWriteOffsByDayCSVRes
 */
export const GetWriteOffsByDayCSVRes = new GetWriteOffsByDayCSVRes$Type();
/**
 * @generated ServiceType for protobuf service rd.api.analytics.AnalyticsService
 */
export const AnalyticsService = new ServiceType("rd.api.analytics.AnalyticsService", [
    { name: "GetProfitAndLossPDF", options: {}, I: GetProfitAndLossPDFReq, O: GetProfitAndLossPDFRes },
    { name: "GetProfitAndLoss", options: {}, I: GetProfitAndLossReq, O: GetProfitAndLossRes },
    { name: "GetGrossProfitAndRevenueByLocation", options: {}, I: GetGrossProfitAndRevenueByLocationReq, O: GetGrossProfitAndRevenueByLocationRes },
    { name: "GetInventoryValuation", options: {}, I: GetInventoryValuationReq, O: GetInventoryValuationRes },
    { name: "GetInventoryValuationPDF", options: {}, I: GetInventoryValuationPDFReq, O: GetInventoryValuationPDFRes },
    { name: "GetInventoryValuationCSV", options: {}, I: GetInventoryValuationCSVReq, O: GetInventoryValuationCSVRes },
    { name: "GetCustomerAging", options: {}, I: GetCustomerAgingReq, O: GetCustomerAgingRes },
    { name: "GetCustomerAgingPDF", options: {}, I: GetCustomerAgingPDFReq, O: GetCustomerAgingPDFRes },
    { name: "GetCustomerAgingCSV", options: {}, I: GetCustomerAgingCSVReq, O: GetCustomerAgingCSVRes },
    { name: "GenerateJournalEntries", options: {}, I: GenerateJournalEntriesReq, O: GenerateJournalEntriesRes },
    { name: "GenerateJournalEntriesPDF", options: {}, I: GenerateJournalEntriesPDFReq, O: GenerateJournalEntriesPDFRes },
    { name: "GetTaxEntries", options: {}, I: GetTaxEntriesReq, O: GetTaxEntriesRes },
    { name: "GetTaxEntriesPDF", options: {}, I: GetTaxEntriesPDFReq, O: GetTaxEntriesPDFRes },
    { name: "GetTaxEntriesCSV", options: {}, I: GetTaxEntriesCSVReq, O: GetTaxEntriesCSVRes },
    { name: "GetPriceEdits", options: {}, I: GetPriceEditsReq, O: GetPriceEditsRes },
    { name: "GetPriceEditsPDF", options: {}, I: GetPriceEditsPDFReq, O: GetPriceEditsPDFRes },
    { name: "GetPriceEditsCSV", options: {}, I: GetPriceEditsCSVReq, O: GetPriceEditsCSVRes },
    { name: "GetNewAccountsOpened", options: {}, I: GetNewAccountsOpenedReq, O: GetNewAccountsOpenedRes },
    { name: "GetNewAccountsOpenedPDF", options: {}, I: GetNewAccountsOpenedPDFReq, O: GetNewAccountsOpenedPDFRes },
    { name: "GetNewAccountsOpenedCSV", options: {}, I: GetNewAccountsOpenedCSVReq, O: GetNewAccountsOpenedCSVRes },
    { name: "GetSalesDetail", options: {}, I: GetSalesDetailReq, O: GetSalesDetailRes },
    { name: "GetSalesDetailCSV", options: {}, I: GetSalesDetailCSVReq, O: GetSalesDetailCSVRes },
    { name: "GetSalesByDay", options: {}, I: GetSalesByDayReq, O: GetSalesByDayRes },
    { name: "GetSalesByDayPDF", options: {}, I: GetSalesByDayPDFReq, O: GetSalesByDayPDFRes },
    { name: "GetSalesByDayCSV", options: {}, I: GetSalesByDayCSVReq, O: GetSalesByDayCSVRes },
    { name: "GetSalesByClerk", options: {}, I: GetSalesByClerkReq, O: GetSalesByClerkRes },
    { name: "GetSalesByClerkPDF", options: {}, I: GetSalesByClerkPDFReq, O: GetSalesByClerkPDFRes },
    { name: "GetSalesByClerkCSV", options: {}, I: GetSalesByClerkCSVReq, O: GetSalesByClerkCSVRes },
    { name: "GetSalesByCustomer", options: {}, I: GetSalesByCustomerReq, O: GetSalesByCustomerRes },
    { name: "GetSalesByCustomerPDF", options: {}, I: GetSalesByCustomerPDFReq, O: GetSalesByCustomerPDFRes },
    { name: "GetSalesByCustomerCSV", options: {}, I: GetSalesByCustomerCSVReq, O: GetSalesByCustomerCSVRes },
    { name: "GetWriteOffsByDay", options: {}, I: GetWriteOffsByDayReq, O: GetWriteOffsByDayRes },
    { name: "GetWriteOffsByDayPDF", options: {}, I: GetWriteOffsByDayPDFReq, O: GetWriteOffsByDayPDFRes },
    { name: "GetWriteOffsByDayCSV", options: {}, I: GetWriteOffsByDayCSVReq, O: GetWriteOffsByDayCSVRes }
]);
