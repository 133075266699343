import { ComponentPropsWithoutRef } from "react"
import { classNames } from "lib/classNames"

interface LoadingSpinnerProps extends ComponentPropsWithoutRef<"svg"> {
  size?: "small" | "large" | "extra-large"
  fill?: string
  pathClassName?: string
}

const SMALL_LOADING_SPINNER_PATH_D =
  "M24 12C24 9.33123 23.1104 6.73861 21.4718 4.63211C19.8332 2.52562 17.5391 1.02546 14.9524 0.368857C12.3656 -0.287744 9.63383 -0.0633154 7.18894 1.00665C4.74404 2.07662 2.72559 3.93107 1.45275 6.27675C0.179917 8.62243 -0.274664 11.3255 0.160896 13.9585C0.596455 16.5915 1.8973 19.0041 3.85772 20.8149C5.81814 22.6258 8.32627 23.7314 10.9855 23.957C13.6447 24.1827 16.3032 23.5154 18.5407 22.0608L17.1629 19.9414C15.3967 21.0896 13.2982 21.6163 11.1992 21.4382C9.10017 21.2601 7.1204 20.3874 5.57296 18.958C4.02551 17.5286 2.9987 15.6242 2.6549 13.5459C2.31109 11.4676 2.66991 9.33394 3.67462 7.4824C4.67932 5.63085 6.27257 4.16706 8.20243 3.32249C10.1323 2.47792 12.2886 2.30077 14.3304 2.81905C16.3723 3.33733 18.183 4.52147 19.4765 6.18422C20.7699 7.84696 21.4721 9.89343 21.4721 12H24Z"

const LARGE_LOADING_SPINNER_PATH_D =
  "M32 16C32 12.4416 30.8138 8.98481 28.629 6.17615C26.4442 3.36749 23.3855 1.36728 19.9365 0.49181C16.4875 -0.383658 12.8451 -0.0844206 9.58525 1.3422C6.32539 2.76883 3.63412 5.24143 1.937 8.369C0.239889 11.4966 -0.366218 15.1006 0.214528 18.6113C0.795274 22.1219 2.52973 25.3388 5.14363 27.7533C7.75752 30.1677 11.1017 31.6419 14.6473 31.9427C18.1929 32.2436 21.7377 31.3539 24.721 29.4143L22.9768 26.7315C20.5901 28.2831 17.7543 28.9948 14.9178 28.7542C12.0814 28.5135 9.40602 27.3342 7.3149 25.4026C5.22378 23.4711 3.83622 20.8976 3.37162 18.089C2.90703 15.2805 3.39191 12.3973 4.7496 9.8952C6.1073 7.39314 8.26031 5.41506 10.8682 4.27376C13.4761 3.13246 16.39 2.89307 19.1492 3.59345C21.9084 4.29382 24.3554 5.89399 26.1032 8.14092C27.8511 10.3879 28.8 13.1533 28.8 16H32Z"

const EXTRA_LARGE_LOADING_SPINNER_PATH_D =
  "M124 62C124 48.2114 119.404 34.8162 110.937 23.9326C102.471 13.049 90.6188 5.2982 77.2539 1.90576C63.8891 -1.48668 49.7748 -0.32713 37.1428 5.20104C24.5109 10.7292 14.0822 20.3105 7.50589 32.4299C0.929569 44.5492 -1.41909 58.515 0.831295 72.1188C3.08169 85.7225 9.8027 98.188 19.9316 107.544C30.0604 116.9 43.019 122.612 56.7583 123.778C70.4976 124.944 84.2335 121.496 95.7938 113.981L92.4411 108.824C82.0277 115.594 69.6546 118.699 57.2784 117.649C44.9022 116.599 33.2291 111.453 24.1052 103.025C14.9812 94.5977 8.927 83.369 6.89987 71.1149C4.87274 58.8608 6.98839 46.2805 12.9123 35.3635C18.8362 24.4466 28.2302 15.8158 39.6089 10.8361C50.9877 5.85637 63.7017 4.81186 75.7406 7.86774C87.7795 10.9236 98.4562 17.9055 106.082 27.7093C113.709 37.5131 117.849 49.5793 117.849 62H124Z"

export function LoadingSpinner(props: LoadingSpinnerProps) {
  const {
    className,
    size = "small",
    fill = "currentColor",
    pathClassName,
    ...otherProps
  } = props

  let svgViewBox = "0 0 24 24"
  let pathD = SMALL_LOADING_SPINNER_PATH_D
  if (size === "large") {
    svgViewBox = "0 0 32 32"
    pathD = LARGE_LOADING_SPINNER_PATH_D
  } else if (size === "extra-large") {
    svgViewBox = "0 0 124 124"
    pathD = EXTRA_LARGE_LOADING_SPINNER_PATH_D
  }

  return (
    <svg
      data-testid="loading-spinner-animation"
      className={classNames(
        "animate-spin",
        size === "small" && "h-5 w-5",
        size === "large" && "h-8 w-8",
        size === "extra-large" && "h-24 w-24",
        className
      )}
      viewBox={svgViewBox}
      {...otherProps}
    >
      <path d={pathD} fill={fill} className={pathClassName} />
    </svg>
  )
}
