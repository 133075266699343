/* eslint-disable */
// @generated by protobuf-ts 2.9.0 with parameter generate_dependencies,add_pb_suffix,ts_nocheck,eslint_disable
// @generated from protobuf file "search/service/service.proto" (package "rd.search.service", syntax proto3)
// tslint:disable
// @ts-nocheck
import { CxaSearchService } from "./service_pb";
import type { CxaSearchReturnsRes } from "./service_pb";
import type { CxaSearchReturnsReq } from "./service_pb";
import type { CxaSearchOutstandingSalesRes } from "./service_pb";
import type { CxaSearchOutstandingSalesReq } from "./service_pb";
import type { CxaSearchSalesRes } from "./service_pb";
import type { CxaSearchSalesReq } from "./service_pb";
import { SearchalyticsService } from "./service_pb";
import type { GetSalesByDayCSVRes } from "../../api/analytics/service_pb";
import type { GetSalesByDayCSVReq } from "../../api/analytics/service_pb";
import type { GetSalesByDayPDFRes } from "../../api/analytics/service_pb";
import type { GetSalesByDayPDFReq } from "../../api/analytics/service_pb";
import type { GetSalesByDayRes } from "../../api/analytics/service_pb";
import type { GetSalesByDayReq } from "../../api/analytics/service_pb";
import { BulkService } from "./service_pb";
import type { BulkExportVendorsRes } from "./service_pb";
import type { BulkExportVendorsReq } from "./service_pb";
import type { BulkExportProductsRes } from "./service_pb";
import type { BulkExportProductsReq } from "./service_pb";
import type { BulkExportCustomersRes } from "./service_pb";
import type { BulkExportCustomersReq } from "./service_pb";
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { SearchService } from "./service_pb";
import type { SearchCustomPricesV2Res } from "./service_pb";
import type { SearchCustomPricesV2Req } from "./service_pb";
import type { SearchInventoryChangesRes } from "./service_pb";
import type { SearchInventoryChangesReq } from "./service_pb";
import type { SearchOrderShipmentsForReconciliationRes } from "./service_pb";
import type { SearchOrderShipmentsForReconciliationReq } from "./service_pb";
import type { ReloadRes } from "./service_pb";
import type { ReloadReq } from "./service_pb";
import type { UpdateRecordRes } from "./service_pb";
import type { UpdateRecordReq } from "./service_pb";
import type { SearchFinancialTransactionsRes } from "./service_pb";
import type { SearchFinancialTransactionsReq } from "./service_pb";
import type { SearchCashTransactionsRes } from "./service_pb";
import type { SearchCashTransactionsReq } from "./service_pb";
import type { SearchVendorsRes } from "./service_pb";
import type { SearchVendorsReq } from "./service_pb";
import type { SearchTransfersRes } from "./service_pb";
import type { SearchTransfersReq } from "./service_pb";
import type { SearchTintColorsRes } from "./service_pb";
import type { SearchTintColorsReq } from "./service_pb";
import type { SearchOutstandingSalesRes } from "./service_pb";
import type { SearchOutstandingSalesReq } from "./service_pb";
import type { SearchReturnsRes } from "./service_pb";
import type { SearchReturnsReq } from "./service_pb";
import type { SearchSalesV2Res } from "./service_pb";
import type { SearchSalesV2Req } from "./service_pb";
import type { SearchProductsV2Res } from "./service_pb";
import type { SearchProductsV2Req } from "./service_pb";
import type { SearchOrderShipmentsRes } from "./service_pb";
import type { SearchOrderShipmentsReq } from "./service_pb";
import type { SearchOrdersRes } from "./service_pb";
import type { SearchOrdersReq } from "./service_pb";
import type { SearchExcludedInventoryCountProductsRes } from "./service_pb";
import type { SearchExcludedInventoryCountProductsReq } from "./service_pb";
import type { SearchInventoryCountProductsRes } from "./service_pb";
import type { SearchInventoryCountProductsReq } from "./service_pb";
import type { SearchExternalTransactionsRes } from "./service_pb";
import type { SearchExternalTransactionsReq } from "./service_pb";
import type { SearchCustomPricesRes } from "./service_pb";
import type { SearchCustomPricesReq } from "./service_pb";
import type { SearchCustomersV2Res } from "./service_pb";
import type { SearchCustomersV2Req } from "./service_pb";
import type { SearchRefundedBillPaymentsRes } from "./service_pb";
import type { SearchRefundedBillPaymentsReq } from "./service_pb";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { SearchBillPaymentsRes } from "./service_pb";
import type { SearchBillPaymentsReq } from "./service_pb";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service rd.search.service.SearchService
 */
export interface ISearchServiceClient {
    /**
     * @generated from protobuf rpc: SearchBillPayments(rd.search.service.SearchBillPaymentsReq) returns (rd.search.service.SearchBillPaymentsRes);
     */
    searchBillPayments(input: SearchBillPaymentsReq, options?: RpcOptions): UnaryCall<SearchBillPaymentsReq, SearchBillPaymentsRes>;
    /**
     * @generated from protobuf rpc: SearchRefundedBillPayments(rd.search.service.SearchRefundedBillPaymentsReq) returns (rd.search.service.SearchRefundedBillPaymentsRes);
     */
    searchRefundedBillPayments(input: SearchRefundedBillPaymentsReq, options?: RpcOptions): UnaryCall<SearchRefundedBillPaymentsReq, SearchRefundedBillPaymentsRes>;
    /**
     * @generated from protobuf rpc: SearchCustomersV2(rd.search.service.SearchCustomersV2Req) returns (rd.search.service.SearchCustomersV2Res);
     */
    searchCustomersV2(input: SearchCustomersV2Req, options?: RpcOptions): UnaryCall<SearchCustomersV2Req, SearchCustomersV2Res>;
    /**
     * @generated from protobuf rpc: SearchCustomPrices(rd.search.service.SearchCustomPricesReq) returns (rd.search.service.SearchCustomPricesRes);
     */
    searchCustomPrices(input: SearchCustomPricesReq, options?: RpcOptions): UnaryCall<SearchCustomPricesReq, SearchCustomPricesRes>;
    /**
     * @generated from protobuf rpc: SearchExternalTransactions(rd.search.service.SearchExternalTransactionsReq) returns (rd.search.service.SearchExternalTransactionsRes);
     */
    searchExternalTransactions(input: SearchExternalTransactionsReq, options?: RpcOptions): UnaryCall<SearchExternalTransactionsReq, SearchExternalTransactionsRes>;
    /**
     * @generated from protobuf rpc: SearchInventoryCountProducts(rd.search.service.SearchInventoryCountProductsReq) returns (rd.search.service.SearchInventoryCountProductsRes);
     */
    searchInventoryCountProducts(input: SearchInventoryCountProductsReq, options?: RpcOptions): UnaryCall<SearchInventoryCountProductsReq, SearchInventoryCountProductsRes>;
    /**
     * @generated from protobuf rpc: SearchExcludedInventoryCountProducts(rd.search.service.SearchExcludedInventoryCountProductsReq) returns (rd.search.service.SearchExcludedInventoryCountProductsRes);
     */
    searchExcludedInventoryCountProducts(input: SearchExcludedInventoryCountProductsReq, options?: RpcOptions): UnaryCall<SearchExcludedInventoryCountProductsReq, SearchExcludedInventoryCountProductsRes>;
    /**
     * @generated from protobuf rpc: SearchOrders(rd.search.service.SearchOrdersReq) returns (rd.search.service.SearchOrdersRes);
     */
    searchOrders(input: SearchOrdersReq, options?: RpcOptions): UnaryCall<SearchOrdersReq, SearchOrdersRes>;
    /**
     * @generated from protobuf rpc: SearchOrderShipments(rd.search.service.SearchOrderShipmentsReq) returns (rd.search.service.SearchOrderShipmentsRes);
     */
    searchOrderShipments(input: SearchOrderShipmentsReq, options?: RpcOptions): UnaryCall<SearchOrderShipmentsReq, SearchOrderShipmentsRes>;
    /**
     * @generated from protobuf rpc: SearchProductsV2(rd.search.service.SearchProductsV2Req) returns (rd.search.service.SearchProductsV2Res);
     */
    searchProductsV2(input: SearchProductsV2Req, options?: RpcOptions): UnaryCall<SearchProductsV2Req, SearchProductsV2Res>;
    /**
     * @generated from protobuf rpc: SearchSalesV2(rd.search.service.SearchSalesV2Req) returns (rd.search.service.SearchSalesV2Res);
     */
    searchSalesV2(input: SearchSalesV2Req, options?: RpcOptions): UnaryCall<SearchSalesV2Req, SearchSalesV2Res>;
    /**
     * @generated from protobuf rpc: SearchReturns(rd.search.service.SearchReturnsReq) returns (rd.search.service.SearchReturnsRes);
     */
    searchReturns(input: SearchReturnsReq, options?: RpcOptions): UnaryCall<SearchReturnsReq, SearchReturnsRes>;
    /**
     * @generated from protobuf rpc: SearchOutstandingSales(rd.search.service.SearchOutstandingSalesReq) returns (rd.search.service.SearchOutstandingSalesRes);
     */
    searchOutstandingSales(input: SearchOutstandingSalesReq, options?: RpcOptions): UnaryCall<SearchOutstandingSalesReq, SearchOutstandingSalesRes>;
    /**
     * @generated from protobuf rpc: SearchTintColors(rd.search.service.SearchTintColorsReq) returns (rd.search.service.SearchTintColorsRes);
     */
    searchTintColors(input: SearchTintColorsReq, options?: RpcOptions): UnaryCall<SearchTintColorsReq, SearchTintColorsRes>;
    /**
     * @generated from protobuf rpc: SearchTransfers(rd.search.service.SearchTransfersReq) returns (rd.search.service.SearchTransfersRes);
     */
    searchTransfers(input: SearchTransfersReq, options?: RpcOptions): UnaryCall<SearchTransfersReq, SearchTransfersRes>;
    /**
     * @generated from protobuf rpc: SearchVendors(rd.search.service.SearchVendorsReq) returns (rd.search.service.SearchVendorsRes);
     */
    searchVendors(input: SearchVendorsReq, options?: RpcOptions): UnaryCall<SearchVendorsReq, SearchVendorsRes>;
    /**
     * @generated from protobuf rpc: SearchCashTransactions(rd.search.service.SearchCashTransactionsReq) returns (rd.search.service.SearchCashTransactionsRes);
     */
    searchCashTransactions(input: SearchCashTransactionsReq, options?: RpcOptions): UnaryCall<SearchCashTransactionsReq, SearchCashTransactionsRes>;
    /**
     * @generated from protobuf rpc: SearchFinancialTransactions(rd.search.service.SearchFinancialTransactionsReq) returns (rd.search.service.SearchFinancialTransactionsRes);
     */
    searchFinancialTransactions(input: SearchFinancialTransactionsReq, options?: RpcOptions): UnaryCall<SearchFinancialTransactionsReq, SearchFinancialTransactionsRes>;
    /**
     * @generated from protobuf rpc: UpdateRecord(rd.search.service.UpdateRecordReq) returns (rd.search.service.UpdateRecordRes);
     */
    updateRecord(input: UpdateRecordReq, options?: RpcOptions): UnaryCall<UpdateRecordReq, UpdateRecordRes>;
    /**
     * @generated from protobuf rpc: Reload(rd.search.service.ReloadReq) returns (rd.search.service.ReloadRes);
     */
    reload(input: ReloadReq, options?: RpcOptions): UnaryCall<ReloadReq, ReloadRes>;
    /**
     * @generated from protobuf rpc: SearchOrderShipmentsForReconciliation(rd.search.service.SearchOrderShipmentsForReconciliationReq) returns (rd.search.service.SearchOrderShipmentsForReconciliationRes);
     */
    searchOrderShipmentsForReconciliation(input: SearchOrderShipmentsForReconciliationReq, options?: RpcOptions): UnaryCall<SearchOrderShipmentsForReconciliationReq, SearchOrderShipmentsForReconciliationRes>;
    /**
     * @generated from protobuf rpc: SearchInventoryChanges(rd.search.service.SearchInventoryChangesReq) returns (rd.search.service.SearchInventoryChangesRes);
     */
    searchInventoryChanges(input: SearchInventoryChangesReq, options?: RpcOptions): UnaryCall<SearchInventoryChangesReq, SearchInventoryChangesRes>;
    /**
     * @generated from protobuf rpc: SearchCustomPricesV2(rd.search.service.SearchCustomPricesV2Req) returns (rd.search.service.SearchCustomPricesV2Res);
     */
    searchCustomPricesV2(input: SearchCustomPricesV2Req, options?: RpcOptions): UnaryCall<SearchCustomPricesV2Req, SearchCustomPricesV2Res>;
}
/**
 * @generated from protobuf service rd.search.service.SearchService
 */
export class SearchServiceClient implements ISearchServiceClient, ServiceInfo {
    typeName = SearchService.typeName;
    methods = SearchService.methods;
    options = SearchService.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * @generated from protobuf rpc: SearchBillPayments(rd.search.service.SearchBillPaymentsReq) returns (rd.search.service.SearchBillPaymentsRes);
     */
    searchBillPayments(input: SearchBillPaymentsReq, options?: RpcOptions): UnaryCall<SearchBillPaymentsReq, SearchBillPaymentsRes> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<SearchBillPaymentsReq, SearchBillPaymentsRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: SearchRefundedBillPayments(rd.search.service.SearchRefundedBillPaymentsReq) returns (rd.search.service.SearchRefundedBillPaymentsRes);
     */
    searchRefundedBillPayments(input: SearchRefundedBillPaymentsReq, options?: RpcOptions): UnaryCall<SearchRefundedBillPaymentsReq, SearchRefundedBillPaymentsRes> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<SearchRefundedBillPaymentsReq, SearchRefundedBillPaymentsRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: SearchCustomersV2(rd.search.service.SearchCustomersV2Req) returns (rd.search.service.SearchCustomersV2Res);
     */
    searchCustomersV2(input: SearchCustomersV2Req, options?: RpcOptions): UnaryCall<SearchCustomersV2Req, SearchCustomersV2Res> {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept<SearchCustomersV2Req, SearchCustomersV2Res>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: SearchCustomPrices(rd.search.service.SearchCustomPricesReq) returns (rd.search.service.SearchCustomPricesRes);
     */
    searchCustomPrices(input: SearchCustomPricesReq, options?: RpcOptions): UnaryCall<SearchCustomPricesReq, SearchCustomPricesRes> {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept<SearchCustomPricesReq, SearchCustomPricesRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: SearchExternalTransactions(rd.search.service.SearchExternalTransactionsReq) returns (rd.search.service.SearchExternalTransactionsRes);
     */
    searchExternalTransactions(input: SearchExternalTransactionsReq, options?: RpcOptions): UnaryCall<SearchExternalTransactionsReq, SearchExternalTransactionsRes> {
        const method = this.methods[4], opt = this._transport.mergeOptions(options);
        return stackIntercept<SearchExternalTransactionsReq, SearchExternalTransactionsRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: SearchInventoryCountProducts(rd.search.service.SearchInventoryCountProductsReq) returns (rd.search.service.SearchInventoryCountProductsRes);
     */
    searchInventoryCountProducts(input: SearchInventoryCountProductsReq, options?: RpcOptions): UnaryCall<SearchInventoryCountProductsReq, SearchInventoryCountProductsRes> {
        const method = this.methods[5], opt = this._transport.mergeOptions(options);
        return stackIntercept<SearchInventoryCountProductsReq, SearchInventoryCountProductsRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: SearchExcludedInventoryCountProducts(rd.search.service.SearchExcludedInventoryCountProductsReq) returns (rd.search.service.SearchExcludedInventoryCountProductsRes);
     */
    searchExcludedInventoryCountProducts(input: SearchExcludedInventoryCountProductsReq, options?: RpcOptions): UnaryCall<SearchExcludedInventoryCountProductsReq, SearchExcludedInventoryCountProductsRes> {
        const method = this.methods[6], opt = this._transport.mergeOptions(options);
        return stackIntercept<SearchExcludedInventoryCountProductsReq, SearchExcludedInventoryCountProductsRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: SearchOrders(rd.search.service.SearchOrdersReq) returns (rd.search.service.SearchOrdersRes);
     */
    searchOrders(input: SearchOrdersReq, options?: RpcOptions): UnaryCall<SearchOrdersReq, SearchOrdersRes> {
        const method = this.methods[7], opt = this._transport.mergeOptions(options);
        return stackIntercept<SearchOrdersReq, SearchOrdersRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: SearchOrderShipments(rd.search.service.SearchOrderShipmentsReq) returns (rd.search.service.SearchOrderShipmentsRes);
     */
    searchOrderShipments(input: SearchOrderShipmentsReq, options?: RpcOptions): UnaryCall<SearchOrderShipmentsReq, SearchOrderShipmentsRes> {
        const method = this.methods[8], opt = this._transport.mergeOptions(options);
        return stackIntercept<SearchOrderShipmentsReq, SearchOrderShipmentsRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: SearchProductsV2(rd.search.service.SearchProductsV2Req) returns (rd.search.service.SearchProductsV2Res);
     */
    searchProductsV2(input: SearchProductsV2Req, options?: RpcOptions): UnaryCall<SearchProductsV2Req, SearchProductsV2Res> {
        const method = this.methods[9], opt = this._transport.mergeOptions(options);
        return stackIntercept<SearchProductsV2Req, SearchProductsV2Res>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: SearchSalesV2(rd.search.service.SearchSalesV2Req) returns (rd.search.service.SearchSalesV2Res);
     */
    searchSalesV2(input: SearchSalesV2Req, options?: RpcOptions): UnaryCall<SearchSalesV2Req, SearchSalesV2Res> {
        const method = this.methods[10], opt = this._transport.mergeOptions(options);
        return stackIntercept<SearchSalesV2Req, SearchSalesV2Res>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: SearchReturns(rd.search.service.SearchReturnsReq) returns (rd.search.service.SearchReturnsRes);
     */
    searchReturns(input: SearchReturnsReq, options?: RpcOptions): UnaryCall<SearchReturnsReq, SearchReturnsRes> {
        const method = this.methods[11], opt = this._transport.mergeOptions(options);
        return stackIntercept<SearchReturnsReq, SearchReturnsRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: SearchOutstandingSales(rd.search.service.SearchOutstandingSalesReq) returns (rd.search.service.SearchOutstandingSalesRes);
     */
    searchOutstandingSales(input: SearchOutstandingSalesReq, options?: RpcOptions): UnaryCall<SearchOutstandingSalesReq, SearchOutstandingSalesRes> {
        const method = this.methods[12], opt = this._transport.mergeOptions(options);
        return stackIntercept<SearchOutstandingSalesReq, SearchOutstandingSalesRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: SearchTintColors(rd.search.service.SearchTintColorsReq) returns (rd.search.service.SearchTintColorsRes);
     */
    searchTintColors(input: SearchTintColorsReq, options?: RpcOptions): UnaryCall<SearchTintColorsReq, SearchTintColorsRes> {
        const method = this.methods[13], opt = this._transport.mergeOptions(options);
        return stackIntercept<SearchTintColorsReq, SearchTintColorsRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: SearchTransfers(rd.search.service.SearchTransfersReq) returns (rd.search.service.SearchTransfersRes);
     */
    searchTransfers(input: SearchTransfersReq, options?: RpcOptions): UnaryCall<SearchTransfersReq, SearchTransfersRes> {
        const method = this.methods[14], opt = this._transport.mergeOptions(options);
        return stackIntercept<SearchTransfersReq, SearchTransfersRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: SearchVendors(rd.search.service.SearchVendorsReq) returns (rd.search.service.SearchVendorsRes);
     */
    searchVendors(input: SearchVendorsReq, options?: RpcOptions): UnaryCall<SearchVendorsReq, SearchVendorsRes> {
        const method = this.methods[15], opt = this._transport.mergeOptions(options);
        return stackIntercept<SearchVendorsReq, SearchVendorsRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: SearchCashTransactions(rd.search.service.SearchCashTransactionsReq) returns (rd.search.service.SearchCashTransactionsRes);
     */
    searchCashTransactions(input: SearchCashTransactionsReq, options?: RpcOptions): UnaryCall<SearchCashTransactionsReq, SearchCashTransactionsRes> {
        const method = this.methods[16], opt = this._transport.mergeOptions(options);
        return stackIntercept<SearchCashTransactionsReq, SearchCashTransactionsRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: SearchFinancialTransactions(rd.search.service.SearchFinancialTransactionsReq) returns (rd.search.service.SearchFinancialTransactionsRes);
     */
    searchFinancialTransactions(input: SearchFinancialTransactionsReq, options?: RpcOptions): UnaryCall<SearchFinancialTransactionsReq, SearchFinancialTransactionsRes> {
        const method = this.methods[17], opt = this._transport.mergeOptions(options);
        return stackIntercept<SearchFinancialTransactionsReq, SearchFinancialTransactionsRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateRecord(rd.search.service.UpdateRecordReq) returns (rd.search.service.UpdateRecordRes);
     */
    updateRecord(input: UpdateRecordReq, options?: RpcOptions): UnaryCall<UpdateRecordReq, UpdateRecordRes> {
        const method = this.methods[18], opt = this._transport.mergeOptions(options);
        return stackIntercept<UpdateRecordReq, UpdateRecordRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: Reload(rd.search.service.ReloadReq) returns (rd.search.service.ReloadRes);
     */
    reload(input: ReloadReq, options?: RpcOptions): UnaryCall<ReloadReq, ReloadRes> {
        const method = this.methods[19], opt = this._transport.mergeOptions(options);
        return stackIntercept<ReloadReq, ReloadRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: SearchOrderShipmentsForReconciliation(rd.search.service.SearchOrderShipmentsForReconciliationReq) returns (rd.search.service.SearchOrderShipmentsForReconciliationRes);
     */
    searchOrderShipmentsForReconciliation(input: SearchOrderShipmentsForReconciliationReq, options?: RpcOptions): UnaryCall<SearchOrderShipmentsForReconciliationReq, SearchOrderShipmentsForReconciliationRes> {
        const method = this.methods[20], opt = this._transport.mergeOptions(options);
        return stackIntercept<SearchOrderShipmentsForReconciliationReq, SearchOrderShipmentsForReconciliationRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: SearchInventoryChanges(rd.search.service.SearchInventoryChangesReq) returns (rd.search.service.SearchInventoryChangesRes);
     */
    searchInventoryChanges(input: SearchInventoryChangesReq, options?: RpcOptions): UnaryCall<SearchInventoryChangesReq, SearchInventoryChangesRes> {
        const method = this.methods[21], opt = this._transport.mergeOptions(options);
        return stackIntercept<SearchInventoryChangesReq, SearchInventoryChangesRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: SearchCustomPricesV2(rd.search.service.SearchCustomPricesV2Req) returns (rd.search.service.SearchCustomPricesV2Res);
     */
    searchCustomPricesV2(input: SearchCustomPricesV2Req, options?: RpcOptions): UnaryCall<SearchCustomPricesV2Req, SearchCustomPricesV2Res> {
        const method = this.methods[22], opt = this._transport.mergeOptions(options);
        return stackIntercept<SearchCustomPricesV2Req, SearchCustomPricesV2Res>("unary", this._transport, method, opt, input);
    }
}
/**
 * @generated from protobuf service rd.search.service.BulkService
 */
export interface IBulkServiceClient {
    /**
     * @generated from protobuf rpc: BulkExportCustomers(rd.search.service.BulkExportCustomersReq) returns (rd.search.service.BulkExportCustomersRes);
     */
    bulkExportCustomers(input: BulkExportCustomersReq, options?: RpcOptions): UnaryCall<BulkExportCustomersReq, BulkExportCustomersRes>;
    /**
     * @generated from protobuf rpc: BulkExportProducts(rd.search.service.BulkExportProductsReq) returns (rd.search.service.BulkExportProductsRes);
     */
    bulkExportProducts(input: BulkExportProductsReq, options?: RpcOptions): UnaryCall<BulkExportProductsReq, BulkExportProductsRes>;
    /**
     * @generated from protobuf rpc: BulkExportVendors(rd.search.service.BulkExportVendorsReq) returns (rd.search.service.BulkExportVendorsRes);
     */
    bulkExportVendors(input: BulkExportVendorsReq, options?: RpcOptions): UnaryCall<BulkExportVendorsReq, BulkExportVendorsRes>;
}
/**
 * @generated from protobuf service rd.search.service.BulkService
 */
export class BulkServiceClient implements IBulkServiceClient, ServiceInfo {
    typeName = BulkService.typeName;
    methods = BulkService.methods;
    options = BulkService.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * @generated from protobuf rpc: BulkExportCustomers(rd.search.service.BulkExportCustomersReq) returns (rd.search.service.BulkExportCustomersRes);
     */
    bulkExportCustomers(input: BulkExportCustomersReq, options?: RpcOptions): UnaryCall<BulkExportCustomersReq, BulkExportCustomersRes> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<BulkExportCustomersReq, BulkExportCustomersRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BulkExportProducts(rd.search.service.BulkExportProductsReq) returns (rd.search.service.BulkExportProductsRes);
     */
    bulkExportProducts(input: BulkExportProductsReq, options?: RpcOptions): UnaryCall<BulkExportProductsReq, BulkExportProductsRes> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<BulkExportProductsReq, BulkExportProductsRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BulkExportVendors(rd.search.service.BulkExportVendorsReq) returns (rd.search.service.BulkExportVendorsRes);
     */
    bulkExportVendors(input: BulkExportVendorsReq, options?: RpcOptions): UnaryCall<BulkExportVendorsReq, BulkExportVendorsRes> {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept<BulkExportVendorsReq, BulkExportVendorsRes>("unary", this._transport, method, opt, input);
    }
}
/**
 * @generated from protobuf service rd.search.service.SearchalyticsService
 */
export interface ISearchalyticsServiceClient {
    /**
     * @generated from protobuf rpc: GetSalesByDay(rd.api.analytics.GetSalesByDayReq) returns (rd.api.analytics.GetSalesByDayRes);
     */
    getSalesByDay(input: GetSalesByDayReq, options?: RpcOptions): UnaryCall<GetSalesByDayReq, GetSalesByDayRes>;
    /**
     * @generated from protobuf rpc: GetSalesByDayPDF(rd.api.analytics.GetSalesByDayPDFReq) returns (rd.api.analytics.GetSalesByDayPDFRes);
     */
    getSalesByDayPDF(input: GetSalesByDayPDFReq, options?: RpcOptions): UnaryCall<GetSalesByDayPDFReq, GetSalesByDayPDFRes>;
    /**
     * @generated from protobuf rpc: GetSalesByDayCSV(rd.api.analytics.GetSalesByDayCSVReq) returns (rd.api.analytics.GetSalesByDayCSVRes);
     */
    getSalesByDayCSV(input: GetSalesByDayCSVReq, options?: RpcOptions): UnaryCall<GetSalesByDayCSVReq, GetSalesByDayCSVRes>;
}
/**
 * @generated from protobuf service rd.search.service.SearchalyticsService
 */
export class SearchalyticsServiceClient implements ISearchalyticsServiceClient, ServiceInfo {
    typeName = SearchalyticsService.typeName;
    methods = SearchalyticsService.methods;
    options = SearchalyticsService.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * @generated from protobuf rpc: GetSalesByDay(rd.api.analytics.GetSalesByDayReq) returns (rd.api.analytics.GetSalesByDayRes);
     */
    getSalesByDay(input: GetSalesByDayReq, options?: RpcOptions): UnaryCall<GetSalesByDayReq, GetSalesByDayRes> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetSalesByDayReq, GetSalesByDayRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetSalesByDayPDF(rd.api.analytics.GetSalesByDayPDFReq) returns (rd.api.analytics.GetSalesByDayPDFRes);
     */
    getSalesByDayPDF(input: GetSalesByDayPDFReq, options?: RpcOptions): UnaryCall<GetSalesByDayPDFReq, GetSalesByDayPDFRes> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetSalesByDayPDFReq, GetSalesByDayPDFRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetSalesByDayCSV(rd.api.analytics.GetSalesByDayCSVReq) returns (rd.api.analytics.GetSalesByDayCSVRes);
     */
    getSalesByDayCSV(input: GetSalesByDayCSVReq, options?: RpcOptions): UnaryCall<GetSalesByDayCSVReq, GetSalesByDayCSVRes> {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetSalesByDayCSVReq, GetSalesByDayCSVRes>("unary", this._transport, method, opt, input);
    }
}
/**
 * CxaSearchService is for cxa specific search endpoints. The search service is
 * seperate because the system needs to be cleanly isolated from the pos.
 *
 * @generated from protobuf service rd.search.service.CxaSearchService
 */
export interface ICxaSearchServiceClient {
    /**
     * @generated from protobuf rpc: CxaSearchSales(rd.search.service.CxaSearchSalesReq) returns (rd.search.service.CxaSearchSalesRes);
     */
    cxaSearchSales(input: CxaSearchSalesReq, options?: RpcOptions): UnaryCall<CxaSearchSalesReq, CxaSearchSalesRes>;
    /**
     * @generated from protobuf rpc: CxaSearchOutstandingSales(rd.search.service.CxaSearchOutstandingSalesReq) returns (rd.search.service.CxaSearchOutstandingSalesRes);
     */
    cxaSearchOutstandingSales(input: CxaSearchOutstandingSalesReq, options?: RpcOptions): UnaryCall<CxaSearchOutstandingSalesReq, CxaSearchOutstandingSalesRes>;
    /**
     * @generated from protobuf rpc: CxaSearchReturns(rd.search.service.CxaSearchReturnsReq) returns (rd.search.service.CxaSearchReturnsRes);
     */
    cxaSearchReturns(input: CxaSearchReturnsReq, options?: RpcOptions): UnaryCall<CxaSearchReturnsReq, CxaSearchReturnsRes>;
}
/**
 * CxaSearchService is for cxa specific search endpoints. The search service is
 * seperate because the system needs to be cleanly isolated from the pos.
 *
 * @generated from protobuf service rd.search.service.CxaSearchService
 */
export class CxaSearchServiceClient implements ICxaSearchServiceClient, ServiceInfo {
    typeName = CxaSearchService.typeName;
    methods = CxaSearchService.methods;
    options = CxaSearchService.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * @generated from protobuf rpc: CxaSearchSales(rd.search.service.CxaSearchSalesReq) returns (rd.search.service.CxaSearchSalesRes);
     */
    cxaSearchSales(input: CxaSearchSalesReq, options?: RpcOptions): UnaryCall<CxaSearchSalesReq, CxaSearchSalesRes> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<CxaSearchSalesReq, CxaSearchSalesRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CxaSearchOutstandingSales(rd.search.service.CxaSearchOutstandingSalesReq) returns (rd.search.service.CxaSearchOutstandingSalesRes);
     */
    cxaSearchOutstandingSales(input: CxaSearchOutstandingSalesReq, options?: RpcOptions): UnaryCall<CxaSearchOutstandingSalesReq, CxaSearchOutstandingSalesRes> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<CxaSearchOutstandingSalesReq, CxaSearchOutstandingSalesRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CxaSearchReturns(rd.search.service.CxaSearchReturnsReq) returns (rd.search.service.CxaSearchReturnsRes);
     */
    cxaSearchReturns(input: CxaSearchReturnsReq, options?: RpcOptions): UnaryCall<CxaSearchReturnsReq, CxaSearchReturnsRes> {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept<CxaSearchReturnsReq, CxaSearchReturnsRes>("unary", this._transport, method, opt, input);
    }
}
