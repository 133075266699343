/* eslint-disable */
// @generated by protobuf-ts 2.9.0 with parameter generate_dependencies,add_pb_suffix,ts_nocheck,eslint_disable
// @generated from protobuf file "analyticsv2/models.proto" (package "rd.analyticsv2", syntax proto3)
// tslint:disable
// @ts-nocheck
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { UUID } from "../proto/uuid/models_pb";
import { TimestampRange } from "../proto/timestamprange/models_pb";
/**
 * @generated from protobuf message rd.analyticsv2.Filters
 */
export interface Filters {
    /**
     * @generated from protobuf field: rd.analyticsv2.Filter timestamp_range_filter = 1;
     */
    timestampRangeFilter?: Filter;
    /**
     * @generated from protobuf field: rd.analyticsv2.Filter clerk_filter = 2;
     */
    clerkFilter?: Filter;
    /**
     * @generated from protobuf field: rd.analyticsv2.Filter rep_filter = 3;
     */
    repFilter?: Filter;
    /**
     * @generated from protobuf field: rd.analyticsv2.Filter location_filter = 4;
     */
    locationFilter?: Filter;
    /**
     * @generated from protobuf field: rd.analyticsv2.Filter product_filter = 5;
     */
    productFilter?: Filter;
    /**
     * @generated from protobuf field: rd.analyticsv2.Filter customer_filter = 6;
     */
    customerFilter?: Filter;
    /**
     * @generated from protobuf field: rd.analyticsv2.Filter vendor_filter = 7;
     */
    vendorFilter?: Filter;
    /**
     * @generated from protobuf field: rd.analyticsv2.Filter department_filter = 8;
     */
    departmentFilter?: Filter;
    /**
     * @generated from protobuf field: rd.analyticsv2.Filter class_filter = 9;
     */
    classFilter?: Filter;
    /**
     * @generated from protobuf field: rd.analyticsv2.Filter fineline_filter = 10;
     */
    finelineFilter?: Filter;
    /**
     * @generated from protobuf field: rd.analyticsv2.Filter account_filter = 11;
     */
    accountFilter?: Filter;
}
/**
 * @generated from protobuf message rd.analyticsv2.Filter
 */
export interface Filter {
    /**
     * @generated from protobuf field: rd.analyticsv2.Filter.Type type = 1;
     */
    type: Filter_Type;
    /**
     * @generated from protobuf field: rd.analyticsv2.Filter.RangeType range_type = 2;
     */
    rangeType: Filter_RangeType;
    /**
     * @generated from protobuf field: rd.proto.timestamprange.TimestampRange client_timestamp_range = 3;
     */
    clientTimestampRange?: TimestampRange;
    /**
     * @generated from protobuf field: string client_timezone = 4;
     */
    clientTimezone: string;
    /**
     * ID
     *
     * @generated from protobuf field: repeated rd.proto.uuid.UUID ids = 5;
     */
    ids: UUID[];
}
/**
 * @generated from protobuf enum rd.analyticsv2.Filter.Type
 */
export enum Filter_Type {
    /**
     * @generated from protobuf enum value: TYPE_UNDEFINED = 0;
     */
    UNDEFINED = 0,
    /**
     * @generated from protobuf enum value: TYPE_TIMESTAMP_RANGE = 1;
     */
    TIMESTAMP_RANGE = 1,
    /**
     * @generated from protobuf enum value: TYPE_ID = 2;
     */
    ID = 2
}
/**
 * @generated from protobuf enum rd.analyticsv2.Filter.RangeType
 */
export enum Filter_RangeType {
    /**
     * @generated from protobuf enum value: RANGE_TYPE_UNDEFINED = 0;
     */
    UNDEFINED = 0,
    /**
     * @generated from protobuf enum value: RANGE_TYPE_FULL = 1;
     */
    FULL = 1,
    /**
     * @generated from protobuf enum value: RANGE_TYPE_START_ONLY = 2;
     */
    START_ONLY = 2,
    /**
     * @generated from protobuf enum value: RANGE_TYPE_END_ONLY = 3;
     */
    END_ONLY = 3
}
/**
 * @generated from protobuf enum rd.analyticsv2.SalesByDaySortBy
 */
export enum SalesByDaySortBy {
    /**
     * @generated from protobuf enum value: SALES_BY_DAY_SORT_BY_UNDEFINED = 0;
     */
    UNDEFINED = 0,
    /**
     * @generated from protobuf enum value: SALES_BY_DAY_SORT_BY_DATE = 1;
     */
    DATE = 1,
    /**
     * @generated from protobuf enum value: SALES_BY_DAY_SORT_BY_SALES_COUNT = 2;
     */
    SALES_COUNT = 2,
    /**
     * @generated from protobuf enum value: SALES_BY_DAY_SORT_BY_SALES_TOTAL = 3;
     */
    SALES_TOTAL = 3,
    /**
     * @generated from protobuf enum value: SALES_BY_DAY_SORT_BY_RETURNS_COUNT = 4;
     */
    RETURNS_COUNT = 4,
    /**
     * @generated from protobuf enum value: SALES_BY_DAY_SORT_BY_RETURNS_TOTAL = 5;
     */
    RETURNS_TOTAL = 5,
    /**
     * @generated from protobuf enum value: SALES_BY_DAY_SORT_BY_REVENUE = 6;
     */
    REVENUE = 6,
    /**
     * @generated from protobuf enum value: SALES_BY_DAY_SORT_BY_COGS = 7;
     */
    COGS = 7,
    /**
     * @generated from protobuf enum value: SALES_BY_DAY_SORT_BY_GROSS_PROFIT = 8;
     */
    GROSS_PROFIT = 8,
    /**
     * @generated from protobuf enum value: SALES_BY_DAY_SORT_BY_GROSS_MARGIN = 9;
     */
    GROSS_MARGIN = 9
}
/**
 * @generated from protobuf enum rd.analyticsv2.WriteOffsByDaySortBy
 */
export enum WriteOffsByDaySortBy {
    /**
     * @generated from protobuf enum value: WRITE_OFFS_BY_DAY_SORT_BY_UNDEFINED = 0;
     */
    UNDEFINED = 0,
    /**
     * @generated from protobuf enum value: WRITE_OFFS_BY_DAY_SORT_BY_DATE_TIME = 1;
     */
    DATE_TIME = 1,
    /**
     * @generated from protobuf enum value: WRITE_OFFS_BY_DAY_SORT_BY_LOCATION = 2;
     */
    LOCATION = 2,
    /**
     * @generated from protobuf enum value: WRITE_OFFS_BY_DAY_SORT_BY_CLERK = 3;
     */
    CLERK = 3,
    /**
     * @generated from protobuf enum value: WRITE_OFFS_BY_DAY_SORT_BY_CUSTOMER_NAME = 4;
     */
    CUSTOMER_NAME = 4,
    /**
     * @generated from protobuf enum value: WRITE_OFFS_BY_DAY_SORT_BY_AMOUNT = 5;
     */
    AMOUNT = 5,
    /**
     * @generated from protobuf enum value: WRITE_OFFS_BY_DAY_SORT_BY_INVOICE_ALLOCATION = 6;
     */
    INVOICE_ALLOCATION = 6,
    /**
     * @generated from protobuf enum value: WRITE_OFFS_BY_DAY_SORT_BY_FINANCE_CHARGE_ALLOCATION = 7;
     */
    FINANCE_CHARGE_ALLOCATION = 7
}
/**
 * @generated from protobuf enum rd.analyticsv2.PaymentsByDaySortBy
 */
export enum PaymentsByDaySortBy {
    /**
     * @generated from protobuf enum value: PAYMENTS_BY_DAY_SORT_BY_UNDEFINED = 0;
     */
    UNDEFINED = 0,
    /**
     * @generated from protobuf enum value: PAYMENTS_BY_DAY_SORT_BY_DATE_TIME = 1;
     */
    DATE_TIME = 1,
    /**
     * @generated from protobuf enum value: PAYMENTS_BY_DAY_SORT_BY_LOCATION = 2;
     */
    LOCATION = 2,
    /**
     * @generated from protobuf enum value: PAYMENTS_BY_DAY_SORT_BY_PAYMENT_METHOD = 3;
     */
    PAYMENT_METHOD = 3,
    /**
     * @generated from protobuf enum value: PAYMENTS_BY_DAY_SORT_BY_TRANSACTION_TYPE = 4;
     */
    TRANSACTION_TYPE = 4,
    /**
     * @generated from protobuf enum value: PAYMENTS_BY_DAY_SORT_BY_CLERK = 5;
     */
    CLERK = 5,
    /**
     * @generated from protobuf enum value: PAYMENTS_BY_DAY_SORT_BY_CUSTOMER_NAME = 6;
     */
    CUSTOMER_NAME = 6,
    /**
     * @generated from protobuf enum value: PAYMENTS_BY_DAY_SORT_BY_AMOUNT = 7;
     */
    AMOUNT = 7,
    /**
     * @generated from protobuf enum value: PAYMENTS_BY_DAY_SORT_BY_CARD_FEES = 8;
     */
    CARD_FEES = 8
}
/**
 * @generated from protobuf enum rd.analyticsv2.PaymentsByDayTransactionType
 */
export enum PaymentsByDayTransactionType {
    /**
     * @generated from protobuf enum value: TRANSACTION_TYPE_UNDEFINED = 0;
     */
    TRANSACTION_TYPE_UNDEFINED = 0,
    /**
     * @generated from protobuf enum value: TRANSACTION_TYPE_SALE = 1;
     */
    TRANSACTION_TYPE_SALE = 1,
    /**
     * @generated from protobuf enum value: TRANSACTION_TYPE_RETURN = 2;
     */
    TRANSACTION_TYPE_RETURN = 2,
    /**
     * @generated from protobuf enum value: TRANSACTION_TYPE_BILL_PAYMENT = 3;
     */
    TRANSACTION_TYPE_BILL_PAYMENT = 3,
    /**
     * @generated from protobuf enum value: TRANSACTION_TYPE_BILL_PAYMENT_REFUND = 4;
     */
    TRANSACTION_TYPE_BILL_PAYMENT_REFUND = 4
}
/**
 * SortOrder represents the different ways a sort can be ordered, i.e.
 * ascending or descending
 *
 * @generated from protobuf enum rd.analyticsv2.SortOrder
 */
export enum SortOrder {
    /**
     * @generated from protobuf enum value: SORT_ORDER_NONE = 0;
     */
    NONE = 0,
    /**
     * @generated from protobuf enum value: SORT_ORDER_ASC = 1;
     */
    ASC = 1,
    /**
     * @generated from protobuf enum value: SORT_ORDER_DESC = 2;
     */
    DESC = 2
}
/**
 * @generated from protobuf enum rd.analyticsv2.SalesByCustomerSortBy
 */
export enum SalesByCustomerSortBy {
    /**
     * @generated from protobuf enum value: SALES_BY_CUSTOMER_SORT_BY_UNDEFINED = 0;
     */
    UNDEFINED = 0,
    /**
     * @generated from protobuf enum value: SALES_BY_CUSTOMER_SORT_BY_NAME = 1;
     */
    NAME = 1,
    /**
     * @generated from protobuf enum value: SALES_BY_CUSTOMER_SORT_BY_IDENTIFIER = 2;
     */
    IDENTIFIER = 2,
    /**
     * @generated from protobuf enum value: SALES_BY_CUSTOMER_SORT_BY_LAST_SALE_DATE = 3;
     */
    LAST_SALE_DATE = 3,
    /**
     * @generated from protobuf enum value: SALES_BY_CUSTOMER_SORT_BY_SALES_COUNT = 4;
     */
    SALES_COUNT = 4,
    /**
     * @generated from protobuf enum value: SALES_BY_CUSTOMER_SORT_BY_SALES_TOTAL = 5;
     */
    SALES_TOTAL = 5,
    /**
     * @generated from protobuf enum value: SALES_BY_CUSTOMER_SORT_BY_AVG_SALE_VALUE = 6;
     */
    AVG_SALE_VALUE = 6,
    /**
     * @generated from protobuf enum value: SALES_BY_CUSTOMER_SORT_BY_RETURNS_COUNT = 7;
     */
    RETURNS_COUNT = 7,
    /**
     * @generated from protobuf enum value: SALES_BY_CUSTOMER_SORT_BY_RETURNS_TOTAL = 8;
     */
    RETURNS_TOTAL = 8,
    /**
     * @generated from protobuf enum value: SALES_BY_CUSTOMER_SORT_BY_REVENUE = 9;
     */
    REVENUE = 9,
    /**
     * @generated from protobuf enum value: SALES_BY_CUSTOMER_SORT_BY_COGS = 10;
     */
    COGS = 10,
    /**
     * @generated from protobuf enum value: SALES_BY_CUSTOMER_SORT_BY_GROSS_PROFIT = 11;
     */
    GROSS_PROFIT = 11,
    /**
     * @generated from protobuf enum value: SALES_BY_CUSTOMER_SORT_BY_GROSS_MARGIN = 12;
     */
    GROSS_MARGIN = 12
}
// @generated message type with reflection information, may provide speed optimized methods
class Filters$Type extends MessageType<Filters> {
    constructor() {
        super("rd.analyticsv2.Filters", [
            { no: 1, name: "timestamp_range_filter", kind: "message", T: () => Filter },
            { no: 2, name: "clerk_filter", kind: "message", T: () => Filter },
            { no: 3, name: "rep_filter", kind: "message", T: () => Filter },
            { no: 4, name: "location_filter", kind: "message", T: () => Filter },
            { no: 5, name: "product_filter", kind: "message", T: () => Filter },
            { no: 6, name: "customer_filter", kind: "message", T: () => Filter },
            { no: 7, name: "vendor_filter", kind: "message", T: () => Filter },
            { no: 8, name: "department_filter", kind: "message", T: () => Filter },
            { no: 9, name: "class_filter", kind: "message", T: () => Filter },
            { no: 10, name: "fineline_filter", kind: "message", T: () => Filter },
            { no: 11, name: "account_filter", kind: "message", T: () => Filter }
        ]);
    }
    create(value?: PartialMessage<Filters>): Filters {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Filters>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Filters): Filters {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.analyticsv2.Filter timestamp_range_filter */ 1:
                    message.timestampRangeFilter = Filter.internalBinaryRead(reader, reader.uint32(), options, message.timestampRangeFilter);
                    break;
                case /* rd.analyticsv2.Filter clerk_filter */ 2:
                    message.clerkFilter = Filter.internalBinaryRead(reader, reader.uint32(), options, message.clerkFilter);
                    break;
                case /* rd.analyticsv2.Filter rep_filter */ 3:
                    message.repFilter = Filter.internalBinaryRead(reader, reader.uint32(), options, message.repFilter);
                    break;
                case /* rd.analyticsv2.Filter location_filter */ 4:
                    message.locationFilter = Filter.internalBinaryRead(reader, reader.uint32(), options, message.locationFilter);
                    break;
                case /* rd.analyticsv2.Filter product_filter */ 5:
                    message.productFilter = Filter.internalBinaryRead(reader, reader.uint32(), options, message.productFilter);
                    break;
                case /* rd.analyticsv2.Filter customer_filter */ 6:
                    message.customerFilter = Filter.internalBinaryRead(reader, reader.uint32(), options, message.customerFilter);
                    break;
                case /* rd.analyticsv2.Filter vendor_filter */ 7:
                    message.vendorFilter = Filter.internalBinaryRead(reader, reader.uint32(), options, message.vendorFilter);
                    break;
                case /* rd.analyticsv2.Filter department_filter */ 8:
                    message.departmentFilter = Filter.internalBinaryRead(reader, reader.uint32(), options, message.departmentFilter);
                    break;
                case /* rd.analyticsv2.Filter class_filter */ 9:
                    message.classFilter = Filter.internalBinaryRead(reader, reader.uint32(), options, message.classFilter);
                    break;
                case /* rd.analyticsv2.Filter fineline_filter */ 10:
                    message.finelineFilter = Filter.internalBinaryRead(reader, reader.uint32(), options, message.finelineFilter);
                    break;
                case /* rd.analyticsv2.Filter account_filter */ 11:
                    message.accountFilter = Filter.internalBinaryRead(reader, reader.uint32(), options, message.accountFilter);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Filters, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.analyticsv2.Filter timestamp_range_filter = 1; */
        if (message.timestampRangeFilter)
            Filter.internalBinaryWrite(message.timestampRangeFilter, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.analyticsv2.Filter clerk_filter = 2; */
        if (message.clerkFilter)
            Filter.internalBinaryWrite(message.clerkFilter, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* rd.analyticsv2.Filter rep_filter = 3; */
        if (message.repFilter)
            Filter.internalBinaryWrite(message.repFilter, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* rd.analyticsv2.Filter location_filter = 4; */
        if (message.locationFilter)
            Filter.internalBinaryWrite(message.locationFilter, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* rd.analyticsv2.Filter product_filter = 5; */
        if (message.productFilter)
            Filter.internalBinaryWrite(message.productFilter, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* rd.analyticsv2.Filter customer_filter = 6; */
        if (message.customerFilter)
            Filter.internalBinaryWrite(message.customerFilter, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* rd.analyticsv2.Filter vendor_filter = 7; */
        if (message.vendorFilter)
            Filter.internalBinaryWrite(message.vendorFilter, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* rd.analyticsv2.Filter department_filter = 8; */
        if (message.departmentFilter)
            Filter.internalBinaryWrite(message.departmentFilter, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* rd.analyticsv2.Filter class_filter = 9; */
        if (message.classFilter)
            Filter.internalBinaryWrite(message.classFilter, writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        /* rd.analyticsv2.Filter fineline_filter = 10; */
        if (message.finelineFilter)
            Filter.internalBinaryWrite(message.finelineFilter, writer.tag(10, WireType.LengthDelimited).fork(), options).join();
        /* rd.analyticsv2.Filter account_filter = 11; */
        if (message.accountFilter)
            Filter.internalBinaryWrite(message.accountFilter, writer.tag(11, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.analyticsv2.Filters
 */
export const Filters = new Filters$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Filter$Type extends MessageType<Filter> {
    constructor() {
        super("rd.analyticsv2.Filter", [
            { no: 1, name: "type", kind: "enum", T: () => ["rd.analyticsv2.Filter.Type", Filter_Type, "TYPE_"] },
            { no: 2, name: "range_type", kind: "enum", T: () => ["rd.analyticsv2.Filter.RangeType", Filter_RangeType, "RANGE_TYPE_"] },
            { no: 3, name: "client_timestamp_range", kind: "message", T: () => TimestampRange },
            { no: 4, name: "client_timezone", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "ids", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UUID }
        ]);
    }
    create(value?: PartialMessage<Filter>): Filter {
        const message = { type: 0, rangeType: 0, clientTimezone: "", ids: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Filter>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Filter): Filter {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.analyticsv2.Filter.Type type */ 1:
                    message.type = reader.int32();
                    break;
                case /* rd.analyticsv2.Filter.RangeType range_type */ 2:
                    message.rangeType = reader.int32();
                    break;
                case /* rd.proto.timestamprange.TimestampRange client_timestamp_range */ 3:
                    message.clientTimestampRange = TimestampRange.internalBinaryRead(reader, reader.uint32(), options, message.clientTimestampRange);
                    break;
                case /* string client_timezone */ 4:
                    message.clientTimezone = reader.string();
                    break;
                case /* repeated rd.proto.uuid.UUID ids */ 5:
                    message.ids.push(UUID.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Filter, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.analyticsv2.Filter.Type type = 1; */
        if (message.type !== 0)
            writer.tag(1, WireType.Varint).int32(message.type);
        /* rd.analyticsv2.Filter.RangeType range_type = 2; */
        if (message.rangeType !== 0)
            writer.tag(2, WireType.Varint).int32(message.rangeType);
        /* rd.proto.timestamprange.TimestampRange client_timestamp_range = 3; */
        if (message.clientTimestampRange)
            TimestampRange.internalBinaryWrite(message.clientTimestampRange, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* string client_timezone = 4; */
        if (message.clientTimezone !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.clientTimezone);
        /* repeated rd.proto.uuid.UUID ids = 5; */
        for (let i = 0; i < message.ids.length; i++)
            UUID.internalBinaryWrite(message.ids[i], writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.analyticsv2.Filter
 */
export const Filter = new Filter$Type();
