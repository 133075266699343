import {
  OrderShipment_Status as OrderShipmentStatus,
  Order_Status as OrderStatus,
  Transfer_Status as TransferStatus,
} from "gen/txn/models_pb"

import { UUID as PBUUID } from "gen/proto/uuid/models_pb"
import { Sale_Status as SaleStatus } from "gen/txn/models_pb"
import { UUID } from "uuid-rd"

function cleanStatus(s: string): string {
  return s.replaceAll("_", "-").toLowerCase()
}

export function getOrderUrl(id?: PBUUID, status?: OrderStatus) {
  if (!id) {
    return ""
  }

  const statusString = cleanStatus(OrderStatus[status ?? OrderStatus.CREATED])
  return `/pos/purchase-orders/${UUID.fromPB(id).toString()}?status=${statusString}`
}

export function getOrderShipmentUrl(id?: PBUUID, status?: OrderShipmentStatus) {
  if (!id) {
    return ""
  }
  const statusString = cleanStatus(
    OrderShipmentStatus[status ?? OrderShipmentStatus.RECEIVED]
  )
  return `/pos/purchase-orders/${UUID.fromPB(id).toString()}?status=${statusString}`
}

export function getSaleURL(id?: PBUUID, status?: SaleStatus): string {
  if (!id) {
    return ""
  }

  if (!status) {
    return `/pos/sales/${UUID.fromPB(id).toString()}`
  }

  const statusString = cleanStatus(SaleStatus[status ?? SaleStatus.SOLD])
  return `/pos/sales/${UUID.fromPB(id).toString()}?status=${statusString}`
}

export function getBillURL(id?: PBUUID, isRefund?: boolean): string {
  if (!id) {
    return ""
  }
  const param = isRefund ? "refunded" : "paid"
  return `/pos/bills/${UUID.fromPB(id).toString()}?status=${param}`
}

export function getCustomerURL(id?: PBUUID): string {
  if (!id) {
    return ""
  }

  return `/pos/customers/${UUID.fromPB(id).toString()}`
}

export function getProductURL(id?: PBUUID): string {
  if (!id) {
    return ""
  }
  return `/pos/products/${UUID.fromPB(id).toString()}`
}

export function getReturnURL(
  saleId?: PBUUID,
  returnIdentifier?: string
): string {
  if (!saleId) {
    return ""
  }

  if (!returnIdentifier) {
    return ""
  }

  return `/pos/sales/${UUID.fromPB(saleId).toString()}/${returnIdentifier}?status=returns`
}

export function getInventoryCountURL(id?: PBUUID): string {
  if (!id) {
    return ""
  }
  return `/pos/counts/${UUID.idString(id)}`
}

export function getTransferURL(id?: PBUUID, status?: TransferStatus): string {
  if (!id) {
    return ""
  }
  if (!status) {
    return `/pos/transfers/${UUID.idString(id)}`
  }
  const statusString = cleanStatus(TransferStatus[status])
  return `/pos/transfers/${UUID.idString(id)}?status=${statusString}`
}
