import Router, { NextRouter } from "next/router"
import { fixParsedId, stringify } from "lib/stringify"
import { formatISO, isValid, parseISO } from "date-fns"

import { Location } from "gen/company/models_pb"
import _ from "lodash"
import { encode } from "querystring"

export function clearParams(router: NextRouter) {
  const newQuery = router.query
  for (const k in router.query) {
    delete newQuery[k]
  }
  Router.push(router, undefined, { shallow: true })
}

export function pushParam(
  router: NextRouter,
  paramName: string,
  value: string
) {
  if (value) {
    router.query = {
      ...router.query,
      [paramName]: value,
    }
    Router.push(router, undefined, { shallow: true })
    return
  }

  if (router.query[paramName] === undefined) {
    return
  }

  delete router.query[paramName]
  Router.push(router, undefined, { shallow: true })
}

// TODO(rkofman): this likely belongs in lib/timestamp.ts; similar to
// `fromQueryParam` in lib/timestamp_range.ts
export function queryParamsToDate(
  dateParam: string | string[] | undefined
): Date | null {
  if (dateParam && typeof dateParam === "string") {
    const d = parseISO(decodeURIComponent(dateParam))
    if (!isValid(d)) {
      return null
    }
    return d
  }

  return null
}

// TODO(rkofman): this likely belongs in lib/timestamp.ts; similar to
// `toQueryParam` in lib/timestamp_range.ts
export function dateToQueryParams(d: Date | null): string {
  if (!d) {
    return ""
  }

  return encodeURIComponent(formatISO(d))
}

/**
 * @deprecated The URL has length limits, and URL encoding entire objects should
 * be avoided.  Instead, use an identifier.
 */
export function queryParamsToLocation(
  locationParam: string | string[] | undefined
): Location | null {
  if (locationParam && typeof locationParam === "string") {
    const decodedLocation = decodeURIComponent(locationParam)
    const parsedLocation = JSON.parse(decodedLocation)
    parsedLocation.id = fixParsedId(parsedLocation.id)
    return parsedLocation
  }

  return null
}

/**
 * @deprecated The URL has length limits, and URL encoding entire objects should
 * be avoided.  Instead, use an identifier.
 */
export function locationToQueryParams(location: Location | null): string {
  if (!location) {
    return ""
  }

  return encodeURIComponent(stringify(location))
}

/**
 * Wrapper around `Router.replace(...{shallow:true})` that removes empty params
 * and merges values with a comma.
 *
 * Note: parameter values are expected to be simple, and not include url-encoded
 * entities like commas.
 *
 * TODO(rkofman): refactor this method's behavior to combine with `pushParam`,
 * above. Currently blocked because `pushParam` is expected to work with complex
 * serialized data - an expectation we should remove before combining.
 *
 * @param query New query params. Lists will be joined with a comma `,`. e.g.
 *   `{foo: ["1", "two"]}` will become `"?foo=1,two"`
 * @returns If route change is cancelled by Next.js, the promise will resolve
 *   successfully but with a false value.
 */
export function replaceParams(
  query: Readonly<{ [key: string]: string | string[] }> = {}
): Promise<boolean> {
  const queryStr = buildParams(query)
  return Router.replace("?" + queryStr, undefined, {
    shallow: true,
  })
}

export function buildParams(
  query: Readonly<{ [key: string]: string | string[] }> = {}
): string {
  const newQuery = { ...query }
  for (const k in newQuery) {
    const val = newQuery[k]
    if (Array.isArray(val)) {
      // Avoids `foo=1&foo=2` syntax in favor of `foo=1,2`
      newQuery[k] = _(val)
        .filter((v) => !!v)
        .join(",")
    }
    // Cleans up empty params to avoid extraneous `&foo=` or `&`
    if (!newQuery[k]) delete newQuery[k]
  }
  // Default encoding is a little too aggressive. Let's put back the commas:
  return encode(newQuery).replaceAll("%2C", ",")
}
