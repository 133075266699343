import { UUID as PBUUID } from "gen/proto/uuid/models_pb"
import { UUID } from "uuid-rd"

export const REPORT_PARAM = "report"
export const END_DATE_PARAM = "end"
export const DATE_RANGE_PARAM = "dateRange"
export const SORT_PARAM = "sort"
export const SORT_ORDER_PARAM = "sortOrder"
export const LOCATION_PARAM = "location"
export const CLERK_PARAM = "clerk"
export const REP_PARAM = "rep"
export const CUSTOMER_PARAM = "customer"
export const PRODUCT_PARAM = "product"
export const PARAM_DELIMITER = ","
export const START_DATE_PARAM = "start"
export const VENDOR_PARAM = "vendor"
export const DEPARTMENT_PARAM = "department"
export const CLASS_PARAM = "class"
export const FINELINE_PARAM = "fineline"
export const ACCOUNT_PARAM = "account"

export function joinParamters(ids: PBUUID[]): string {
  return ids.map((id) => UUID.fromPB(id).toString()).join(PARAM_DELIMITER)
}
