import { Combobox, ComboboxProps } from "components/Combobox"

import { UUID } from "uuid-rd"
import { Vendor } from "gen/vendors/models_pb"
import { useListVendorsForCompanyQuery } from "features/api/vendor"

type VendorComboboxProps = Omit<
  ComboboxProps<Vendor>,
  "items" | "keyExtractor" | "valueExtractor"
>

export function VendorCombobox(props: VendorComboboxProps) {
  const { data: listProductVendorsData } = useListVendorsForCompanyQuery({
    filterActive: true,
  })

  const items = [...(listProductVendorsData?.vendors ?? [])].sort((a, b) =>
    a.name.localeCompare(b.name)
  )

  return (
    <Combobox<Vendor>
      items={items}
      keyExtractor={(vendor) =>
        vendor.id ? UUID.fromPB(vendor.id).toString() : ""
      }
      valueExtractor={(vendor) => vendor.name}
      {...props}
    />
  )
}
